import {
	HStack,
	Table,
	Tbody,
	Td,
	Box,
	Tr,
	Th,
	Thead,
	useBreakpointValue,
	Stack,
	IconButton,
	Flex,
	Heading,
	useToast,
	AlertDialog,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogCloseButton,
	AlertDialogBody,
	AlertDialogOverlay,
	AlertDialogFooter,
	useDisclosure,
	Button,
	Text,
} from "@chakra-ui/react";
import { useState, useRef } from "react";
import { FiTrash } from "react-icons/fi";
import { TbActions, TbAdd, TbDelete } from "../../../../icons/Icons";
import { async } from "q";
import ApiService from "../../../../service/Api.service";
import { AddEventModal } from "../AddEventModal/AddEventModal";
import { data } from "./data";
import { EditEventModal } from "../EditEventModal/EditEventModal";
import { ShimmerTable } from "react-shimmer-effects-18";
import { useAuthProvider } from "../../../../context/AuthProvider";

export const TournamentEventDetailsTable = (props) => {
	// User Details
	const user = useAuthProvider();

	let eventTypesData = props.eventTypesData;
	let allFormats = props.allFormats;
	let setEventTypesData = props.setEventTypesData;
	let setAllFormats = props.setAllFormats;

	//Desktop BreakPoint Values
	const isDesktop = useBreakpointValue({ base: false, xl: true });

	const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();
	const cancelRef = useRef();

	// Toast
	let toast = useToast();

	let [currEvent, setCurrEvent] = useState({});

	let events = props.eventData;
	//let loading = props.loadingEventTable;
	let loadingLookups = props.loadingLookups;

	let eventsReload = props.eventsReload;
	let setEventsReload = props.setEventsReload;
	const [loading, setLoading] = useState(false);
	let handleDelete = async () => {
		try {
			setLoading(true);
			let deleteResponse = await ApiService.httpDelete(
				`/TourEvents?TourEventId=${currEvent.TourEventId}`
			);
			console.log("Deleted Event: ", deleteResponse);
			if (deleteResponse.status === true) {
				onAlertClose();
				toast({
					title: "Delete Event",
					description: "Event Deleted Successfully!",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				setEventsReload(!eventsReload);
				console.log("Event Reloaded...");
			} else {
				onAlertClose();
				toast({
					title: "Delete Event",
					description: "Failed to Delete Event",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
		} catch (err) {
			console.log(err);
			onAlertClose();
			toast({
				title: "Delete Event",
				description: "Failed to Delete Event",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
		} finally {
			setLoading(false);
		}
	};

	let closeModal = async () => {
		try {
			setLoading(true);
			const response = await ApiService.httpGet(
				"/TourEvents?TourId=" + props.tournamentId,
				user.userId
			);
			events = response.Events;
			console.log("Tournament Events Table", response.Events);
			props.refreshView();
			setLoading(false);
		} catch (err) {
			console.error(err.message);
		} finally {
			setLoading(false);
		}
	};

	const tableDesktop = () => (
		<>
			<Box>
				<Stack>
					<Flex justify="space-between" px="1rem">
						<Heading as="h3" size="xs" m={0}>
							Event and Fee Details
						</Heading>
						<AddEventModal
							tourId={props.tournamentId}
							onCloseModal={closeModal}
							loading={loading}
							eventTypesData={eventTypesData}
							setEventTypesData={setEventTypesData}
							allFormats={allFormats}
							setAllFormats={setAllFormats}
						/>
					</Flex>

					<Box
						borderRadius={"2xl"}
						overflow="auto"
						boxShadow={"sm"}
						maxHeight="md"
						css={{
							"::-webkit-scrollbar": {
								width: "0.3rem",
								height: "0.3rem",
							},
							"::-webkit-scrollbar-track": {
								background: "#bee3f8",
								borderRadius: "1rem",
							},
							"::-webkit-scrollbar-thumb": {
								background: "#3182ce",
								borderRadius: "1rem",
							},
						}}>
						{loading ? (
							<ShimmerTable row={4} col={6} />
						) : (
							<Table variant="striped">
								<Thead position="sticky" top={0} bgColor="black" zIndex="overlay">
									<Tr>
										<Th
											textColor="white"
											fontSize="md"
											textAlign="start"
											py={5}>
											Event Name
										</Th>
										<Th textColor="white" fontSize="md" textAlign="start">
											Event Type
										</Th>
										<Th textColor="white" fontSize="md" textAlign="start">
											Groups
										</Th>
										<Th textColor="white" fontSize="md" textAlign="start">
											Rounds
										</Th>
										<Th textColor="white" fontSize="md" textAlign="start">
											Knockouts
										</Th>
										<Th textColor="white" fontSize="md" textAlign="start">
											Format
										</Th>
										<Th textColor="white" fontSize="md" textAlign="start">
											Actions
										</Th>
									</Tr>
								</Thead>
								<Tbody>
									{events.map((event, index) => (
										<Tr key={event.TourEventId}>
											<Td textAlign="start" fontSize="lg" fontWeight="bold">
												{event.TourEventName}
											</Td>
											<Td textAlign="start" fontSize="lg" fontWeight="bold">
												{event.EventType}
											</Td>
											<Td textAlign="start" fontSize="lg" fontWeight="bold">
												{event.Groups}
											</Td>
											<Td textAlign="start" fontSize="lg" fontWeight="bold">
												{event.NoOfRounds}
											</Td>
											<Td textAlign="start" fontSize="lg" fontWeight="bold">
												{event.knockouts}
											</Td>
											<Td textAlign="start" fontSize="lg" fontWeight="bold">
												{event.FormatName}
											</Td>
											<Td textAlign="start" fontSize="lg" fontWeight="bold">
												<HStack justify="center">
													<EditEventModal
														eventData={props.eventData}
														index={index}
														onCloseModal={closeModal}
														tourId={props.tourId}
														eventTypesData={eventTypesData}
														setEventTypesData={setEventTypesData}
														allFormats={allFormats}
														setAllFormats={setAllFormats}
														loadingLookups={loadingLookups}
													/>
													<IconButton
														icon={<FiTrash />}
														onClick={() => {
															setCurrEvent(event);
															onAlertOpen();
														}}
														color="red"
														bgColor="red.100"
														_hover={{ bgColor: "red.200" }}
														_active={{ bgColor: "red.300" }}
													/>
												</HStack>
											</Td>
										</Tr>
									))}
								</Tbody>
							</Table>
						)}
					</Box>
				</Stack>
			</Box>

			<AlertDialog
				motionPreset="slideInBottom"
				leastDestructiveRef={cancelRef}
				onClose={() => {
					setCurrEvent({});
					onAlertClose();
				}}
				isOpen={isAlertOpen}
				isCentered>
				<AlertDialogOverlay />
				<AlertDialogContent>
					<AlertDialogHeader>Delete Event</AlertDialogHeader>
					<AlertDialogCloseButton />
					<AlertDialogBody>
						Are you sure you want to delete{" "}
						<Text as={"span"} fontWeight="bold">
							{currEvent?.TourEventName}
						</Text>{" "}
						Event ?
					</AlertDialogBody>
					<AlertDialogFooter>
						{loading ? null : (
							<Button
								ref={cancelRef}
								onClick={() => {
									setCurrEvent({});
									onAlertClose();
								}}>
								Cancel
							</Button>
						)}
						<Button colorScheme="red" ml={3} onClick={handleDelete} isLoading={loading}>
							Delete
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
		</>
	);

	// const tableMobile = () => (
	// 	<Container
	// 		bgColor="white"
	// 		boxShadow={"sm"}
	// 		borderRadius="lg"
	// 		py="1rem"
	// 		px="1.5rem">
	// 		<Stack divider={<Divider />} spacing="1rem">
	// 			<Stack spacing={5} divider={<Divider />} fontSize="lg">
	// 				<HStack justify="space-between">
	// 					<Text fontWeight="bold">Event Name</Text>
	// 					<Text>{data[0].eventname}</Text>
	// 				</HStack>
	// 				<HStack justify="space-between">
	// 					<Text fontWeight="bold">Event Type</Text>
	// 					<Text>{data[0].eventtype}</Text>
	// 				</HStack>
	// 				<Stack>
	// 					<HStack justify="space-between">
	// 						<Text fontWeight="bold">Groups</Text>
	// 						<Text>{data[0].groups}</Text>
	// 					</HStack>
	// 					<HStack justify="space-between">
	// 						<Text fontWeight="bold">Rounds</Text>
	// 						<Text>{data[0].rounds}</Text>
	// 					</HStack>
	// 					<HStack justify="space-between">
	// 						<Text fontWeight="bold">Knockouts</Text>
	// 						<Text>{data[0].knockouts}</Text>
	// 					</HStack>
	// 					<HStack justify="space-between">
	// 						<Text fontWeight="bold">Format</Text>
	// 						<Text>{data[0].format}</Text>
	// 					</HStack>
	// 				</Stack>
	// 				<HStack justify="center">
	// 					<IconButton
	// 						borderRadius="full"
	// 						bgColor="green"
	// 						_hover={{ bgColor: "green" }}
	// 						icon={<TbAdd />}
	// 					/>
	// 					<IconButton
	// 						borderRadius="full"
	// 						bgColor="red"
	// 						_hover={{ bgColor: "red" }}
	// 						icon={<TbDelete />}
	// 					/>
	// 				</HStack>
	// 			</Stack>
	// 			<Flex justify="center">
	// 				<AddEventModal />
	// 			</Flex>
	// 		</Stack>
	// 	</Container>
	// );

	return tableDesktop();
};

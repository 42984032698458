import { DownloadIcon } from "@chakra-ui/icons";
import {
	Card,
	CardHeader,
	IconButton,
	Icon,
	Input,
	InputGroup,
	InputLeftElement,
	Stack,
	Text,
	useBreakpointValue,
	useColorModeValue,
	Menu,
	MenuList,
	MenuItem,
	MenuButton,
	Button,
	CloseButton,
	useDisclosure,
	Modal,
	ModalHeader,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	FormControl,
	FormLabel,
	ModalFooter,
	useToast,
} from "@chakra-ui/react";
import { useRef } from "react";
import { BsDownload, BsUpload } from "react-icons/bs";
import { FiDownloadCloud, FiSearch, FiUploadCloud, FiSend, FiMoreVertical } from "react-icons/fi";
import { HiDownload, HiUpload } from "react-icons/hi";
import { TbSend } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ApiService from "../../service/Api.service";
import { CSVLink } from "react-csv";
import { BlobServiceClient } from "@azure/storage-blob";
import { saveAs } from "file-saver";
import uploadXlsFile from "../Upload/uploadXlsFile";
import { useAuthProvider } from "../../context/AuthProvider";

const headers = [
	{ label: "Player Id", key: "TE_PlayerId" },
	{ label: "Player Name", key: "PlayerName" },
	{ label: "Nick Name", key: "NickName" },
	{ label: "Event", key: "TourEventName" },
	{ label: "Mobile", key: "MobileNumber" },
	{ label: "Email", key: "Email" },
	{ label: "Rank", key: "Rank" },
	{ label: "Live", key: "Live" },
];

export const SinglesTabCard = (props) => {
	// User Details
	const user = useAuthProvider();

	//Box Shadow
	const shadow = useColorModeValue("sm", "sm-dark");

	const { isOpen: isSendMsgOpen, onOpen: openSendMsg, onClose: closeSendMsg } = useDisclosure();

	const { isOpen: isUploadOpen, onOpen: openUpload, onClose: closeUpload } = useDisclosure();

	const toast = useToast();

	let setQuery = props.setQuery;
	let EventId = props.state.EventId;
	let EventType = props.state.EventType;
	let [eventSinglesData, setEventSinglesData] = useState([]);
	let [message, setMessage] = useState("");
	const [downloadLoading, setDownloadLoading] = useState(false);
	const [menuLoading, setMenuLoading] = useState(false);
	const [messageLoading, setMessageLoading] = useState(false);
	const csvLinkEl = useRef();
	const fetchSingles = async () => {
		try {
			setDownloadLoading(true);
			console.log("EventId", EventId);
			let response = await ApiService.httpGet(
				"/FileUtilities?Class=SinglesPlayers&EventId=" + EventId,
				user.userId
			);
			console.log("Singles Download Data", response);
			setEventSinglesData(response.SinglesPlayersJson);
			setTimeout(() => {
				csvLinkEl.current.link.click();
				setDownloadLoading(false);
			});
			toast({
				title: "Singles Players Download",
				description: "Singles Players Downloaded Successfully",
				status: "success",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
		} catch (err) {
			console.error(err.message);
			setDownloadLoading(false);
			toast({
				title: "Download Failed",
				description: err.message,
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
		}
	};

	const notificationHandler = async (event) => {
		console.log("Action performed for:", event.target.value);
		let notification = event.target.value;
		switch (notification) {
			case "sendMessage":
				openSendMsg();
				console.log("Opened Modal");
				break;
			case "sendWelcome":
				try {
					setMenuLoading(true);
					let welcomeRespose = await ApiService.httpPost(
						`/ScoreLink?EventId=${EventId}&Class=Welcome`,
						{}
					);
					console.log("Sent Message:", welcomeRespose);
					if (welcomeRespose.status === true) {
						toast({
							title: "Send Welcome Message",
							description: "Welcome Message Sent Successfully!",
							status: "success",
							duration: 3000,
							isClosable: true,
							position: "top-right",
						});
					} else {
						toast({
							title: "Send Welcome Message",
							description: "Failed to Send Welcome Message",
							status: "error",
							duration: 3000,
							isClosable: true,
							position: "top-right",
						});
					}
					setMenuLoading(false);
				} catch (err) {
					console.log(err);
					toast({
						title: "Send Welcome Message",
						description: "Failed to Send Welcome Message",
						status: "error",
						duration: 3000,
						isClosable: true,
						position: "top-right",
					});
					setMenuLoading(false);
				}
				break;
			case "sendPlayers":
				try {
					setMenuLoading(true);
					let sentPlayersRes = await ApiService.httpPost(
						`/ScoreLink?EventId=${EventId}&Class=Players`,
						{}
					);
					console.log("Sent Message:", sentPlayersRes);
					if (sentPlayersRes) {
						toast({
							title: "Send Players Message",
							description: "Players Message Sent Successfully!",
							status: "success",
							duration: 3000,
							isClosable: true,
							position: "top-right",
						});
					} else {
						toast({
							title: "Send Players Message",
							description: "Failed to Send Players Message",
							status: "error",
							duration: 3000,
							isClosable: true,
							position: "top-right",
						});
					}
					setMenuLoading(false);
				} catch (err) {
					console.log(err);
					toast({
						title: "Send Players Message",
						description: "Failed to Send Players Message",
						status: "error",
						duration: 3000,
						isClosable: true,
						position: "top-right",
					});
					setMenuLoading(false);
				}
				break;
		}
	};

	const sendMessage = async () => {
		try {
			setMessageLoading(true);
			setMenuLoading(true);
			let msgRespose = await ApiService.httpPost(
				`/ScoreLink?EventId=${EventId}&Class=CustomMessage`,
				{ text: message }
			);
			console.log("Sent Message:", msgRespose);
			if (msgRespose.status === true) {
				toast({
					title: "Send Message",
					description: "Message Sent Successfully!",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			} else {
				toast({
					title: "Send Message",
					description: "Failed to Send Message",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
			closeSendMsg();
		} catch (err) {
			console.log(err);
			toast({
				title: "Send Message",
				description: "Failed to Send Message",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
		} finally {
			setMessageLoading(false);
			setMenuLoading(false);
		}
	};

	// const downloadBlob = async () => {
	// 	const blobServiceClient = new BlobServiceClient(connectionString);
	// 	const containerClient = blobServiceClient.getContainerClient(containerName);

	// 	const folderPath = "SinglesPlayers/";
	// 	const blobName = folderPath + "SinglesPlayers.xlsx";

	// 	const blobClient = containerClient.getBlobClient(blobName);
	// 	await blobClient.download().then(response => {
	// 		const blob = response.blobBody;
	// 		saveAs(blob, 'SinglesPlayers.xlsx');
	// 	})

	// 	console.log(`download of ${blobName} success`);
	// };

	// State for File that needs to be uploaded
	let [file, setFile] = useState(null);

	// setFile that needs to be uploaded
	const handleFileChange = (event) => {
		console.log(event.target.files);
		setFile(event.target.files[0]);
		console.log("File selected");
	};
	const folderName = "SinglesPlayers";
	const fileName = "SinglesPlayers";
	// Upload the file to Azure Blob
	let { handleUpload } = uploadXlsFile(file, toast, closeUpload, folderName, fileName);

	return (
		<>
			<Card variant={"elevated"} bgColor="white" boxShadow={shadow}>
				<CardHeader>
					<Stack
						direction={useBreakpointValue({
							base: "column",
							md: "row",
						})}
						spacing="1.2rem"
						justify="space-between">
						<Stack direction="column" spacing=".7rem">
							<Text
								as="h3"
								fontSize={useBreakpointValue({
									base: "lg",
									md: "xl",
								})}
								fontWeight="semibold">
								Singles Players
							</Text>
							<InputGroup maxW="xs" colorScheme="blue">
								<InputLeftElement pointerEvents="none">
									<Icon as={FiSearch} color="accent" boxSize="5" />
								</InputLeftElement>
								<Input
									w="100vw"
									placeholder="Search"
									onChange={(e) => setQuery(e.target.value)}
								/>
							</InputGroup>
						</Stack>
						<Stack direction="row" align="end" justify="flex-end" spacing=".7rem">
							<IconButton
								variant="ghost"
								size="md"
								color="#3182ce"
								icon={<FiUploadCloud size="1.7rem" />}
								onClick={openUpload}
							/>
							<IconButton
								variant="ghost"
								size="md"
								color="#3182ce"
								icon={<FiDownloadCloud size="1.7rem" />}
								isLoading={downloadLoading}
								onClick={fetchSingles}
							/>
							<CSVLink
								headers={headers}
								filename={"SinglesPlayers.csv"}
								data={eventSinglesData}
								asyncOnClick={true}
								ref={csvLinkEl}
							/>
							<Menu direction="rtl">
								{({ isOpen }) => (
									<>
										<MenuButton
											isActive={isOpen}
											as={IconButton}
											variant="ghost"
											size="md"
											color="green.500"
											isLoading={menuLoading}
											icon={
												isOpen ? (
													<CloseButton />
												) : (
													<FiMoreVertical size="1.7rem" />
												)
											}
										/>
										<MenuList zIndex="overlay">
											<MenuItem
												textColor="green.400"
												fontWeight="medium"
												value="sendMessage"
												onClick={notificationHandler}>
												Send Message
											</MenuItem>
											<MenuItem
												textColor="green.400"
												fontWeight="medium"
												value="sendWelcome"
												onClick={notificationHandler}>
												Send Welcome
											</MenuItem>
											<MenuItem
												textColor="green.400"
												fontWeight="medium"
												value="sendPlayers"
												onClick={notificationHandler}>
												Send Players
											</MenuItem>
										</MenuList>
									</>
								)}
							</Menu>
						</Stack>
					</Stack>
				</CardHeader>
			</Card>
			<>
				<Modal isOpen={isSendMsgOpen} onClose={closeSendMsg}>
					<ModalOverlay />
					<ModalContent>
						<ModalHeader>Send Message</ModalHeader>
						<ModalCloseButton />
						<ModalBody pb={6}>
							<FormControl>
								<FormLabel>Custom Message</FormLabel>
								<Input
									placeholder="Enter message"
									onChange={(e) => setMessage(e.target.value)}
								/>
							</FormControl>
						</ModalBody>

						<ModalFooter>
							<Button
								colorScheme="blue"
								mr={3}
								onClick={sendMessage}
								isDisabled={message < 1}
								isLoading={messageLoading}>
								Send Message
							</Button>
							<Button onClick={closeSendMsg}>Cancel</Button>
						</ModalFooter>
					</ModalContent>
				</Modal>
				<Modal isOpen={isUploadOpen} onClose={closeUpload}>
					<ModalOverlay />
					<ModalContent>
						<ModalHeader>Upload</ModalHeader>
						<ModalCloseButton />
						<ModalBody pb={6}>
							<Button>Generate Template</Button>
							&emsp;
							<Button>Download</Button>
							<input
								type={"file"}
								name={"fileUpload"}
								accept=".xlsx, .xls"
								onChange={handleFileChange}
							/>
							<Button onClick={handleUpload}>Upload</Button>
						</ModalBody>
					</ModalContent>
				</Modal>
			</>
		</>
	);
};

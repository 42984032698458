import {
	HStack,
	Table,
	Tbody,
	Td,
	Box,
	Tr,
	Th,
	Thead,
	useBreakpointValue,
	Divider,
	IconButton,
	Tooltip,
	FormControl,
	Select,
	Text,
	Button,
	ButtonGroup,
	useToast,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalBody,
	ModalFooter,
	ModalHeader,
	useDisclosure,
	useColorModeValue,
	AlertDialog,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogCloseButton,
	AlertDialogBody,
	AlertDialogOverlay,
	AlertDialogFooter,
	ModalCloseButton,
	Stack,
	Center,
	Skeleton,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { MdAssignmentInd } from "react-icons/md";
import ApiService from "../../../service/Api.service";
import usePagination from "../../../hooks/usePagination";
import { Pagination } from "../../Pagination/Pagination";
import _ from "lodash";

export const PlayerSelectionTable = ({
	selectedGroup,
	plyrSelData,
	rounds,
	plyrSelPositions,
	setPlyrSelPositions,
	refreshPlyrSelection,
	navigateToTeamMatchups,
	latestRoundPlyrSelection,
	eventId,
	plyrSelLoading,
	plyrSelModalLoad,
	fetchFlags,
}) => {
	const isDesktop = useBreakpointValue({ base: false, xl: true });
	const isMobile = useBreakpointValue({
		base: true,
		md: false,
	});

	let toast = useToast();

	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isMatchOpen, onOpen: onMatchOpen, onClose: onMatchClose } = useDisclosure();

	const { isOpen: isPlayerOpen, onOpen: onPlayerOpen, onClose: onPlayerClose } = useDisclosure();
	const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();
	const cancelRef = useRef();

	const shadow = useColorModeValue("sm", "sm-dark");

	const [initialPlayers, setInitialPlayers] = useState([]);
	let [selectedPlayers, setSelectedPlayers] = useState([]);
	let [currTeamId, setCurrTeamId] = useState(null);
	const [editMode, setEditMode] = useState(false);
	const [saveLoading, setSaveLoading] = useState(false);
	const [roundLoading, setRoundLoading] = useState(false);
	const [matchupLoading, setMatchupLoading] = useState(false);

	const playerSelector = (player, roundId, position, matchType, roundNo, playerPos) => {
		let tempObj = JSON.parse(player);
		if (
			matchType !== "Singles" &&
			selectedPlayers.findIndex((round) => round.RoundTeamId === roundId) > -1
		) {
			let selectedRoundData = {
				...selectedPlayers.find((round) => round.RoundTeamId === roundId),
			};
			if (playerPos === "Player2") {
				selectedRoundData["Player2Id"] = tempObj.PlayerId;
				selectedRoundData["Player2Name"] = tempObj.PlayerName;
			} else {
				selectedRoundData["Player1Id"] = tempObj.PlayerId;
				selectedRoundData["Player1Name"] = tempObj.PlayerName;
			}
			console.log("Selected Round Data", selectedRoundData);
			if (
				selectedPlayers?.length > 0 &&
				selectedPlayers.some((ele) =>
					ele.Position === selectedRoundData.Position ? true : false
				) > 0
			) {
				let newArray = selectedPlayers?.map((player, index) => {
					if (player.Position === selectedRoundData.Position) {
						return {
							...player,
							...selectedRoundData,
						};
					}
					return player;
				});
				setSelectedPlayers(newArray);
			}
		} else {
			let obj;
			if (playerPos === "Player2") {
				obj = {
					Player2Id: tempObj.PlayerId,
					Player2Name: tempObj.PlayerName,
				};
			} else {
				obj = {
					Player1Id: tempObj.PlayerId,
					Player1Name: tempObj.PlayerName,
				};
			}
			obj.RoundTeamId = roundId;
			obj.Position = position;
			obj.MatchType = matchType;
			obj.RoundNo = roundNo;
			if (
				selectedPlayers?.length > 0 &&
				selectedPlayers.some((ele) => (ele.Position === obj.Position ? true : false)) > 0
			) {
				let newArray = selectedPlayers?.map((player, index) => {
					if (player.Position === obj.Position) {
						return {
							...player,
							Player1Id: obj.Player1Id,
							Player1Name: obj.Player1Name,
						};
					}
					return player;
				});
				setSelectedPlayers(newArray);
			} else {
				setSelectedPlayers([...selectedPlayers, obj]);
			}
		}
	};

	const discardHandler = () => {
		setInitialPlayers([]);
		setSelectedPlayers([]);
		setEditMode(false);
		onAlertClose();
		onPlayerClose();
	};
	const savePlayers = async () => {
		console.log("Latest", selectedPlayers);
		let doublesErr = 0;
		selectedPlayers.forEach((ele) => {
			if (ele.MatchType === "Doubles") {
				if (
					!("Player1Id" in ele) ||
					!("Player2Id" in ele) ||
					ele.Player1Id === "" ||
					ele.Player2Id === ""
				) {
					doublesErr = doublesErr + 1;
				}
			}
		});
		if (doublesErr > 0) {
			toast({
				title: "Save Player Selection",
				description: "Select Two Players for Doubles Event",
				status: "warning",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
			doublesErr = 0;
		} else {
			try {
				setSaveLoading(true);
				let reqObj = { TETeamId: currTeamId, Rounds: selectedPlayers };
				let savedPlayersRes = await ApiService.httpPut(
					`/TeamsRoundPlayers?Class=Rounds`,
					reqObj
				);
				if (savedPlayersRes.status === true) {
					toast({
						title: "Save Player Selection",
						description: "Saved Selected Players successfully",
						status: "success",
						duration: 3000,
						isClosable: true,
						position: "top-right",
					});
					console.log("Saved Players Response:", savedPlayersRes);
					discardHandler();
					refreshPlyrSelection();
				} else if (savedPlayersRes.status === false) {
					toast({
						title: "Save Player Selection",
						description: savedPlayersRes?.Message,
						status: "warning",
						duration: 5000,
						isClosable: true,
						position: "top-right",
					});
				} else {
					toast({
						title: "Save Player Selection",
						description: "Failed to Save Selected Players",
						status: "error",
						duration: 3000,
						isClosable: true,
						position: "top-right",
					});
					onPlayerClose();
					setEditMode(false);
				}
				setSaveLoading(false);
			} catch (err) {
				console.log(err);
				toast({
					title: "Save Player Selection",
					description: "Failed to Save Selected Players",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				onPlayerClose();
				setEditMode(false);
				setSaveLoading(false);
			}
		}
	};
	const generateRound = async () => {
		let reqObj = {
			TourEventId: eventId,
			GroupName: selectedGroup?.GroupId,
		};
		try {
			setRoundLoading(true);
			let response = await ApiService.httpPost(
				"/TeamsRoundPlayers?Class=GenerateRounds",
				reqObj
			);
			console.log(response);
			if (response.status === true) {
				onClose();
				toast({
					title: "Generate Round",
					description: `${response.Message}
					 ${response?.TeamMatchups}`,
					status: "success",
					duration: 5000,
					isClosable: true,
					position: "top-right",
				});
				fetchFlags();
				latestRoundPlyrSelection();
			} else if (response.status === false) {
				onClose();
				toast({
					title: "Generate Round",
					description: response.Message,
					status: "warning",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			} else {
				onClose();
				toast({
					title: "Generate Round",
					description: "Failed to Generate Round",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
			setRoundLoading(false);
		} catch (err) {
			console.log(err);
			onClose();
			toast({
				title: "Generate Round",
				description: "Failed to Generate Round",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
			setRoundLoading(false);
		}
	};

	const generateMatchups = async () => {
		let reqObj = {
			TourEventId: eventId,
			GroupName: selectedGroup?.GroupId,
		};
		try {
			setMatchupLoading(true);
			let response = await ApiService.httpPost("/TeamEventTeams?Class=TeamsMatchups", reqObj);
			console.log(response);
			if (response.status === true) {
				onMatchClose();
				toast({
					title: "Generate Matchups",
					description: response.Message,
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				fetchFlags();
				navigateToTeamMatchups();
			} else if (response.status === false) {
				onMatchClose();
				toast({
					title: "Generate Matchups",
					description: response.Message,
					status: "warning",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			} else {
				onMatchClose();
				toast({
					title: "Generate Matchups",
					description: "Failed to Generate Matchup",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
			setMatchupLoading(false);
		} catch (err) {
			console.log(err);
			onMatchClose();
			toast({
				title: "Generate Matchups",
				description: "Failed to Generate Matchup",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
			setMatchupLoading(false);
		}
	};

	// Pagination
	let [rows, setRows] = useState(10);
	const perPage = rows;

	const {
		currentPage,
		currentData,
		jump,
		prev,
		next,
		first,
		last,
		setLast,
		setCurrentPage,
		maxPage,
	} = usePagination(plyrSelData?.Teams, perPage);

	const handleChange = (e, p) => {
		jump(p);
	};

	const handleRows = (event) => {
		setRows(Number(event.target.value));
		setLast(Number(event.target.value));
		setCurrentPage(1);
		// 	setParamTwo(Number(event.target.value));
	};

	// const previousPage = (event) => {
	// 	setParamTwo(Number(paramOne));
	// 	setParamOne(Number(paramTwo - rows));
	// };
	// const nextPage = (event) => {
	// 	setParamOne(Number(paramTwo));
	// 	setParamTwo(Number(paramTwo + rows));
	// };

	return (
		<>
			<Box>
				<Box
					borderRadius={"2xl"}
					overflow="auto"
					boxShadow={shadow}
					css={{
						"::-webkit-scrollbar": {
							width: "0.3rem",
							height: "0.3rem",
						},
						"::-webkit-scrollbar-track": {
							background: "#bee3f8",
							borderRadius: "1rem",
						},
						"::-webkit-scrollbar-thumb": {
							background: "#3182ce",
							borderRadius: "1rem",
						},
					}}>
					<Table variant="striped">
						<Thead position="sticky" top={0} bgColor="black" zIndex="2">
							<Tr>
								<Th
									textColor="white"
									fontSize="md"
									textAlign="start"
									py={4}
									borderTopLeftRadius="2xl">
									Team Name
								</Th>
								<Th
									textColor="white"
									fontSize="md"
									textAlign="center"
									borderTopRightRadius="2xl">
									Action
								</Th>
							</Tr>
						</Thead>
						<Tbody>
							{plyrSelLoading
								? Array(5)
										.fill()
										.map((rows, index) => (
											<Tr key={index}>
												<Td>
													<Skeleton
														isLoaded={!plyrSelLoading}
														h={"1.2rem"}
														w={"100%"}
													/>
												</Td>
												<Td>
													<Skeleton
														isLoaded={!plyrSelLoading}
														h={"1.2rem"}
														w={"100%"}
													/>
												</Td>
											</Tr>
										))
								: currentData()?.map((team, index) => {
										return (
											<Tr key={team.TETeamId}>
												<Td
													textAlign="start"
													fontSize="lg"
													fontWeight="medium"
													pr={2}>
													{team.TeamName}
												</Td>
												<Td textAlign="center">
													<Tooltip
														closeOnClick={true}
														hasArrow
														label="Player Selection"
														aria-label="Player Selection Button">
														<IconButton
															isLoading={plyrSelModalLoad}
															icon={<MdAssignmentInd />}
															mb={1}
															colorScheme="blue"
															_hover={{ bgColor: "blue.400" }}
															_active={{ bgColor: "blue.700" }}
															size="sm"
															onClick={() => {
																setCurrTeamId(team.TETeamId);
																setInitialPlayers([
																	...team?.Rounds,
																]);
																setSelectedPlayers([
																	...team?.Rounds,
																]);
																team?.Rounds?.some((ele) =>
																	ele.MatchType === "Singles"
																		? ele.Player1Id === ""
																		: ele.Player1Id === "" ||
																		  ele.Player2Id === ""
																)
																	? setEditMode(true)
																	: setEditMode(false);
																onPlayerOpen();
															}}
														/>
													</Tooltip>
												</Td>
											</Tr>
										);
								  })}
						</Tbody>
					</Table>
				</Box>

				<Divider />
				<Stack p="1rem" bgColor="white" borderBottomRadius={"2xl"}>
					{/*---------------For Pagination-------------------------------*/}
					<ButtonGroup justifyContent={"space-between"}>
						{
							<Button
								color="white"
								bgColor="bg-accent"
								borderRadius="full"
								onClick={onOpen}
								disabled={plyrSelData.isRoundComplete === 0}
								isLoading={roundLoading}>
								Generate Round
							</Button>
						}
						<Button
							color="white"
							bgColor="bg-accent"
							borderRadius="full"
							onClick={onMatchOpen}
							isLoading={matchupLoading}>
							Generate Matchups
						</Button>
					</ButtonGroup>
					{isMobile ? (
						<Stack>
							<Center>
								<Pagination
									count={plyrSelData?.Teams?.length}
									pageSize={rows}
									siblingCount={0}
									page={currentPage}
									onChange={(e) => setCurrentPage(e.page)}
								/>
							</Center>
							<HStack justify="space-between">
								<HStack spacing={1}>
									<Text as="span" whiteSpace="normal">
										Rows per page{" "}
									</Text>
									<Select
										boxSize={"-webkit-max-content"}
										minW="-webkit-fit-content"
										size="sm"
										value={rows}
										onChange={(event) => handleRows(event)}>
										{[10, 25, 50].map((rows) => (
											<option key={rows} value={rows}>
												{rows}
											</option>
										))}
									</Select>
								</HStack>
								<Text whiteSpace="normal">
									Showing {currentPage * rows - rows + 1} -{" "}
									{currentPage * rows < plyrSelData?.Teams?.length
										? currentPage * rows
										: plyrSelData?.Teams?.length}{" "}
									of {plyrSelData?.Teams?.length} Entries
								</Text>
							</HStack>
						</Stack>
					) : (
						<HStack justify="space-between" pt={3}>
							<HStack spacing={1}>
								<Text as="span" whiteSpace={"nowrap"}>
									Rows per page{" "}
								</Text>
								<Select
									boxSize={"-webkit-max-content"}
									minW="-webkit-fit-content"
									value={rows}
									onChange={(event) => handleRows(event)}>
									{[10, 25, 50].map((rows) => (
										<option key={rows} value={rows}>
											{rows}
										</option>
									))}
								</Select>
							</HStack>
							<Center>
								<Pagination
									count={plyrSelData?.Teams?.length}
									pageSize={rows}
									siblingCount={1}
									page={currentPage}
									onChange={(e) => setCurrentPage(e.page)}
								/>
							</Center>
							<Text whiteSpace="normal">
								Showing {currentPage * rows - rows + 1} -{" "}
								{currentPage * rows < plyrSelData?.Teams?.length
									? currentPage * rows
									: plyrSelData?.Teams?.length}{" "}
								of {plyrSelData?.Teams?.length} Entries
							</Text>
						</HStack>
					)}
				</Stack>
			</Box>
			<Modal
				closeOnOverlayClick={false}
				closeOnEsc={false}
				onClose={
					_.isEqual(initialPlayers, selectedPlayers) !== true
						? onAlertOpen
						: () => {
								discardHandler();
								setEditMode(false);
						  }
				}
				isOpen={isPlayerOpen}
				scrollBehavior="inside"
				blockScrollOnMount={false}
				isCentered
				size={{ base: "sm", sm: "xl", md: "2xl", lg: "4xl" }}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader textAlign={"center"}>
						{plyrSelData?.Teams?.find((team) => team.TETeamId === currTeamId)?.TeamName}
					</ModalHeader>
					<ModalBody
						overflow="auto"
						css={{
							"::-webkit-scrollbar": {
								width: "0.3rem",
								height: "0.3rem",
							},
							"::-webkit-scrollbar-track": {
								background: "#bee3f8",
								borderRadius: "1rem",
							},
							"::-webkit-scrollbar-thumb": {
								background: "#3182ce",
								borderRadius: "1rem",
							},
						}}>
						<Box borderRadius={"2xl"} mb="1rem" boxShadow={"sm"}>
							<Table variant="striped">
								<Thead position="sticky" top={0} bgColor="black" zIndex="overlay">
									<Tr>
										<Th
											textColor="white"
											fontSize="md"
											textAlign="start"
											py={4}
											borderTopLeftRadius="2xl">
											Position
										</Th>
										<Th textColor="white" fontSize="md" textAlign="start">
											Player Name
										</Th>
										<Th
											textColor="white"
											fontSize="md"
											textAlign="start"
											borderTopRightRadius="2xl"></Th>
									</Tr>
								</Thead>
								<Tbody>
									{plyrSelData?.Teams?.find(
										(team) => team.TETeamId === currTeamId
									)?.Rounds.map((position, index) => (
										<Tr key={position.RoundTeamId}>
											<Td
												textAlign="start"
												fontSize="lg"
												fontWeight="medium"
												pr={2}>
												{position.Position}
											</Td>
											<Td textAlign="start">
												{editMode === true &&
												plyrSelData.isEditable === 1 ? (
													<FormControl textAlign="start">
														<Select
															defaultValue={JSON.stringify({
																PlayerId: position.Player1Id,
																PlayerName: position.Player1Name,
															})}
															onChange={(e) => {
																playerSelector(
																	e.target.value,
																	position.RoundTeamId,
																	position?.Position,
																	position.MatchType,
																	position.RoundNo,
																	"Player1"
																);
															}}>
															<option
																value={JSON.stringify({
																	PlayerId: "",
																	PlayerName: "",
																})}>
																{selectedPlayers?.find(
																	(ele) =>
																		ele.Position ===
																		position?.Position
																)?.Player1Id &&
																selectedPlayers?.find(
																	(ele) =>
																		ele.Position ===
																		position?.Position
																)?.Player1Id.length > 0
																	? "---Unselect the Player---"
																	: "Select a Player"}
															</option>
															{plyrSelPositions
																?.find(
																	(pos) =>
																		pos.TETeamId === currTeamId
																)
																?.Players?.map((players, index) => {
																	return (
																		<option
																			hidden={selectedPlayers?.some(
																				(ele) =>
																					ele.Player1Id ===
																						players.PlayerId ||
																					ele.Player2Id ===
																						players.PlayerId
																			)}
																			key={players.PlayerId}
																			value={JSON.stringify(
																				players
																			)}>
																			{players.PlayerName}
																		</option>
																	);
																})}
														</Select>
													</FormControl>
												) : (
													<Text fontSize="lg" fontWeight="medium">
														{position.Player1Name}
													</Text>
												)}
											</Td>
											<Td textAlign="start">
												{position.MatchType !== "Singles" &&
												editMode === true &&
												plyrSelData.isEditable === 1 ? (
													<FormControl textAlign="start">
														<Select
															defaultValue={JSON.stringify({
																PlayerId: position.Player2Id,
																PlayerName: position.Player2Name,
															})}
															onChange={(e) => {
																playerSelector(
																	e.target.value,
																	position.RoundTeamId,
																	position?.Position,
																	position.MatchType,
																	position.RoundNo,
																	"Player2"
																);
															}}>
															<option
																value={JSON.stringify({
																	PlayerId: "",
																	PlayerName: "",
																})}>
																{selectedPlayers?.find(
																	(ele) =>
																		ele.Position ===
																		position?.Position
																)?.Player2Id &&
																selectedPlayers?.find(
																	(ele) =>
																		ele.Position ===
																		position?.Position
																)?.Player2Id.length > 0
																	? "---Unselect the Player---"
																	: "Select a Player"}
															</option>
															{plyrSelPositions
																?.find(
																	(pos) =>
																		pos.TETeamId === currTeamId
																)
																?.Players?.map((players, index) => {
																	return (
																		<option
																			hidden={selectedPlayers?.some(
																				(ele) =>
																					ele.Player1Id ===
																						players.PlayerId ||
																					ele.Player2Id ===
																						players.PlayerId
																			)}
																			key={players.PlayerId}
																			value={JSON.stringify(
																				players
																			)}>
																			{players.PlayerName}
																		</option>
																	);
																})}
														</Select>
													</FormControl>
												) : (
													<Text fontSize="lg" fontWeight="medium">
														{position.Player2Name}
													</Text>
												)}
											</Td>
										</Tr>
									))}
								</Tbody>
							</Table>
						</Box>
					</ModalBody>
					<ModalFooter>
						<ButtonGroup spacing="3">
							{!saveLoading && (
								<Button
									size="lg"
									borderRadius="full"
									colorScheme="red"
									onClick={
										_.isEqual(initialPlayers, selectedPlayers) !== true
											? onAlertOpen
											: () => {
													discardHandler();
													setEditMode(false);
											  }
									}>
									Close
								</Button>
							)}
							{editMode && plyrSelData.isEditable === 1 ? (
								<Button
									type="submit"
									size="lg"
									borderRadius="full"
									colorScheme="blue"
									onClick={savePlayers}
									isLoading={saveLoading}
									isDisabled={
										_.isEqual(initialPlayers, selectedPlayers) === true
									}>
									Save
								</Button>
							) : editMode === false && plyrSelData.isEditable === 1 ? (
								<Button
									type="submit"
									size="lg"
									borderRadius="full"
									colorScheme="blue"
									onClick={() => setEditMode(true)}>
									Edit Players
								</Button>
							) : (
								selectedPlayers.every((ele) =>
									ele.MatchType === "Singles"
										? ele.Player1Id !== ""
										: ele.Player1Id !== "" && ele.Player2Id !== ""
								) &&
								plyrSelData.isEditable === 1 && (
									<Button
										type="submit"
										size="lg"
										borderRadius="full"
										colorScheme="blue"
										onClick={() => setEditMode(false)}>
										View Players
									</Button>
								)
							)}
						</ButtonGroup>
					</ModalFooter>
				</ModalContent>
			</Modal>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
				size={{ base: "xs", sm: "md", md: "lg", lg: "xl" }}
				isCentered>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Generate Round</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Text>Are you sure want to Generate Round?</Text>
					</ModalBody>
					<ModalFooter>
						<Button
							colorScheme="blue"
							mr={3}
							onClick={generateRound}
							isLoading={roundLoading}>
							Submit
						</Button>
						{!roundLoading && <Button onClick={onClose}>Cancel</Button>}
					</ModalFooter>
				</ModalContent>
			</Modal>
			<Modal
				isOpen={isMatchOpen}
				onClose={onMatchClose}
				size={{ base: "xs", sm: "md", md: "lg", lg: "xl" }}
				isCentered>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Generate Matchups</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Text>Are you sure want to Generate Matchups?</Text>
					</ModalBody>
					<ModalFooter>
						<Button
							colorScheme="blue"
							mr={3}
							onClick={generateMatchups}
							isLoading={matchupLoading}>
							Submit
						</Button>
						{!matchupLoading && <Button onClick={onMatchClose}>Cancel</Button>}
					</ModalFooter>
				</ModalContent>
			</Modal>
			<AlertDialog
				motionPreset="slideInBottom"
				leastDestructiveRef={cancelRef}
				onClose={onAlertClose}
				isOpen={isAlertOpen}
				isCentered>
				<AlertDialogOverlay />

				<AlertDialogContent>
					<AlertDialogHeader>Discard Changes?</AlertDialogHeader>
					<AlertDialogCloseButton />
					<AlertDialogBody>
						Are you sure you want to discard all the selected players?
					</AlertDialogBody>
					<AlertDialogFooter>
						<Button ref={cancelRef} onClick={onAlertClose}>
							No
						</Button>
						<Button colorScheme="red" ml={3} onClick={discardHandler}>
							Yes
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
		</>
	);
};

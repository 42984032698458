import {
	Card,
	CardHeader,
	IconButton,
	Icon,
	Input,
	InputGroup,
	InputLeftElement,
	Stack,
	Text,
	useBreakpointValue,
	useColorModeValue,
	Select,
	FormControl,
	HStack,
	MenuItem,
	MenuList,
	CloseButton,
	MenuButton,
	Menu,
	useToast,
	Modal,
	useDisclosure,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Button,
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogCloseButton,
	AlertDialogBody,
	AlertDialogFooter,
	Skeleton,
} from "@chakra-ui/react";
import { FiDownloadCloud, FiMoreVertical, FiRefreshCw, FiSearch } from "react-icons/fi";
import { AiOutlineSwap } from "react-icons/ai";
import ApiService from "../../service/Api.service";
import { CSVLink } from "react-csv";
import { useState, useRef } from "react";

const headers = [
	{ label: "Player 1", key: "Player1" },
	{ label: "Player1 Score", key: "Team1Score" },
	{ label: "Player 2", key: "Player2" },
	{ label: "Player2 Score", key: "Team2Score" },
];

export const MatchupsTabCard = (props) => {
	const isDesktop = useBreakpointValue({
		base: false,
		md: true,
	});

	const stackDirection = useBreakpointValue({
		base: "column",
		md: "row",
	});

	const titleText = useBreakpointValue({
		base: "lg",
		md: "xl",
	});

	let toast = useToast();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();
	const cancelRef = useRef();

	// Loading States
	let groupsLookupLoad = props.groupsLookupLoad;
	let roundsLookupLoad = props.roundsLookupLoad;

	// States
	let [selectedMatch1, setSelectedMatch1] = useState("");
	let [selectedMatch2, setSelectedMatch2] = useState("");
	const [menuLoading, setMenuLoading] = useState(false);

	const handleNotificaiton = async (event) => {
		console.log("Action performed for:", event.target.value);
		let notification = event.target.value;
		switch (notification) {
			case "sendScoreLinks":
				try {
					setMenuLoading(true);
					let reqObj = {
						TourEventId: props.state.EventId,
						RoundNo: props.selectedRound.RoundNo,
						GroupName: props.selectedGroup.GroupName.split(" - ")[1],
					};
					let sendLinksRes = await ApiService.httpPost(
						`/ScoreLink?EventId=${props.state.EventId}&RoundNo=${
							props.selectedRound.RoundNo
						}&GroupName=${props.selectedGroup.GroupName.split(" - ")[1]}`,
						{}
					);
					if (sendLinksRes.status === true) {
						console.log("Sent Score Links:", sendLinksRes);
						toast({
							title: "Send Score Links",
							description: "Score Links Sent Successfully!",
							status: "success",
							duration: 3000,
							isClosable: true,
							position: "top-right",
						});
					} else {
						toast({
							title: "Send Score Links",
							description: "Failed to Send Score Links",
							status: "error",
							duration: 3000,
							isClosable: true,
							position: "top-right",
						});
					}
					setMenuLoading(false);
				} catch (err) {
					console.log(err);
					toast({
						title: "Send Score Links",
						description: "Failed to Send Score Links",
						status: "error",
						duration: 3000,
						isClosable: true,
						position: "top-right",
					});
					setMenuLoading(false);
				}
				break;
			case "sendResults":
				try {
					setMenuLoading(true);
					let sendResultsRes = await ApiService.httpPost(
						`/ScoreLink?EventId=${props.state.EventId}&Class=Results`,
						{}
					);
					console.log("Sent Results:", sendResultsRes);
					if (sendResultsRes.status === true) {
						toast({
							title: "Send Results",
							description: "Results Sent Successfully!",
							status: "success",
							duration: 3000,
							isClosable: true,
							position: "top-right",
						});
					} else {
						toast({
							title: "Send Results",
							description: "Failed to Send Results",
							status: "error",
							duration: 3000,
							isClosable: true,
							position: "top-right",
						});
					}
					setMenuLoading(false);
				} catch (err) {
					console.log(err);
					toast({
						title: "Send Results",
						description: "Failed to Send Results",
						status: "error",
						duration: 3000,
						isClosable: true,
						position: "top-right",
					});
					setMenuLoading(false);
				}
				break;
			case "generateStandings":
				try {
					setMenuLoading(true);
					let reqObj = {
						TourEventId: props.state.EventId,
						RoundNo: props.selectedRound.RoundNo,
						GroupName: props.selectedGroup.GroupName.split(" - ")[1],
					};
					let generateStandingsRes = await ApiService.httpPost(`/TourStandings`, reqObj);
					console.log("Genereated Standings:", generateStandingsRes);
					if (generateStandingsRes.status === true) {
						toast({
							title: "Generate Standings",
							description: "Standings Generated Successfully!",
							status: "success",
							duration: 3000,
							isClosable: true,
							position: "top-right",
						});
						props.navigateToStandings();
					} else {
						toast({
							title: "Generate Standings",
							description: "Failed to Generate Standings",
							status: "error",
							duration: 3000,
							isClosable: true,
							position: "top-right",
						});
					}
					setMenuLoading(false);
				} catch (err) {
					console.log(err);
					toast({
						title: "Generate Standings",
						description: "Failed to Generate Standings",
						status: "error",
						duration: 3000,
						isClosable: true,
						position: "top-right",
					});
					setMenuLoading(false);
				}
				break;
			case "deleteLatestRound":
				onAlertOpen();
				break;
			case "updateStreaming":
				try {
					setMenuLoading(true);
					let updateStreamingRes = await ApiService.httpPut(
						`/TourEventMatchupsByGroup?GetStreaming=Yes&TourEventId=${
							props.state.EventId
						}&GroupName=${props.selectedGroup.GroupName.split(" - ")[1]}&RoundNo=${
							props.selectedRound.RoundNo
						}`,
						{}
					);
					console.log("Updated Streaming:", updateStreamingRes);
					if (updateStreamingRes.status === true) {
						toast({
							title: "Update Streaming",
							description: "Updated Streaming Successfully!",
							status: "success",
							duration: 3000,
							isClosable: true,
							position: "top-right",
						});
					} else {
						toast({
							title: "Update Streaming",
							description: "Failed to Update Streaming",
							status: "error",
							duration: 3000,
							isClosable: true,
							position: "top-right",
						});
					}
					props.navigateToStreaming();
					setMenuLoading(false);
				} catch (err) {
					console.log(err);
					toast({
						title: "Update Streaming",
						description: "Failed to Update Streaming",
						status: "error",
						duration: 3000,
						isClosable: true,
						position: "top-right",
					});
					setMenuLoading(false);
				}
				break;
			case "swapVenues":
				onOpen();
				break;
		}
	};

	const swapVenues = async () => {
		try {
			setMenuLoading(true);
			let swapRes = await ApiService.httpPut(
				`/TourEventMatchupsByGroup?Matchup1Id=${selectedMatch1}&Matchup2Id=${selectedMatch2}`,
				{}
			);
			console.log("Venues Swapped", swapRes);
			if (swapRes.status === true) {
				toast({
					title: "Swap Venues",
					description: "Venues Swapped Successfully!",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			} else {
				toast({
					title: "Swap Venues",
					description: "Failed to Swap Venues",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
			await props.refreshMatchups();
			setSelectedMatch1("");
			setSelectedMatch2("");
			onClose();
			setMenuLoading(false);
		} catch (err) {
			console.log(err);
			toast({
				title: "Swap Venues",
				description: "Failed to Swap Venues",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
			setMenuLoading(false);
		}
	};

	const deleteLatestRound = async () => {
		try {
			setMenuLoading(true);
			let groupName = props.selectedGroup.GroupName.split(" - ")[1];
			let deleteRoundRes = await ApiService.httpDelete(
				`/TourEventMatchupsByGroup?TourEventId=${props.state.EventId}&GroupName=${groupName}`
			);
			console.log("Deleted Latest Round:", deleteRoundRes);
			if (deleteRoundRes.status === true) {
				onAlertClose();
				toast({
					title: "Delete Latest Round",
					description: "Latest Round Deleted Successfully!",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				props.refreshMatchupsView();
			} else if (deleteRoundRes.status === false) {
				onAlertClose();
				toast({
					title: "Delete Latest Round",
					description: deleteRoundRes.Message,
					status: "warning",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			} else {
				onAlertClose();
				toast({
					title: "Delete Latest Round",
					description: "Failed to Delete Latest Round",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
			setMenuLoading(false);
		} catch (err) {
			console.log(err);
			onAlertClose();
			toast({
				title: "Delete Latest Round",
				description: "Failed to Delete Latest Round",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
			setMenuLoading(false);
		}
	};

	return (
		<>
			<Card
				variant={"elevated"}
				bgColor="white"
				boxShadow={useColorModeValue("sm", "sm-dark")}>
				<CardHeader>
					<Stack
						direction={useBreakpointValue({
							base: "column",
							xl: "row",
						})}
						spacing="2rem"
						justify="space-between">
						<Stack direction="column" spacing=".7rem">
							{props.selectedRound?.RoundNo && props.selectedGroup.GroupId && (
								<Text as="h3" fontSize={titleText} fontWeight="semibold">
									Matchups for {props?.eventType} Event Round{" "}
									{props.selectedRound?.RoundNo} of Group{" "}
									{props.selectedGroup?.GroupId}
								</Text>
							)}
							<InputGroup maxW="xs" colorScheme="blue">
								<InputLeftElement pointerEvents="none">
									<Icon as={FiSearch} color="accent" boxSize="5" />
								</InputLeftElement>
								<Input
									w="100vw"
									placeholder="Search"
									onChange={(e) => props.setQuery(e.target.value)}
								/>
							</InputGroup>
						</Stack>
						<Stack
							direction={stackDirection}
							align={isDesktop && "end"}
							justify="end"
							spacing=".7rem">
							{isDesktop && (
								<Stack spacing=".7rem" direction={stackDirection}>
									{props.matchupsData?.length > 0 && (
										<CSVLink
											headers={headers}
											filename={
												props.eventType === "Singles"
													? "SinglesPlayerMatchups.csv"
													: props.eventType === "Doubles"
													? "DoublesPlayerMatchups.csv"
													: props.eventType === "Teams" &&
													  "TeamsMatchups.csv"
											}
											data={props.matchupsData}
											asyncOnClick={true}>
											<IconButton
												variant="ghost"
												size="md"
												color="accent"
												icon={<FiDownloadCloud size="1.7rem" />}
											/>
										</CSVLink>
									)}
									<IconButton
										variant="ghost"
										size="md"
										color="accent"
										icon={
											<FiRefreshCw
												size="1.7rem"
												onClick={props.refreshMatchups}
											/>
										}
									/>
								</Stack>
							)}
							<Stack
								spacing="1rem"
								flex={1}
								direction={useBreakpointValue({
									base: "row",
								})}>
								<FormControl>
									{groupsLookupLoad ? (
										<Skeleton isLoaded={!groupsLookupLoad} w="8vw">
											<Select />
										</Skeleton>
									) : (
										<Select
											name="Group"
											colorScheme="blue"
											fontSize={{ base: "sm", md: "md" }}
											textAlign="start"
											onChange={(event) => {
												props.updateMatchupsData(
													event.target.value,
													props.selectedRound?.RoundNo
												);
											}}>
											{props.groups?.map((group, index) => {
												return (
													<option
														key={group.GroupId}
														value={group.GroupId}>
														{group.GroupName}
													</option>
												);
											})}
										</Select>
									)}
								</FormControl>
								<FormControl>
									{roundsLookupLoad ? (
										<Skeleton isLoaded={!roundsLookupLoad} w="8vw">
											<Select />
										</Skeleton>
									) : (
										<Select
											name="Round"
											colorScheme="blue"
											value={props.selectedRound?.RoundNo}
											fontSize={{ base: "sm", md: "md" }}
											textAlign="start"
											onChange={(event) => {
												props.updateMatchupsData(
													props.selectedGroup?.GroupId,
													event.target.value
												);
											}}>
											{props.rounds?.map((round, index) => {
												return (
													<option
														key={round.RoundNo}
														value={round.RoundNo}>
														{round.RoundName}
													</option>
												);
											})}
										</Select>
									)}
								</FormControl>
							</Stack>
							{isDesktop ? (
								<Menu direction="rtl">
									{({ isOpen }) => (
										<>
											<MenuButton
												isActive={isOpen}
												as={IconButton}
												variant="ghost"
												color="green.500"
												size="md"
												borderRadius="full"
												isLoading={menuLoading}
												icon={
													isOpen ? (
														<CloseButton />
													) : (
														<FiMoreVertical size="1.7rem" />
													)
												}
											/>
											<MenuList zIndex="overlay">
												<MenuItem
													textColor="green.400"
													fontWeight="medium"
													value="sendScoreLinks"
													onClick={handleNotificaiton}>
													Send Score Links
												</MenuItem>
												<MenuItem
													textColor="green.400"
													fontWeight="medium"
													value="sendResults"
													onClick={handleNotificaiton}>
													Send Results
												</MenuItem>
												<MenuItem
													textColor="green.400"
													fontWeight="medium"
													value="swapVenues"
													onClick={handleNotificaiton}>
													Swap Venues
												</MenuItem>
												<MenuItem
													textColor="green.400"
													fontWeight="medium"
													value="generateStandings"
													onClick={handleNotificaiton}>
													Generate Standings
												</MenuItem>
												<MenuItem
													textColor="green.400"
													fontWeight="medium"
													value="updateStreaming"
													onClick={handleNotificaiton}>
													Update Streaming
												</MenuItem>
												<MenuItem
													textColor="green.400"
													fontWeight="medium"
													value="deleteLatestRound"
													onClick={handleNotificaiton}>
													Delete Latest Round
												</MenuItem>
											</MenuList>
										</>
									)}
								</Menu>
							) : (
								<Stack spacing=".7rem" direction="row" justify="end">
									{props.matchupsData?.length > 0 && (
										<CSVLink
											headers={headers}
											filename={"SinglesPlayerMatchups.csv"}
											data={props.matchupsData}
											asyncOnClick={true}>
											<IconButton
												variant="ghost"
												size="md"
												color="accent"
												icon={<FiDownloadCloud size="1.7rem" />}
											/>
										</CSVLink>
									)}
									<IconButton
										variant="ghost"
										size="md"
										color="accent"
										icon={
											<FiRefreshCw
												size="1.7rem"
												onClick={props.refreshMatchups}
											/>
										}
									/>
									<Menu direction="rtl">
										{({ isOpen }) => (
											<>
												<MenuButton
													isActive={isOpen}
													as={IconButton}
													variant="ghost"
													color="green.500"
													size="md"
													borderRadius="full"
													isLoading={menuLoading}
													icon={
														isOpen ? (
															<CloseButton variant="ghost" />
														) : (
															<FiMoreVertical size="1.7rem" />
														)
													}
												/>
												<MenuList zIndex="overlay">
													<MenuItem
														textColor="green.400"
														fontWeight="medium"
														value="sendScoreLinks"
														onClick={handleNotificaiton}>
														Send Score Links
													</MenuItem>
													<MenuItem
														textColor="green.400"
														fontWeight="medium"
														value="sendResults"
														onClick={handleNotificaiton}>
														Send Results
													</MenuItem>
													<MenuItem
														textColor="green.400"
														fontWeight="medium"
														value="swapVenues"
														onClick={handleNotificaiton}>
														Swap Venues
													</MenuItem>
													<MenuItem
														textColor="green.400"
														fontWeight="medium"
														value="generateStandings"
														onClick={handleNotificaiton}>
														Generate Standings
													</MenuItem>
													<MenuItem
														textColor="green.400"
														fontWeight="medium"
														value="updateStreaming"
														onClick={handleNotificaiton}>
														Update Streaming
													</MenuItem>
													<MenuItem
														textColor="green.400"
														fontWeight="medium"
														value="deleteLatestRound"
														onClick={handleNotificaiton}>
														Delete Latest Round
													</MenuItem>
												</MenuList>
											</>
										)}
									</Menu>
								</Stack>
							)}
						</Stack>
					</Stack>
				</CardHeader>
			</Card>
			<Modal
				onClose={() => {
					setSelectedMatch1("");
					setSelectedMatch2("");
					onClose();
				}}
				isOpen={isOpen}
				isCentered>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader textAlign={"center"}>Swap Venues</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<HStack justify={"space-evenly"}>
							<Select
								width={"-webkit-max-content"}
								placeholder="Select a Team"
								onChange={(e) => setSelectedMatch1(e.target.value)}>
								{props.matchupsData
									?.filter((matchup) => matchup.MatchupId !== selectedMatch2)
									?.map((players, index) => (
										<option key={players.MatchupId} value={players.MatchupId}>
											{players.Venue && players.Venue + ". "}
											{players.Player1} vs {players.Player2}
										</option>
									))}
							</Select>
							<Icon as={AiOutlineSwap} boxSize={"1.5rem"} />
							<Select
								width={"-webkit-max-content"}
								placeholder="Select a Team"
								onChange={(e) => setSelectedMatch2(e.target.value)}>
								{props.matchupsData
									?.filter((matchup) => matchup.MatchupId !== selectedMatch1)
									?.map((players, index) => (
										<option key={players.MatchupId} value={players.MatchupId}>
											{players.Venue && players.Venue + ". "}
											{players.Player1} vs {players.Player2}
										</option>
									))}
							</Select>
						</HStack>
					</ModalBody>
					<ModalFooter>
						<Button
							colorScheme={"green"}
							onClick={swapVenues}
							isLoading={menuLoading}
							disabled={
								selectedMatch1 !== "" && selectedMatch2 !== "" ? false : true
							}>
							Swap
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
			<AlertDialog
				motionPreset="slideInBottom"
				leastDestructiveRef={cancelRef}
				onClose={onAlertClose}
				isOpen={isAlertOpen}
				isCentered>
				<AlertDialogOverlay />

				<AlertDialogContent>
					<AlertDialogHeader>Delete Latest Round</AlertDialogHeader>
					<AlertDialogCloseButton />
					<AlertDialogBody>Are you sure you want to Delete Latest Round?</AlertDialogBody>
					<AlertDialogFooter>
						{!menuLoading && (
							<Button ref={cancelRef} onClick={onAlertClose}>
								Cancel
							</Button>
						)}
						<Button
							colorScheme="red"
							ml={3}
							isLoading={menuLoading}
							onClick={deleteLatestRound}>
							Delete
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
		</>
	);
};

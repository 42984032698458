import { HStack, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useEffect } from "react";
import Marquee from "react-fast-marquee";
import ApiService from "../../../service/Api.service";
import { useAuthProvider } from "../../../context/AuthProvider";

export const FlashNews = ({ eventId, boardNo }) => {
	// User Details
	const user = useAuthProvider();

	const [flashMessage, setFlashMessage] = useState(null);
	useEffect(() => {
		async function fetchMessage() {
			try {
				let message = await ApiService.httpGet(
					`/StreamingConfigurations?TourEventId=${eventId}&Board=${boardNo}&Class=getMessage`,
					user.userId
				);
				if (message.status === true) {
					setFlashMessage(message);
				}
			} catch (err) {
				console.log(err);
			}
		}
		fetchMessage();
	}, [eventId, boardNo]);
	if (flashMessage !== null) {
		return (
			<HStack bg={"white"}>
				<Text
					fontWeight={"bold"}
					px={3}
					textColor={"white"}
					bg={"#DA2C43"}
					w="fit-content"
					whiteSpace={"nowrap"}>
					{flashMessage.MessageTitle}
				</Text>
				<Marquee style={{ margin: 0 }}>
					<Text>{flashMessage.Message}</Text>
				</Marquee>
			</HStack>
		);
	}
};

import {
	Card,
	Button,
	CardHeader,
	IconButton,
	Icon,
	Input,
	InputGroup,
	InputLeftElement,
	Stack,
	Text,
	useBreakpointValue,
	useColorModeValue,
	CloseButton,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	useDisclosure,
	useToast,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
} from "@chakra-ui/react";
import { useState } from "react";
import { BsDownload, BsUpload } from "react-icons/bs";
import { FiDownloadCloud, FiMoreHorizontal, FiSearch, FiUploadCloud } from "react-icons/fi";
import { HiDotsVertical, HiDownload, HiUpload } from "react-icons/hi";
import { SlPaperPlane } from "react-icons/sl";
import { useNavigate } from "react-router-dom";
import { DoublesAddTeamModal } from "../Events/DoublesTeamModal/DoublesAddTeamModal";
import uploadXlsFile from "../Upload/uploadXlsFile";
import ApiService from "../../service/Api.service";

export const DoublesTeamTabCard = (props) => {
	const { isOpen: isUploadOpen, onOpen: openUpload, onClose: closeUpload } = useDisclosure();

	const [menuLoading, setMenuLoading] = useState(false);

	const toast = useToast();

	let setQuery = props.setQuery;

	const notificationHandler = async (event) => {
		console.log("Action performed for:", event.target.value);
		let notification = event.target.value;
		switch (notification) {
			case "sendPairs":
				try {
					setMenuLoading(true);
					let sentPlayersRes = await ApiService.httpPost(
						`/ScoreLink?Class=DoublesPlayers&EventId=${props.eventId}`,
						{}
					);
					console.log("Sent Message:", sentPlayersRes);
					if (sentPlayersRes.status === true) {
						toast({
							title: "Send Doubles Pairs",
							description: "Doubles Pairs Sent Successfully!",
							status: "success",
							duration: 3000,
							isClosable: true,
							position: "top-right",
						});
					} else {
						toast({
							title: "Send Doubles Pairs",
							description: "Failed to Send Doubles Pairs",
							status: "error",
							duration: 3000,
							isClosable: true,
							position: "top-right",
						});
					}
					setMenuLoading(false);
				} catch (err) {
					console.log(err);
					toast({
						title: "Send Doubles Pairs",
						description: "Failed to Send Doubles Pairs",
						status: "error",
						duration: 3000,
						isClosable: true,
						position: "top-right",
					});
					setMenuLoading(false);
				}
				break;
		}
	};

	// State for File that needs to be uploaded
	let [file, setFile] = useState(null);

	// setFile that needs to be uploaded
	const handleFileChange = (event) => {
		console.log(event.target.files);
		setFile(event.target.files[0]);
		console.log("File selected");
	};
	const folderName = "DoublesTeams";
	const fileName = "DoublesTeams";
	// Upload the file to Azure Blob
	let { handleUpload } = uploadXlsFile(file, toast, closeUpload, folderName, fileName);

	return (
		<>
			<Card
				variant={"elevated"}
				bgColor="white"
				boxShadow={useColorModeValue("sm", "sm-dark")}>
				<CardHeader>
					<Stack
						direction={useBreakpointValue({
							base: "column",
							sm: "row",
						})}
						spacing="1.2rem"
						justify="space-between">
						<Stack direction="column" spacing=".7rem">
							<Text
								as="h3"
								fontSize={useBreakpointValue({
									base: "lg",
									md: "xl",
								})}
								fontWeight="semibold">
								Teams
							</Text>
							<InputGroup maxW="xs" colorScheme="blue">
								<InputLeftElement pointerEvents="none">
									<Icon as={FiSearch} color="accent" boxSize="5" />
								</InputLeftElement>
								<Input
									w="100vw"
									placeholder="Search"
									onChange={(e) => setQuery(e.target.value)}
								/>
							</InputGroup>
						</Stack>
						<Stack direction="row" justify="flex-end" align="end" spacing=".7rem">
							<IconButton
								variant="ghost"
								size="md"
								color="accent"
								icon={<FiUploadCloud size="1.7rem" />}
							/>
							<IconButton
								variant="ghost"
								size="md"
								color="accent"
								icon={<FiDownloadCloud size="1.7rem" />}
							/>
							<DoublesAddTeamModal
								setTeamsReload={props.setTeamsReload}
								teamsReload={props.teamsReload}
								EventId={props.state.EventId}
							/>
							<Menu direction="rtl">
								{({ isOpen }) => (
									<>
										<MenuButton
											isActive={isOpen}
											as={IconButton}
											color="green.500"
											variant="ghost"
											size="md"
											borderRadius="full"
											icon={
												isOpen ? (
													<CloseButton />
												) : (
													<HiDotsVertical size="1.7rem" />
												)
											}
										/>
										<MenuList zIndex="overlay">
											<MenuItem
												textColor="green.400"
												fontWeight="medium"
												value="sendPairs"
												onClick={notificationHandler}>
												Send Pairs
											</MenuItem>
										</MenuList>
									</>
								)}
							</Menu>
						</Stack>
					</Stack>
				</CardHeader>
			</Card>
			<Modal isOpen={isUploadOpen} onClose={closeUpload}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Upload</ModalHeader>
					<ModalCloseButton />
					<ModalBody pb={6}>
						<Button>Generate Template</Button>
						&emsp;
						<Button>Download</Button>
						<input
							type={"file"}
							name={"fileUpload"}
							accept=".xlsx, .xls"
							onChange={handleFileChange}
						/>
						<Button onClick={handleUpload}>Upload</Button>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
};

import { Button, HStack, Icon, Text } from "@chakra-ui/react";
import * as React from "react";
import { Link } from "react-router-dom";

export const NavButton = (props) => {
	const { icon, label, link, ...buttonProps } = props;
	return (
		<Link to={link}>
			<Button
				variant="ghost-on-accent"
				justifyContent="start"
				{...buttonProps}
				w="100%">
				<HStack spacing="3">
					<Icon as={icon} boxSize="6" />
					<Text>{label}</Text>
				</HStack>
			</Button>
		</Link>
	);
};

import { useEffect, useState } from "react";
import {
	Table,
	Thead,
	Th,
	Td,
	Tr,
	Tbody,
	Stack,
	HStack,
	Flex,
	Image,
	Box,
	CardHeader,
	Card,
	CardBody,
	Heading,
	AspectRatio,
	ScaleFade,
} from "@chakra-ui/react";
import clubLogo from "../../../assets/clublogo.png";
import logo from "../../../images/logo.png";
import ApiService from "../../../service/Api.service";
import { useAuthProvider } from "../../../context/AuthProvider";

const PlayerStandingsTable = ({ groupname, roundNo, eventId }) => {
	// User Details
	const user = useAuthProvider();

	const [playerStandings, setPlayerStandings] = useState([]);
	const [standingsRound, setStandingsRound] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 10;

	const startIndex = (currentPage - 1) * itemsPerPage;
	const endIndex = startIndex + itemsPerPage;

	const displayedPlayerStandings = playerStandings?.slice(startIndex, endIndex);
	async function fetchPlayerStandings() {
		try {
			let playerStand = await ApiService.httpGet(
				`/TourStandings?TourEventId=${eventId}&GroupName=${groupname}&RoundNo=${roundNo}`,
				user.userId
			);
			console.log("fetching player standings");
			if (playerStand.status === true) {
				setPlayerStandings(playerStand.TourStandings);
				setStandingsRound(playerStand.RoundNo);
			}
		} catch (err) {
			console.log(err);
		}
	}
	useEffect(() => {
		fetchPlayerStandings();
	}, [eventId, groupname, roundNo]);

	useEffect(() => {
		// Function to show the next values
		console.log("second useEffect");
		const showNextValues = () => {
			if (endIndex < playerStandings.length) {
				setCurrentPage(currentPage + 1);
			} else {
				setCurrentPage(1); // Start from the beginning if reached the end
			}
		};

		// Automatically show next values every 5 seconds
		const timer = setTimeout(showNextValues, 5000);

		// Clear the timer when the component unmounts or when the currentPage changes
		return () => clearTimeout(timer);
	}, [currentPage, endIndex, playerStandings.length]);

	if (playerStandings.length > 0) {
		return (
			<ScaleFade initialScale={0.9} in>
				<Card
					bg="white"
					boxShadow={"2xl"}
					minW={"70vw"}
					userSelect={"none"}
					draggable="false">
					<CardHeader py={1}>
						<HStack align="center" justify={"center"} minH={75} minW={500}>
							<Image
								src={clubLogo}
								alt="Club Image"
								blockSize={50}
								objectFit="contain"
								position="absolute"
								left={5}
								style={{ userSelect: "none" }}
								zIndex={1} // Ensure the overlay is above other components
							/>
							<Flex flex={1} alignItems="center" flexDirection="column">
								<Heading
									as={"h2"}
									size="sm"
									fontSize="2xl"
									fontWeight="bold"
									color="#0AB042"
									textAlign="center"
									w={"fit-content"}>
									PLAYER STANDINGS
								</Heading>
								<Heading
									as={"h4"}
									size="xs"
									fontSize="md"
									fontWeight="bold"
									color="#000000"
									textAlign="center"
									w={"fit-content"}>
									{standingsRound > 0 && `AFTER ROUND ${standingsRound}`}
								</Heading>
							</Flex>
							<Image
								src={logo}
								alt="Carrom Platform Logo"
								blockSize={75}
								objectFit="contain"
								position="absolute"
								right={0}
								style={{ userSelect: "none" }}
								zIndex={1} // Ensure the overlay is above other components
							/>
						</HStack>
					</CardHeader>

					<CardBody boxShadow="sm" pt={0}>
						<Table bgColor={"white"} variant="striped" colorScheme="gray">
							<Thead>
								<Tr>
									<Th textColor="#4B39B7" fontSize="sm" textAlign="center" px={3}>
										Position
									</Th>
									<Th textColor="#4B39B7" fontSize="sm" textAlign="start" px={3}>
										Player Name
									</Th>
									<Th textColor="#4B39B7" fontSize="sm" textAlign="center" px={3}>
										Matches Played
									</Th>
									<Th textColor="#4B39B7" fontSize="sm" textAlign="center" px={3}>
										Points
									</Th>
									<Th textColor="#4B39B7" fontSize="sm" textAlign="center" px={3}>
										Score
									</Th>
									<Th textColor="#4B39B7" fontSize="sm" textAlign="center" px={3}>
										Opp Strength
									</Th>
								</Tr>
							</Thead>

							<Tbody bg="white">
								{displayedPlayerStandings?.slice(0, 10)?.map((player, i) => (
									<Tr key={i}>
										<Td
											p={"0.5rem"}
											textAlign="center"
											fontSize="sm"
											fontWeight="medium"
											textColor="#000000">
											{player.Position}
										</Td>
										<Td
											p={"0.5rem"}
											textAlign="start"
											fontSize="sm"
											fontWeight="medium"
											textColor="#000000">
											{player.TeamName}
										</Td>
										<Td
											p={"0.5rem"}
											textAlign="center"
											fontSize="sm"
											fontWeight="medium"
											textColor="#000000">
											{player.MatchesPlayed}
										</Td>
										<Td
											p={"0.5rem"}
											textAlign="center"
											fontSize="sm"
											fontWeight="medium"
											textColor="#000000">
											{player.Points}
										</Td>
										<Td
											p={"0.5rem"}
											textAlign="center"
											fontSize="sm"
											fontWeight="medium"
											textColor="#000000">
											{player.PointsScored}
										</Td>
										<Td
											p={"0.5rem"}
											textAlign="center"
											fontSize="sm"
											fontWeight="medium"
											textColor="#000000">
											{player.OppStrength}
										</Td>
									</Tr>
								))}
							</Tbody>
						</Table>
					</CardBody>
				</Card>
			</ScaleFade>
		);
	} else {
		return null;
	}
};

export default PlayerStandingsTable;

import {
	HStack,
	Image,
	Table,
	Tbody,
	Td,
	Box,
	Tr,
	Th,
	Thead,
	VStack,
	FormControl,
	Select,
	Input,
	TableContainer,
	useBreakpointValue,
	Container,
	Text,
	Stack,
	Divider,
	useColorModeValue,
	FormLabel,
	IconButton,
	Icon,
	position,
	ButtonGroup,
	Button,
	Flex,
} from "@chakra-ui/react";

export const DoublesPairsTable = ({ playersData, eventType }) => {
	//Desktop Breakpoint Value
	const isDesktop = useBreakpointValue({ base: false, lg: true, xl: true });
	// Mobile Breakpoint Value
	const isMobile = useBreakpointValue({
		base: true,
		sm: true,
		md: true,
		lg: false,
		xl: false,
	});
	// Tablet Breakpoint Value
	const isTablet = useBreakpointValue({
		base: false,
		sm: false,
		md: true,
		lg: false,
		xl: false,
	});
	//Box Shadow
	const shadow = useColorModeValue("sm", "sm-dark");

	const tableDesktop = () => (
		<Box>
			<Box
				borderRadius={"2xl"}
				{...(isDesktop && { overflow: "hidden", _hover: { overflow: "auto" } })}
				{...((isMobile || isTablet) && { overflow: "auto" })}
				boxShadow={shadow}>
				<Table variant="striped">
					<Thead position="sticky" top={0} bgColor="black" zIndex="2">
						<Tr>
							<Th
								textColor="white"
								fontSize={isTablet ? "lg" : "xl"}
								py={4}
								{...(isMobile && { fontSize: "md" })}
								textAlign={"start"}>
								{eventType === "Doubles" ? "Player 1" : "Player Name"}
							</Th>
							{eventType === "Doubles" && (
								<Th
									textColor="white"
									fontSize={isTablet ? "lg" : "xl"}
									{...(isMobile && { fontSize: "md" })}
									textAlign={"start"}>
									Player 2
								</Th>
							)}

							<Th
								textColor="white"
								fontSize={isTablet ? "lg" : "xl"}
								{...(isMobile && { fontSize: "md" })}
								textAlign={"start"}>
								{eventType !== "Doubles" ? "Nick Name" : "Team Name"}
							</Th>
						</Tr>
					</Thead>
					<Tbody>
						{playersData?.map((board, index) => (
							<Tr key={index}>
								<Td
									textAlign={"start"}
									fontSize={"lg"}
									fontWeight="medium"
									{...(isMobile && {
										fontSize: "md",
										maxW: "-webkit-fit-content",
									})}
									maxW="-webkit-fit-content"
									whiteSpace={"break-spaces"}>
									{eventType !== "Teams" ? board.Player1Name : board.PlayerName}
								</Td>
								{eventType === "Doubles" && (
									<Td
										textAlign="start"
										fontSize={"lg"}
										fontWeight="medium"
										{...(isMobile && {
											fontSize: "md",
											maxW: "-webkit-fit-content",
										})}
										maxW="-webkit-fit-content">
										{board.Player2Name}
									</Td>
								)}
								<Td
									textAlign="start"
									fontSize={"lg"}
									fontWeight="medium"
									{...(isMobile && {
										fontSize: "md",
										maxW: "-webkit-fit-content",
									})}
									maxW="-webkit-fit-content">
									{eventType !== "Doubles" ? board.NickName : board.TeamName}
								</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</Box>
			<Divider />
			<HStack
				justify="space-between"
				p="1rem"
				bgColor="white"
				borderBottomRadius={"2xl"}>
				{/*---------------For Pagination-------------------------------*/}
			</HStack>
		</Box>
	);

	// const tableMobile = () => (
	// 	<Container
	// 		bgColor="white"
	// 		boxShadow={"sm"}
	// 		borderRadius="lg"
	// 		py="1rem"
	// 		px="1.5rem">
	// 		<Stack spacing={5} divider={<Divider />} fontSize="lg">
	// 			<HStack justify="space-between">
	// 				<Text fontWeight="bold">{data[0].id}</Text>
	// 				<Text>{data[0].player}</Text>
	// 			</HStack>
	// 			<Stack>
	// 				<HStack justify="space-between">
	// 					<Text fontWeight="bold">Played</Text>
	// 					<Text>{data[0].played}</Text>
	// 				</HStack>
	// 				<HStack justify="space-between">
	// 					<Text fontWeight="bold">Points</Text>
	// 					<Text>{data[0].points}</Text>
	// 				</HStack>
	// 				<HStack justify="space-between">
	// 					<Text fontWeight="bold">O/S</Text>
	// 					<Text>{data[0].os}</Text>
	// 				</HStack>
	// 				<HStack justify="space-between">
	// 					<Text fontWeight="bold">Net Diff</Text>
	// 					<Text>{data[0].netdiff}</Text>
	// 				</HStack>
	// 			</Stack>
	// 			<Stack direction={["column", "row"]} justify="space-between" pt="1rem">
	// 				{/*-----------For Pagination----------------------*/}
	// 			</Stack>
	// 		</Stack>
	// 	</Container>
	// );

	return tableDesktop();
};

import { Container, Heading, Text, useBreakpointValue, Stack, Box } from "@chakra-ui/react";
import { ManageEventCard } from "../../Components/Events/ManageEventCards/ManageEventCards";
import { useEffect, useState } from "react";
import ApiService from "../../service/Api.service";
import { BallTriangle } from "react-loader-spinner";
import { useAuthProvider } from "../../context/AuthProvider";

export const ManageEvents = () => {
	//Desktop BreakPoint Value
	const isDesktop = useBreakpointValue({
		base: false,
		lg: true,
	});
	//Page Header
	const pageHeader = useBreakpointValue({
		base: "xs",
		sm: "sm",
	});
	//Page Caption
	const pageCaption = useBreakpointValue({
		base: "sm",
		sm: "md",
	});

	// User Details
	const user = useAuthProvider();

	const [TournamentInfo, setTournamentInfo] = useState([]);

	let [loading, setLoading] = useState(false);

	const fetchTournmentsInfo = async () => {
		try {
			setLoading(true);
			const response = await ApiService.httpGet("/TourEvents?TourInfo=all", user.userId);
			console.log("TourInfo", response.Tournaments);
			setTournamentInfo(response.Tournaments);
			setLoading(false);
		} catch (err) {
			console.error(err.message);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchTournmentsInfo();
	}, []);

	return (
		<Container
			flex="1"
			overflowY="auto"
			maxWidth="100vw"
			marginTop={isDesktop ? "5rem" : "1rem"}
			marginX={0}
			paddingBottom={10}>
			<Stack
				spacing={{
					base: "8",
					lg: "6",
				}}>
				<Stack
					spacing={7}
					direction={{ base: "column", sm: "row" }}
					alignItems={{ sm: "center" }}
					justify="space-between">
					<Stack
						spacing="4"
						direction={{
							base: "column",
							lg: "row",
						}}
						justify="space-between"
						align={{
							base: "start",
							lg: "center",
						}}>
						<Stack spacing="1">
							<Heading size={pageHeader} fontWeight="bold">
								Manage Events
							</Heading>
							<Text fontWeight="bold" color="blackAlpha.500" fontSize={pageCaption}>
								View & Manage All Events
							</Text>
						</Stack>
					</Stack>
				</Stack>
				{loading ? (
					<Box
						position={"absolute"}
						bottom={"50%"}
						left={"50%"}
						p={0}
						m={0}
						zIndex={"overlay"}>
						<BallTriangle
							height={100}
							width={100}
							radius={5}
							color="#4fa94d"
							ariaLabel="ball-triangle-loading"
							wrapperClass={{}}
							wrapperStyle=""
							visible={true}
						/>
					</Box>
				) : (
					<div>
						{TournamentInfo.map((tournament, index) => {
							return (
								<ManageEventCard
									key={tournament.TournamentId}
									tournamentData={tournament}
									index={index}
								/>
							);
						})}
					</div>
				)}
			</Stack>
		</Container>
	);
};

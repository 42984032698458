import "./ManageClubCards.css";
import {
	Box,
	Center,
	Flex,
	Icon,
	Image,
	SimpleGrid,
	Stack,
	Text,
	Button,
	useColorModeValue,
	useToast,
	useDisclosure,
	AlertDialog,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogCloseButton,
	AlertDialogBody,
	AlertDialogOverlay,
	AlertDialogFooter,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	IconButton,
} from "@chakra-ui/react";
import { FiArrowLeft, FiEdit, FiMoreHorizontal, FiTrash } from "react-icons/fi";
import { useEffect, useState, useRef } from "react";

import club1 from "../../../images/club-img1.png";
import club2 from "../../../images/club-img2.png";
import club3 from "../../../images/club-img3.png";
import club4 from "../../../images/club-img4.png";
import club5 from "../../../images/club-img5.png";
import club6 from "../../../images/club-img6.png";
import { useNavigate } from "react-router-dom";
import ApiService from "../../../service/Api.service";

export const ManageClubCard = (props) => {
	//Box Shadow
	const shadow = useColorModeValue("sm", "sm-dark");
	const [showOverlay, setShowOverlay] = useState(false);

	const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();
	const cancelRef = useRef();

	// Toast
	let toast = useToast();

	let club = props.clubData;
	let setClubReload = props.setClubReload;
	let clubReload = props.clubReload;
	let setLoading = props.setLoadingDelete;

	let navigate = useNavigate();
	let NavigateToClubDetails = () => {
		navigate("/clubs/clubdetails", { state: { clubId: club.ClubId } });
		console.log("Navigate to ClubDetails..");
	};

	let NavigateToUpdateClubs = () => {
		navigate("/clubs/updateclub", { state: { clubId: club.ClubId } });
		console.log("Navigate to UpdateClubs..");
	};

	const handleShow = () => {
		setShowOverlay(!showOverlay);
	};

	let handleDelete = async () => {
		try {
			handleShow();
			onAlertClose();
			setLoading(true);
			let deleteResponse = await ApiService.httpDelete(`/Clubs?ClubId=${club.ClubId}`);
			console.log("Deleted Club:", club.ClubId);
			if (deleteResponse.status === true) {
				toast({
					title: "Delete Club",
					description: "Club Deleted Successfully!",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				setLoading(false);
			} else {
				toast({
					title: "Delete Club",
					description: "Failed to Delete Club",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				setLoading(false);
			}
			setLoading(false);
			setClubReload(!clubReload);
			console.log("Club Reloaded...");
		} catch (err) {
			console.log(err);
			onAlertClose();
			toast({
				title: "Delete Club",
				description: "Failed to Delete Club",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
			setLoading(false);
		}
	};
	let handleEdit = () => {
		try {
			handleShow();
		} catch (err) {
			console.log(err);
		}
	};
	return (
		// <SimpleGrid columns={{ base: 1, sm: 3, md: 4, lg: 5, xl: 6 }} spacing={5}>
		<>
			<div>
				<Box
					boxShadow={shadow}
					bgColor="white"
					borderRadius="lg"
					pb="4"
					_hover={{ border: "1px solid", borderColor: "blue.500" }}
					align="center">
					<Stack>
						<Flex justify="end" px="3" pt="1">
							<Menu direction="rtl">
								<MenuButton
									as={IconButton}
									icon={<FiMoreHorizontal />}
									size="sm"
									variant="ghost"
									color="blackAlpha.500"								/>
								<MenuList minWidth="unset" maxWidth="fit-content">
									<MenuItem onClick={NavigateToUpdateClubs}>Edit</MenuItem>
								</MenuList>
							</Menu>
						</Flex>
						<Center onClick={NavigateToClubDetails}>
							<Image
								src={club1}
								alt="Carrom Clubs"
								bgColor="purple.200"
								borderRadius="5"
								p="1.5"
								boxSize="76"
							/>
						</Center>
						<Text fontWeight="bold" fontSize="sm">
							{club?.ClubName}
						</Text>
						<Text fontWeight="semibold" fontSize="xs" color="blackAlpha.500">
							{club?.NickName}
						</Text>
					</Stack>
				</Box>
			</div>
			<AlertDialog
				motionPreset="slideInBottom"
				leastDestructiveRef={cancelRef}
				onClose={onAlertClose}
				isOpen={isAlertOpen}
				isCentered>
				<AlertDialogOverlay />
				<AlertDialogContent>
					<AlertDialogHeader>Delete Club</AlertDialogHeader>
					<AlertDialogCloseButton />
					<AlertDialogBody>
						Are you sure you want to delete{" "}
						<Text as={"span"} fontWeight={"bold"}>
							{club?.ClubName}
						</Text>{" "}
						club?
					</AlertDialogBody>
					<AlertDialogFooter>
						<Button ref={cancelRef} onClick={onAlertClose}>
							Cancel
						</Button>
						<Button colorScheme="red" ml={3} onClick={handleDelete}>
							Delete
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
		</>
		// </SimpleGrid>
	);
};

import {
	Container,
	Heading,
	useBreakpointValue,
	Stack,
	Card,
	CardHeader,
	useColorModeValue,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ApiService from "../../service/Api.service";
import { DoublesPairsTable } from "../../Components/Events/DoublesPairsTable/DoublesPairsTable";
import { useAuthProvider } from "../../context/AuthProvider";

export const DoublesPairs = () => {
	// User Details
	const user = useAuthProvider();

	//Desktop BreakPoint Value
	const isDesktop = useBreakpointValue({
		base: false,
		lg: true,
	});
	// Mobile Breakpoint Value
	const isMobile = useBreakpointValue({
		base: true,
		sm: true,
		md: false,
	});
	//Page Header
	const pageHeader = useBreakpointValue({
		base: "xs",
		sm: "sm",
	});
	//Box Shadow
	const shadow = useColorModeValue("sm", "sm-dark");
	//Card Heading
	const cardHeading = useBreakpointValue({
		base: "xs",
		md: "sm",
	});

	let { eventId } = useParams();
	let [eventTeamsData, setEventTeamsData] = useState([]);

	const fetchPairs = async () => {
		try {
			//setLoading(true);
			console.log("EventId", eventId);
			const response = await ApiService.httpGet(
				"/EventTeams?TeamsEventId=" + eventId,
				"U00184"
			);
			console.log("Pairs Response", response);
			setEventTeamsData(response);
		} catch (err) {
			console.error(err.message);
		}
	};

	useEffect(() => {
		fetchPairs();
	}, []);
	if (eventTeamsData?.EventType === "Doubles") {
		return (
			<Container
				flex="1"
				maxWidth="100vw"
				marginTop={isDesktop ? "7rem" : "5rem"}
				marginX={0}
				{...(isMobile && { px: "1" })}
				paddingBottom={10}>
				<Stack spacing="1rem">
					<Card variant={"elevated"} bgColor="white" boxShadow={shadow}>
						<CardHeader>
							<Heading as="h2" size={cardHeading}>
								{eventTeamsData?.EventName} Pairs
							</Heading>
						</CardHeader>
					</Card>

					<DoublesPairsTable
						playersData={eventTeamsData.EventTeams}
						eventType={eventTeamsData.EventType}
					/>
				</Stack>
			</Container>
		);
	}
};

import {
	Card,
	CardHeader,
	IconButton,
	Icon,
	Input,
	InputGroup,
	InputLeftElement,
	Stack,
	Text,
	useBreakpointValue,
	useColorModeValue,
	Menu,
	MenuList,
	MenuItem,
	MenuButton,
	CloseButton,
	Box,
	useToast,
	useDisclosure,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	FormControl,
	FormLabel,
	ModalFooter,
	Button,
} from "@chakra-ui/react";
import { useRef } from "react";
import { FiDownloadCloud, FiSearch, FiUploadCloud, FiSend, FiMoreVertical } from "react-icons/fi";
import { useState } from "react";
import ApiService from "../../service/Api.service";
import { CSVLink } from "react-csv";
import { useAuthProvider } from "../../context/AuthProvider";

export const DoublesTabCard = (props) => {
	// User Details
	const user = useAuthProvider();

	//Box Shadow
	const shadow = useColorModeValue("sm", "sm-dark");

	let setQuery = props.setQuery;
	let EventId = props.state.EventId;
	let EventType = props.state.EventType;
	const headers = [
		{ label: "Player Id", key: "TE_PlayerId" },
		{ label: "Event Id", key: "EventId" },
		{ label: "Player Name", key: "PlayerName" },
		{ label: "Nick Name", key: "NickName" },
		{ label: "Event", key: EventType === "Teams" ? "TourEventName" : "Event" },
		{ label: "Mobile", key: "Mobile" },
		{ label: "Email", key: "Email" },
		{ label: "Rank", key: "Rank" },
		{ label: "Live", key: "Live" },
	];

	let toast = useToast();
	const { isOpen: isSendMsgOpen, onOpen: openSendMsg, onClose: closeSendMsg } = useDisclosure();

	let [message, setMessage] = useState("");
	const [downloadLoading, setDownloadLoading] = useState(false);
	const [menuLoading, setMenuLoading] = useState(false);

	let [eventTeamsData, setEventTeamsData] = useState([]);
	const csvLinkEl = useRef();
	const fetchDoubles = async () => {
		try {
			setDownloadLoading(true);
			console.log("EventId", EventId);
			const response =
				EventType === "Doubles"
					? await ApiService.httpGet(
							"/FileUtilities?Class=DoublesPlayers&EventId=" + EventId,
							user.userId
					  )
					: EventType === "Teams" &&
					  (await ApiService.httpGet(
							"/EventTeams?TeamsEventId=" + EventId,
							user.userId
					  ));
			console.log("Doubles Download Data", response);
			setEventTeamsData(
				EventType === "Doubles"
					? response.DoublesPlayersJson
					: EventType === "Teams" && response.EventTeams
			);

			setTimeout(() => {
				csvLinkEl.current.link.click();
				setDownloadLoading(false);
			});
		} catch (err) {
			console.error(err.message);
		}
	};

	const notificationHandler = async (event) => {
		console.log("Action performed for:", event.target.value);
		let notification = event.target.value;
		switch (notification) {
			case "sendMessage":
				openSendMsg();
				console.log("Opened Modal");
				break;
			case "sendWelcome":
				try {
					setMenuLoading(true);
					let welcomeRespose = await ApiService.httpPost(
						`/ScoreLink?EventId=${EventId}&Class=Welcome`,
						{}
					);
					console.log("Sent Message:", welcomeRespose);
					if (welcomeRespose.status === true) {
						toast({
							title: "Send Welcome Message",
							description: "Welcome Message Sent Successfully!",
							status: "success",
							duration: 3000,
							isClosable: true,
							position: "top-right",
						});
					} else {
						toast({
							title: "Send Welcome Message",
							description: "Failed to Send Welcome Message",
							status: "error",
							duration: 3000,
							isClosable: true,
							position: "top-right",
						});
					}
					setMenuLoading(false);
				} catch (err) {
					console.log(err);
					toast({
						title: "Send Welcome Message",
						description: "Failed to Send Welcome Message",
						status: "error",
						duration: 3000,
						isClosable: true,
						position: "top-right",
					});
					setMenuLoading(false);
				}
				break;
			case "sendPlayers":
				try {
					setMenuLoading(true);
					let sentPlayersRes = await ApiService.httpPost(
						`/ScoreLink?EventId=${EventId}&Class=Players`,
						{}
					);
					console.log("Sent Message:", sentPlayersRes);
					if (sentPlayersRes.status === true) {
						toast({
							title: "Send Players Message",
							description: "Players Message Sent Successfully!",
							status: "success",
							duration: 3000,
							isClosable: true,
							position: "top-right",
						});
					} else {
						toast({
							title: "Send Players Message",
							description: "Failed to Send Players Message",
							status: "error",
							duration: 3000,
							isClosable: true,
							position: "top-right",
						});
					}
					setMenuLoading(false);
				} catch (err) {
					console.log(err);
					toast({
						title: "Send Players Message",
						description: "Failed to Send Players Message",
						status: "error",
						duration: 3000,
						isClosable: true,
						position: "top-right",
					});
					setMenuLoading(false);
				}
				break;
		}
	};

	const sendMessage = async () => {
		try {
			setMenuLoading(true);
			let msgRespose = await ApiService.httpPost(
				`/ScoreLink?EventId=${EventId}&Class=CustomMessage`,
				{ text: message }
			);
			console.log("Sent Message:", msgRespose);
			if (msgRespose.status === true) {
				toast({
					title: "Send Message",
					description: "Message Sent Successfully!",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			} else {
				toast({
					title: "Send Message",
					description: "Failed to Send Message",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
			closeSendMsg();
			setMenuLoading(false);
		} catch (err) {
			console.log(err);
			toast({
				title: "Send Message",
				description: "Failed to Send Message",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
			setMenuLoading(false);
		}
	};

	return (
		<>
			<Card variant={"elevated"} bgColor="white" boxShadow={shadow}>
				<CardHeader>
					<Stack
						direction={useBreakpointValue({
							base: "column",
							md: "row",
						})}
						spacing="1.2rem"
						justify="space-between">
						<Stack direction="column" spacing=".7rem">
							<Text
								as="h3"
								fontSize={useBreakpointValue({
									base: "lg",
									md: "xl",
								})}
								fontWeight="semibold">
								{EventType} Players
							</Text>
							<InputGroup maxW="xs" colorScheme="blue">
								<InputLeftElement pointerEvents="none">
									<Icon as={FiSearch} color="accent" boxSize="5" />
								</InputLeftElement>
								<Input
									w="100vw"
									placeholder="Search"
									onChange={(e) => {
										setQuery(e.target.value);
									}}
								/>
							</InputGroup>
						</Stack>
						<Stack direction="row" align="end" justify="flex-end" spacing=".7rem">
							<IconButton
								variant="ghost"
								size="md"
								color="#3182ce"
								icon={<FiDownloadCloud size="1.7rem" />}
								isLoading={downloadLoading}
								onClick={fetchDoubles}
							/>
							<CSVLink
								headers={headers}
								filename={
									EventType === "Teams"
										? "TeamsPlayers.csv"
										: "DoublesPlayers.csv"
								}
								data={eventTeamsData}
								asyncOnClick={true}
								ref={csvLinkEl}
							/>
							<Box>
								<Menu direction="rtl">
									{({ isOpen }) => (
										<>
											<MenuButton
												isActive={isOpen}
												as={IconButton}
												isLoading={menuLoading}
												variant="ghost"
												size="md"
												color="green.500"
												borderRadius="full"
												icon={
													isOpen ? (
														<CloseButton />
													) : (
														<FiMoreVertical size="1.7rem" />
													)
												}
											/>
											<MenuList zIndex="overlay">
												<MenuItem
													textColor="green.400"
													fontWeight="medium"
													value="sendMessage"
													onClick={notificationHandler}>
													Send Message
												</MenuItem>
												<MenuItem
													textColor="green.400"
													fontWeight="medium"
													value="sendWelcome"
													onClick={notificationHandler}>
													Send Welcome
												</MenuItem>
												<MenuItem
													textColor="green.400"
													fontWeight="medium"
													value="sendPlayers"
													onClick={notificationHandler}>
													Send Players
												</MenuItem>
											</MenuList>
										</>
									)}
								</Menu>
							</Box>
						</Stack>
					</Stack>
				</CardHeader>
			</Card>
			<Modal isOpen={isSendMsgOpen} onClose={closeSendMsg}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Send Message</ModalHeader>
					<ModalCloseButton />
					<ModalBody pb={6}>
						<FormControl>
							<FormLabel>Custom Message</FormLabel>
							<Input
								placeholder="Enter message"
								onChange={(e) => setMessage(e.target.value)}
							/>
						</FormControl>
					</ModalBody>

					<ModalFooter>
						<Button colorScheme="blue" mr={3} onClick={sendMessage}>
							Send Message
						</Button>
						<Button onClick={closeSendMsg}>Cancel</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
};

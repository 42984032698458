import {
	Box,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	Container,
	Flex,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Tr,
	useBreakpointValue,
	AbsoluteCenter,
	HStack,
	Image,
	Stack,
	Circle,
	VStack,
	Heading,
	Avatar,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ApiService from "../../service/Api.service";
import PlayerStandingsTable from "./GreenScreen/PlayerStandingsTable";
import TeamStandingsTable from "./GreenScreen/TeamStandingsTable";
import { BoardScoresTable } from "./GreenScreen/BoardScoresTable";
import clubLogo from "../../assets/cpl-logo.png";
import carromLogo from "../../images/logo.png";
import memberimg1 from "../../images/member-img1.png";
import Matchups from "./GreenScreen/Matchups";
import { PlayerMatchupResults } from "./GreenScreen/PlayerMatchupResults";
import { PlayerMatchup } from "./GreenScreen/PlayerMatchup";
import { PlayerProfile } from "./GreenScreen/PlayerProfile";
import { WebPubSubClient } from "@azure/web-pubsub-client";
import Marquee from "react-fast-marquee";
import { PlayerLeftJourney } from "./GreenScreen/PlayerLeftJourney";
import { PlayerRightJourney } from "./GreenScreen/PlayerRightJourney";
import { TeamLeftJourney } from "./GreenScreen/TeamLeftJourney";
import { TeamRightJourney } from "./GreenScreen/TeamRightJourney";
import { DaySchedule } from "./GreenScreen/DaySchedule";
import { FlashNews } from "./GreenScreen/FlashNews";
import { playerImgDir } from "../../Utilities/ImageLinks";
import { TourSchedule } from "./GreenScreen/TourSchedule";
import { MatchupResults } from "./GreenScreen/MatchupResults";
import { TeamMatchupResults } from "./GreenScreen/TeamMatchupResults";
import { useAuthProvider } from "../../context/AuthProvider";
import { clubImgDir } from "../../Utilities/ImageLinks";

export const NewScoreBoard = () => {
	// User Details
	const user = useAuthProvider();

	const isMobile = useBreakpointValue({
		base: true,
		sm: false,
		md: false,
		lg: false,
		xl: false,
	});
	const { eventId, boardNo } = useParams();
	let [boardData, setBoardData] = useState(null);

	const [overlaysState, setOverlaysState] = useState(null);

	// overlays
	async function getOverlaysState() {
		try {
			const resp = await ApiService.httpGet(
				`/StreamingConfigurations?TourEventId=${eventId}&Board=${boardNo}&Class=getStreamControlSB&User=U00184`,
				user.userId
			);
			const states = resp.StreamControl.map((c) => {
				return {
					control: c.ControlCode,
					state: c.State,
					streamControlId: c.StreamControlId,
				};
			});
			console.log(states);
			setOverlaysState(states);
		} catch (err) {
			console.log(err);
		}
	}

	// Groupname, RoundNo, MatchupId
	let fetchScoreData = async () => {
		try {
			let boardRes = await ApiService.httpGet(
				`/TourEventScores?Class=StreamingJSON&Board=${boardNo}&TourEventId=${eventId}`,
				user.userId
			);
			setBoardData(boardRes.payload);
		} catch (err) {
			console.log(err);
		}
	};

	const setupSocketConn = async () => {
		const resp = await fetch("https://carromslive.azurewebsites.net/pubsub/token");
		const token = await resp.json();

		const client = new WebPubSubClient(token.url);
		client.on("connected", (e) => {
			console.log(`Web PubSub connected`, e);
		});
		client.on("server-message", (e) => {
			if (e.message.data.event === "streaming-control-update") {
				const { boardId, tourEventId, streamControlId, state } = e.message.data;
				if (boardId === boardNo && tourEventId === eventId) {
					console.log("board matches - updating the control state");
					getOverlaysState();
				}
			} else if (e.message.data.event === "score-update") {
				const { board, tourEventId } = e.message.data;
				const boardId = board.toString();
				console.log(boardId, boardNo, tourEventId, eventId);
				if (boardId === boardNo && tourEventId === eventId) {
					console.log("updating the scores realtime");
					fetchScoreData();
				}
			}
		});
		client.start();
		return client;
	};

	useEffect(() => {
		fetchScoreData();
		getOverlaysState();
		let client;
		(async () => {
			client = await setupSocketConn();
		})();

		return () => {
			client.stop();
		};
	}, []);
	if (boardData) {
		return (
			<>
				<AbsoluteCenter zIndex="overlay">
					{boardData &&
						overlaysState &&
						overlaysState.map((overlay) => {
							if (
								boardData.isteams &&
								overlay.state &&
								overlay.control === "PlayerStandings"
							) {
								return (
									<PlayerStandingsTable
										key={overlay.control}
										eventId={eventId}
										groupname={boardData.groupname}
										roundNo={boardData.roundNo}
									/>
								);
							} else if (overlay.state && overlay.control === "Standings") {
								return (
									<PlayerStandingsTable
										key={overlay.control}
										eventId={eventId}
										groupname={boardData.groupname}
										roundNo={boardData.roundNo}
									/>
								);
							} else if (
								boardData.isteams &&
								overlay.state &&
								overlay.control === "TeamStandings"
							) {
								return (
									<TeamStandingsTable
										key={overlay.control}
										eventId={eventId}
										groupname={boardData.groupname}
										roundNo={boardData.roundNo}
									/>
								);
							} else if (overlay.state && overlay.control === "BoardScore") {
								return (
									<BoardScoresTable
										key={overlay.control}
										matchupId={boardData.matchupId}
									/>
								);
							} else if (
								boardData.isteams &&
								overlay.state &&
								overlay.control === "TeamLeftJourney"
							) {
								return (
									<TeamLeftJourney
										key={overlay.control}
										teamId={boardData.TeamsConfigs.Team1Id}
									/>
								);
							} else if (
								boardData.isteams &&
								overlay.state &&
								overlay.control === "TeamRightJourney"
							) {
								return (
									<TeamRightJourney
										key={overlay.control}
										teamId={boardData.TeamsConfigs.Team2Id}
									/>
								);
							} else if (overlay.state && overlay.control === "PlayerLeftJourney") {
								return (
									<PlayerLeftJourney
										key={overlay.control}
										teamId={boardData.playerAId}
									/>
								);
							} else if (overlay.state && overlay.control === "PlayerRightJourney") {
								return (
									<PlayerRightJourney
										key={overlay.control}
										teamId={boardData.playerBId}
									/>
								);
							} else if (overlay.state && overlay.control === "DaySchedule") {
								return (
									<DaySchedule key={overlay.control} tourId={boardData.tourId} />
								);
							} else if (overlay.state && overlay.control === "TournamentSchedule") {
								return (
									<TourSchedule key={overlay.control} tourId={boardData.tourId} />
								);
							} else if (
								boardData.isteams &&
								overlay.state &&
								overlay.control === "PlayerMatchupResults"
							) {
								return (
									<PlayerMatchupResults
										key={overlay.control}
										eventId={eventId}
										groupname={boardData.groupname}
										roundNo={boardData.roundNo}
									/>
								);
							} else if (
								boardData.isteams &&
								overlay.state &&
								overlay.control === "TeamMatchupResults"
							) {
								return (
									<TeamMatchupResults
										key={overlay.control}
										eventId={eventId}
										groupname={boardData.groupname}
										roundNo={boardData.roundNo}
									/>
								);
							} else if (
								!boardData.isteams &&
								overlay.state &&
								overlay.control === "MatchupResults"
							) {
								return (
									<MatchupResults
										key={overlay.control}
										eventId={eventId}
										groupname={boardData.groupname}
										roundNo={boardData.roundNo}
										isteams={boardData.isteams}
									/>
								);
							} else if (overlay.state && overlay.control === "PlayerProfile") {
								return <PlayerProfile eventId={eventId} boardNo={boardNo} />;
							}
							return null;
						})}
				</AbsoluteCenter>
				{boardData &&
				overlaysState &&
				(overlaysState?.find(
					(overlay) => overlay.state && overlay.control === "PlayerMatchup"
				) ||
					overlaysState?.find(
						(overlay) => overlay.state && overlay.control === "Matchups"
					)) ? (
					<PlayerMatchup
						matchupId={boardData.matchupId}
						tournamentname={boardData.tournamentname}
						round={boardData.round}
					/>
				) : (
					<Flex
						userSelect={"none"}
						as="section"
						direction="column"
						height="100vh"
						bg="#FFFF00">
						{overlaysState?.find(
							(overlay) => overlay.state && overlay.control === "TopBar"
						) && (
							<HStack
								justify={"space-between"}
								p={3}
								w={"100%"}
								position={"absolute"}
								top={0}>
								{/* Club Image */}
								<Image
									src={
										boardData.clubimage
											? `${clubImgDir}${boardData.clubimage}`
											: clubLogo
									}
									alt="Club Image"
									blockSize={100}
									objectFit="contain"
									draggable="false"
									margin='2'
									zIndex={1} // Ensure the overlay is above other components
								/>
								{/* Carrom Logo */}
								<Image
									src={carromLogo}
									alt="Carrom Platform Logo"
									blockSize={125}
									objectFit="contain"
									draggable="false"
									zIndex={1} // Ensure the overlay is above other components
								/>
							</HStack>
						)}
						<Stack
							dir="row"
							position={"absolute"}
							bottom={3}
							w={"100%"}
							my={0}
							spacing={1}>
							{boardData &&
								overlaysState &&
								overlaysState?.find(
									(overlay) => overlay.state && overlay.control === "FlashNews"
								) && <FlashNews eventId={eventId} boardNo={boardNo} />}
							{boardData &&
								overlaysState &&
								overlaysState?.find(
									(overlay) => overlay.state && overlay.control === "ScoreCard"
								) && (
									<HStack
										alignItems={"center"}
										w={"100%"}
										paddingTop={"0px"}
										paddingBottom={"0px"}
										bg="#4B39B7"
										px={3}>
										<HStack spacing={3} justify={"center"} w={"14%"}>
											<Stack justify={"center"} align={"center"} spacing={0}  >
												{boardData.PlayersData.MatchType === "Doubles" && (
													<Avatar
														src={`${playerImgDir}${boardData.PlayersData.TeamAPlayer1ImageLink}`}
														alt="Player Image"
														draggable="false"
														size={"lg"}
														
													/>
												)}
												{boardData.PlayersData.MatchType !== "Doubles" && (
													<Avatar
														src={`${playerImgDir}${boardData.PlayersData.TeamAPlayer1ImageLink}`}
														alt="Player Image"
														draggable="false"
														size={"lg"}
													/>
												)}
												<Text
													mt={0.5}
													textColor="#FFFFFF"
													fontSize={"xs"}
													textTransform={"uppercase"}
													fontWeight={"bold"}
													textAlign="center">
													{boardData.PlayersData.MatchType === "Doubles"
														? boardData.PlayersData.TeamAPlayer1Name.split(
																" ",
																1
														  )
														: boardData.PlayersData.TeamAPlayer1Name.split(" ", 1)}
												</Text>
											</Stack>
											{boardData.PlayersData.MatchType === "Doubles" && (
												<Stack
													justify={"center"}
													align={"center"}
													spacing={0}>
													<Avatar
														src={`${playerImgDir}${boardData.PlayersData.TeamAPlayer2ImageLink}`}
														alt="Player Image"
														draggable="false"
														size={"lg"}
													/>
													<Text
														mt={0.5}
														textColor="#FFFFFF"
														fontSize={"xs"}
														fontWeight={"bold"}
														textAlign="center"
														textTransform={"uppercase"}>
														{boardData.PlayersData.MatchType ===
														"Doubles"
															? boardData.PlayersData.TeamAPlayer2Name.split(
																	" ",
																	1
															  )
															: boardData.PlayersData
																	.TeamAPlayer2Name}
													</Text>
												</Stack>
											)}
										</HStack>
										<Text
											textColor={"white"}
											whiteSpace={"break-spaces"}
											width={"fit-content"}
											w={"21%"}
											fontSize={"larger"}
											textTransform={"uppercase"}
											px={1}
											fontWeight={"medium"}
											textAlign={"center"}>
											{boardData.tournamentname}
										</Text>

										<Card boxShadow={false} w={"30%"}>
											<CardBody boxShadow="sm" p={0}>
												<Table size={"md"} h={"90px"}>
													<Tbody>
														<Tr
															borderLeftWidth={5}
															borderColor={"#0B6623"}>
															<Td
																width={"fit-content"}
																borderBottomWidth={1}
																borderColor={"gray.200"}
																py={1}
																px={2}
																textAlign={"-webkit-center"}>
																{boardData.Player1queen ===
																	"True" && (
																	<Circle
																		size={"0.6rem"}
																		backgroundColor="#DA2C43"
																		justifySelf={"start"}
																	/>
																)}
															</Td>
															<Td
																borderBottomWidth={1}
																borderColor={"gray.200"}
																py={1}
																px={0.5}>
																<VStack
																	alignItems={"start"}
																	spacing={0}>
																	<Text
																		lineHeight={"shorter"}
																		whiteSpace="normal"
																		textAlign="left"
																		textTransform={"uppercase"}
																		fontSize={
																			boardData.eventtype ===
																			"Teams"
																				? "large"
																				: "xl"
																		}
																		fontStyle={"inherit"}
																		fontWeight={"bold"}>
																		{boardData.playerA}
																	</Text>
																	{boardData.eventtype ===
																		"Teams" && (
																		<Text
																			lineHeight={"shorter"}
																			whiteSpace="normal"
																			textColor={
																				boardData
																					?.TeamsConfigs
																					?.Team1Color !==
																				null
																					? boardData
																							?.TeamsConfigs
																							?.Team1Color
																					: "GrayText"
																			}
																			textAlign="left"
																			fontSize={"medium"}
																			textTransform={
																				"uppercase"
																			}
																			fontWeight={"bold"}
																			fontStyle={"italic"}>
																			{
																				boardData
																					?.TeamsConfigs
																					?.Team1Name
																			}
																		</Text>
																	)}
																</VStack>
															</Td>
															<Td
																width={"fit-content"}
																borderBottomWidth={1}
																borderColor={"gray.200"}
																py={1}
																px={1}
																textAlign={"-webkit-center"}>
																{boardData.isPlayerACurrentStrike && (
																	<Box
																		textColor="white"
																		width="0.5rem"
																		height="1rem"
																		backgroundColor="#4B39B7"
																		clipPath="polygon(0 50%, 100% 0, 100% 100%)"
																	/>
																)}
															</Td>

															{boardData.set1Display === "true" && (
																<Td
																	textAlign={"center"}
																	fontSize={"xl"}
																	fontWeight="medium"
																	textColor="#FFFFFF"
																	bg={
																		boardData.set1Display ===
																			"true" &&
																		boardData.set2Display ===
																			"false" &&
																		boardData.set3Display ===
																			"false"
																			? "#0B6623"
																			: "#4B39B7"
																	}
																	borderBottomWidth={1}
																	borderColor={
																		boardData.set1Display ===
																			"true" &&
																		boardData.set2Display ===
																			"false" &&
																		boardData.set3Display ===
																			"false"
																			? "#027a23"
																			: "#644de8"
																	}
																	py={1}
																	px={2}>
																	{boardData.playerASet1}
																</Td>
															)}

															{boardData.set2Display === "true" && (
																<Td
																	textAlign={"center"}
																	fontSize={"xl"}
																	fontWeight="medium"
																	textColor="#FFFFFF"
																	bg={
																		boardData.set1Display ===
																			"true" &&
																		boardData.set2Display ===
																			"true" &&
																		boardData.set3Display ===
																			"false"
																			? "#0B6623"
																			: "#4B39B7"
																	}
																	borderBottomWidth={1}
																	borderLeftWidth={1}
																	borderColor={
																		boardData.set1Display ===
																			"true" &&
																		boardData.set2Display ===
																			"true" &&
																		boardData.set3Display ===
																			"false"
																			? "#027a23"
																			: "#644de8"
																	}
																	py={1}
																	px={2}>
																	{boardData.playerASet2}
																</Td>
															)}
															{boardData.set3Display === "true" && (
																<Td
																	textAlign={"center"}
																	fontSize={"xl"}
																	fontWeight={"medium"}
																	textColor="#FFFFFF"
																	bg="#0B6623"
																	borderBottomWidth={1}
																	borderColor={"#027a23"}
																	py={1}
																	px={2}>
																	{boardData.playerASet3}
																</Td>
															)}
														</Tr>
														<Tr
															borderLeftWidth={5}
															borderColor={"#0B6623"}>
															<Td
																width={"fit-content"}
																borderTopWidth={1}
																borderColor={"gray.200"}
																py={1}
																px={2}
																textAlign={"-webkit-center"}>
																{boardData.Player2queen ===
																	"True" && (
																	<Circle
																		size={"0.6rem"}
																		backgroundColor="#DA2C43"
																		justifySelf={"start"}
																	/>
																)}
															</Td>
															<Td
																borderTopWidth={1}
																borderColor={"gray.200"}
																py={1}
																px={0.5}>
																<VStack
																	alignItems={"start"}
																	spacing={0}>
																	<Text
																		lineHeight={"shorter"}
																		whiteSpace="normal"
																		textTransform={"uppercase"}
																		textAlign="left"
																		fontSize={
																			boardData.eventtype ===
																			"Teams"
																				? "medium"
																				: "xl"
																		}
																		fontWeight={"bold"}>
																		{boardData.playerB}
																	</Text>
																	{boardData.eventtype ===
																		"Teams" && (
																		<Text
																			lineHeight={"shorter"}
																			whiteSpace="normal"
																			textTransform={
																				"uppercase"
																			}
																			textColor={
																				boardData
																					?.TeamsConfigs
																					?.Team2Color !==
																				null
																					? boardData
																							?.TeamsConfigs
																							?.Team2Color
																					: "GrayText"
																			}
																			textAlign="left"
																			fontSize={"lg"}
																			fontWeight={"bold"}
																			fontStyle={"italic"}>
																			{
																				boardData
																					?.TeamsConfigs
																					?.Team2Name
																			}
																		</Text>
																	)}
																</VStack>
															</Td>
															<Td
																width={"fit-content"}
																borderTopWidth={1}
																borderColor={"gray.200"}
																py={1}
																px={1}
																textAlign={"-webkit-center"}>
																{boardData.isPlayerBCurrentStrike && (
																	<Box
																		textColor="white"
																		width="0.5rem"
																		height="1rem"
																		backgroundColor="#4B39B7"
																		clipPath="polygon(0 50%, 100% 0, 100% 100%)"
																	/>
																)}
															</Td>
															{boardData.set1Display === "true" && (
																<Td
																	textAlign={"center"}
																	fontSize={"xl"}
																	fontWeight="medium"
																	textColor="#FFFFFF"
																	bg={
																		boardData.set1Display ===
																			"true" &&
																		boardData.set2Display ===
																			"false" &&
																		boardData.set3Display ===
																			"false"
																			? "#0B6623"
																			: "#4B39B7"
																	}
																	borderBottomWidth={1}
																	borderColor={
																		boardData.set1Display ===
																			"true" &&
																		boardData.set2Display ===
																			"false" &&
																		boardData.set3Display ===
																			"false"
																			? "#027a23"
																			: "#644de8"
																	}
																	py={1}
																	px={2}>
																	{boardData.playerBSet1}
																</Td>
															)}
															{boardData.set2Display === "true" && (
																<Td
																	textAlign={"center"}
																	fontSize={"xl"}
																	fontWeight="medium"
																	textColor="#FFFFFF"
																	bg={
																		boardData.set1Display ===
																			"true" &&
																		boardData.set2Display ===
																			"true" &&
																		boardData.set3Display ===
																			"false"
																			? "#0B6623"
																			: "#4B39B7"
																	}
																	borderBottomWidth={1}
																	borderLeftWidth={1}
																	borderColor={
																		boardData.set1Display ===
																			"true" &&
																		boardData.set2Display ===
																			"true" &&
																		boardData.set3Display ===
																			"false"
																			? "#027a23"
																			: "#644de8"
																	}
																	py={1}
																	px={2}>
																	{boardData.playerBSet2}
																</Td>
															)}
															{boardData.set3Display === "true" && (
																<Td
																	textAlign={"center"}
																	fontSize={"xl"}
																	fontWeight={"medium"}
																	textColor="#FFFFFF"
																	bg="#0B6623"
																	borderBottomWidth={1}
																	borderColor={"#027a23"}
																	py={1}
																	px={2}>
																	{boardData.playerBSet3}
																</Td>
															)}
														</Tr>
													</Tbody>
												</Table>
											</CardBody>
										</Card>
										<Stack
											textColor={"white"}
											whiteSpace={"break-spaces"}
											width={"fit-content"}
											w={"21%"}
											fontSize={"larger"}
											textTransform={"uppercase"}
											px={1}
											fontWeight={"medium"}
											textAlign={"center"}
											spacing={0}>
											<Text>{boardData.round}</Text>
											<Text>
												{boardData.board !== null &&
												boardData.board !== undefined
													? "Board - " + boardData?.board
													: ""}
											</Text>
										</Stack>
										<HStack spacing={3} justify={"center"} w={"14%"}>
											<Stack justify={"center"} align={"center"} spacing={0}>
												{boardData.PlayersData.MatchType === "Doubles" && (
													<Avatar
														src={`${playerImgDir}${boardData.PlayersData.TeamBPlayer1ImageLink}`}
														alt="Player Image"
														draggable="false"
														size={"lg"}						
													/>
												)}

												{boardData.PlayersData.MatchType !== "Doubles" && (
													<Avatar
														src={`${playerImgDir}${boardData.PlayersData.TeamBPlayer1ImageLink}`}
														alt="Player Image"
														draggable="false"
														size={"lg"}														
													/>
												)}
												<Text
													mt={0.5}
													textColor="#FFFFFF"
													fontSize={"xs"}
													textTransform={"uppercase"}
													fontWeight={"bold"}
													textAlign="center">
													{boardData.PlayersData.MatchType === "Doubles"
														? boardData.PlayersData.TeamBPlayer1Name.split(
																" ",
																1
														  )
														: boardData.PlayersData.TeamBPlayer1Name.split(" ", 1)}
												</Text>
											</Stack>
											{boardData.PlayersData.MatchType === "Doubles" && (
												<Stack
													justify={"center"}
													align={"center"}
													spacing={0}>
													<Avatar
														src={`${playerImgDir}${boardData.PlayersData.TeamBPlayer2ImageLink}`}
														alt="Player Image"
														draggable="false"
														size={"lg"}
													/>
													<Text
														mt={0.5}
														textColor="#FFFFFF"
														fontSize={"xs"}
														fontWeight={"bold"}
														textTransform={"uppercase"}
														textAlign="center">
														{boardData.PlayersData.MatchType ===
														"Doubles"
															? boardData.PlayersData.TeamBPlayer2Name.split(
																	" ",
																	1
															  )
															: boardData.PlayersData
																	.TeamBPlayer2Name}
													</Text>
												</Stack>
											)}
										</HStack>
									</HStack>
								)}
						</Stack>
					</Flex>
				)}
			</>
		);
	}
};

import {
	Card,
	CardHeader,
	Button,
	Stack,
	Text,
	useBreakpointValue,
	useColorModeValue,
	HStack,
	FormControl,
	Select,
	useToast,
	Menu,
	MenuList,
	MenuItem,
	MenuButton,
	CloseButton,
	IconButton,
	Skeleton,
} from "@chakra-ui/react";
import { FiMoreVertical } from "react-icons/fi";
import { async } from "q";
import ApiService from "../../service/Api.service";
import { useState } from "react";

export const GroupsTabCard = (props) => {
	// let [selectedGroup, setSelectedGroup] = useState("");
	// setSelectedGroup(props.groups[0]?.GroupName);

	let toast = useToast();

	const cardText = useBreakpointValue({
		base: "md",
		md: "lg",
	});

	let groupsLookupLoad = props.groupsLookupLoad;
	let groupsDataLoading = props.groupsDataLoading;

	const [menuLoading, setMenuLoading] = useState(false);

	const handleNotificaiton = async () => {
		try {
			setMenuLoading(true);
			let notificationRes = await ApiService.httpPost(
				`/ScoreLink?EventId=${props.state.EventId}&Class=Groups`,
				{}
			);
			console.log("Notificaiton Response", notificationRes);
			if (notificationRes.status === true) {
				toast({
					title: "Send Groups",
					description: "Groups Sent Successfully!",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			} else {
				toast({
					title: "Send Groups",
					description: "Failed to Send Groups",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
			setMenuLoading(false);
		} catch (err) {
			console.log(err);
			toast({
				title: "Send Groups",
				description: "Failed to Send Groups",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
			setMenuLoading(false);
		}
	};
	return (
		<Card variant={"elevated"} bgColor="white" boxShadow={useColorModeValue("sm", "sm-dark")}>
			<CardHeader>
				<Stack
					direction={useBreakpointValue({
						base: "column",
						sm: "row",
					})}
					spacing="1.2rem"
					justify="space-between">
					<Stack direction="column">
						<Text
							as="h3"
							fontSize={useBreakpointValue({
								base: "lg",
								md: "xl",
							})}
							fontWeight="semibold">
							Groups for {props.eventType}
						</Text>
						<Stack direction={{ base: "column", sm: "row" }}>
							<Skeleton isLoaded={!groupsDataLoading}>
								<Text color="accent" fontSize={cardText} fontWeight="semibold">
									{groupsDataLoading ? "Loading Data..." : props.groupName}
								</Text>
							</Skeleton>
							<Skeleton isLoaded={!groupsDataLoading}>
								<Text fontSize={cardText} fontWeight="semibold">
									{props.groupsLength
										? props.eventType === "Singles"
											? "Total Players: "
											: "Total Teams: "
										: groupsDataLoading
										? "Loading Data..."
										: ""}
									<Text as="span" color="accent">
										{props.groupsLength}
									</Text>
								</Text>
							</Skeleton>
						</Stack>
					</Stack>
					<Stack
						spacing="1rem"
						direction={useBreakpointValue({
							base: "row",
							sm: "column",
							md: "row",
						})}
						justify="end"
						align="end">
						<FormControl>
							<Skeleton isLoaded={!groupsLookupLoad} w="10vw">
								<Select
									name="Group"
									colorScheme="blue"
									onChange={props.updateGroupsData}>
									{props.groups?.map((group, index) => {
										return (
											<option key={group.GroupName} value={group.GroupName}>
												{group.GroupName}
											</option>
										);
									})}
								</Select>
							</Skeleton>
						</FormControl>
						<Menu direction="rtl">
							{({ isOpen }) => (
								<>
									<MenuButton
										isActive={isOpen}
										as={IconButton}
										isLoading={menuLoading}
										variant="ghost"
										size="md"
										color="green.500"
										borderRadius="full"
										icon={
											isOpen ? (
												<CloseButton />
											) : (
												<FiMoreVertical size="1.7rem" />
											)
										}
									/>
									<MenuList zIndex="overlay">
										<MenuItem
											textColor="green.400"
											fontWeight="medium"
											onClick={handleNotificaiton}>
											Send Groups
										</MenuItem>
									</MenuList>
								</>
							)}
						</Menu>
					</Stack>
				</Stack>
			</CardHeader>
		</Card>
	);
};

import {
	Container,
	Flex,
	Heading,
	SimpleGrid,
	Stack,
	Text,
	useBreakpointValue,
} from "@chakra-ui/react";
import * as React from "react";
import { StatsCards } from "../../Components/Dashboard/StatsCards/StatsCards";
import { Navbar } from "../../Components/Navbar/Navbar";
import { Sidebar } from "../../Components/Sidebar/Sidebar";
import { Table1, Table2 } from "../../Components/Dashboard/Tables/Tables";
import { useEffect } from "react";
import ApiService from "../../service/Api.service";
import { useState } from "react";
import { useAuthProvider } from "../../context/AuthProvider";

export const Home = () => {
	// User Details
	const user = useAuthProvider();

	//Desktop BreakPoint Value
	const isDesktop = useBreakpointValue({
		base: false,
		lg: true,
	});
	//Page Header
	const pageHeader = useBreakpointValue({
		base: "xs",
		sm: "sm",
	});
	//Page Caption
	const pageCaption = useBreakpointValue({
		base: "sm",
		sm: "md",
	});

	// States
	let [loading, setLoading] = useState(false);
	let [dashboardDetails, setDashboardDetails] = useState({});

	const fetchDashboardData = async () => {
		try {
			setLoading(true);
			let response = await ApiService.httpGet(`/Clubs?Class=Dashboard`, user.userId);
			console.log("Dashboard Data", response);
			setDashboardDetails(response);
			setLoading(false);
		} catch (err) {
			console.log(err);
			setLoading(false);
		}
	};
	useEffect(() => {
		fetchDashboardData();
	}, []);
	return (
		<Container
			flex="1"
			overflowY="auto"
			maxWidth="100vw"
			marginTop={isDesktop ? "5rem" : "1rem"}
			marginX={0}
			paddingBottom={10}
			css={{
				"::-webkit-scrollbar": {
					width: "0.5rem",
					height: "0.5rem",
				},
				"::-webkit-scrollbar-track": {
					background: "#bee3f8", //#CBCBE5
					borderRadius: "1rem",
				},
				"::-webkit-scrollbar-thumb": {
					background: "#3182ce", //#4E4EE9
					borderRadius: "1rem",
				},
			}}>
			<Stack
				spacing={{
					base: "8",
					lg: "6",
				}}>
				<Stack
					spacing="4"
					direction={{
						base: "column",
						lg: "row",
					}}
					justify="space-between"
					align={{
						base: "start",
						lg: "center",
					}}>
					<Stack spacing="1">
						<Heading size={pageHeader} fontWeight="bold">
							Dashboard
						</Heading>
						<Text color="blackAlpha.500" fontWeight="bold" fontSize={pageCaption}>
							Hello James, Welcome back!
						</Text>
					</Stack>
				</Stack>
				<StatsCards dashboardDetails={dashboardDetails} loading={loading} />
				<SimpleGrid columns={{ base: 1, lg: 2 }} spacing="6">
					<Table1 clubsDetails={dashboardDetails?.ActiveClubs} loading={loading} />

					<Table2 tourDetails={dashboardDetails?.UpcomingTournaments} loading={loading} />
				</SimpleGrid>
			</Stack>
		</Container>
	);
};

import {
	Box,
	Divider,
	Text,
	FormControl,
	FormLabel,
	Input,
	Stack,
	useColorModeValue,
	FormErrorMessage,
	Select,
} from "@chakra-ui/react";

export const TournamentsForm1 = ({ register, errors, clubs }) => {
	return (
		<>
			<Box>
				<Text fontSize="xl" mb={2} fontWeight="medium">
					Club
				</Text>
				<Box
					as="form"
					bg="bg-surface"
					boxShadow={useColorModeValue("sm", "sm-dark")}
					borderRadius="lg">
					<FormControl id="ClubId" isRequired>
						<Select
							name="Club"
							colorScheme="blue"
							placeholder="Select Club"
							{...register("ClubId", { required: true })}>
							{clubs.map((club, index) => {
								return <option value={club.ClubId}>{club.ClubName}</option>;
							})}
						</Select>
					</FormControl>
				</Box>
			</Box>
			<Box>
				<Text fontSize="xl" mb={2} fontWeight="medium">
					Basic Details
				</Text>
				<Box
					as="form"
					bg="bg-surface"
					boxShadow={useColorModeValue("sm", "sm-dark")}
					borderRadius="lg">
					<Stack
						spacing="5"
						px={{
							base: "4",
							md: "6",
						}}
						py={{
							base: "5",
							md: "6",
						}}>
						<Stack
							spacing="6"
							direction={{
								base: "column",
								md: "row",
							}}>
							<FormControl
								id="TournamentName"
								isInvalid={errors.TournamentName}>
								<FormLabel>Tournament Name</FormLabel>
								<Input
									type="text"
									{...register("TournamentName", {
										required: "Please enter Tournament Name",
										pattern: {
											value: /^[A-Za-z0-9 ]+$/,
											message:
												"Tournament Name can be alphanumeric and can contain whitespaces",
										},
										minLength: {
											value: 2,
											message: "Tournament Name can't be this short",
										},
									})}
								/>
								<FormErrorMessage>
									{errors.TournamentName && errors.TournamentName.message}
								</FormErrorMessage>
							</FormControl>
							<FormControl id="ContactEmail" isInvalid={errors.ContactEmail}>
								<FormLabel>Email Address</FormLabel>
								<Input
									type="email"
									{...register("ContactEmail", {
										required: "Please enter your email",
										pattern: {
											value:
												/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
											message: "Please enter a valid Email",
										},
									})}
								/>
								<FormErrorMessage>
									{errors.ContactEmail && errors.ContactEmail.message}
								</FormErrorMessage>
							</FormControl>
							<FormControl id="ContactNo" isInvalid={errors.ContactNo}>
								<FormLabel>Phone Number</FormLabel>
								<Input
									type="tel"
									{...register("ContactNo", {
										required: "Please enter your Phone Number",
										pattern: {
											value:
												/^(?:\+\d{1,2}\s?)?(?=\d{1,15}$)(?:\d{10}|\d{3}-\d{3}-\d{4}|\(\d{3}\)\s?\d{3}-\d{4})$|^\+91[1-9]\d{9}$|^\+44\d{10}$|^\+971\d{9}$/,
											message: "Please enter a valid Phone Number",
										},
									})}
								/>
								<FormErrorMessage>
									{errors.ContactNo && errors.ContactNo.message}
								</FormErrorMessage>
							</FormControl>
						</Stack>
						<Stack
							spacing="6"
							direction={{
								base: "column",
								md: "row",
							}}>
							<FormControl id="StartDate" isInvalid={errors.StartDate}>
								<FormLabel>Start Date</FormLabel>
								<Input
									type="date"
									{...register("StartDate", {
										required: "Please enter or select a Start Date",
									})}
								/>
								<FormErrorMessage>
									{errors.StartDate && errors.StartDate.message}
								</FormErrorMessage>
							</FormControl>
							<FormControl id="EndDate" isInvalid={errors.EndDate}>
								<FormLabel>End Date</FormLabel>
								<Input
									type="date"
									{...register("EndDate", {
										required: "Please enter or select a End Date",
									})}
								/>
								<FormErrorMessage>
									{errors.EndDate && errors.EndDate.message}
								</FormErrorMessage>
							</FormControl>
						</Stack>
					</Stack>
					<Divider />
				</Box>
			</Box>
			<Box>
				<Text fontSize="xl" mb={2} fontWeight="medium">
					Venue Details
				</Text>
				<Box
					as="form"
					bg="bg-surface"
					boxShadow={useColorModeValue("sm", "sm-dark")}
					borderRadius="lg">
					<Stack
						spacing="5"
						px={{
							base: "4",
							md: "6",
						}}
						py={{
							base: "5",
							md: "6",
						}}>
						<Stack
							spacing="6"
							direction={{
								base: "column",
								md: "row",
							}}>
							<FormControl id="FullAddress" isInvalid={errors.FullAddress}>
								<FormLabel>Full Address</FormLabel>
								<Input
									type="text"
									{...register("FullAddress", {
										required: "Please enter your Full Address",
									})}
								/>
								<FormErrorMessage>
									{errors.FullAddress && errors.FullAddress.message}
								</FormErrorMessage>
							</FormControl>
							<FormControl id="WebsiteURL" isInvalid={errors.WebsiteURL}>
								<FormLabel>Website URL</FormLabel>
								<Input
									type="url"
									{...register("WebsiteURL", {
										pattern: {
											value:
												/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
											message: "Please enter a valid Website URL",
										},
									})}
								/>
								<FormErrorMessage>
									{errors.WebsiteURL && errors.WebsiteURL.message}
								</FormErrorMessage>
							</FormControl>
						</Stack>
						<Stack
							spacing="6"
							direction={{
								base: "column",
								md: "row",
							}}>
							<FormControl
								id="DiscountDetails"
								isInvalid={errors.DiscountDetails}>
								<FormLabel>Discount Details</FormLabel>
								<Input
									type="number"
									{...register("DiscountDetails", {
										pattern: {
											value: /^[1-9]\d*(\.\d+)?$/,
											message: "Enter a valid discount value",
										},
									})}
								/>
								<FormErrorMessage>
									{errors.DiscountDetails && errors.DiscountDetails.message}
								</FormErrorMessage>
							</FormControl>
							<FormControl id="AdditionalNotes">
								<FormLabel>Additional Notes</FormLabel>
								<Input type="text" {...register("AdditionalNotes")} />
							</FormControl>
						</Stack>
					</Stack>
					<Divider />
				</Box>
			</Box>
		</>
	);
};

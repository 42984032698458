import {
	HStack,
	Table,
	Tbody,
	Td,
	Box,
	Tr,
	Th,
	Thead,
	FormControl,
	Input,
	useBreakpointValue,
	Divider,
	Checkbox,
	useToast,
	ButtonGroup,
	Button,
	Stack,
	Center,
	Text,
	Select,
	Skeleton,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import ApiService from "../../../service/Api.service";
import usePagination from "../../../hooks/usePagination";
import { Pagination } from "../../Pagination/Pagination";

export const DoublesPlayersTable = (props) => {
	//Desktop Breakpoint Value
	const isDesktop = useBreakpointValue({ base: false, xl: true });
	const isMobile = useBreakpointValue({
		base: true,
		md: false,
	});

	let toast = useToast();

	// Reload States
	let doublesReload = props.doublesReload;
	let setDoublesReload = props.setDoublesReload;

	let query = props.query;
	let EventId = props.state.EventId;
	let EventType = props.state.EventType;
	let doublesPlayers = props.doublesPlayers;

	// Loading State
	let doublesLoading = props.doublesLoading;

	const [buttonLoading, setButtonLoading] = useState(false);

	let updatedPlayersList = [];

	const updateLive = (live, player) => {
		player.Live = Number(live);
		updatedPlayersList.push({
			TourEventId: player.TourEventId,
			TE_PlayerId: player.TE_PlayerId,
			Live: live,
		});
		console.log("Updated Player", player);
		console.log("Updated live", live);
	};

	const savePlayers = async () => {
		try {
			setButtonLoading(true);
			let reqObj = {
				Players: doublesPlayers.map((event) => {
					return {
						TE_PlayerId: event.TE_PlayerId,
						Live: event.Live,
					};
				}),
			};
			console.log(reqObj);
			let savedPlayersRes = await ApiService.httpPut(
				`/TeamEventTeams?Class=BulkLive`,
				reqObj
			);
			console.log("Saved Players Response:", savedPlayersRes);
			setDoublesReload(!doublesReload);
			if (savedPlayersRes.status === true) {
				toast({
					title: "Save Player",
					description: "Saved Players successfully",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			} else if (savedPlayersRes.status === false) {
				toast({
					title: "Save Player",
					description: "Failed to Save Player",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			} else {
				toast({
					title: "Save Player",
					description: "Failed to Save Player",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
			setButtonLoading(false);
		} catch (err) {
			console.log(err);
			toast({
				title: "Save Player",
				description: "Failed to Save Player",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
		}
		setButtonLoading(false);
	};

	// Pagination
	let [rows, setRows] = useState(10);
	const perPage = rows;

	const {
		currentPage,
		currentData,
		jump,
		prev,
		next,
		first,
		last,
		setLast,
		setCurrentPage,
		maxPage,
	} = usePagination(
		doublesPlayers?.filter((player) => {
			if (query === "") {
				return player;
			} else if (
				player.PlayerName.toLowerCase().includes(query.toLowerCase()) ||
				player.NickName.toLowerCase().includes(query.toLowerCase()) ||
				player.Email.toLowerCase().includes(query.toLowerCase()) ||
				player.MobileNumber.includes(query)
			) {
				return player;
			}
		}),
		perPage
	);

	const handleChange = (e, p) => {
		jump(p);
	};

	const handleRows = (event) => {
		setRows(Number(event.target.value));
		setLast(Number(event.target.value));
		setCurrentPage(1);
		// 	setParamTwo(Number(event.target.value));
	};

	// const previousPage = (event) => {
	// 	setParamTwo(Number(paramOne));
	// 	setParamOne(Number(paramTwo - rows));
	// };
	// const nextPage = (event) => {
	// 	setParamOne(Number(paramTwo));
	// 	setParamTwo(Number(paramTwo + rows));
	// };

	const tableDesktop = () => (
		<Box>
			<Box
				borderTopRadius={"2xl"}
				overflow="auto"
				boxShadow={"sm"}
				css={{
					"::-webkit-scrollbar": {
						width: "0.2em",
						height: "0.2rem",
					},
					"::-webkit-scrollbar-track": {
						background: "#bee3f8",
						borderRadius: "1rem",
						borderTop: "30px",
					},
					"::-webkit-scrollbar-thumb": {
						background: "#3182ce",
						borderRadius: "1rem",
						borderTop: "30px",
					},
				}}>
				<Table variant="striped">
					<Thead position="sticky" top={0} bgColor="black" zIndex="2">
						<Tr>
							<Th flex="1" textColor="white" fontSize="md" textAlign="start" py={5}>
								Player
							</Th>

							<Th flex="1" textColor="white" fontSize="md" textAlign="start">
								Nick Name
							</Th>
							<Th flex="1" textColor="white" fontSize="md" textAlign="start">
								Mobile
							</Th>
							<Th flex="1" textColor="white" fontSize="md" textAlign="start">
								Email
							</Th>
							{EventType === "Teams" && (
								<Th flex="1" textColor="white" fontSize="md" textAlign="start">
									Live
								</Th>
							)}
						</Tr>
					</Thead>
					<Tbody>
						{doublesLoading
							? Array(5)
									.fill()
									.map((rows, index) => (
										<Tr key={index}>
											<Td>
												<Skeleton
													isLoaded={!doublesLoading}
													h={"1.2rem"}
													w={"100%"}
												/>
											</Td>
											<Td>
												<Skeleton
													isLoaded={!doublesLoading}
													h={"1.2rem"}
													w={"100%"}
												/>
											</Td>
											<Td>
												<Skeleton
													isLoaded={!doublesLoading}
													h={"1.2rem"}
													w={"100%"}
												/>
											</Td>
											<Td>
												<Skeleton
													isLoaded={!doublesLoading}
													h={"1.2rem"}
													w={"100%"}
												/>
											</Td>
											{EventType === "Teams" && (
												<Td>
													<Skeleton
														isLoaded={!doublesLoading}
														h={"1.2rem"}
														w={"100%"}
													/>
												</Td>
											)}
										</Tr>
									))
							: currentData()?.map((player) => (
									<Tr key={player.TE_PlayerId}>
										<Td
											flex="1"
											textAlign="start"
											fontSize="md"
											fontWeight="medium">
											{player.PlayerName}
										</Td>

										<Td
											flex="1"
											textAlign="start"
											fontSize="md"
											fontWeight="medium">
											{player.NickName}
										</Td>
										<Td
											flex="1"
											textAlign="start"
											fontSize="md"
											fontWeight="medium">
											{player.MobileNumber}
										</Td>
										<Td
											flex="1"
											textAlign="start"
											fontSize="md"
											fontWeight="medium">
											{player.Email}
										</Td>
										{EventType === "Teams" && (
											<Td>
												<FormControl>
													<Checkbox
														size="lg"
														defaultChecked={player.Live}
														textAlign="start"
														onChange={(e) => {
															player.Live = !player.Live;
															updateLive(
																e.target.checked ? 1 : 0,
																player
															);
														}}
													/>
												</FormControl>
											</Td>
										)}
									</Tr>
							  ))}
					</Tbody>
				</Table>
			</Box>
			<Divider />
			<Stack p="1rem" bgColor="white" borderBottomRadius={"2xl"}>
				{/*---------------For Pagination-------------------------------*/}
				{EventType === "Teams" && (
					<ButtonGroup justifyContent={"end"}>
						<Button
							color="white"
							bgColor="bg-accent"
							borderRadius="full"
							isLoading={buttonLoading}
							onClick={savePlayers}>
							Save
						</Button>
					</ButtonGroup>
				)}
				{isMobile ? (
					<Stack>
						<Center>
							<Pagination
								count={doublesPlayers?.length}
								pageSize={rows}
								siblingCount={0}
								page={currentPage}
								onChange={(e) => setCurrentPage(e.page)}
							/>
						</Center>
						<HStack justify="space-between">
							<HStack spacing={1}>
								<Text as="span" whiteSpace="normal">
									Rows per page{" "}
								</Text>
								<Select
									boxSize={"-webkit-max-content"}
									minW="-webkit-fit-content"
									size="sm"
									value={rows}
									onChange={(event) => handleRows(event)}>
									{[10, 25, 50].map((rows) => (
										<option key={rows} value={rows}>
											{rows}
										</option>
									))}
								</Select>
							</HStack>
							<Text whiteSpace="normal">
								Showing {currentPage * rows - rows + 1}-
								{currentPage * rows < doublesPlayers?.length
									? currentPage * rows
									: doublesPlayers?.length}{" "}
								of {doublesPlayers?.length} Entries
							</Text>
						</HStack>
					</Stack>
				) : (
					<HStack justify="space-between" pt={3}>
						<HStack spacing={1}>
							<Text as="span" whiteSpace={"nowrap"}>
								Rows per page{" "}
							</Text>
							<Select
								boxSize={"-webkit-max-content"}
								minW="-webkit-fit-content"
								value={rows}
								onChange={(event) => handleRows(event)}>
								{[10, 25, 50].map((rows) => (
									<option key={rows} value={rows}>
										{rows}
									</option>
								))}
							</Select>
						</HStack>
						<Center>
							<Pagination
								count={doublesPlayers?.length}
								pageSize={rows}
								siblingCount={1}
								page={currentPage}
								onChange={(e) => setCurrentPage(e.page)}
							/>
						</Center>
						<Text whiteSpace="normal">
							Showing {currentPage * rows - rows + 1}-
							{currentPage * rows < doublesPlayers?.length
								? currentPage * rows
								: doublesPlayers?.length}{" "}
							of {doublesPlayers?.length} Entries
						</Text>
					</HStack>
				)}
			</Stack>
		</Box>
	);

	// const tableMobile = () => (
	//   <Box>
	//     {doublesPlayers.map((player) => (
	//       <Container
	//         mb="1rem"
	//         bgColor="white"
	//         boxShadow={"sm"}
	//         borderRadius="lg"
	//         py="1rem"
	//         px="1.5rem"
	//       >
	//         <Stack spacing={5} divider={<Divider />} fontSize="lg">
	//           <HStack justify="space-between">
	//             <Text fontWeight="bold">Player</Text>
	//             <Text>{player.PlayerName}</Text>
	//           </HStack>
	//           <HStack justify="space-between">
	//             <Text fontWeight="bold">Event</Text>
	//             <Text>{player.TourEventName}</Text>
	//           </HStack>
	//           <HStack justify="space-between">
	//             <Text fontWeight="bold">Nick Name</Text>
	//             <Text>{player.NickName}</Text>
	//           </HStack>
	//           <HStack justify="space-between">
	//             <Text fontWeight="bold">Mobile</Text>
	//             <Text>{player.MobileNumber}</Text>
	//           </HStack>
	//           <HStack justify="space-between">
	//             <Text fontWeight="bold">Email</Text>
	//             <Text>{player.Email}</Text>
	//           </HStack>
	//           <HStack justify="space-between">
	//             <FormControl>
	//               <Input
	//                 placeholder={player.Rank}
	//                 textAlign="start"
	//                 fontSize="lg"
	//               />
	//             </FormControl>
	//             <FormControl>
	//               <Input
	//                 type="checkbox"
	//                 value={player.Live}
	//                 textAlign="start"
	//                 fontSize="lg"
	//               />
	//             </FormControl>
	//           </HStack>

	//           <Stack
	//             direction={["column", "row"]}
	//             justify="space-between"
	//             pt="1rem"
	//           >
	//             {/*-----------For Pagination----------------------*/}
	//           </Stack>
	//         </Stack>
	//       </Container>
	//     ))}
	//   </Box>
	// );

	return tableDesktop();
};

import {
	HStack,
	Table,
	Tbody,
	Td,
	Box,
	Tr,
	Th,
	Thead,
	Icon,
	FormControl,
	Select,
	Input,
	useBreakpointValue,
	Divider,
	Button,
	IconButton,
	Tooltip,
	useToast,
	Text,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
	useDisclosure,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalCloseButton,
	Center,
	Stack,
	ButtonGroup,
	useColorModeValue,
	Card,
	Tabs,
	TabList,
	Tab,
	CardHeader,
	Heading,
	Circle,
	CardBody,
	Slider,
	SliderTrack,
	SliderFilledTrack,
	SliderThumb,
	FormLabel,
	RadioGroup,
	Radio,
	VStack,
	Skeleton,
} from "@chakra-ui/react";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router";
import { GrScorecard } from "react-icons/gr";
import { GiTrophy } from "react-icons/gi";
import ApiService from "../../../service/Api.service";
import usePagination from "../../../hooks/usePagination";
import { Pagination } from "../../Pagination/Pagination";
import { BoardScoresTable } from "../../Events/BoardScores/BoardScoresTable";
import { CalendarWhite } from "../../../icons/Icons";
import _ from "lodash";
import { useAuthProvider } from "../../../context/AuthProvider";

export const MatchupsTable = (props) => {
	// User Details
	const user = useAuthProvider();

	//Desktop BreakPoint Value
	const isDesktop = useBreakpointValue({
		base: false,
		lg: true,
	});
	// Mobile BreakPoint Value
	const isMobile = useBreakpointValue({
		base: true,
		sm: true,
		md: false,
	});
	const isTablet = useBreakpointValue({
		base: false,
		sm: false,
		md: true,
		lg: false,
	});
	//Page Header
	const pageHeader = useBreakpointValue({
		base: "xs",
		sm: "sm",
	});
	//Box Shadow
	const shadow = useColorModeValue("sm", "sm-dark");
	//Card Heading
	const cardHeading = useBreakpointValue({
		base: "xs",
		md: "sm",
	});
	//Card Subtitle
	const cardSubtitle = useBreakpointValue({
		base: "lg",
		md: "xl",
	});
	// Round Info Text
	const roundText = useBreakpointValue({
		base: "md",
		md: "lg",
	});
	//Player Names
	const playerNames = useBreakpointValue({
		base: "md",
		sm: "lg",
		md: "2xl",
	});
	const playerScores = useBreakpointValue({
		base: "1.6rem",
		md: "2rem",
	});

	// Loading States
	let matchupsLoad = props.matchupsLoad;

	let navigate = useNavigate();

	const { isOpen, onOpen, onClose } = useDisclosure();
	// BoardScores Modal
	const { isOpen: isBoardOpen, onOpen: onBoardOpen, onClose: onBoardClose } = useDisclosure();

	// const [updatedScoreData, setUpdatedScoreData] = useState([]);
	// let updatedScoreData = [];
	const toast = useToast();

	const [generateLoading, setGenerateLoading] = useState(false);
	const [saveLoading, setSaveLoading] = useState(false);
	const [bsLoading, setBSLoading] = useState(null);
	const [boardLoading, setBoardLoading] = useState(false);
	// let navigateToScoreBord = (matchup) => {
	// 	console.log(props);
	// 	// navigate('/boardscores', {state: {matchupId: matchup.MatchupId}})
	// 	window.open(
	// 		`/boardscores?matchupId=${matchup.MatchupId}`,
	// 		"_board"
	// 	);
	// };

	// BoardScores States
	const [currMatchupId, setCurrMatchupId] = useState("");
	const [setCount, setSetCount] = useState(1);
	let [selectedSet, setSelectedSet] = useState(1);
	let [boardDetails, setBoardDetails] = useState(null);
	let [playersData, setPlayersData] = useState([]);
	const [editMode, setEditMode] = useState(false);
	const [player1Score, setPlayer1Score] = useState(null);
	const [player2Score, setPlayer2Score] = useState(null);
	const [strikeSlider, setStrikeSlider] = useState(0);
	const [player1Dir, setPlayer1Dir] = useState("");
	const [player2Dir, setPlayer2Dir] = useState("");
	const inputFocus = useRef(null);
	const [tabIndex, setTabIndex] = useState(0);
	const [koSetFlags, setKOSetFlags] = useState({});

	let generateMatchUps = async () => {
		try {
			setGenerateLoading(true);
			console.log("Generating Matchups");
			let matchUpsReponse = await ApiService.httpPost("/TourEventMatchupsByGroup", {
				TourEventId: props.state.EventId,
				TourId: props.state.TourId,
				GroupName: props.selectedGroup.GroupId,
			});
			console.log("Generated Matchup Response: ", matchUpsReponse);
			if (matchUpsReponse.status === true) {
				onClose();
				toast({
					title: "Generate Matchups!",
					description: "Matchups Generated Successfully.",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				props.refreshMatchupsView();
				props.fetchFlags();
			} else if (matchUpsReponse.status === false) {
				onClose();
				toast({
					title: "Generate Matchups!",
					description: matchUpsReponse.Message,
					status: "warning",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			} else {
				onClose();
				toast({
					title: "Generate Matchups!",
					description: "Failed to Generate Matchups.",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
			setGenerateLoading(false);
		} catch (err) {
			onClose();
			toast({
				title: "Generate Matchups!",
				description: "Failed to Generate Matchups.",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
			setGenerateLoading(false);
		}
	};

	const updateMatchupsData = (propertyName, value, matchupId) => {
		if (!isNaN(value)) {
			props.setMatchupsData((prevData) => {
				const updatedData = prevData.map((matchup) =>
					matchup.MatchupId === matchupId
						? { ...matchup, [propertyName]: value }
						: { ...matchup }
				);
				return updatedData;
			});
		}
	};

	const handleStatusUpdate = async (propertyName, value, matchupId) => {
		try {
			let isCompleted = value === "true" ? 1 : 0;
			let updatedStatusRes = await ApiService.httpPut(
				`/TourEventScores?MatchupId=${matchupId}&isCompleted=${isCompleted}`
			);
			if (updatedStatusRes.status === true) {
				console.log("Matchups Updated Status:", updatedStatusRes);
				toast({
					title: "Update Matchup Status",
					description: "Matchup Status Updated Successfully!",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				props.setMatchupsData((prevData) => {
					const updatedData = prevData.map((matchup) =>
						matchup.MatchupId === matchupId
							? { ...matchup, [propertyName]: value }
							: { ...matchup }
					);
					return updatedData;
				});
				props.setMatchupsInitData((prevData) => {
					const updatedData = prevData.map((matchup) =>
						matchup.MatchupId === matchupId
							? { ...matchup, [propertyName]: value }
							: { ...matchup }
					);
					return updatedData;
				});
				props.refreshMatchups();
			} else if (updatedStatusRes.isTie === true) {
				console.log("Matchups Updated Status:", updatedStatusRes);
				toast({
					title: "Update Matchup Status",
					description: "It's a tie." + " " + updatedStatusRes.Message,
					status: "warning",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			} else {
				console.log("Matchups Updated Status:", updatedStatusRes);
				toast({
					title: "Update Matchup Status",
					description: "Failed to Update Matchup Status",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
		} catch (err) {
			console.log(err);
			toast({
				title: "Update Matchup Status",
				description: "Failed to Update Matchup Status",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
		}
	};

	const saveScores = async () => {
		try {
			setSaveLoading(true);
			let saveDataObj = {
				Scores: props.matchupsData
					.filter((updatedObj) => {
						const correspondingInitialObj = props.matchupsInitData.find(
							(initialObj) => initialObj.MatchupId === updatedObj.MatchupId
						);
						return (
							correspondingInitialObj &&
							!_.isEqual(correspondingInitialObj, updatedObj)
						);
					})
					.map((obj) => ({
						MatchupId: obj.MatchupId,
						Team1Score: obj.Team1Score,
						Team2Score: obj.Team2Score,
					})),
			};
			let updatedMatchUpsScoreResponse = await ApiService.httpPut(
				`/TourEventMatchupsByGroup?IsBulk=Yes`,
				saveDataObj
			);
			if (updatedMatchUpsScoreResponse.status === true) {
				toast({
					title: "Save Scores",
					description: "Scores Saved Successfully.",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				console.log("Saved Scores: ", updatedMatchUpsScoreResponse);
				props.refreshMatchups();
			} else if (updatedMatchUpsScoreResponse.status === false) {
				toast({
					title: "Save Scores",
					description: updatedMatchUpsScoreResponse.Message,
					status: "warning",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			} else {
				toast({
					title: "Save Scores",
					description: "Failed to Save Scores.",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
			setSaveLoading(false);
		} catch (err) {
			console.log(err);
			toast({
				title: "Save Scores!",
				description: "Failed to Save Scores.",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
			setSaveLoading(false);
		}
	};

	// Board Scores modal
	const handleTabsChange = (index) => {
		setTabIndex(index);
		setSelectedSet(index + 1);
		console.log("Tab Index", index + 1);
	};
	const fetchBoardScores = async (matchupId, setIndex) => {
		try {
			setBSLoading(matchupId);
			setBoardLoading(true);
			let url = `/TourEventScores?MatchupId=${matchupId}`;
			let boardScoresRes = await ApiService.httpGet(url, user.userId);
			console.log("Score boards data:", boardScoresRes);
			if (setIndex === -1 && "LatestKnockoutSet" in boardScoresRes) {
				setIndex = boardScoresRes.LatestKnockoutSet;
				setTabIndex(boardScoresRes.LatestKnockoutSet - 1);
				setSelectedSet(boardScoresRes.LatestKnockoutSet);
			} else if (setIndex === -1) {
				setIndex = 1;
			}
			setSetCount(boardScoresRes.SetCount);
			setKOSetFlags({
				isSet1Accessible: boardScoresRes.isSet1Accessible,
				isSet2Accessible: boardScoresRes.isSet2Accessible,
				isSet3Accessible: boardScoresRes.isSet3Accessible,
			});
			setBoardDetails(boardScoresRes.ScoreDetails);
			setPlayer1Score(boardScoresRes.ScoreDetails[setIndex - 1].Player1TotalScore);
			setPlayer2Score(boardScoresRes.ScoreDetails[0].Player2TotalScore);
			setPlayersData([
				{
					Id: boardScoresRes.ScoreDetails[setIndex - 1].Player1Id,
					Name: boardScoresRes.ScoreDetails[setIndex - 1].Player1Name,
				},
				{
					Id: boardScoresRes.ScoreDetails[setIndex - 1].Player2Id,
					Name: boardScoresRes.ScoreDetails[setIndex - 1].Player2Name,
				},
			]);
			setStrikeSlider(
				boardScoresRes.ScoreDetails[setIndex - 1]?.FirstStrike !== null
					? boardScoresRes.ScoreDetails[setIndex - 1]?.FirstStrike ===
					  boardScoresRes.ScoreDetails[setIndex - 1]?.Player1Id
						? -1
						: boardScoresRes.ScoreDetails[setIndex - 1]?.FirstStrike ===
						  boardScoresRes.ScoreDetails[setIndex - 1]?.Player2Id
						? 1
						: 0
					: 0
			);
			if (
				!(
					boardScoresRes.ScoreDetails[setIndex - 1]?.Team1SittingLeft === false &&
					boardScoresRes.ScoreDetails[setIndex - 1]?.Team2SittingLeft === false
				)
			) {
				setPlayer1Dir(
					boardScoresRes.ScoreDetails[setIndex - 1]?.Team1SittingLeft === true ? "L" : "R"
				);
				setPlayer2Dir(
					boardScoresRes.ScoreDetails[setIndex - 1]?.Team2SittingLeft === true ? "L" : "R"
				);
			} else {
				setPlayer1Dir("");
				setPlayer2Dir("");
			}
			setBSLoading(null);
			setBoardLoading(false);
			onBoardOpen();
		} catch (err) {
			console.log(err);
			setBSLoading(null);
			setBoardLoading(false);
		}
	};

	function refreshOnScoresSave(matchupId, setIndex, boardScoresRes) {
		try {
			setBSLoading(matchupId);
			setBoardLoading(true);
			if (setIndex === -1 && "LatestKnockoutSet" in boardScoresRes) {
				setIndex = boardScoresRes.LatestKnockoutSet;
				setTabIndex(boardScoresRes.LatestKnockoutSet - 1);
				setSelectedSet(boardScoresRes.LatestKnockoutSet);
			} else if (setIndex === -1) {
				setIndex = 1;
			}
			setSetCount(boardScoresRes.SetCount);
			setKOSetFlags({
				isSet1Accessible: boardScoresRes.isSet1Accessible,
				isSet2Accessible: boardScoresRes.isSet2Accessible,
				isSet3Accessible: boardScoresRes.isSet3Accessible,
			});
			setBoardDetails(boardScoresRes.ScoreDetails);
			setPlayer1Score(boardScoresRes.ScoreDetails[setIndex - 1].Player1TotalScore);
			setPlayer2Score(boardScoresRes.ScoreDetails[0].Player2TotalScore);
			setPlayersData([
				{
					Id: boardScoresRes.ScoreDetails[setIndex - 1].Player1Id,
					Name: boardScoresRes.ScoreDetails[setIndex - 1].Player1Name,
				},
				{
					Id: boardScoresRes.ScoreDetails[setIndex - 1].Player2Id,
					Name: boardScoresRes.ScoreDetails[setIndex - 1].Player2Name,
				},
			]);
			setStrikeSlider(
				boardScoresRes.ScoreDetails[setIndex - 1]?.FirstStrike !== null
					? boardScoresRes.ScoreDetails[setIndex - 1]?.FirstStrike ===
					  boardScoresRes.ScoreDetails[setIndex - 1]?.Player1Id
						? -1
						: boardScoresRes.ScoreDetails[setIndex - 1]?.FirstStrike ===
						  boardScoresRes.ScoreDetails[setIndex - 1]?.Player2Id
						? 1
						: 0
					: 0
			);
			if (
				!(
					boardScoresRes.ScoreDetails[setIndex - 1]?.Team1SittingLeft === false &&
					boardScoresRes.ScoreDetails[setIndex - 1]?.Team2SittingLeft === false
				)
			) {
				setPlayer1Dir(
					boardScoresRes.ScoreDetails[setIndex - 1]?.Team1SittingLeft === true ? "L" : "R"
				);
				setPlayer2Dir(
					boardScoresRes.ScoreDetails[setIndex - 1]?.Team2SittingLeft === true ? "L" : "R"
				);
			} else {
				setPlayer1Dir("");
				setPlayer2Dir("");
			}
			setBSLoading(null);
			setBoardLoading(false);
			onBoardOpen();
		} catch (err) {
			console.log(err);
			setBSLoading(null);
			setBoardLoading(false);
		}
	}

	const saveBoardScores = async () => {
		try {
			let reqObj = {
				MatchupId: currMatchupId,
				Team1Score: player1Score,
				Team2Score: player2Score,
			};
			if (setCount > 1) {
				reqObj = {
					MatchupId: currMatchupId,
					Team1Score: player1Score,
					Team2Score: player2Score,
					KnockoutSet: selectedSet,
				};
			}
			let url = `/TourEventMatchupsByGroup?MatchupId=${currMatchupId}`;
			let saveScoresResp = await ApiService.httpPut(url, reqObj);
			if (saveScoresResp.status === true) {
				toast({
					title: "Save Board Scores",
					description: "Board Scores Saved Successfully.",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				console.log("Saved Scores: ", saveScoresResp);
				fetchBoardScores(currMatchupId, selectedSet);
				props.refreshMatchups();
			} else {
				toast({
					title: "Save Board Scores",
					description: saveScoresResp.Message,
					status: "warning",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
		} catch (err) {
			console.log(err);
			toast({
				title: "Save Board Scores",
				description: "Failed to Save Board Scores.",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
		}
	};

	const saveFirstStrike = async (value) => {
		setStrikeSlider(value);
		if (value !== 0) {
			try {
				let reqObj = {
					MatchupId: currMatchupId,
					FirstStrike:
						value === -1
							? boardDetails[tabIndex]?.Player1Id
							: boardDetails[tabIndex]?.Player2Id,
				};
				let url = "/TourEventScores?Class=UpdateFirstStrike";
				if (setCount > 1) {
					url = `/TourEventScores?Class=UpdateFirstStrike&KnockoutSet=${selectedSet}`;
				}
				let strikeRes = await ApiService.httpPut(url, reqObj);
				if (strikeRes.status === true) {
					toast({
						title: "Save First Strike",
						description: "First Strike Saved Successfully.",
						status: "success",
						duration: 3000,
						isClosable: true,
						position: "top-right",
					});
					fetchBoardScores(currMatchupId, selectedSet);
				} else {
					toast({
						title: "Save First Strike",
						description: "Failed to Save First Strike.",
						status: "error",
						duration: 3000,
						isClosable: true,
						position: "top-right",
					});
				}
				console.log(reqObj);
			} catch (err) {
				console.log(err);
				toast({
					title: "Save First Strike",
					description: "Failed to Save First Strike.",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
		}
	};

	const updatePlayerPosition = async (position, value) => {
		let reqObj;
		if (position === "Player1" && value !== "") {
			if (position === "Player1" && value === "L") {
				reqObj = {
					MatchupId: boardDetails[tabIndex]?.MatchupId,
					SittingLeft: boardDetails[tabIndex]?.Player1Id,
				};
			} else {
				reqObj = {
					MatchupId: boardDetails[tabIndex]?.MatchupId,
					SittingLeft: boardDetails[tabIndex]?.Player2Id,
				};
			}
		} else if (position === "Player2" && value !== "") {
			if (position === "Player2" && value === "L") {
				reqObj = {
					MatchupId: boardDetails[tabIndex]?.MatchupId,
					SittingLeft: boardDetails[tabIndex]?.Player2Id,
				};
			} else {
				reqObj = {
					MatchupId: boardDetails[tabIndex]?.MatchupId,
					SittingLeft: boardDetails[tabIndex]?.Player1Id,
				};
			}
		}
		try {
			let url = `/TourEventScores?Class=UpdateSittingLeft`;
			if (setCount > 1) {
				url = `/TourEventScores?Class=UpdateSittingLeft&KnockoutSet=${selectedSet}`;
			}
			let res = await ApiService.httpPut(url, reqObj);
			if (res.status === true) {
				toast({
					title: "Update Player Seating Position",
					description: "Player Seating Position Updated Successfully.",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				fetchBoardScores(currMatchupId, selectedSet);
			} else {
				toast({
					title: "Update Player Seating Position",
					description: "Failed to Update Player Seating Position.",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
		} catch (err) {
			console.log(err);
			toast({
				title: "Update Player Seating Position",
				description: "Failed to Update Player Seating Position.",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
		}
	};

	useEffect(() => {
		editMode && inputFocus.current.focus();
	}, [editMode]);

	// Pagination
	let [rows, setRows] = useState(10);
	const perPage = rows;

	const {
		currentPage,
		currentData,
		jump,
		prev,
		next,
		first,
		last,
		setLast,
		setCurrentPage,
		maxPage,
	} = usePagination(
		props.matchupsData?.filter((player) => {
			if (props.query === "") {
				return player;
			} else if (
				player.Player1.toLowerCase().includes(props.query.toLowerCase()) ||
				player.Player2.toLowerCase().includes(props.query.toLowerCase())
			) {
				return player;
			}
		}),
		perPage
	);

	const handleChange = (e, p) => {
		jump(p);
	};

	const handleRows = (event) => {
		setRows(Number(event.target.value));
		setLast(Number(event.target.value));
		setCurrentPage(1);
		// 	setParamTwo(Number(event.target.value));
	};

	// const previousPage = (event) => {
	// 	setParamTwo(Number(paramOne));
	// 	setParamOne(Number(paramTwo - rows));
	// };
	// const nextPage = (event) => {
	// 	setParamOne(Number(paramTwo));
	// 	setParamTwo(Number(paramTwo + rows));
	// };

	const tableDesktop = () => (
		<>
			<Box>
				<Box
					borderTopRadius={"2xl"}
					overflow="auto"
					boxShadow={"sm"}
					css={{
						"::-webkit-scrollbar": {
							width: "0.2rem",
							height: "0.2rem",
						},
						"::-webkit-scrollbar-track": {
							background: "#bee3f8",
							borderRadius: "1rem",
						},
						"::-webkit-scrollbar-thumb": {
							background: "#3182ce",
							borderRadius: "1rem",
						},
					}}>
					<Table variant="striped">
						<Thead position="sticky" top={0} bgColor="black" zIndex="2">
							<Tr>
								<Th
									flex="1"
									textColor="white"
									fontSize="md"
									textAlign="start"
									py={5}>
									Board
								</Th>
								<Th flex="1" textColor="white" fontSize="md" textAlign="start">
									Status
								</Th>
								<Th flex="1" textColor="white" fontSize="md" textAlign="start">
									Player 1
								</Th>
								<Th flex="1" textColor="white" fontSize="md" textAlign="start">
									Score
								</Th>
								<Th flex="1" textColor="white" fontSize="md" textAlign="start"></Th>
								<Th flex="1" textColor="white" fontSize="md" textAlign="start">
									Player 2
								</Th>
								<Th flex="1" textColor="white" fontSize="md" textAlign="start">
									Score
								</Th>
								<Th flex="1" textColor="white" fontSize="md" textAlign="start"></Th>
							</Tr>
						</Thead>
						<Tbody>
							{matchupsLoad
								? Array(5)
										.fill()
										.map((rows, index) => (
											<Tr key={index}>
												<Td>
													<Skeleton
														isLoaded={!matchupsLoad}
														h={"1.2rem"}
														w={"100%"}
													/>
												</Td>
												<Td>
													<Skeleton
														isLoaded={!matchupsLoad}
														h={"1.2rem"}
														w={"100%"}
													/>
												</Td>
												<Td>
													<Skeleton
														isLoaded={!matchupsLoad}
														h={"1.2rem"}
														w={"100%"}
													/>
												</Td>
												<Td>
													<Skeleton
														isLoaded={!matchupsLoad}
														h={"1.2rem"}
														w={"100%"}
													/>
												</Td>
												<Td>
													<Skeleton
														isLoaded={!matchupsLoad}
														h={"1.2rem"}
														w={"100%"}
													/>
												</Td>
												<Td>
													<Skeleton
														isLoaded={!matchupsLoad}
														h={"1.2rem"}
														w={"100%"}
													/>
												</Td>
												<Td>
													<Skeleton
														isLoaded={!matchupsLoad}
														h={"1.2rem"}
														w={"100%"}
													/>
												</Td>
												<Td>
													<Skeleton
														isLoaded={!matchupsLoad}
														h={"1.2rem"}
														w={"100%"}
													/>
												</Td>
											</Tr>
										))
								: currentData()?.map((board, index) => (
										<Tr key={board.MatchupId}>
											<Td
												flex="1"
												textAlign="start"
												fontSize="md"
												fontWeight="medium">
												{board.Venue}
											</Td>
											<Td
												flex="1"
												textAlign="start"
												fontSize="md"
												fontWeight="medium">
												<FormControl
													textAlign="start"
													w="-webkit-max-content">
													<Select
														isDisabled={
															!_(props.matchupsInitData)
																.differenceWith(
																	props.matchupsData,
																	_.isEqual
																)
																.isEmpty()
														}
														name="IsComplete"
														value={board?.IsComplete}
														onChange={(e) =>
															handleStatusUpdate(
																e.target.name,
																e.target.value,
																board.MatchupId
															)
														}>
														<option value="false">In Progress</option>
														<option value="true">Completed</option>;
													</Select>
												</FormControl>
											</Td>
											<Td
												flex="1"
												textAlign="start"
												fontSize="md"
												fontWeight="medium">
												<HStack justify="space-between">
													<Text>{board.Player1}</Text>
													{board.isPlayer1Won === "True" && (
														<Icon as={GiTrophy} />
													)}
												</HStack>
											</Td>
											<Td
												flex="1"
												textAlign="start"
												fontSize="md"
												fontWeight="medium">
												{board.IsComplete ? (
													<Text textAlign={"center"} padding={0}>
														{board.Team1Score}
													</Text>
												) : (
													<FormControl>
														<NumberInput
															value={Number(board.Team1Score)}
															min={0}
															max={25}
															inputMode="numeric"
															keepWithinRange={true}
															onChange={(value) =>
																updateMatchupsData(
																	"Team1Score",
																	Number(value),
																	board.MatchupId
																)
															}>
															<NumberInputField
																textAlign={"center"}
																padding={0}
															/>
														</NumberInput>

														{/* <Input
											value={board.Team1Score}
											bgColor="gray.100"
											textAlign="start"
											fontSize="md"
											type="number"
											onBlur={(e) =>
												updateMatchupsData("Team1Score", e.target.value, index)
											}
										/> */}
													</FormControl>
												)}
											</Td>

											<Td
												flex="1"
												textAlign="start"
												textColor="accent"
												fontSize="xl"
												fontWeight="bold">
												vs
											</Td>
											<Td
												flex="1"
												textAlign="start"
												fontSize="md"
												fontWeight="medium">
												<HStack justify="space-between">
													<Text>{board.Player2}</Text>
													{board.isPlayer2Won === "True" && (
														<Icon as={GiTrophy} />
													)}
												</HStack>
											</Td>
											<Td
												flex="1"
												textAlign="start"
												fontSize="md"
												fontWeight="medium">
												{board.IsComplete ? (
													<Text textAlign="center" padding={0}>
														{board.Team2Score}
													</Text>
												) : (
													<FormControl>
														<NumberInput
															value={Number(board.Team2Score)}
															min={0}
															max={25}
															inputMode="numeric"
															keepWithinRange={true}
															onChange={(value) =>
																updateMatchupsData(
																	"Team2Score",
																	Number(value),
																	board.MatchupId
																)
															}>
															<NumberInputField
																textAlign={"center"}
																padding={0}
															/>
														</NumberInput>
														{/* <Input
											value={board.Team2Score}
											bgColor="gray.100"
											textAlign="start"
											fontSize="md"
											type="number"
											onBlur={(e) =>
												updateMatchupsData("Team2Score", e.target.value, index)
											}
										/> */}
													</FormControl>
												)}
											</Td>
											<Td flex="1" textAlign="start" fontWeight="semibold">
												<Tooltip
													closeOnClick={true}
													hasArrow
													label="Board Scores"
													aria-label="Board Scores Button">
													<IconButton
														color="#3182ce"
														variant="ghost"
														icon={<GrScorecard size="1.3rem" />}
														isLoading={bsLoading === board.MatchupId}
														onClick={() => {
															setCurrMatchupId(board.MatchupId);
															fetchBoardScores(board.MatchupId, -1);
														}}
													/>
												</Tooltip>
											</Td>
										</Tr>
								  ))}
						</Tbody>
					</Table>
				</Box>
				<Divider />
				<Stack p="1rem" bgColor="white" borderBottomRadius={"2xl"}>
					{/*---------------For Pagination-------------------------------*/}
					<ButtonGroup justifyContent={"space-between"}>
						<Button
							color="white"
							bgColor="bg-accent"
							borderRadius="full"
							isLoading={saveLoading}
							isDisabled={_(props.matchupsInitData)
								.differenceWith(props.matchupsData, _.isEqual)
								.isEmpty()}
							onClick={saveScores}>
							Save Scores
						</Button>
						<Button
							color="white"
							bgColor="bg-accent"
							borderRadius="full"
							isLoading={generateLoading}
							onClick={onOpen}>
							Generate Matchups
						</Button>
					</ButtonGroup>
					{isMobile ? (
						<Stack>
							<Center>
								<Pagination
									count={props.matchupsData?.length}
									pageSize={rows}
									siblingCount={0}
									page={currentPage}
									onChange={(e) => setCurrentPage(e.page)}
								/>
							</Center>
							<HStack justify="space-between">
								<HStack spacing={1}>
									<Text as="span" whiteSpace="normal">
										Rows per page{" "}
									</Text>
									<Select
										boxSize={"-webkit-max-content"}
										minW="-webkit-fit-content"
										size="sm"
										value={rows}
										onChange={(event) => handleRows(event)}>
										{[10, 25, 50].map((rows) => (
											<option key={rows} value={rows}>
												{rows}
											</option>
										))}
									</Select>
								</HStack>
								<Text whiteSpace="normal">
									Showing {currentPage * rows - rows + 1} -{" "}
									{currentPage * rows < props.matchupsData?.length
										? currentPage * rows
										: props.matchupsData?.length}{" "}
									of {props.matchupsData?.length} Entries
								</Text>
							</HStack>
						</Stack>
					) : (
						<HStack justify="space-between" pt={3}>
							<HStack spacing={1}>
								<Text as="span" whiteSpace={"nowrap"}>
									Rows per page{" "}
								</Text>
								<Select
									boxSize={"-webkit-max-content"}
									minW="-webkit-fit-content"
									value={rows}
									onChange={(event) => handleRows(event)}>
									{[10, 25, 50].map((rows) => (
										<option key={rows} value={rows}>
											{rows}
										</option>
									))}
								</Select>
							</HStack>
							<Center>
								<Pagination
									count={props.matchupsData?.length}
									pageSize={rows}
									siblingCount={1}
									page={currentPage}
									onChange={(e) => setCurrentPage(e.page)}
								/>
							</Center>
							<Text whiteSpace="normal">
								Showing {currentPage * rows - rows + 1} -{" "}
								{currentPage * rows < props.matchupsData?.length
									? currentPage * rows
									: props.matchupsData?.length}{" "}
								of {props.matchupsData?.length} Entries
							</Text>
						</HStack>
					)}
				</Stack>
			</Box>
			{boardDetails && (
				<Modal
					isOpen={isBoardOpen}
					onClose={() => {
						onBoardClose();
						setCurrMatchupId("");
						setEditMode(false);
					}}
					closeOnOverlayClick={false}
					size="6xl"
					isCentered
					scrollBehavior="inside">
					<ModalOverlay />
					<ModalContent>
						<ModalHeader fontSize={"2xl"}>Board Scores</ModalHeader>
						<ModalBody
							css={{
								"::-webkit-scrollbar": {
									width: "0.3rem",
									height: "0.3rem",
								},
								"::-webkit-scrollbar-track": {
									background: "#bee3f8",
									borderRadius: "1rem",
								},
								"::-webkit-scrollbar-thumb": {
									background: "#3182ce",
									borderRadius: "1rem",
								},
							}}>
							<Stack spacing="2rem">
								<Card variant={"elevated"} bgColor="white" boxShadow={shadow}>
									<Tabs
										variant="line"
										isFitted
										size="lg"
										index={tabIndex}
										onChange={handleTabsChange}>
										{setCount > 1 && (
											<TabList>
												<Tab isDisabled={!koSetFlags.isSet1Accessible}>
													Set-1
												</Tab>
												<Tab isDisabled={!koSetFlags.isSet2Accessible}>
													Set-2
												</Tab>
												<Tab isDisabled={!koSetFlags.isSet3Accessible}>
													Set-3
												</Tab>
											</TabList>
										)}
										<CardHeader>
											<Stack spacing="1.2rem" divider={<Divider />}>
												<Stack
													direction={{
														base: "column",
														md: "row",
													}}
													{...(isMobile
														? { spacing: "1rem" }
														: { spacing: "1.5rem" })}
													align={{
														base: "start",
														md: "center",
													}}
													justify="space-between">
													<Stack
														direction={"row"}
														spacing="1.2rem"
														align="center">
														<Center
															bgColor={editMode ? "red" : "accent"}
															p="1rem"
															borderRadius="xl"
															onClick={() => {
																boardDetails[tabIndex]
																	.FirstStrike !== null &&
																	setEditMode(!editMode);
																(player1Score !==
																	boardDetails[tabIndex]
																		?.Player1TotalScore ||
																	player2Score !==
																		boardDetails[tabIndex]
																			?.Player2TotalScore) &&
																	editMode &&
																	saveBoardScores();
															}}>
															<Icon as={CalendarWhite} />
														</Center>
														<Stack
															justify={"space-between"}
															{...(isMobile && { w: "100%" })}
															spacing={0}>
															<Heading as="h2" size={cardHeading}>
																{
																	boardDetails[tabIndex]
																		?.TournamentName
																}
															</Heading>
															<Text
																fontSize={cardSubtitle}
																fontWeight="semibold"
																color="accent"
																as="h4">
																{boardDetails[tabIndex]?.EventName}
																{boardDetails[tabIndex]
																	?.GroupName &&
																	" - Group " +
																		boardDetails[tabIndex]
																			?.GroupName}
															</Text>
															<Text
																fontSize={roundText}
																color={"InactiveCaptionText"}
																fontWeight="semibold"
																as="h4">
																{boardDetails[tabIndex]
																	?.RoundName &&
																	boardDetails[tabIndex]
																		?.RoundName}
															</Text>
														</Stack>
													</Stack>
													<VStack
														align={"end"}
														spacing={1}
														alignSelf={"end"}>
														<Text
															fontSize={roundText}
															color={"InactiveCaptionText"}
															fontWeight={"medium"}>
															{boardDetails[tabIndex]?.Venue !==
																null &&
																"Venue: " +
																	boardDetails[tabIndex]?.Venue}
														</Text>
														<Text
															fontSize={roundText}
															color={"InactiveCaptionText"}
															fontWeight={"medium"}>
															{boardDetails[tabIndex]?.RefereeName !==
																null &&
																"Referee: " +
																	boardDetails[tabIndex]
																		?.RefereeName}
														</Text>
													</VStack>
												</Stack>
												<Stack direction={"row"} justify="space-between">
													<Stack direction={"column"}>
														<Stack
															direction="row"
															align={"center"}
															spacing=".7rem">
															<Text
																as="h3"
																fontSize={playerNames}
																boxSize={{
																	base: "-webkit-min-content",
																	sm: "-webkit-fit-content",
																}}
																fontWeight="semibold">
																{boardDetails[tabIndex].Player1Name}
															</Text>
															{editMode ? (
																<NumberInput
																	min={0}
																	max={25}
																	defaultValue={
																		boardDetails[tabIndex]
																			.Player1TotalScore
																	}
																	onChange={(value) => {
																		setPlayer1Score(
																			Number(value)
																		);
																	}}>
																	<NumberInputField
																		ref={inputFocus}
																		boxSize={{
																			base: "1.8rem",
																			md: "2.1rem",
																		}}
																		p={0}
																		textAlign={"center"}
																		fontWeight={"semibold"}
																		bg={"#3A95FF"}
																		borderRadius={"full"}
																		textColor={"white"}
																	/>
																</NumberInput>
															) : (
																<Circle
																	size={playerScores}
																	fontWeight="semibold"
																	textColor="white"
																	bgColor="#3A95FF">
																	{
																		boardDetails[tabIndex]
																			.Player1TotalScore
																	}
																</Circle>
															)}
														</Stack>
														<FormControl>
															<RadioGroup
																onChange={(value) => {
																	setPlayer1Dir(value);
																	value !== "" && value === "L"
																		? setPlayer2Dir("R")
																		: setPlayer2Dir("L");
																	updatePlayerPosition(
																		"Player1",
																		value
																	);
																}}
																value={player1Dir}>
																<Stack
																	direction={"row"}
																	spacing={isMobile ? 2 : 3}>
																	<Radio
																		colorScheme="red"
																		spacing={isMobile ? 1 : 2}
																		value="L">
																		{isMobile ? "L" : "Left"}
																	</Radio>
																	<Radio
																		colorScheme="green"
																		spacing={isMobile ? 1 : 2}
																		value="R">
																		{isMobile ? "R" : "Right"}
																	</Radio>
																</Stack>
															</RadioGroup>
														</FormControl>
													</Stack>
													<FormControl
														alignSelf={"center"}
														w="-webkit-fit-content">
														<FormLabel textAlign={"center"}>
															First Strike
														</FormLabel>
														<Slider
															value={strikeSlider}
															min={-1}
															max={1}
															step={1}
															onChange={(value) => {
																saveFirstStrike(value);
															}}>
															<SliderTrack
																bg={
																	strikeSlider === -1
																		? "#3A95FF"
																		: strikeSlider === 1
																		? "#800080"
																		: "gray"
																}
																h={"1rem"}
																borderRadius={"full"}>
																<Box
																	position="relative"
																	right={10}
																/>
																<SliderFilledTrack bg="" />
															</SliderTrack>
															<SliderThumb boxSize={6} />
														</Slider>
													</FormControl>
													<Stack direction={"column"}>
														<Stack
															direction="row"
															align={"center"}
															spacing=".7rem">
															<Text
																as="h3"
																fontSize={playerNames}
																boxSize={{
																	base: "-webkit-min-content",
																	sm: "-webkit-fit-content",
																}}
																fontWeight="semibold">
																{boardDetails[tabIndex].Player2Name}
															</Text>
															{editMode ? (
																<NumberInput
																	min={0}
																	max={25}
																	defaultValue={
																		boardDetails[tabIndex]
																			.Player2TotalScore
																	}
																	onChange={(value) => {
																		setPlayer2Score(
																			Number(value)
																		);
																	}}>
																	<NumberInputField
																		boxSize={{
																			base: "1.8rem",
																			md: "2.1rem",
																		}}
																		p={0}
																		textAlign={"center"}
																		fontWeight={"semibold"}
																		bg={"#800080"}
																		borderRadius={"full"}
																		textColor={"white"}
																	/>
																</NumberInput>
															) : (
																<Circle
																	size={playerScores}
																	fontWeight="semibold"
																	textColor="white"
																	bgColor="#800080">
																	{
																		boardDetails[tabIndex]
																			.Player2TotalScore
																	}
																</Circle>
															)}
														</Stack>
														<FormControl>
															<RadioGroup
																onChange={(value) => {
																	setPlayer2Dir(value);
																	value !== "" && value === "L"
																		? setPlayer1Dir("R")
																		: setPlayer1Dir("L");
																	updatePlayerPosition(
																		"Player2",
																		value
																	);
																}}
																value={player2Dir}>
																<Stack
																	direction={"row"}
																	spacing={isMobile ? 2 : 3}>
																	<Radio
																		colorScheme="red"
																		spacing={isMobile ? 1 : 2}
																		value="L">
																		{isMobile ? "L" : "Left"}
																	</Radio>
																	<Radio
																		colorScheme="green"
																		spacing={isMobile ? 1 : 2}
																		value="R">
																		{isMobile ? "R" : "Right"}
																	</Radio>
																</Stack>
															</RadioGroup>
														</FormControl>
													</Stack>
												</Stack>
											</Stack>
										</CardHeader>
										<CardBody p={0}>
											<BoardScoresTable
												matchupId={currMatchupId}
												scoresData={boardDetails[tabIndex].Scores}
												boardsData={boardDetails[tabIndex]}
												playersData={playersData}
												refreshView={fetchBoardScores}
												refreshMatchups={props.refreshMatchups}
												selectedSet={selectedSet}
												setCount={setCount}
												setLoading={setBoardLoading}
												loading={boardLoading}
												setBSLoading={setBSLoading}
												refreshOnScoresSave={refreshOnScoresSave}
											/>
										</CardBody>
									</Tabs>
								</Card>
							</Stack>
						</ModalBody>
						<ModalFooter>
							<ButtonGroup spacing="5">
								<Button
									size="lg"
									borderRadius="full"
									colorScheme="red"
									onClick={() => {
										onBoardClose();
										setCurrMatchupId("");
										setEditMode(false);
									}}>
									Close
								</Button>
							</ButtonGroup>
						</ModalFooter>
					</ModalContent>
				</Modal>
			)}
			<Modal
				isOpen={isOpen}
				onClose={onClose}
				size={{ base: "xs", sm: "md", md: "lg", lg: "xl" }}
				isCentered>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Generate Matchups</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Text>Are you sure want to Generate Matchups?</Text>
					</ModalBody>
					<ModalFooter>
						<Button
							colorScheme="blue"
							mr={3}
							onClick={generateMatchUps}
							isLoading={generateLoading}>
							Yes
						</Button>
						{!generateLoading && <Button onClick={onClose}>No</Button>}
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);

	// const tableMobile = () => (
	// 	<Box>
	// 		{data.map((board) => (
	// 			<Container
	// 				mb="1rem"
	// 				bgColor="white"
	// 				boxShadow={"sm"}
	// 				borderRadius="lg"
	// 				py="1rem"
	// 				px="1.5rem">
	// 				<Stack spacing={5} divider={<Divider />} fontSize="lg">
	// 					<HStack justify="space-between">
	// 						<Text fontWeight="bold">Player</Text>
	// 						<Text>{board.player}</Text>
	// 					</HStack>
	// 					<HStack justify="space-between">
	// 						<Text fontWeight="bold">Event</Text>
	// 						<Text>{board.event}</Text>
	// 					</HStack>
	// 					<HStack justify="space-between">
	// 						<Text fontWeight="bold">Nick Name</Text>
	// 						<Text>{board.nickname}</Text>
	// 					</HStack>
	// 					<HStack justify="space-between">
	// 						<Text fontWeight="bold">Mobile</Text>
	// 						<Text>{board.mobile}</Text>
	// 					</HStack>
	// 					<HStack justify="space-between">
	// 						<Text fontWeight="bold">Email</Text>
	// 						<Text>{board.email}</Text>
	// 					</HStack>
	// 					<HStack justify="space-between">
	// 						<FormControl>
	// 							<Input
	// 								placeholder={board.rank}
	// 								textAlign="start"
	// 								fontSize="lg"
	// 							/>
	// 						</FormControl>
	// 						<FormControl>
	// 							<Input
	// 								type="checkbox"
	// 								value={board.live}
	// 								textAlign="start"
	// 								fontSize="lg"
	// 							/>
	// 						</FormControl>
	// 					</HStack>

	// 					<Stack
	// 						direction={["column", "row"]}
	// 						justify="space-between"
	// 						pt="1rem">
	// 						{/*-----------For Pagination----------------------*/}
	// 					</Stack>
	// 				</Stack>
	// 			</Container>
	// 		))}
	// 	</Box>
	// );

	return tableDesktop();
};

import { useEffect, useState } from "react";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalBody,
	Box,
	ModalHeader,
	FormControl,
	FormLabel,
	Input,
	Stack,
	Button,
} from "@chakra-ui/react";
import ApiService from "../../../../service/Api.service";
import { useAuthProvider } from "../../../../context/AuthProvider";

export function NewBroadcastModal({ tourEventId, boardNum, handleSubmit, handleCancel }) {
	// User Details
	const user = useAuthProvider();

	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [startTime, setStartTime] = useState("");

	useEffect(() => {
		console.log("use effect");
		(async () => {
			try {
				const broadcastInfo = await ApiService.httpGet(
					`/StreamingConfigurations?Class=getTitle&TourEventId=${tourEventId}&board=${boardNum}`,
					user.userId
				);
				setTitle(broadcastInfo.Title);
				setDescription(broadcastInfo.Title);
			} catch (err) {
				console.log("failed to get broadcast title");
			}
		})();
	}, [boardNum, tourEventId]);

	function handleCreate() {
		if (title === "" || description === "" || startTime === "") {
			return;
		}
		handleSubmit(title, description, startTime);
	}

	return (
		<Modal isOpen={true}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Modal Title</ModalHeader>
				<ModalBody>
					<Box my={4} textAlign="left">
						<FormControl>
							<FormLabel>Title</FormLabel>
							<Input
								type="text"
								value={title}
								onChange={(e) => setTitle(e.target.value)}
							/>
							<FormLabel>Description</FormLabel>
							<Input
								type="text"
								value={description}
								onChange={(e) => setDescription(e.target.value)}
							/>
							<FormLabel>Start Time</FormLabel>
							<Input
								type="datetime-local"
								value={startTime}
								onChange={(e) => setStartTime(e.target.value)}
							/>
						</FormControl>
						<Stack spacing={2} direction="row" align="center">
							<Button size="sm" onClick={() => handleCreate()}>
								Create
							</Button>
							<Button variant="outline" size="sm" onClick={() => handleCancel()}>
								Cancel
							</Button>
						</Stack>
					</Box>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
}

import {
	HStack,
	Table,
	Tbody,
	Td,
	Box,
	Tr,
	Th,
	Thead,
	useBreakpointValue,
	Divider,
	Stack,
	Text,
	Center,
	Select,
	Skeleton,
} from "@chakra-ui/react";
import { useState, useRef } from "react";
import usePagination from "../../../hooks/usePagination";
import { Pagination } from "../../Pagination/Pagination";

export const StandingsTable = (props) => {
	//Desktop BreakPoint Value
	const isDesktop = useBreakpointValue({ base: false, xl: true });
	const isMobile = useBreakpointValue({
		base: true,
		md: false,
	});

	// Loading States
	let standingsLoad = props.standingsLoad;

	// Pagination
	let [rows, setRows] = useState(10);
	const perPage = rows;

	const {
		currentPage,
		currentData,
		jump,
		prev,
		next,
		first,
		last,
		setLast,
		setCurrentPage,
		maxPage,
	} = usePagination(
		props.standingsData?.filter((player) => {
			if (props.query === "") {
				return player;
			} else if (
				player.TeamName.toLowerCase().includes(
					props.eventType === "Teams"
						? props.queryTeams.toLowerCase()
						: props.query.toLowerCase()
				) ||
				player.NetScore.toString().includes(
					props.eventType === "Teams" ? props.queryTeams : props.query
				) ||
				(props.eventType !== "Teams" && player.Points.toString().includes(props.query)) ||
				player.Position.toString().includes(
					props.eventType === "Teams" ? props.queryTeams : props.query
				)
			) {
				return player;
			}
		}),
		perPage
	);

	const handleChange = (e, p) => {
		jump(p);
	};

	const handleRows = (event) => {
		setRows(Number(event.target.value));
		setLast(Number(event.target.value));
		setCurrentPage(1);
		// 	setParamTwo(Number(event.target.value));
	};

	// const previousPage = (event) => {
	// 	setParamTwo(Number(paramOne));
	// 	setParamOne(Number(paramTwo - rows));
	// };
	// const nextPage = (event) => {
	// 	setParamOne(Number(paramTwo));
	// 	setParamTwo(Number(paramTwo + rows));
	// };

	const tableDesktop = () => (
		<Box>
			<Box
				borderTopRadius={"2xl"}
				overflow="auto"
				boxShadow={"sm"}
				css={{
					"::-webkit-scrollbar": {
						width: "0.3rem",
						height: "0.3rem",
					},
					"::-webkit-scrollbar-track": {
						background: "#bee3f8",
						borderRadius: "1rem",
					},
					"::-webkit-scrollbar-thumb": {
						background: "#3182ce",
						borderRadius: "1rem",
					},
				}}>
				<Table variant="striped">
					<Thead position="sticky" top={0} bgColor="black" zIndex="1">
						<Tr>
							<Th textColor="white" fontSize="md" textAlign="center" py={5} px={5}>
								Position
							</Th>
							<Th textColor="white" fontSize="md" textAlign="center" px={5}>
								{props.selectedClass === "Teams" && props.eventType === "Teams"
									? "Team Name"
									: "Player Name"}
							</Th>
							<Th
								textColor="white"
								fontSize="md"
								textAlign="center"
								whiteSpace={"normal"}
								px={5}>
								{props.selectedClass === "Teams" && props.eventType === "Teams"
									? "Matches Won"
									: "Matches Played"}
							</Th>
							{props.selectedClass === "Teams" && props.eventType === "Teams" && (
								<Th
									textColor="white"
									fontSize="md"
									textAlign="center"
									px={5}
									whiteSpace={"normal"}>
									Net Score
								</Th>
							)}
							<Th textColor="white" fontSize="md" textAlign="center" px={5}>
								Points
							</Th>
							<Th
								textColor="white"
								fontSize="md"
								textAlign="center"
								whiteSpace={"normal"}
								px={5}>
								Opponent(s) Strength
							</Th>
							{props.selectedClass !== "Teams" && (
								<Th textColor="white" fontSize="md" textAlign="center" px={5}>
									Net Score
								</Th>
							)}
						</Tr>
					</Thead>
					<Tbody>
						{standingsLoad
							? Array(5)
									.fill()
									.map((rows, index) => (
										<Tr key={index}>
											<Td>
												<Skeleton
													isLoaded={!standingsLoad}
													h={"1.2rem"}
													w={"100%"}
												/>
											</Td>
											<Td>
												<Skeleton
													isLoaded={!standingsLoad}
													h={"1.2rem"}
													w={"100%"}
												/>
											</Td>
											<Td>
												<Skeleton
													isLoaded={!standingsLoad}
													h={"1.2rem"}
													w={"100%"}
												/>
											</Td>
											<Td>
												<Skeleton
													isLoaded={!standingsLoad}
													h={"1.2rem"}
													w={"100%"}
												/>
											</Td>
											<Td>
												<Skeleton
													isLoaded={!standingsLoad}
													h={"1.2rem"}
													w={"100%"}
												/>
											</Td>
											{props.eventType === "Teams" && (
												<Td>
													<Skeleton
														isLoaded={!standingsLoad}
														h={"1.2rem"}
														w={"100%"}
													/>
												</Td>
											)}
										</Tr>
									))
							: currentData()?.map((board) => (
									<Tr key={board.Position}>
										<Td
											flex="1"
											textAlign="center"
											fontSize="md"
											fontWeight="medium">
											{board.Position}
										</Td>
										<Td
											flex="1"
											textAlign="center"
											fontSize="md"
											fontWeight="medium"
											whiteSpace={"normal"}>
											{board.TeamName}
										</Td>
										{props.selectedClass === "Teams" &&
											props.eventType === "Teams" && (
												<Td
													flex="1"
													textAlign="center"
													fontSize="md"
													fontWeight="medium">
													{board.MatchesWon}
												</Td>
											)}
										{props.selectedClass === "Teams" &&
											props.eventType === "Teams" && (
												<Td
													flex="1"
													textAlign="center"
													fontSize="md"
													fontWeight="medium">
													{board.NetScore}
												</Td>
											)}
										{props.selectedClass !== "Teams" && (
											<Td
												flex="1"
												textAlign="center"
												fontSize="md"
												fontWeight="medium">
												{board.MatchesPlayed}
											</Td>
										)}
										<Td textAlign="center" fontSize="md" fontWeight="medium">
											{props.selectedClass === "Teams" &&
											props.eventType === "Teams"
												? board.PointsScored
												: board.Points}
										</Td>
										<Td
											flex="1"
											textAlign="center"
											fontSize="md"
											fontWeight="medium">
											{board.OppStrength}
										</Td>
										{props.selectedClass !== "Teams" &&
											 (
												<Td
													flex="1"
													textAlign="center"
													fontSize="md"
													fontWeight="medium">
													{board.NetScore}
												</Td>
											)}
									</Tr>
							  ))}
					</Tbody>
				</Table>
			</Box>
			<Divider />
			<Stack p="1rem" bgColor="white" borderBottomRadius={"2xl"}>
				{/*---------------For Pagination-------------------------------*/}
				{isMobile ? (
					<Stack>
						<Center>
							<Pagination
								count={props.standingsData?.length}
								pageSize={rows}
								siblingCount={0}
								page={currentPage}
								onChange={(e) => setCurrentPage(e.page)}
							/>
						</Center>
						<HStack justify="space-between">
							<HStack spacing={1}>
								<Text as="span" whiteSpace="normal">
									Rows per page{" "}
								</Text>
								<Select
									boxSize={"-webkit-max-content"}
									minW="-webkit-fit-content"
									size="sm"
									value={rows}
									onChange={(event) => handleRows(event)}>
									{[10, 25, 50].map((rows) => (
										<option key={rows} value={rows}>
											{rows}
										</option>
									))}
								</Select>
							</HStack>
							<Text whiteSpace="normal">
								Showing {currentPage * rows - rows + 1} -{" "}
								{currentPage * rows < props.standingsData?.length
									? currentPage * rows
									: props.standingsData?.length}{" "}
								of {props.standingsData?.length} Entries
							</Text>
						</HStack>
					</Stack>
				) : (
					<HStack justify="space-between">
						<HStack spacing={1}>
							<Text as="span" whiteSpace={"nowrap"}>
								Rows per page{" "}
							</Text>
							<Select
								boxSize={"-webkit-max-content"}
								minW="-webkit-fit-content"
								value={rows}
								onChange={(event) => handleRows(event)}>
								{[10, 25, 50].map((rows) => (
									<option key={rows} value={rows}>
										{rows}
									</option>
								))}
							</Select>
						</HStack>
						<Center>
							<Pagination
								count={props.standingsData?.length}
								pageSize={rows}
								siblingCount={1}
								page={currentPage}
								onChange={(e) => setCurrentPage(e.page)}
							/>
						</Center>
						<Text whiteSpace="normal">
							Showing {currentPage * rows - rows + 1} -{" "}
							{currentPage * rows < props.standingsData?.length
								? currentPage * rows
								: props.standingsData?.length}{" "}
							of {props.standingsData?.length} Entries
						</Text>
					</HStack>
				)}
			</Stack>
		</Box>
	);

	// const tableMobile = () => (
	// 	<Container
	// 		bgColor="white"
	// 		boxShadow={"sm"}
	// 		borderRadius="lg"
	// 		py="1rem"
	// 		px="1.5rem">
	// 		<Stack spacing={5} divider={<Divider />} fontSize="lg">
	// 			<HStack justify="space-between">
	// 				<Text fontWeight="bold">Position</Text>
	// 				<Text>{data[0].position}</Text>
	// 			</HStack>
	// 			<HStack justify="space-between">
	// 				<Text fontWeight="bold">Player Name</Text>
	// 				<Text>{data[0].player}</Text>
	// 			</HStack>
	// 			<Stack>
	// 				<HStack justify="space-between">
	// 					<Text fontWeight="bold">Matches Played</Text>
	// 					<Text>{data[0].played}</Text>
	// 				</HStack>
	// 				<HStack justify="space-between">
	// 					<Text fontWeight="bold">Points</Text>
	// 					<Text>{data[0].points}</Text>
	// 				</HStack>
	// 				<HStack justify="space-between">
	// 					<Text fontWeight="bold">Opponent(s) Strength</Text>
	// 					<Text>{data[0].os}</Text>
	// 				</HStack>
	// 				<HStack justify="space-between">
	// 					<Text fontWeight="bold">Net Score</Text>
	// 					<Text>{data[0].netscore}</Text>
	// 				</HStack>
	// 			</Stack>
	// 			<Stack direction={["column", "row"]} justify="space-between" pt="1rem">
	// 				{/*-----------For Pagination----------------------*/}
	// 			</Stack>
	// 		</Stack>
	// 	</Container>
	// );

	return tableDesktop();
};

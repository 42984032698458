import {
	Container,
	Heading,
	Icon,
	useBreakpointValue,
	Stack,
	HStack,
	Card,
	CardHeader,
	Center,
	useColorModeValue,
	FormControl,
	Select,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MatchupsResultsTable } from "../../Components/Events/MatchupsResults/MatchupsResultsTable";
import { CalendarWhite } from "../../icons/Icons";
import ApiService from "../../service/Api.service";
import { useAuthProvider } from "../../context/AuthProvider";

export const MatchupsResults = ({ eventId: propEventId }) => {
	// User Details
	const user = useAuthProvider();

	//Desktop BreakPoint Value
	const isDesktop = useBreakpointValue({
		base: false,
		lg: true,
	});
	// Mobile BreakPoint Value
	const isMobile = useBreakpointValue({
		base: true,
		sm: true,
		md: false,
	});
	//Page Header
	const pageHeader = useBreakpointValue({
		base: "xs",
		sm: "sm",
	});
	//Box Shadow
	const shadow = useColorModeValue("sm", "sm-dark");
	//Card Heading
	const cardHeading = useBreakpointValue({
		base: "xs",
		md: "sm",
	});

	let { eventId: urlParamEventId } = useParams();
	let eventId = propEventId || urlParamEventId;

	let [groups, setGroups] = useState([]);
	let [matchupRounds, setMatchupRounds] = useState([]);
	let [selectedMatchRound, setSelectedMatchRound] = useState({});
	let [selectedMatchGroup, setSelectedMatchGroup] = useState({});
	let [matchupsData, setMatchupsData] = useState([]);
	let [tourEventName, setTourEventName] = useState("");

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		Promise.all([
			ApiService.httpGet("/EventTeams?GroupsEventId=" + eventId, "U00184"),
			ApiService.httpGet(
				"/TourEventMatchupsByGroup?RoundsByTourEventId=" + eventId,
				"U00184"
			),
		])
			.then(function (responses) {
				// Get a JSON object from each of the responses
				return Promise.all(
					responses.map(function (response) {
						return response;
					})
				);
			})
			.then(function (data) {
				// Log the data to the console
				// You would do something with both sets of data here
				console.log(data);
				setGroups(data[0].GroupNames);
				setMatchupRounds(data[1].Rounds);
				setSelectedMatchGroup(data[0].GroupNames[0]);
				setSelectedMatchRound(data[1].Rounds.find((round) => round.Latest === "YES"));
				fetchMatchups(data[1].Rounds.find((round) => round.Latest === "YES"));
			})
			.catch(function (error) {
				// if there's an error, log it
				console.log(error);
			});
	};

	const fetchMatchups = async (selectedRound) => {
		try {
			let roundNo = selectedRound?.RoundNo;
			let matchupsResponse = await ApiService.httpGet(
				`/TourEventMatchupsByGroup?TourEventId=${eventId}&RoundNo=${roundNo}&GroupName=A`,
				"U00184"
			);
			setMatchupsData(matchupsResponse.TourEventMatchups);
			setTourEventName(matchupsResponse.TourEventName + " " + "Event");
			//setLoading(false);
		} catch (err) {
			console.log(err);
		}
	};

	const updateMatchupsData = async (groupName, roundNo) => {
		console.log("Updated GroupName", groupName);
		console.log("Updated RoundNo", roundNo);
		setSelectedMatchRound({ RoundNo: roundNo });
		setSelectedMatchGroup({ GroupName: groupName });
		try {
			let updatedResponse = await ApiService.httpGet(
				`/TourEventMatchupsByGroup?TourEventId=${eventId}&RoundNo=${roundNo}&GroupName=${
					groupName.split(" - ")[1]
				}`,
				"U00184"
			);
			console.log("Updated Matchups Data:", updatedResponse.TourEventMatchups);
			setMatchupsData(updatedResponse.TourEventMatchups);
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<>
			<Container
				flex="1"
				maxWidth="100vw"
				{...(propEventId === undefined && {
					marginTop: isDesktop ? "7rem" : "5rem",
				})}
				marginX={0}
				{...(isMobile && { px: "1" })}
				paddingBottom={10}>
				<Stack spacing="1.7rem">
					<Heading size={pageHeader} fontWeight="bold">
						Matchups Results
					</Heading>
					<Stack spacing="2rem">
						<Card variant={"elevated"} bgColor="white" boxShadow={shadow}>
							<CardHeader>
								<Stack
									direction={{
										base: "column",
										md: "row",
									}}
									spacing="1.2rem"
									align={{
										base: "start",
										md: "center",
									}}
									justify={{ md: "space-between" }}>
									<HStack spacing="1.2rem" align="center">
										{isMobile ? (
											""
										) : (
											<Center bgColor="accent" p="1rem" borderRadius="xl">
												<Icon as={CalendarWhite} />
											</Center>
										)}
										<Heading as="h2" size={cardHeading}>
											{tourEventName}
										</Heading>
									</HStack>
									<Stack spacing="1rem" direction={"row"}>
										<FormControl w={"-webkit-fit-content"}>
											<Select
												name="Group"
												colorScheme="blue"
												fontSize={{ base: "sm", md: "md" }}
												defaultValue={selectedMatchGroup.GroupName}
												onChange={(event) => {
													updateMatchupsData(
														event.target.value,
														selectedMatchRound.RoundNo
													);
												}}>
												{groups.map((group, index) => {
													return (
														<option
															key={group.GroupName}
															value={group.GroupName}>
															{group.GroupName}
														</option>
													);
												})}
											</Select>
										</FormControl>
										<FormControl w={"-webkit-fit-content"}>
											<Select
												name="Round"
												colorScheme="blue"
												fontSize={{ base: "sm", md: "md" }}
												value={selectedMatchRound?.RoundNo}
												onChange={(event) => {
													updateMatchupsData(
														selectedMatchGroup.GroupName,
														event.target.value
													);
												}}>
												{matchupRounds.map((round, index) => {
													return (
														<option
															key={round.RoundNo}
															value={round.RoundNo}>
															{round.RoundName}
														</option>
													);
												})}
											</Select>
										</FormControl>
									</Stack>
								</Stack>
							</CardHeader>
						</Card>

						<MatchupsResultsTable matchupsData={matchupsData} />
					</Stack>
				</Stack>
			</Container>
		</>
	);
};

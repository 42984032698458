import {
	Container,
	Stack,
	IconButton,
	Heading,
	HStack,
	useBreakpointValue,
	Flex,
	Button,
	ButtonGroup,
	Box,
	useColorModeValue,
	SimpleGrid,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";

import { Navbar } from "../../Components/Navbar/Navbar";
import { Sidebar } from "../../Components/Sidebar/Sidebar";
import { AddressForm } from "../../Components/Clubs/AddClubForm/ClubsAddressForm";

import { IoChevronBack } from "react-icons/io5";
import { ClubsBasicDetailsForm } from "../../Components/Clubs/AddClubForm/ClubsBasicDetailsForm";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import ApiService from "../../service/Api.service";
import { ClubDetailsCard } from "../../Components/Clubs/ClubDetails/ClubDetailsCard";
import { ClubMembersCard } from "../../Components/Clubs/ClubDetails/ClubMembersCard";
import { BallTriangle } from "react-loader-spinner";
import { useAuthProvider } from "../../context/AuthProvider";

export const ClubDetails = () => {
	// User Details
	const user = useAuthProvider();

	//Desktop BreakPoint Value
	const isDesktop = useBreakpointValue({
		base: false,
		lg: true,
	});
	//Page Header
	const pageHeader = useBreakpointValue({
		base: "xs",
		sm: "sm",
	});

	const [loadingMembers, setLoadingMembers] = useState(false);
	const [loadingClubs, setLoadingClubs] = useState(false);
	const [loadingDelete, setLoadingDelete] = useState(false);
	const [clubMembers, setClubMembers] = useState([]);
	const [reload, setReload] = useState(false);

	const location = useLocation();

	const fetchClubMembers = async () => {
		try {
			setLoadingMembers(true);
			const response = await ApiService.httpGet(
				"/Members?ClubId=" + location.state.clubId,
				user.userId
			);
			setClubMembers(response.Members);
			console.log(response.Members);
			setLoadingMembers(false);
		} catch (err) {
			console.error(err.message);
			setLoadingMembers(false);
		} finally {
			setLoadingMembers(false);
		}
	};

	const [clubs, setClubs] = useState([]);
	const fetchClubs = async () => {
		try {
			setLoadingClubs(true);
			const response = await ApiService.httpGet(
				"/Clubs?ClubId=" + location.state.clubId,
				user.userId
			);
			setClubs(response.Clubs[0]);
			console.log("clubs list", response.Clubs[0]);
			setLoadingClubs(false);
		} catch (err) {
			console.error(err.message);
			setLoadingClubs(false);
		} finally {
			setLoadingClubs(false);
		}
	};

	useEffect(() => {
		fetchClubs();
		fetchClubMembers();
	}, []);

	let navigate = useNavigate();

	const navigateToClubs = () => {
		return navigate("/clubs");
	};

	return (
		<>
			{loadingDelete ? (
				<Box
					position={"absolute"}
					bottom={"50%"}
					left={"50%"}
					p={0}
					m={0}
					zIndex={"overlay"}>
					<BallTriangle
						height={100}
						width={100}
						radius={5}
						color="#4fa94d"
						ariaLabel="ball-triangle-loading"
						wrapperClass={{}}
						wrapperStyle=""
						visible={true}
					/>
				</Box>
			) : (
				<Container
					flex="1"
					overflowY="auto"
					maxWidth="100vw"
					marginTop={isDesktop ? "3.5rem" : "0rem"}
					marginX={0}
					paddingBottom={10}
					css={{
						"::-webkit-scrollbar": {
							width: "0.3rem",
							height: "0.3rem",
						},
						"::-webkit-scrollbar-track": {
							background: "#bee3f8",
							borderRadius: "1rem",
						},
						"::-webkit-scrollbar-thumb": {
							background: "#3182ce",
							borderRadius: "1rem",
						},
					}}>
					<Stack
						mt={10}
						spacing={{
							base: "8",
							lg: "6",
						}}>
						<Stack
							spacing="4"
							direction={{
								base: "column",
								lg: "row",
							}}
							justify="space-between"
							align={{
								base: "start",
								lg: "center",
							}}>
							<HStack spacing="5">
								<IconButton
									borderRadius="full"
									p={2}
									size="md"
									bgColor="white"
									as={IoChevronBack}
									onClick={navigateToClubs}
								/>
								<Heading size={pageHeader} fontWeight="bold">
									Club Details
								</Heading>
							</HStack>
						</Stack>
						<ClubDetailsCard
							key={clubs.ClubId}
							clubData={clubs}
							loadingClubs={loadingClubs}
						/>
						<ClubMembersCard
							club={clubs}
							key={clubMembers.UserId}
							clubMembers={clubMembers}
							loadingMembers={loadingMembers}
							reload={reload}
							setReload={setReload}
							setLoading={setLoadingDelete}
						/>
					</Stack>
				</Container>
			)}
		</>
	);
};

import {
	Card,
	Button,
	CardHeader,
	IconButton,
	Icon,
	Input,
	InputGroup,
	InputLeftElement,
	Stack,
	Text,
	useBreakpointValue,
	useColorModeValue,
	SimpleGrid,
	Divider,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { BsDownload, BsUpload } from "react-icons/bs";
import { FiDownloadCloud, FiMoreHorizontal, FiSearch, FiUploadCloud } from "react-icons/fi";
import { HiDotsVertical, HiDownload, HiUpload } from "react-icons/hi";
import { SlPaperPlane } from "react-icons/sl";
import { useNavigate } from "react-router-dom";
import ApiService from "../../../../service/Api.service";
import { CSVLink } from "react-csv";
import { useAuthProvider } from "../../../../context/AuthProvider";

const headers = [
	{ label: "First Name", key: "FirstName" },
	{ label: "Last Name", key: "LastName" },
	{ label: "Nick Name", key: "NickName" },
	{ label: "Email", key: "Email" },
	{ label: "Mobile", key: "Mobile" },
	{ label: "Event Names", key: "EventNames" },
];

export const TournamentRegPlayersCard = ({ TourId, setQuery }) => {
	// User Details
	const user = useAuthProvider();

	const csvLinkEl = useRef();

	let [tourRegPlayers, setTourRegPlayers] = useState([]);
	const fetchRegPlayers = async () => {
		try {
			//setLoading(true);
			console.log("TourId", TourId);
			const response = await ApiService.httpGet(
				"/FileUtilities?Class=RegPlayers&TourId=" + TourId,
				user.userId
			);
			console.log("Registered Players Download Data", response);
			setTourRegPlayers(response.RegPlayersJson);
			setTimeout(() => {
				csvLinkEl.current.link.click();
			});

			//   setLoading(false);
		} catch (err) {
			console.error(err.message);
		}
	};

	return (
		<Card variant={"elevated"} bgColor="white" boxShadow={useColorModeValue("sm", "sm-dark")}>
			<CardHeader>
				<Stack
					align="end"
					direction={useBreakpointValue({
						base: "column",
						sm: "row",
					})}
					spacing="1.2rem"
					justify="space-between">
					<Stack direction="column" spacing=".7rem">
						<Text
							as="h3"
							fontSize={useBreakpointValue({
								base: "lg",
								md: "xl",
							})}
							fontWeight="semibold">
							Registered Players
						</Text>
						<InputGroup maxW="xs" colorScheme="blue">
							<InputLeftElement pointerEvents="none">
								<Icon as={FiSearch} color="#3182ce" boxSize="5" />
							</InputLeftElement>
							<Input
								w="100vw"
								placeholder="Search Registered Players"
								onChange={(event) => setQuery(event.target.value)}
							/>
						</InputGroup>
					</Stack>
					<Stack direction="row" justify="flex-end" spacing=".7rem">
						<IconButton
							variant="ghost"
							size="md"
							color="#3182ce"
							icon={<FiUploadCloud size="1.7rem" />}
						/>
						<IconButton
							variant="ghost"
							size="md"
							color="#3182ce"
							icon={<FiDownloadCloud size="1.7rem" />}
							onClick={fetchRegPlayers}
						/>
						<CSVLink
							headers={headers}
							filename={"TourRegisteredPlayers.csv"}
							data={tourRegPlayers}
							asyncOnClick={true}
							ref={csvLinkEl}
						/>
					</Stack>
				</Stack>
			</CardHeader>
		</Card>
	);
};

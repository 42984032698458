import {
	Container,
	Heading,
	Icon,
	useBreakpointValue,
	Flex,
	Stack,
	Box,
	HStack,
	Divider,
	IconButton,
	Text,
	Card,
	CardHeader,
	CardBody,
	Center,
	Badge,
	Circle,
	Avatar,
	useColorModeValue,
	FormControl,
	Select,
} from "@chakra-ui/react";
import { Navbar, NavbarEvents } from "../../Components/Navbar/Navbar";
import { StandingsTable } from "../../Components/Events/StandingsTable/StandingsTable";
import { CalendarWhite } from "../../icons/Icons";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ApiService from "../../service/Api.service";
import { TeamsTable } from "../../Components/Events/TeamsTable/TeamsTable";
import { useAuthProvider } from "../../context/AuthProvider";

export const Teams = () => {
	// User Details
	const user = useAuthProvider();

	//Desktop BreakPoint Value
	const isDesktop = useBreakpointValue({
		base: false,
		lg: true,
	});
	// Mobile Breakpoint Value
	const isMobile = useBreakpointValue({
		base: true,
		sm: true,
		md: false,
	});
	//Page Header
	const pageHeader = useBreakpointValue({
		base: "xs",
		sm: "sm",
	});
	//Box Shadow
	const shadow = useColorModeValue("sm", "sm-dark");
	//Card Heading
	const cardHeading = useBreakpointValue({
		base: "xs",
		md: "sm",
	});

	// Query Params
	let { eventId, teamId } = useParams();
	const [currTeamId, setCurrTeamId] = useState(teamId);

	// States
	const [teamsList, setTeamsList] = useState([]);
	let [teams, setTeams] = useState([]);

	let fetchTeams = async () => {
		try {
			let teamsRes = await ApiService.httpGet(
				`/TeamEventTeams?Class=Teams&TourEventId=${eventId}`,
				"U00184"
			);
			console.log("Teams List", teamsRes);
			setTeamsList(teamsRes.TeamNames);
			const res = await ApiService.httpGet(
				"/TeamEventTeams?Class=PlayersInfo&TourEventId=" + eventId,
				"U00184"
			);
			setTeams(res.Teams);
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		fetchTeams();
	}, []);

	return (
		<Container
			flex="1"
			maxWidth="100vw"
			marginTop={isDesktop ? "7rem" : "5rem"}
			marginX={0}
			{...(isMobile && { px: "1" })}
			paddingBottom={10}>
			<Stack spacing="1.7rem">
				<Heading size={pageHeader} fontWeight="bold">
					Teams
				</Heading>
				<Stack spacing="2rem" justify={"space-between"}>
					<Card variant={"elevated"} bgColor="white" boxShadow={shadow}>
						<CardHeader>
							<Stack
								direction={{
									base: "column",
									sm: "row",
								}}
								spacing="1.2rem"
								align={{
									base: "start",
									md: "center",
								}}
								justify="space-between">
								<HStack spacing="1.2rem" align="center">
									{isMobile ? (
										""
									) : (
										<Center bgColor="accent" p="1rem" borderRadius="xl">
											<Icon as={CalendarWhite} />
										</Center>
									)}
									<Heading as="h2" size={cardHeading}>
										{
											teams?.find((team) => team.TETeamId === currTeamId)
												?.TeamName
										}
									</Heading>
								</HStack>
								<Select
									name="Teams"
									colorScheme="blue"
									fontSize={{ base: "sm", md: "md" }}
									textAlign="start"
									w={"fit-content"}
									value={currTeamId}
									onChange={(e) => {
										setCurrTeamId(e.target.value);
									}}
									alignSelf={"end"}>
									{teamsList?.map((group, index) => {
										return (
											<option key={group.TETeamId} value={group.TETeamId}>
												{group.TeamName}
											</option>
										);
									})}
								</Select>
							</Stack>
						</CardHeader>
					</Card>

					<TeamsTable teams={teams} currTeamId={currTeamId} />
				</Stack>
			</Stack>
		</Container>
	);
};

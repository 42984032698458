import { Center, Divider, Flex, Stack, Link, HStack, Button, Icon, Text } from "@chakra-ui/react";
import * as React from "react";
import { FiHelpCircle, FiSettings, FiUsers, FiCalendar } from "react-icons/fi";
import { RxDashboard } from "react-icons/rx";
import { BiLogOut } from "react-icons/bi";
import { BsTrophy } from "react-icons/bs";
import { HiOutlineUserGroup } from "react-icons/hi";

import { CarromLogo } from "../../logo/Logo";
import { NavButton } from "./NavButton";
import { UserProfile } from "./UserProfile";
import { useLocation, useNavigate, useMatch } from "react-router-dom";
import { useAuthProvider } from "../../context/AuthProvider";

export const Sidebar = ({ onClose }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const user = useAuthProvider();

	let isHomeActive = useMatch("/");
	let isClubsActive = location.pathname.startsWith("/clubs");
	let isMembersActive = location.pathname.startsWith("/members");
	let isToursActive = location.pathname.startsWith("/tournaments");
	let isEventsActive = location.pathname.startsWith("/events");
	return (
		<Flex as="section" minH="100vh" bg="bg-canvas" zIndex="overlay" overflow={"hidden"}>
			<Flex
				className="sidebar"
				flex="1"
				bg="bg-accent"
				color="on-accent"
				maxW={{
					base: "full",
					sm: "2xs",
				}}
				py={{
					base: "6",
					sm: "8",
				}}
				px={{
					base: "4",
					sm: "3",
				}}>
				<Stack justify="space-between" spacing="1">
					<Stack
						spacing={{
							base: "5",
							sm: "6",
						}}
						shouldWrapChildren>
						<Center>
							<CarromLogo onClick={() => navigate("/")} />
						</Center>
						<Divider borderColor="bg-accent-subtle" />

						<Stack spacing="1">
							<NavButton
								label="Dashboard"
								icon={RxDashboard}
								link="/"
								aria-current={isHomeActive ? "page" : undefined}
								className="buttonColor"
								onClick={onClose}
							/>
							<NavButton
								label="Manage Clubs"
								icon={HiOutlineUserGroup}
								link="/clubs"
								aria-current={isClubsActive ? "page" : undefined}
								className="buttonColor"
								onClick={onClose}
							/>
							<NavButton
								label="Manage Members"
								icon={FiUsers}
								link="/members"
								aria-current={isMembersActive ? "page" : undefined}
								className="buttonColor"
								onClick={onClose}
							/>
							<NavButton
								label="Manage Tournaments"
								icon={BsTrophy}
								link="/tournaments"
								aria-current={isToursActive ? "page" : undefined}
								className="buttonColor"
								onClick={onClose}
							/>
							<NavButton
								label="Manage Events"
								icon={FiCalendar}
								link="/events"
								aria-current={isEventsActive ? "page" : undefined}
								className="buttonColor"
								onClick={onClose}
							/>
						</Stack>
					</Stack>
					<Stack
						spacing={{
							base: "5",
							sm: "6",
						}}>
						<Stack spacing="1">
							{/* <NavButton label="Settings" icon={FiSettings} /> */}
							{user.loggedIn && (
								<Link href="/.auth/logout?post_logout_redirect_uri=/" onClick={onClose}>
									<Button
										variant="ghost-on-accent"
										justifyContent="start"
										w="100%">
										<HStack spacing="3">
											<Icon as={BiLogOut} boxSize="6" />
											<Text>Logout</Text>
										</HStack>
									</Button>
								</Link>
							)}
						</Stack>

						<Divider borderColor="bg-accent-subtle" />
						<UserProfile
							name={`${user.firstName} ${user.lastName}`}
							image={user.picture}
							email={user.email}
						/>
					</Stack>
				</Stack>
			</Flex>
		</Flex>
	);
};

import {
	Box,
	Drawer,
	DrawerContent,
	DrawerOverlay,
	Flex,
	useDisclosure,
	Container,
	HStack,
	ButtonGroup,
	useBreakpointValue,
	Avatar,
	IconButton,
	Link,
	Image,
} from "@chakra-ui/react";
import * as React from "react";
import { CarromLogo } from "../../logo/Logo";
import { Sidebar } from "../Sidebar/Sidebar";
import { ToggleButton } from "./ToggleButton";
import LiveLogo from "../../assets/LiveLogo.png";
import { FiHelpCircle, FiSearch, FiSettings } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useAuthProvider } from "../../context/AuthProvider";

export const Navbar = () => {
	const { isOpen, onToggle, onClose } = useDisclosure();
	const navigate = useNavigate();
	const user = useAuthProvider();
	const navbarMobile = () => {
		return (
			<Box
				bg="bg-accent"
				width="full"
				px={{
					base: "4",
					md: "8",
				}}
				zIndex="overlay">
				<ToggleButton
					isOpen={isOpen}
					aria-label="Open Menu"
					onClick={onToggle}
				/>
				<Drawer
					isOpen={isOpen}
					placement="left"
					onClose={onClose}
					isFullHeight
					// Only disabled for showcase
					trapFocus={false}>
					<DrawerOverlay />
					<DrawerContent maxWidth="fit-content">
						<Sidebar onClose={onClose} />
					</DrawerContent>
				</Drawer>
				<Flex justify="center">
					<CarromLogo onClick={() => navigate("/")} />
				</Flex>
			</Box>
		);
	};
	const navbarDesktop = () => (
		<Box as="section" position="fixed" minWidth="100vw" zIndex="overlay">
			<Box as="nav" bg="bg-accent" color="on-accent" className="navBar">
				<Container py={{ base: "3", lg: "4" }}>
					<Flex justify="right">
						<HStack spacing="4">
							<ButtonGroup variant="ghost-on-accent">
								<IconButton
									icon={<FiSearch fontSize="1.25rem" />}
									aria-label="Search"
								/>
							</ButtonGroup>
							<Avatar
								boxSize="10"
								name={`${user.firstName} ${user.lastName}`}
								src={user.picture}
							/>
						</HStack>
					</Flex>
				</Container>
			</Box>
		</Box>
	);

	const isDesktop = useBreakpointValue({ base: false, lg: true });

	return isDesktop ? navbarDesktop() : navbarMobile();
};

export const NavbarEvents = () => {
	return (
		<Box as="section" position="fixed" minWidth="100vw" zIndex="overlay">
			<Box as="nav" bg="bg-accent" color="on-accent" className="navBar">
				<Container>
					<Flex justify="space-between" align={"center"}>
						<CarromLogo />
						<Link
							href="https://www.youtube.com/@carromlive9/streams"
							target="_blank">
							<Image
								src={LiveLogo}
								blockSize={{base: "2.3rem", sm: "2.4rem", md: "2.6rem", lg: "3.1rem"}}
								alt="Live Logo"
								style={{ userSelect: "none" }}
							/>
						</Link>
					</Flex>
				</Container>
			</Box>
		</Box>
	);
};

import {
	Avatar,
	Box,
	Button,
	HStack,
	Icon,
	Stack,
	Tag,
	Text,
	useColorModeValue,
	Wrap,
	Card,
	CardBody,
	VStack,
	Image,
} from "@chakra-ui/react";
import { useState } from "react";
import { HiCash, HiLocationMarker, HiShieldCheck } from "react-icons/hi";
import ApiService from "../../../service/Api.service";
import { useEffect } from "react";
import { playerImgDir } from "../../../Utilities/ImageLinks";
import { FaUserCircle } from "react-icons/fa";
import { useAuthProvider } from "../../../context/AuthProvider";

export const PlayerProfile = ({ eventId, boardNo }) => {
	// User Details
	const user = useAuthProvider();

	const textColor = useColorModeValue("gray.600", "gray.300");
	const [playerProfile, setPlayerProfile] = useState(null);
	async function fetchPlayerProfile() {
		try {
			let profileRes = await ApiService.httpGet(
				`/StreamingConfigurations?TourEventId=${eventId}&Board=${boardNo}&Class=getPlayerProfile`,
				user.userId
			);
			if (profileRes.status === true) {
				setPlayerProfile(profileRes.Profile);
			}
		} catch (err) {
			console.log(err);
		}
	}
	useEffect(() => {
		fetchPlayerProfile();
	}, []);
	if (playerProfile) {
		return (
			<Card minW="70vw">
				<CardBody bg={"white"} borderRadius={"2xl"}>
					<Stack
						direction={{ base: "column", md: "row" }}
						spacing={{ base: "3", md: "10" }}
						align="center">
						<Image
							boxSize={150}
							src={`${playerImgDir}${playerProfile.ImageLink}`}
							fallback={<FaUserCircle color="#90A4AE" size={200} />}
							name="Player Name"
							borderRadius={"full"}
							objectFit={"cover"}
						/>
						<VStack align={"start"}>
							<Stack
								spacing={{ base: "1", md: "2" }}
								direction={{ base: "column", md: "row" }}>
								<Text as="h2" fontWeight="bold" fontSize="xl">
									{playerProfile.PlayerName}
								</Text>
							</Stack>
							<Text mt="2" textColor={"GrayText"}>
								{playerProfile.SubTitle}
							</Text>
							<Wrap shouldWrapChildren spacing="4">
								<HStack spacing="1">
									<Icon as={HiLocationMarker} color="gray.400" />
									<Text fontSize="sm" fontWeight="medium" color={textColor}>
										{playerProfile.Location}
									</Text>
								</HStack>
							</Wrap>
							<Box fontSize="sm" noOfLines={2}>
								{playerProfile.Description}
							</Box>
							<Wrap shouldWrapChildren mt="5" color={textColor}>
								{playerProfile.Tags.map((tag) => (
									<Tag key={tag} color="inherit" px="3">
										{tag}
									</Tag>
								))}
							</Wrap>
						</VStack>
					</Stack>
				</CardBody>
			</Card>
		);
	} else {
		return null;
	}
};

import React from "react";
import {
	Table,
	Thead,
	Th,
	Td,
	Tr,
	Tbody,
	Box,
	Divider,
	HStack,
	useColorModeValue,
	useBreakpointValue,
} from "@chakra-ui/react";

export const TeamMatchupsTable = ({ matchups }) => {
	//Desktop BreakPoint Value
	const isDesktop = useBreakpointValue({ base: false, xl: true });
	// Mobile Breakpoint Value
	const isMobile = useBreakpointValue({
		base: true,
		sm: true,
		md: false,
		lg: false,
		xl: false,
	});
	// Tablet Breakpoint Value
	const isTablet = useBreakpointValue({
		base: false,
		sm: false,
		md: true,
		lg: false,
		xl: false,
	});
	//Box Shadow
	const shadow = useColorModeValue("sm", "sm-dark");
	return (
		<Box>
			<Box
				borderRadius={"2xl"}
				{...(isDesktop && { overflow: "hidden", _hover: { overflow: "auto" } })}
				{...((isMobile || isTablet) && { overflow: "auto" })}
				boxShadow={shadow}>
				<Table variant="striped">
					<Thead position="sticky" top={0} bgColor="black" zIndex="2">
						<Tr>
							<Th
								textColor="white"
								fontSize={isTablet ? "lg" : "xl"}
								py={4}
								px={2}
								{...(isMobile && { fontSize: "md" })}
								textAlign={"center"} whiteSpace={"normal"}>
								Round No
							</Th>
							<Th
								textColor="white"
								fontSize={isTablet ? "lg" : "xl"}
								{...(isMobile && { px: 1, fontSize: "md" })}
								textAlign={"start"}>
								Round Name
							</Th>
							<Th
								textColor="white"
								fontSize={isTablet ? "lg" : "xl"}
								{...(isMobile && { fontSize: "md" })}
								textAlign={"start"}>
								Opponent
							</Th>
						</Tr>
					</Thead>
					<Tbody>
						{matchups?.map((board, index) => (
							<Tr key={index}>
								<Td
									textAlign={"center"}
									fontSize={"lg"}
									fontWeight="medium"
									{...(isMobile && {
										fontSize: "md",
										maxW: "-webkit-fit-content",
									})}
									maxW="-webkit-fit-content"
									whiteSpace={"break-spaces"}>
									{board.RoundNo}
								</Td>
								<Td
									textAlign="start"
									fontSize={"lg"}
									fontWeight="medium"
									{...(isMobile && {
										fontSize: "sm",
										maxW: "-webkit-fit-content",
									})}
									maxW="-webkit-fit-content">
									{board.RoundName}
								</Td>
								<Td
									textAlign="start"
									fontSize={"lg"}
									fontWeight="medium"
									{...(isMobile && {
										fontSize: "md",
										maxW: "-webkit-fit-content",
									})}
									maxW="-webkit-fit-content" whiteSpace={"normal"}>
									{board.Opponent}
								</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</Box>
			<Divider />
			<HStack justify="space-between" p="1rem" bgColor="white" borderBottomRadius={"2xl"}>
				{/*---------------For Pagination-------------------------------*/}
			</HStack>
		</Box>
	);
};

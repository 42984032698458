import {
	HStack,
	Image,
	Table,
	Tbody,
	Td,
	Box,
	Tr,
	Th,
	Thead,
	VStack,
	FormControl,
	Select,
	Input,
	TableContainer,
	useBreakpointValue,
	Container,
	Text,
	Stack,
	Divider,
	useColorModeValue,
	FormLabel,
	IconButton,
	Icon,
	position,
	ButtonGroup,
	Button,
	Flex,
} from "@chakra-ui/react";
import { data } from "./data";

export const StandingsTable = ({ standingsData }) => {
	//Desktop Breakpoint Value
	const isDesktop = useBreakpointValue({ base: false, xl: true });
	// Mobile Breakpoint Value
	const isMobile = useBreakpointValue({
		base: true,
		sm: true,
		md: true,
		lg: false,
		xl: false,
	});
	// Tablet Breakpoint Value
	const isTablet = useBreakpointValue({
		base: false,
		sm: false,
		md: true,
		lg: false,
		xl: false,
	});
	//Box Shadow
	const shadow = useColorModeValue("sm", "sm-dark");

	const tableDesktop = () => (
		<Box>
			<Box borderTopRadius={"2xl"} boxShadow={shadow}>
				<Table variant="striped">
					<Thead position="sticky" top={0} bgColor="black" zIndex="2">
						<Tr>
							<Th
								textColor="white"
								fontSize={"md"}
								px={3}
								py={4}
								textAlign={isTablet ? "end" : "center"}
								{...(isMobile && {
									px: 1,
									maxW: "-webkit-fit-content",
									fontSize: "xs",
									py: 5,
								})}
								{...(isTablet && {
									maxW: "-webkit-fit-content",
									px: 1,
									fontSize: "sm",
								})}>
								Rank
							</Th>
							<Th
								textColor="white"
								fontSize={isTablet ? "sm" : "md"}
								px={5}
								{...(isMobile && { px: 2, fontSize: "xs" })}
								textAlign={"start"}>
								Player
							</Th>
							<Th
								textColor="white"
								fontSize={isTablet ? "sm" : "md"}
								px={3}
								{...(isMobile && { px: 1, fontSize: "xs" })}
								textAlign={"center"}>
								Played
							</Th>
							<Th
								textColor="white"
								fontSize={isTablet ? "sm" : "md"}
								px={3}
								{...(isMobile && { px: 1, fontSize: "xs" })}
								textAlign={"center"}>
								Points
							</Th>
							<Th
								textColor="white"
								fontSize={isTablet ? "sm" : "md"}
								px={3}
								{...(isMobile && { px: 1, fontSize: "xs" })}
								textAlign={"center"}>
								O/S
							</Th>
							<Th
								textColor="white"
								fontSize={isTablet ? "sm" : "md"}
								px={3}
								{...(isMobile && { px: 1, fontSize: "xs" })}
								textAlign={"center"}>
								Net Diff
							</Th>
						</Tr>
					</Thead>
					<Tbody>
						{standingsData.map((board) => (
							<Tr key={board.Position}>
								<Td
									textAlign={"center"}
									fontSize={isTablet ? "md" : "lg"}
									fontWeight="medium"
									{...(isMobile && {
										px: 1.5,
										maxW: "-webkit-fit-content",
										fontSize: "sm",
									})}
									{...(isTablet && { px: 2, maxW: "-webkit-fit-content" })}>
									{board.Position}
								</Td>
								<Td
									textAlign={"start"}
									fontSize={"lg"}
									fontWeight="medium"
									{...(isMobile && {
										px: 1,
										fontSize: "sm",
										maxW: "-webkit-fit-content",
									})}
									{...(isTablet && { px: 2 })}
									maxW="-webkit-fit-content"
									whiteSpace={"break-spaces"}>
									{board.TeamName}
								</Td>
								<Td
									textAlign="center"
									fontSize={"lg"}
									fontWeight="medium"
									{...(isMobile && {
										px: 1,
										fontSize: "sm",
										maxW: "-webkit-fit-content",
									})}
									{...(isTablet && { px: 2 })}
									maxW="-webkit-fit-content">
									{board.MatchesPlayed}
								</Td>
								<Td
									textAlign="center"
									fontSize={"lg"}
									fontWeight="medium"
									{...(isMobile && {
										px: 1,
										fontSize: "sm",
										maxW: "-webkit-fit-content",
									})}
									{...(isTablet && { px: 2 })}
									maxW="-webkit-fit-content">
									{board.Points}
								</Td>
								<Td
									textAlign="center"
									fontSize={"lg"}
									fontWeight="medium"
									{...(isMobile && {
										px: 1,
										fontSize: "sm",
										maxW: "-webkit-fit-content",
									})}
									{...(isTablet && { px: 2 })}
									maxW="-webkit-fit-content">
									{board.OppStrength}
								</Td>
								<Td
									textAlign="center"
									fontSize={"lg"}
									fontWeight="medium"
									{...(isMobile && {
										px: 1,
										fontSize: "sm",
										maxW: "-webkit-fit-content",
									})}
									{...(isTablet && { px: 2 })}
									maxW="-webkit-fit-content">
									{board.NetScore}
								</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</Box>
			<Divider />
			<HStack
				justify="space-between"
				p="1rem"
				bgColor="white"
				borderBottomRadius={"2xl"}>
				{/*---------------For Pagination-------------------------------*/}
			</HStack>
		</Box>
	);

	// const tableMobile = () => (
	// 	<Container
	// 		bgColor="white"
	// 		boxShadow={"sm"}
	// 		borderRadius="lg"
	// 		py="1rem"
	// 		px="1.5rem">
	// 		<Stack spacing={5} divider={<Divider />} fontSize="lg">
	// 			<HStack justify="space-between">
	// 				<Text fontWeight="bold">{data[0].id}</Text>
	// 				<Text>{data[0].player}</Text>
	// 			</HStack>
	// 			<Stack>
	// 				<HStack justify="space-between">
	// 					<Text fontWeight="bold">Played</Text>
	// 					<Text>{data[0].played}</Text>
	// 				</HStack>
	// 				<HStack justify="space-between">
	// 					<Text fontWeight="bold">Points</Text>
	// 					<Text>{data[0].points}</Text>
	// 				</HStack>
	// 				<HStack justify="space-between">
	// 					<Text fontWeight="bold">O/S</Text>
	// 					<Text>{data[0].os}</Text>
	// 				</HStack>
	// 				<HStack justify="space-between">
	// 					<Text fontWeight="bold">Net Diff</Text>
	// 					<Text>{data[0].netdiff}</Text>
	// 				</HStack>
	// 			</Stack>
	// 			<Stack direction={["column", "row"]} justify="space-between" pt="1rem">
	// 				{/*-----------For Pagination----------------------*/}
	// 			</Stack>
	// 		</Stack>
	// 	</Container>
	// );

	return tableDesktop();
};

import {
	Container,
	Stack,
	IconButton,
	Heading,
	HStack,
	useBreakpointValue,
	Flex,
	Button,
	ButtonGroup,
	useToast,
	Box,
	Tooltip,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";

import { Navbar } from "../../Components/Navbar/Navbar";
import { Sidebar } from "../../Components/Sidebar/Sidebar";

import { IoChevronBack } from "react-icons/io5";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import ApiService from "../../service/Api.service";
import { UpdateClubsBasicDetailsForm } from "../../Components/Clubs/UpdateClubForm/UpdateClubsBasicDetailsForm";
import { UpdateClubAddressForm } from "../../Components/Clubs/UpdateClubForm/UpdateClubsAddressForm";
import { BallTriangle } from "react-loader-spinner";
import uploadImageFile from "../../Components/Upload/uploadImageFile";
import { useAuthProvider } from "../../context/AuthProvider";

export const UpdateClub = () => {
	// User Details
	const user = useAuthProvider();

	let navigate = useNavigate();

	// Toast
	let toast = useToast();

	//Desktop BreakPoint Value
	const isDesktop = useBreakpointValue({
		base: false,
		lg: true,
	});
	//Page Header
	const pageHeader = useBreakpointValue({
		base: "xs",
		sm: "sm",
	});

	const {
		register,
		handleSubmit,
		setValue,
		formState: { isDirty, isValid, errors },
	} = useForm({
		delayError: "500",
		mode: "all",
	});
	let [file, setFile] = useState(null);

	// setFile that needs to be uploaded
	const handleFileChange = (event) => {
		console.log(event.target.files);
		setFile(event.target.files[0]);
		console.log("File selected");
	};

	const onSubmit = async (data) => {
		const streamingValue = data.FormValues.Streaming ? 1 : 0;
		let clubsObj = {
			ClubName: data.FormValues.ClubName,
			RegisteredName: data.FormValues.RegisteredName,
			Address1: data.FormValues.Address1,
			Address2: "",
			Address3: "",
			City: data.FormValues.City,
			State: data.FormValues.State,
			Country: data.FormValues.Country,
			NickName: data.FormValues.NickName,
			Phone: data.FormValues.Phone,
			Email: data.FormValues.Email,
			ZipCode: data.FormValues.ZipCode,
			Streaming: streamingValue,
			ClubImage:
				data.ClubName + "_" + Date.now() + "." + data.ClubImage[0].type.split("/").pop(),
		};
		console.log("saved", clubsObj);
		updateClub(clubsObj);
	};

	const location = useLocation();

	let [loading, setLoading] = useState(false);
	const [clubs, setClubs] = useState([]);
	const fetchClubDetails = async () => {
		try {
			setLoading(true);
			const response = await ApiService.httpGet(
				"/Clubs?ClubId=" + location.state.clubId,
				user.userId
			);
			let res = response.Clubs[0];
			setClubs(res);
			console.log("Selected Club", res);
			setClubValues(res);
			setLoading(false);
		} catch (err) {
			console.error(err.message);
			setLoading(false);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchClubDetails();
	}, []);

	const setClubValues = (clubDetails) => {
		setValue("FormValues", {
			ClubName: clubDetails.ClubName,
			RegisteredName: clubDetails.RegisteredName,
			Address1: clubDetails.Address1,
			City: clubDetails.City,
			State: clubDetails.State,
			Country: clubDetails.Country,
			NickName: clubDetails.NickName,
			Phone: clubDetails.Phone,
			Email: clubDetails.Email,
			ZipCode: clubDetails.ZipCode,
		});
	};

	const updateClub = async (reqObj) => {
		try {
			setLoading(true);
			const clubs = await ApiService.httpPut(
				`/Clubs?ClubId=${location.state.clubId}`,
				reqObj
			);
			console.log("SavedClubs", clubs);
			console.log("Successfully Club Updated.");
			if (clubs.status === true) {
				toast({
					title: "Update Club",
					description: "Club Updated Successfully!",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				navigateToClubs();
				setLoading(false);
			} else {
				toast({
					title: "Update Club",
					description: "Failed to Update Club",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				setLoading(false);
			}
		} catch (err) {
			console.error(err.message);
			toast({
				title: "Update Club",
				description: "Failed to Update Club",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
			setLoading(false);
		} finally {
			setLoading(false);
		}
		if (
			reqObj.ClubImage !== "" &&
			reqObj.ClubImage !== null &&
			reqObj.ClubImage !== undefined
		) {
			// Upload the file to Azure Blob
			const folderName = file && "Players";
			let { handleUpload } = uploadImageFile(file, toast, folderName, reqObj.ClubImage);
			await handleUpload();
		}
		// setAlertMessage("Clud Added Successfully");
		// setAlertTrigger(!AlertTrigger);
		// setClubs(clubs.Clubs)
	};

	const navigateToClubs = () => {
		return navigate("/clubs");
	};

	return (
		<Container
			flex="1"
			overflowY="auto"
			maxWidth="100vw"
			marginTop={isDesktop ? "3.5rem" : "0rem"}
			marginX={0}
			paddingBottom={10}
			css={{
				"::-webkit-scrollbar": {
					width: "0.5rem",
					height: "0.5rem",
				},
				"::-webkit-scrollbar-track": {
					background: "#bee3f8",
					borderRadius: "1rem",
				},
				"::-webkit-scrollbar-thumb": {
					background: "#3182ce",
					borderRadius: "1rem",
				},
			}}>
			<Stack
				mt={10}
				spacing={{
					base: "8",
					lg: "6",
				}}>
				<Stack
					spacing="4"
					direction={{
						base: "column",
						lg: "row",
					}}
					justify="space-between"
					align={{
						base: "start",
						lg: "center",
					}}>
					<HStack spacing="5">
						<IconButton
							borderRadius="full"
							p={2}
							size="md"
							bgColor="white"
							as={IoChevronBack}
							onClick={navigateToClubs}
						/>
						<Heading size={pageHeader} fontWeight="bold">
							Update Club
						</Heading>
					</HStack>
				</Stack>

				{loading ? (
					<Box
						position={"absolute"}
						bottom={"50%"}
						left={"50%"}
						p={0}
						m={0}
						zIndex={"overlay"}>
						<BallTriangle
							height={100}
							width={100}
							radius={5}
							color="#4fa94d"
							ariaLabel="ball-triangle-loading"
							wrapperClass={{}}
							wrapperStyle=""
							visible={true}
						/>
					</Box>
				) : (
					<form onSubmit={handleSubmit(onSubmit)}>
						<Stack spacing="1rem">
							<Stack gap="1rem">
								<UpdateClubsBasicDetailsForm
									club={clubs}
									register={register}
									errors={errors}
								/>
								<UpdateClubAddressForm
									club={clubs}
									register={register}
									errors={errors}
								/>
							</Stack>
							<Flex justify={"end"}>
								<ButtonGroup spacing="5">
									<Button
										onClick={navigateToClubs}
										size="lg"
										borderRadius="full"
										colorScheme="red">
										Cancel
									</Button>
									<Tooltip
										label={!isDirty ? "Nothing to update" : undefined}
										hasArrow>
										<Button
											type="submit"
											size="lg"
											isDisabled={!isDirty}
											borderRadius="full"
											colorScheme="blue">
											Submit
										</Button>
									</Tooltip>
								</ButtonGroup>
							</Flex>
						</Stack>
					</form>
				)}
			</Stack>
		</Container>
	);
};

import {
	Card,
	CardHeader,
	IconButton,
	Icon,
	Input,
	InputGroup,
	InputLeftElement,
	Stack,
	Text,
	useBreakpointValue,
	useColorModeValue,
	Menu,
	MenuList,
	MenuItem,
	MenuButton,
	CloseButton,
	FormControl,
	Select,
	Button,
	useToast,
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogCloseButton,
	AlertDialogBody,
	AlertDialogFooter,
	useDisclosure,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	HStack,
	Skeleton,
} from "@chakra-ui/react";
import {
	FiDownloadCloud,
	FiSearch,
	FiUploadCloud,
	FiSend,
	FiMoreVertical,
	FiRefreshCw,
} from "react-icons/fi";
import { useState, useRef } from "react";
import ApiService from "../../service/Api.service";
import { AiOutlineSwap } from "react-icons/ai";

export const TeamMatchupsTabCard = ({
	teamMatchupGroups,
	teamMatchupRounds,
	teamMatchupData,
	selctdTeamMatchGroup,
	selctdTeamMatchRound,
	refreshTeamMatchups,
	updateTeamMatchSelection,
	setQuery,
	eventId,
	groupsLookupLoad,
	roundsLookupLoad,
}) => {
	//Box Shadow
	const shadow = useColorModeValue("sm", "sm-dark");

	let toast = useToast();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();
	const cancelRef = useRef();

	// States
	let [selectedMatch1, setSelectedMatch1] = useState("");
	let [selectedMatch2, setSelectedMatch2] = useState("");
	let [selectedMatchType, setSelectedMatchType] = useState("Singles");
	const [menuLoading, setMenuLoading] = useState(false);

	const notificationHandler = async (event) => {
		console.log("Action performed for:", event.target.value);
		let notification = event.target.value;
		switch (notification) {
			case "sendTieLink":
				try {
					setMenuLoading(true);
					let welcomeRespose = await ApiService.httpPost(
						`/ScoreLink?EventId=${eventId}&Class=TieLink&RoundNo=1`,
						{}
					);
					console.log("Sent Message:", welcomeRespose);
					if (welcomeRespose.status === true) {
						toast({
							title: "Send Tie Link",
							description: "Tie Link Sent Successfully!",
							status: "success",
							duration: 3000,
							isClosable: true,
							position: "top-right",
						});
					} else {
						toast({
							title: "Send Tie Link",
							description: "Failed to Send Tie Link",
							status: "error",
							duration: 3000,
							isClosable: true,
							position: "top-right",
						});
					}
					setMenuLoading(false);
				} catch (err) {
					console.log(err);
					toast({
						title: "Send Tie Link",
						description: "Failed to Send Tie Link",
						status: "error",
						duration: 3000,
						isClosable: true,
						position: "top-right",
					});
					setMenuLoading(false);
				}
				break;
			case "sendMatchups":
				try {
					setMenuLoading(true);
					let welcomeRespose = await ApiService.httpPost(
						`/ScoreLink?EventId=${eventId}&Class=TeamsMatchups`,
						{}
					);
					console.log("Sent Message:", welcomeRespose);
					if (welcomeRespose.status === true) {
						toast({
							title: "Send Matchups",
							description: "Matchups Sent Successfully!",
							status: "success",
							duration: 3000,
							isClosable: true,
							position: "top-right",
						});
					} else {
						toast({
							title: "Send Matchups",
							description: "Failed to Send Matchups",
							status: "error",
							duration: 3000,
							isClosable: true,
							position: "top-right",
						});
					}
					setMenuLoading(false);
				} catch (err) {
					console.log(err);
					toast({
						title: "Send Matchups",
						description: "Failed to Send Matchups",
						status: "error",
						duration: 3000,
						isClosable: true,
						position: "top-right",
					});
					setMenuLoading(false);
				}
				break;
			case "sendResults":
				try {
					setMenuLoading(true);
					let sentPlayersRes = await ApiService.httpPost(
						`/ScoreLink?EventId=${eventId}&Class=Results`,
						{}
					);
					console.log("Sent Message:", sentPlayersRes);
					if (sentPlayersRes.status === true) {
						toast({
							title: "Send Results",
							description: "Results Sent Successfully!",
							status: "success",
							duration: 3000,
							isClosable: true,
							position: "top-right",
						});
					} else {
						toast({
							title: "Send Results",
							description: "Failed to Send Results",
							status: "error",
							duration: 3000,
							isClosable: true,
							position: "top-right",
						});
					}
					setMenuLoading(false);
				} catch (err) {
					console.log(err);
					toast({
						title: "Send Results",
						description: "Failed to Send Results",
						status: "error",
						duration: 3000,
						isClosable: true,
						position: "top-right",
					});
					setMenuLoading(false);
				}
				break;
			case "swapVenues":
				onOpen();
				break;
			case "deleteLatestMatchup":
				onAlertOpen();
				break;
		}
	};

	const deleteLatestMatchup = async () => {
		try {
			setMenuLoading(true);
			let deleteMatchupRes = await ApiService.httpDelete(
				`/TourEventMatchupsByGroup?Class=TeamsRound&TourEventId=${eventId}&GroupName=${selctdTeamMatchGroup.GroupId}`
			);
			console.log("Deleted Latest MatchUp:", deleteMatchupRes);
			if (deleteMatchupRes.status === true) {
				onAlertClose();
				toast({
					title: "Delete Latest Matchup",
					description: "Latest Matchup Deleted Successfully!",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				refreshTeamMatchups();
			} else if (deleteMatchupRes.status === false) {
				onAlertClose();
				toast({
					title: "Delete Latest Matchup",
					description: deleteMatchupRes.Message,
					status: "warning",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			} else {
				onAlertClose();
				toast({
					title: "Delete Latest Matchup",
					description: "Failed to Delete Latest Matchup",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
			setMenuLoading(false);
		} catch (err) {
			console.log(err);
			onAlertClose();
			toast({
				title: "Delete Latest Matchup",
				description: "Failed to Delete Latest Matchup",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
			setMenuLoading(false);
		}
	};

	const swapVenues = async () => {
		try {
			setMenuLoading(true);
			let swapRes = await ApiService.httpPut(
				`/TourEventMatchupsByGroup?Matchup1Id=${selectedMatch1}&Matchup2Id=${selectedMatch2}`,
				{}
			);
			console.log("Venues Swapped", swapRes);
			if (swapRes.status === true) {
				toast({
					title: "Swap Venues",
					description: "Venues Swapped Successfully!",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				refreshTeamMatchups();
				onClose();
			} else {
				toast({
					title: "Swap Venues",
					description: "Failed to Swap Venues",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
			setMenuLoading(false);
		} catch (err) {
			console.log(err);
			toast({
				title: "Swap Venues",
				description: "Failed to Swap Venues",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
			setMenuLoading(false);
		}
	};

	return (
		<>
			<Card variant={"elevated"} bgColor="white" boxShadow={shadow}>
				<CardHeader>
					<Stack direction={"column"} spacing="1.2rem" justify="space-between">
						<Stack direction="column" spacing=".7rem">
							<Text
								as="h3"
								fontSize={useBreakpointValue({
									base: "lg",
									md: "xl",
								})}
								fontWeight="semibold">
								Matchups
							</Text>
							<InputGroup maxW="xs" colorScheme="blue">
								<InputLeftElement pointerEvents="none">
									<Icon as={FiSearch} color="accent" boxSize="5" />
								</InputLeftElement>
								<Input
									w="100vw"
									placeholder="Search"
									onChange={(e) => {
										setQuery(e.target.value);
									}}
								/>
							</InputGroup>
						</Stack>
						<Stack direction="row" align="end" justify="flex-end" spacing=".7rem">
							<IconButton
								variant="ghost"
								size="md"
								color="accent"
								icon={<FiRefreshCw size="1.7rem" onClick={refreshTeamMatchups} />}
							/>
							<FormControl width={"-webkit-max-content"}>
								<Skeleton isLoaded={!groupsLookupLoad} w="10vw">
									<Select
										name="Group"
										colorScheme="blue"
										value={selctdTeamMatchGroup.GroupId}
										width={"-webkit-max-content"}
										onChange={(event) =>
											updateTeamMatchSelection(
												event.target.value,
												selctdTeamMatchRound?.RoundNo
											)
										}>
										{teamMatchupGroups?.map((group, index) => {
											return (
												<option key={group.GroupName} value={group.GroupId}>
													{group.GroupName}
												</option>
											);
										})}
									</Select>
								</Skeleton>
							</FormControl>
							<FormControl width={"-webkit-max-content"}>
								<Skeleton isLoaded={!roundsLookupLoad} w="10vw">
									<Select
										name="Round"
										colorScheme="blue"
										value={selctdTeamMatchRound?.RoundNo}
										width={"-webkit-max-content"}
										fontSize={{ base: "sm", md: "md" }}
										{...((teamMatchupRounds?.length < 1 ||
											teamMatchupRounds === undefined) && {
											placeholder: "-",
										})}
										textAlign="start"
										onChange={(event) => {
											updateTeamMatchSelection(
												selctdTeamMatchGroup.GroupId,
												event.target.value
											);
										}}>
										{teamMatchupRounds?.map((round, index) => (
											<option key={index} value={round.RoundNo}>
												{round.RoundName}
											</option>
										))}
									</Select>
								</Skeleton>
							</FormControl>
							<Menu direction="rtl">
								{({ isOpen }) => (
									<>
										<MenuButton
											isActive={isOpen}
											as={IconButton}
											variant="ghost"
											size="md"
											color="green.500"
											borderRadius="full"
											isLoading={menuLoading}
											icon={
												isOpen ? (
													<CloseButton />
												) : (
													<FiMoreVertical size="1.7rem" />
												)
											}
										/>
										<MenuList zIndex="overlay">
											<MenuItem
												textColor="green.400"
												fontWeight="medium"
												value="sendTieLink"
												onClick={notificationHandler}>
												Send Tie Link
											</MenuItem>
											<MenuItem
												textColor="green.400"
												fontWeight="medium"
												value="sendMatchups"
												onClick={notificationHandler}>
												Send Matchups
											</MenuItem>
											<MenuItem
												textColor="green.400"
												fontWeight="medium"
												value="sendResults"
												onClick={notificationHandler}>
												Send Results
											</MenuItem>
											<MenuItem
												textColor="green.400"
												fontWeight="medium"
												value="swapVenues"
												onClick={notificationHandler}>
												Swap Venues
											</MenuItem>
											<MenuItem
												textColor="green.400"
												fontWeight="medium"
												value="deleteLatestMatchup"
												onClick={notificationHandler}>
												Delete Latest Matchup
											</MenuItem>
										</MenuList>
									</>
								)}
							</Menu>
						</Stack>
					</Stack>
				</CardHeader>
			</Card>
			<Modal
				onClose={() => {
					onClose();
					setSelectedMatchType("Singles");
					setSelectedMatch1("");
					setSelectedMatch2("");
				}}
				isOpen={isOpen}
				size={{ base: "xs", sm: "md", md: "lg", lg: "xl" }}
				isCentered>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader textAlign={"center"}>Swap Venues</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<HStack justify={"space-evenly"}>
							<Select
								onChange={(e) => {
									setSelectedMatchType(e.target.value);
									setSelectedMatch1("");
									setSelectedMatch2("");
								}}>
								<option value={"Singles"}>Singles</option>
								<option value={"Doubles"}>Doubles</option>
							</Select>
							<Select
								placeholder="Select a Team"
								onChange={(e) => setSelectedMatch1(e.target.value)}>
								{teamMatchupData?.map((team) =>
									team?.Matchups?.filter(
										(matchup) =>
											matchup.MatchupId !== selectedMatch2 &&
											matchup.MatchType === selectedMatchType
									)?.map((players, index) => (
										<option key={players.MatchupId} value={players.MatchupId}>
											{players.Board && players.Board + ". "}
											{players.Player1Name} vs {players.Player2Name}
										</option>
									))
								)}
							</Select>
							<Icon as={AiOutlineSwap} boxSize={"1.5rem"} />
							<Select
								placeholder="Select a Team"
								onChange={(e) => setSelectedMatch2(e.target.value)}>
								{teamMatchupData?.map((team) =>
									team?.Matchups?.filter(
										(matchup) =>
											matchup.MatchupId !== selectedMatch1 &&
											matchup.MatchType === selectedMatchType
									)?.map((players, index) => (
										<option key={players.MatchupId} value={players.MatchupId}>
											{players.Board && players.Board + ". "}
											{players.Player1Name} vs {players.Player2Name}
										</option>
									))
								)}
							</Select>
						</HStack>
					</ModalBody>
					<ModalFooter>
						<Button
							colorScheme={"green"}
							onClick={swapVenues}
							disabled={
								selectedMatch1 !== "" && selectedMatch2 !== "" ? false : true
							}>
							Swap
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
			<AlertDialog
				motionPreset="slideInBottom"
				leastDestructiveRef={cancelRef}
				onClose={onAlertClose}
				isOpen={isAlertOpen}
				isCentered>
				<AlertDialogOverlay />

				<AlertDialogContent>
					<AlertDialogHeader>Delete Latest Matchup</AlertDialogHeader>
					<AlertDialogCloseButton />
					<AlertDialogBody>
						Are you sure you want to Delete Latest Matchup?
					</AlertDialogBody>
					<AlertDialogFooter>
						<Button ref={cancelRef} onClick={onAlertClose}>
							Cancel
						</Button>
						<Button colorScheme="red" ml={3} onClick={deleteLatestMatchup}>
							Delete
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
		</>
	);
};

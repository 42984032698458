import {
	HStack,
	Table,
	Tbody,
	Td,
	Box,
	Tr,
	Th,
	Thead,
	FormControl,
	Input,
	useBreakpointValue,
	Divider,
	Button,
	Checkbox,
	IconButton,
	useToast,
	AlertDialog,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogCloseButton,
	AlertDialogBody,
	AlertDialogOverlay,
	AlertDialogFooter,
	useDisclosure,
	Text,
	ButtonGroup,
	Stack,
	Center,
	Select,
	Skeleton,
	NumberInput,
	NumberInputField,
} from "@chakra-ui/react";
import { async } from "q";
import { useState, useRef } from "react";
import { FiTrash } from "react-icons/fi";
import { TbActions, TbDelete } from "../../../icons/Icons";
import ApiService from "../../../service/Api.service";
import { DoublesEditTeamModal } from "../../Events/DoublesTeamModal/DoublesEditTeamModal";
import usePagination from "../../../hooks/usePagination";
import { Pagination } from "../../Pagination/Pagination";

export const DoublesTeamTable = (props) => {
	const isDesktop = useBreakpointValue({ base: false, xl: true });
	const isMobile = useBreakpointValue({
		base: true,
		md: false,
	});

	// Reload States
	let teamsReload = props.teamsReload;
	let setTeamsReload = props.setTeamsReload;

	//Loading State
	let doublesTeamLoad = props.doublesTeamLoad;

	const toast = useToast();

	const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();
	const cancelRef = useRef();

	const [saveLoading, setSaveLoading] = useState(false);
	const [delLoading, setDelLoading] = useState(false);
	let [currTeam, setCurrTeam] = useState({});
	let generateGroups = props.generateGroups;
	let generateLoading = props.generateLoading;
	let updatedPlayersData = [];

	const updateRank = (rank, live, player) => {
		player.Rank = Number(rank);
		player.Live = Number(live);
		updatedPlayersData.push({
			DETeamId: player.DETeamId,
			Rank: rank,
			Live: live,
		});
	};

	const saveTeams = async () => {
		try {
			setSaveLoading(true);
			let reqObj = {
				Players: props.doublesTeamData.map((event) => {
					return {
						DETeamId: event.DETeamId,
						Rank: event.Rank,
						Live: event.Live,
					};
				}),
			};
			let saveTeamsRes = await ApiService.httpPut(`/DoublesEventTeams?BulkRank=Yes`, reqObj);
			console.log("Saved Teams: ", saveTeamsRes);
			if (saveTeamsRes.status === true) {
				toast({
					title: "Saved Teams!",
					description: "Successfully Saved Teams",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				setTeamsReload(!teamsReload);
			} else if (saveTeamsRes.status === false) {
				toast({
					title: "Saved Team!",
					description: saveTeamsRes?.Message,
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			} else {
				toast({
					title: "Saved Team!",
					description: "Failed to Save Teams.",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
			setSaveLoading(false);
		} catch (err) {
			console.log(err);
			toast({
				title: "Saved Team!",
				description: "Failed to Save Teams.",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
			setSaveLoading(false);
		}
	};

	const deleteTeam = async () => {
		try {
			setDelLoading(true);
			let deleteTeamRes = await ApiService.httpDelete(
				`/DoublesEventTeams?DETeamId=${currTeam.DETeamId}`
			);
			console.log("Deleted Team:", deleteTeamRes);
			if (deleteTeamRes.status === true) {
				onAlertClose();
				toast({
					title: "Delete Doubles Team",
					description: "Team Deleted Successfully!",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			} else {
				onAlertClose();
				toast({
					title: "Delete Doubles Team",
					description: "Failed to Delete Team",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
			props.doublesTeamData.splice(currTeam.index, 1);
			setTeamsReload(!teamsReload);
			setDelLoading(false);
		} catch (err) {
			console.log(err);
			onAlertClose();
			toast({
				title: "Delete Doubles Team",
				description: "Failed to Delete Team",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
			setDelLoading(false);
		}
	};

	// Pagination
	let [rows, setRows] = useState(10);
	const perPage = rows;

	const {
		currentPage,
		currentData,
		jump,
		prev,
		next,
		first,
		last,
		setLast,
		setCurrentPage,
		maxPage,
	} = usePagination(
		props.doublesTeamData?.filter((player) => {
			if (props.query === "") {
				return player;
			} else if (
				player.Player1Name.toLowerCase().includes(props.query.toLowerCase()) ||
				player.Player2Name.toLowerCase().includes(props.query.toLowerCase()) ||
				player.TeamName.toLowerCase().includes(props.query.toLowerCase())
			) {
				return player;
			}
		}),
		perPage
	);

	const handleChange = (e, p) => {
		jump(p);
	};

	const handleRows = (event) => {
		setRows(Number(event.target.value));
		setLast(Number(event.target.value));
		setCurrentPage(1);
		// 	setParamTwo(Number(event.target.value));
	};

	// const previousPage = (event) => {
	// 	setParamTwo(Number(paramOne));
	// 	setParamOne(Number(paramTwo - rows));
	// };
	// const nextPage = (event) => {
	// 	setParamOne(Number(paramTwo));
	// 	setParamTwo(Number(paramTwo + rows));
	// };

	const tableDesktop = () => (
		<>
			<Box>
				<Box
					borderRadius={"2xl"}
					overflow="auto"
					boxShadow={"sm"}
					css={{
						"::-webkit-scrollbar": {
							width: "0.2rem",
							height: "0.2rem",
						},
						"::-webkit-scrollbar-track": {
							background: "#bee3f8",
							borderRadius: "1rem",
						},
						"::-webkit-scrollbar-thumb": {
							background: "#3182ce",
							borderRadius: "1rem",
						},
					}}>
					<Table variant="striped">
						<Thead position="sticky" top={0} bgColor="black" zIndex="2">
							<Tr>
								<Th textColor="white" fontSize="md" textAlign="start" py={5}>
									Player 1
								</Th>
								<Th textColor="white" fontSize="md" textAlign="start">
									Player 2
								</Th>
								<Th textColor="white" fontSize="md" textAlign="start">
									Team Name
								</Th>
								<Th textColor="white" fontSize="md" textAlign="start">
									Rank
								</Th>
								<Th textColor="white" fontSize="md" textAlign="start">
									Live
								</Th>
								<Th
									textColor="white"
									fontSize="md"
									textAlign="start"
									borderTopRightRadius="2xl">
									Actions
								</Th>
							</Tr>
						</Thead>
						<Tbody>
							{doublesTeamLoad
								? Array(5)
										.fill()
										.map((rows, index) => (
											<Tr key={index}>
												<Td>
													<Skeleton
														isLoaded={!doublesTeamLoad}
														h={"1.2rem"}
														w={"100%"}
													/>
												</Td>
												<Td>
													<Skeleton
														isLoaded={!doublesTeamLoad}
														h={"1.2rem"}
														w={"100%"}
													/>
												</Td>
												<Td>
													<Skeleton
														isLoaded={!doublesTeamLoad}
														h={"1.2rem"}
														w={"100%"}
													/>
												</Td>
												<Td>
													<Skeleton
														isLoaded={!doublesTeamLoad}
														h={"1.2rem"}
														w={"100%"}
													/>
												</Td>
												<Td>
													<Skeleton
														isLoaded={!doublesTeamLoad}
														h={"1.2rem"}
														w={"100%"}
													/>
												</Td>
												<Td>
													<Skeleton
														isLoaded={!doublesTeamLoad}
														h={"1.2rem"}
														w={"100%"}
													/>
												</Td>
											</Tr>
										))
								: currentData()?.map((player, index) => (
										<Tr key={player.DETeamId}>
											<Td
												flex="1"
												textAlign="start"
												fontSize="md"
												fontWeight="medium">
												{player.Player1Name}
											</Td>
											<Td
												flex="1"
												textAlign="start"
												fontSize="md"
												fontWeight="medium">
												{player.Player2Name}
											</Td>
											<Td
												flex="1"
												textAlign="start"
												fontSize="md"
												fontWeight="medium">
												{player.TeamName}
											</Td>
											<Td
												flex="1"
												textAlign="start"
												fontSize="md"
												fontWeight="medium">
												<FormControl w="fit-content">
													<NumberInput
														defaultValue={player.Rank}
														min={1}
														inputMode="numeric"
														textAlign="center"
														fontSize="lg"
														w={"5vw"}
														px={2}
														keepWithinRange={true}
														onChange={(value) =>
															(player.Rank = Number(value))
														}
														onBlur={(e) =>
															updateRank(
																e.target.value,
																player.Live,
																player
															)
														}>
														<NumberInputField
															textAlign={"center"}
															padding={0}
														/>
													</NumberInput>
												</FormControl>
											</Td>
											<Td>
												<FormControl>
													<Checkbox
														size="lg"
														defaultChecked={player.Live}
														textAlign="start"
														onChange={(e) => {
															player.Live = !player.Live;
															updateRank(
																player.Rank,
																e.target.checked ? 1 : 0,
																player
															);
														}}
													/>
												</FormControl>
											</Td>
											<Td>
												<HStack justify="start">
													<DoublesEditTeamModal
														player={player}
														setTeamsReload={props.setTeamsReload}
														teamsReload={props.teamsReload}
														EventId={props.state.EventId}
													/>
													<IconButton
														icon={<FiTrash />}
														color="red"
														bgColor="red.100"
														_hover={{ bgColor: "red.200" }}
														_active={{ bgColor: "red.300" }}
														onClick={(e) => {
															setCurrTeam({
																DETeamId: player.DETeamId,
																TeamName: player.TeamName,
																index: index,
															});
															onAlertOpen();
														}}
													/>
												</HStack>
											</Td>
										</Tr>
								  ))}
						</Tbody>
					</Table>
				</Box>
				<Divider />
				<Stack p="1rem" bgColor="white" borderBottomRadius={"2xl"}>
					{/*---------------For Pagination-------------------------------*/}
					<ButtonGroup justifyContent={"space-between"}>
						<Button
							color="white"
							bgColor="bg-accent"
							borderRadius="full"
							onClick={saveTeams}
							isLoading={saveLoading}>
							Save Teams
						</Button>
						<Button
							color="white"
							bgColor="bg-accent"
							borderRadius="full"
							onClick={() => generateGroups()}
							isLoading={generateLoading}>
							Generate Groups
						</Button>
					</ButtonGroup>
					{isMobile ? (
						<Stack>
							<Center>
								<Pagination
									count={props.doublesTeamData?.length}
									pageSize={rows}
									siblingCount={0}
									page={currentPage}
									onChange={(e) => setCurrentPage(e.page)}
								/>
							</Center>
							<HStack justify="space-between">
								<HStack spacing={1}>
									<Text as="span" whiteSpace="normal">
										Rows per page{" "}
									</Text>
									<Select
										boxSize={"-webkit-max-content"}
										minW="-webkit-fit-content"
										size="sm"
										value={rows}
										onChange={(event) => handleRows(event)}>
										{[10, 25, 50].map((rows) => (
											<option key={rows} value={rows}>
												{rows}
											</option>
										))}
									</Select>
								</HStack>
								<Text whiteSpace="normal">
									Showing {currentPage * rows - rows + 1} -{" "}
									{currentPage * rows < props.doublesTeamData?.length
										? currentPage * rows
										: props.doublesTeamData?.length}{" "}
									of {props.doublesTeamData?.length} Entries
								</Text>
							</HStack>
						</Stack>
					) : (
						<HStack justify="space-between" pt={3}>
							<HStack spacing={1}>
								<Text as="span" whiteSpace={"nowrap"}>
									Rows per page{" "}
								</Text>
								<Select
									boxSize={"-webkit-max-content"}
									minW="-webkit-fit-content"
									value={rows}
									onChange={(event) => handleRows(event)}>
									{[10, 25, 50].map((rows) => (
										<option key={rows} value={rows}>
											{rows}
										</option>
									))}
								</Select>
							</HStack>
							<Center>
								<Pagination
									count={props.doublesTeamData?.length}
									pageSize={rows}
									siblingCount={1}
									page={currentPage}
									onChange={(e) => setCurrentPage(e.page)}
								/>
							</Center>
							<Text whiteSpace="normal">
								Showing {currentPage * rows - rows + 1} -{" "}
								{currentPage * rows < props.doublesTeamData?.length
									? currentPage * rows
									: props.doublesTeamData?.length}{" "}
								of {props.doublesTeamData?.length} Entries
							</Text>
						</HStack>
					)}
				</Stack>
			</Box>
			<AlertDialog
				motionPreset="slideInBottom"
				leastDestructiveRef={cancelRef}
				onClose={() => {
					setCurrTeam({});
					onAlertClose();
				}}
				isOpen={isAlertOpen}
				isCentered>
				<AlertDialogOverlay />

				<AlertDialogContent>
					<AlertDialogHeader>Delete Team</AlertDialogHeader>
					<AlertDialogCloseButton />
					<AlertDialogBody>
						Are you sure you want to delete Team{" "}
						<Text as={"span"} fontWeight="bold">
							{currTeam?.TeamName}
						</Text>
						?
					</AlertDialogBody>
					<AlertDialogFooter>
						{!delLoading && (
							<Button
								ref={cancelRef}
								onClick={() => {
									setCurrTeam({});
									onAlertClose();
								}}>
								Cancel
							</Button>
						)}
						<Button
							colorScheme="red"
							ml={3}
							onClick={deleteTeam}
							isLoading={delLoading}>
							Delete
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
		</>
	);

	// const tableMobile = () => (
	// 	<Box>
	// 		{data.map((board) => (
	// 			<Container
	// 				mb="1rem"
	// 				bgColor="white"
	// 				boxShadow={"sm"}
	// 				borderRadius="lg"
	// 				py="1rem"
	// 				px="1.5rem">
	// 				<Stack spacing={5} divider={<Divider />} fontSize="lg">
	// 					<HStack justify="space-between">
	// 						<Text fontWeight="bold">Player 1</Text>
	// 						<Text>{board.player1}</Text>
	// 					</HStack>
	// 					<HStack justify="space-between">
	// 						<Text fontWeight="bold">Player 2</Text>
	// 						<Text>{board.player2}</Text>
	// 					</HStack>
	// 					<HStack justify="space-between">
	// 						<Text fontWeight="bold">Team Name</Text>
	// 						<Text>{board.teamname}</Text>
	// 					</HStack>
	// 					<HStack justify="space-between">
	// 						<FormControl>
	// 							<Input
	// 								placeholder={board.rank}
	// 								textAlign="start"
	// 								fontSize="lg"
	// 							/>
	// 						</FormControl>
	// 						<FormControl>
	// 							<Input
	// 								type="checkbox"
	// 								value={board.live}
	// 								textAlign="start"
	// 								fontSize="lg"
	// 							/>
	// 						</FormControl>
	// 					</HStack>

	// 					<Stack
	// 						direction={["column", "row"]}
	// 						justify="space-between"
	// 						pt="1rem">
	// 						{/*-----------For Pagination----------------------*/}
	// 					</Stack>
	// 				</Stack>
	// 			</Container>
	// 		))}
	// 	</Box>
	// );

	return tableDesktop();
};

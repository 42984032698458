import {
	Box,
	Flex,
	HStack,
	Center,
	Icon,
	SimpleGrid,
	Stack,
	Text,
	useColorModeValue,
	Image,
	useBreakpointValue,
	useToast,
	useDisclosure,
	Button,
	AlertDialog,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogCloseButton,
	AlertDialogBody,
	AlertDialogOverlay,
	AlertDialogFooter,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	IconButton,
} from "@chakra-ui/react";
import { useEffect, useState, useRef } from "react";
import { FiEdit, FiMoreHorizontal, FiTrash } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { Calendar } from "../../../icons/Icons";

import tour1 from "../../../images/tour-img1.png";
import tour2 from "../../../images/tour-img2.png";
import tour3 from "../../../images/tour-img3.png";
import tour4 from "../../../images/tour-img4.png";
import tour5 from "../../../images/tour-img5.png";
import ApiService from "../../../service/Api.service";
import { ShimmerBadge, ShimmerPostItem } from "react-shimmer-effects-18";

export const ManageTournamentCard = (props) => {
	//Card Heading
	const cardHeading = useBreakpointValue({ base: "lg", sm: "xl", md: "2xl" });
	//Box Shadow
	const shadow = useColorModeValue("sm", "sm-dark");
	const [showOverlay, setShowOverlay] = useState(false);
	let [currTournament, setCurrTournament] = useState(null);

	const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();
	const cancelRef = useRef();

	// Toast
	let toast = useToast();

	let loading = props.loading;
	let tournamentData = props.tournamentData;
	let setTourReload = props.setTourReload;
	let tourReload = props.tourReload;

	let navigate = useNavigate();
	let NavigateToTournamentDetails = (tournament) => {
		navigate("/tournaments/tournamentdetails", {
			state: { tourId: tournament.TourId },
		});
		console.log("Navigate to TournamentDetails..");
	};

	let NavigateToUpdateTournaments = (tournament) => {
		navigate("/tournaments/updatetournament", {
			state: { tourId: tournament.TourId },
		});
		console.log("Navigate to UpdateTournament..");
	};
	let handleDelete = async () => {
		try {
			let deleteResponse = await ApiService.httpDelete(
				`/Tournaments?TourId=${currTournament.TourId}`
			);
			console.log("Deleted Tournament:", currTournament.TourId);
			if (deleteResponse.status === true) {
				onAlertClose();
				toast({
					title: "Delete Tournament",
					description: "Tournament Deleted Successfully!",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			} else if (deleteResponse.status === false) {
				onAlertClose();
				toast({
					title: "Delete Tournament",
					description: deleteResponse?.Message,
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			} else {
				onAlertClose();
				toast({
					title: "Delete Tournament",
					description: "Failed to Delete Tournament",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
			setTourReload(!tourReload);
			console.log("Tournament Reloaded...");
		} catch (err) {
			console.log(err);
			onAlertClose();
			toast({
				title: "Delete Tournament",
				description: "Failed to Delete Tournament",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
		}
	};

	return (
		<>
			<Box>
				{loading ? (
					<ShimmerBadge width={200} />
				) : (
					<Text mb={2} fontSize={cardHeading} fontWeight="medium">
						{tournamentData.ClubName}
					</Text>
				)}
				<Box bgColor="white" p={5} borderRadius="lg">
					<SimpleGrid columns={{ base: 1, sm: 2, md: 3, xl: 4 }} spacing={5}>
						{loading
							? Array(5)
									.fill()
									.map((load, index) => (
										<ShimmerPostItem
											key={index}
											card
											title
											imageType="thumbnail"
											imageWidth={80}
											imageHeight={80}
											contentCenter
										/>
									))
							: tournamentData.Tournaments.map((tournament, index) => {
									return (
										<div key={tournament?.TourId}>
											<Box
												boxShadow={shadow}
												bgColor="white"
												borderRadius="lg"
												pb="4"
												_hover={{
													border: "1px solid",
													borderColor: "blue.500",
												}}>
												<Stack>
													<Flex justify="end" px="3" pt="1">
														<Menu>
															<MenuButton
																as={IconButton}
																icon={<FiMoreHorizontal />}
																size="sm"
																variant="ghost"
																color="blackAlpha.500"
															/>
															<MenuList
																minWidth="unset"
																maxWidth="fit-content">
																<MenuItem
																	onClick={() =>
																		NavigateToUpdateTournaments(
																			tournament
																		)
																	}>
																	Edit
																</MenuItem>
															</MenuList>
														</Menu>
													</Flex>
													<Center
														onClick={() =>
															NavigateToTournamentDetails(tournament)
														}>
														<Image
															src={tour1}
															alt="Carrom Clubs"
															bgColor="purple.200"
															borderRadius="5"
															p="1.5"
															boxSize="76"
														/>
													</Center>
													<Text
														align="center"
														fontWeight="bold"
														fontSize="sm">
														{tournament?.TournamentName}
													</Text>
													<HStack spacing={2} justify="center">
														<Icon
															as={Calendar}
															boxSize={4}
															color="blackAlpha.500"
														/>
														<Text
															fontWeight="bold"
															fontSize="xs"
															color="blackAlpha.600">
															{tournament?.StartDate} -{" "}
															{tournament?.EndDate}
														</Text>
													</HStack>
												</Stack>
											</Box>
										</div>
									);
							  })}
					</SimpleGrid>
				</Box>
			</Box>

			<AlertDialog
				motionPreset="slideInBottom"
				leastDestructiveRef={cancelRef}
				onClose={() => {
					setCurrTournament(null);
					onAlertClose();
				}}
				isOpen={isAlertOpen}
				isCentered>
				<AlertDialogOverlay />
				<AlertDialogContent>
					<AlertDialogHeader>Delete Club</AlertDialogHeader>
					<AlertDialogCloseButton />
					<AlertDialogBody>
						Are you sure you want to delete{" "}
						<Text as={"span"} fontWeight={"bold"}>
							{currTournament?.TournamentName}
						</Text>{" "}
						tournament?
					</AlertDialogBody>
					<AlertDialogFooter>
						<Button
							ref={cancelRef}
							onClick={() => {
								setCurrTournament(null);
								onAlertClose();
							}}>
							Cancel
						</Button>
						<Button colorScheme="red" ml={3} onClick={handleDelete}>
							Delete
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
		</>
	);
};

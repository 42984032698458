import {
	Container,
	Button,
	Heading,
	Text,
	useBreakpointValue,
	Flex,
	Stack,
	SimpleGrid,
} from "@chakra-ui/react";
import { Navbar } from "../../Components/Navbar/Navbar";
import { Sidebar } from "../../Components/Sidebar/Sidebar";
import { ManageMemberCards } from "../../Components/Members/ManageMemberCards/ManageMemberCards";
import ApiService from "../../service/Api.service";
import { useEffect, useState } from "react";
import { data } from "../../App";
import { useNavigate } from "react-router-dom";
import { ShimmerPostItem } from "react-shimmer-effects-18";
import { useAuthProvider } from "../../context/AuthProvider";

export const ManageMembers = () => {
	// User Details
	const user = useAuthProvider();

	//Desktop BreakPoint Value
	const isDesktop = useBreakpointValue({
		base: false,
		lg: true,
	});
	//Page Header
	const pageHeader = useBreakpointValue({
		base: "xs",
		sm: "sm",
	});
	//Page Caption
	const pageCaption = useBreakpointValue({
		base: "sm",
		sm: "sm",
	});

	let [memberReload, setMemberReload] = useState(false);
	let [loading, setLoading] = useState(false);

	const [members, setMembers] = useState([]);
	const fetchMembers = async () => {
		try {
			setLoading(true);
			const response = await ApiService.httpGet("/Members?UserId=all", user.userId);
			setMembers(response.Members);
			console.log(response.Members);
			setLoading(false);
		} catch (err) {
			console.error(err.message);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchMembers();
	}, [memberReload]);

	const navigate = useNavigate();
	return (
		<Container
			flex="1"
			overflowY="auto"
			maxWidth="100vw"
			marginTop={isDesktop ? "5rem" : "1rem"}
			marginX={0}
			paddingBottom={10}
			css={{
				"::-webkit-scrollbar": {
					width: "0.3rem",
					height: "0.3rem",
				},
				"::-webkit-scrollbar-track": {
					background: "#bee3f8",
					borderRadius: "1rem",
				},
				"::-webkit-scrollbar-thumb": {
					background: "#3182ce",
					borderRadius: "1rem",
				},
			}}>
			<Stack
				spacing={{
					base: "8",
					lg: "6",
				}}>
				<Stack
					spacing={7}
					direction={{ base: "column", sm: "row" }}
					alignItems={{ sm: "center" }}
					justify="space-between">
					<Stack
						spacing="4"
						direction={{
							base: "column",
							lg: "row",
						}}
						justify="space-between"
						align={{
							base: "start",
							lg: "center",
						}}>
						<Stack spacing="1">
							<Heading size={pageHeader} fontWeight="bold">
								Manage Members
							</Heading>
							<Text fontWeight="bold" color="blackAlpha.500" fontSize={pageCaption}>
								View & Manage All Members
							</Text>
						</Stack>
					</Stack>
					<Button
						bgColor="bg-accent"
						color="white"
						_hover={{
							bgColor: "bg-accent",
							boxShadow: "0px 3px 20px 3px cornflowerblue",
						}}
						borderRadius={50}
						fontWeight="semibold"
						onClick={() => {
							navigate("/members/addmember");
						}}>
						Add New Member
					</Button>
				</Stack>
				<div>
					<SimpleGrid
						columns={{ base: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
						columnGap={{ base: "4", md: "6" }}
						rowGap={{ base: "8", md: "10" }}>
						{loading
							? Array(10)
									.fill()
									.map((load, index) => (
										<ShimmerPostItem
											key={index}
											card
											title
											imageType="thumbnail"
											imageWidth={80}
											imageHeight={80}
											contentCenter
										/>
									))
							: members.map((member, index) => {
									return (
										<ManageMemberCards
											key={member.UserId}
											memberData={member}
											index={index}
											setMemberReload={setMemberReload}
											memberReload={memberReload}
										/>
									);
							  })}

						{/*----------------------- ManageMember Card -------------------------*/}
					</SimpleGrid>
				</div>
			</Stack>
		</Container>
	);
};

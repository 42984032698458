import {
	HStack,
	Table,
	Tbody,
	Td,
	Box,
	Tr,
	Th,
	Thead,
	useBreakpointValue,
	Stack,
	Divider,
	IconButton,
	Flex,
	Heading,
	useToast,
	AlertDialog,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogCloseButton,
	AlertDialogBody,
	AlertDialogOverlay,
	AlertDialogFooter,
	useDisclosure,
	Button,
	Text,
	Center,
	Select,
} from "@chakra-ui/react";
import { useState, useRef } from "react";
import { FiTrash } from "react-icons/fi";
import { TbActions, TbAdd, TbDelete } from "../../../../icons/Icons";
import { AddPlayerModal } from "../AddPlayerModal/AddPlayerModal";
import { EditPlayerModal } from "../EditPlayerModal/EditPlayerModal";
import ApiService from "../../../../service/Api.service";
import { data } from "./data";
import { ShimmerTable } from "react-shimmer-effects-18";
import usePagination from "../../../../hooks/usePagination";
import { Pagination } from "../../../Pagination/Pagination";

export const TournamentRegPlayersTable = (props) => {
	//Desktop BreakPoint Value
	const isDesktop = useBreakpointValue({ base: false, xl: true });
	const isMobile = useBreakpointValue({
		base: true,
		md: false,
	});

	const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();
	const cancelRef = useRef();

	// Toast
	let toast = useToast();

	let [currPlayer, setCurrPlayer] = useState({});

	let players = props.playerData;
	let query = props.query;
	let playersReload = props.playersReload;
	let setPlayersReload = props.setPlayersReload;
	//let loading = props.loadingPlayerTable;
	const [loading, setLoading] = useState(false);
	let handleDelete = async () => {
		try {
			setLoading(true);
			let deleteResponse = await ApiService.httpDelete(
				`/TourPlayers?TourPlayerId=${currPlayer.TourPlayerId}`
			);
			console.log("Deleted Player: ", deleteResponse);
			if (deleteResponse.status === true) {
				onAlertClose();
				toast({
					title: "Delete Player",
					description: "Player Deleted Successfully!",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				setPlayersReload(!playersReload);
				console.log("Players Reloaded...");
			} else {
				onAlertClose();
				toast({
					title: "Delete Player",
					description: "Failed to Delete Player",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
		} catch (err) {
			console.log(err);
			onAlertClose();
			toast({
				title: "Delete Player",
				description: "Failed to Delete Player",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
		} finally {
			setLoading(false);
		}
	};

	// Pagination
	let [rows, setRows] = useState(10);
	const perPage = rows;

	const {
		currentPage,
		currentData,
		jump,
		prev,
		next,
		first,
		last,
		setLast,
		setCurrentPage,
		maxPage,
	} = usePagination(
		players?.filter((player) => {
			let fullName = player.FirstName + " " + player.LastName;
			if (query === "") {
				return player;
			} else if (
				fullName.toLowerCase().includes(query.toLowerCase()) ||
				player.NickName.toLowerCase().includes(query.toLowerCase()) ||
				player.Email.toLowerCase().includes(query.toLowerCase()) ||
				player.Mobile.includes(query)
			) {
				return player;
			}
		}),
		perPage
	);

	const handleChange = (e, p) => {
		jump(p);
	};

	const handleRows = (event) => {
		setRows(Number(event.target.value));
		setLast(Number(event.target.value));
		setCurrentPage(1);
		// 	setParamTwo(Number(event.target.value));
	};

	// const previousPage = (event) => {
	// 	setParamTwo(Number(paramOne));
	// 	setParamOne(Number(paramTwo - rows));
	// };
	// const nextPage = (event) => {
	// 	setParamOne(Number(paramTwo));
	// 	setParamTwo(Number(paramTwo + rows));
	// };

	const tableDesktop = () => (
		<>
			<Box>
				<Stack>
					<Flex justify="end" px="1rem">
						<AddPlayerModal
							eventData={props.eventData}
							tourId={props.tourId}
							refreshView={props.refreshView}
							loading={loading}
							players={players}
						/>
					</Flex>

					<Box
						borderRadius={"2xl"}
						overflow="auto"
						boxShadow={"sm"}
						css={{
							"::-webkit-scrollbar": {
								width: "0.3rem",
								height: "0.3rem",
							},
							"::-webkit-scrollbar-track": {
								background: "#bee3f8",
								borderRadius: "1rem",
							},
							"::-webkit-scrollbar-thumb": {
								background: "#3182ce",
								borderRadius: "1rem",
							},
						}}>
						{loading ? (
							<ShimmerTable row={4} col={6} />
						) : (
							<Table variant="striped">
								<Thead position="sticky" top={0} bgColor="black" zIndex="2">
									<Tr>
										<Th
											textColor="white"
											fontSize="md"
											textAlign="start"
											py={7}>
											Full Name
										</Th>
										<Th textColor="white" fontSize="md" textAlign="start">
											Event
										</Th>
										<Th textColor="white" fontSize="md" textAlign="start">
											Nick Name
										</Th>
										<Th textColor="white" fontSize="md" textAlign="start">
											Mobile
										</Th>
										<Th textColor="white" fontSize="md" textAlign="start">
											Email
										</Th>
										<Th textColor="white" fontSize="md" textAlign="start">
											Actions
										</Th>
									</Tr>
								</Thead>
								<Tbody>
									{currentData()?.map((player) => (
										<Tr key={player.TourPlayerId}>
											<Td textAlign="start" fontSize="lg" fontWeight="bold">
												{player.FirstName + " " + player.LastName}
											</Td>
											<Td textAlign="start" fontSize="lg" fontWeight="bold">
												{player.EventNames}
											</Td>
											<Td textAlign="start" fontSize="lg" fontWeight="bold">
												{player.NickName}
											</Td>
											<Td textAlign="start" fontSize="lg" fontWeight="bold">
												{player.DispPhoneNo
													? player.DispPhoneNo
													: player.Mobile}
											</Td>
											<Td textAlign="start" fontSize="lg" fontWeight="bold">
												{player.Email}
											</Td>
											<Td textAlign="start" fontSize="lg" fontWeight="bold">
												<HStack justify="center">
													<EditPlayerModal
														playerData={player}
														eventData={props.eventData}
														tourId={props.tourId}
														refreshView={props.refreshView}
													/>
													<IconButton
														icon={<FiTrash />}
														onClick={() => {
															setCurrPlayer(player);
															onAlertOpen();
														}}
														color="red"
														bgColor="red.100"
														_hover={{ bgColor: "red.200" }}
														_active={{ bgColor: "red.300" }}
													/>
												</HStack>
											</Td>
										</Tr>
									))}
								</Tbody>
							</Table>
						)}
					</Box>
					<Divider />
					<Stack p="1rem" bgColor="white" borderBottomRadius={"2xl"}>
						{/*---------------For Pagination-------------------------------*/}
						{isMobile ? (
							<Stack>
								<Center>
									<Pagination
										count={players?.length}
										pageSize={rows}
										siblingCount={0}
										page={currentPage}
										onChange={(e) => setCurrentPage(e.page)}
									/>
								</Center>
								<HStack justify="space-between">
									<HStack spacing={1}>
										<Text as="span" whiteSpace="normal">
											Rows per page{" "}
										</Text>
										<Select
											boxSize={"-webkit-max-content"}
											minW="-webkit-fit-content"
											size="sm"
											value={rows}
											onChange={(event) => handleRows(event)}>
											{[10, 25, 50].map((rows) => (
												<option key={rows} value={rows}>
													{rows}
												</option>
											))}
										</Select>
									</HStack>
									<Text whiteSpace="normal">
										Showing {currentPage * rows - rows + 1} -{" "}
										{currentPage * rows < players?.length
											? currentPage * rows
											: players?.length}{" "}
										of {players?.length} Entries
									</Text>
								</HStack>
							</Stack>
						) : (
							<HStack justify="space-between">
								<HStack spacing={1}>
									<Text as="span" whiteSpace={"nowrap"}>
										Rows per page{" "}
									</Text>
									<Select
										boxSize={"-webkit-max-content"}
										minW="-webkit-fit-content"
										value={rows}
										onChange={(event) => handleRows(event)}>
										{[10, 25, 50].map((rows) => (
											<option key={rows} value={rows}>
												{rows}
											</option>
										))}
									</Select>
								</HStack>
								<Center>
									<Pagination
										count={players?.length}
										pageSize={rows}
										siblingCount={1}
										page={currentPage}
										onChange={(e) => setCurrentPage(e.page)}
									/>
								</Center>
								<Text whiteSpace="normal">
									Showing {currentPage * rows - rows + 1} -{" "}
									{currentPage * rows < players?.length
										? currentPage * rows
										: players?.length}{" "}
									of {players?.length} Entries
								</Text>
							</HStack>
						)}
					</Stack>
				</Stack>
			</Box>

			<AlertDialog
				motionPreset="slideInBottom"
				leastDestructiveRef={cancelRef}
				onClose={() => {
					setCurrPlayer({});
					onAlertClose();
				}}
				isOpen={isAlertOpen}
				isCentered>
				<AlertDialogOverlay />
				<AlertDialogContent>
					<AlertDialogHeader>Delete Player</AlertDialogHeader>
					<AlertDialogCloseButton />
					<AlertDialogBody>
						Are you sure you want to delete{" "}
						<Text as={"span"} fontWeight="bold">
							{currPlayer.FirstName + " " + currPlayer.LastName}
						</Text>
						?
					</AlertDialogBody>
					<AlertDialogFooter>
						{loading ? null : (
							<Button
								ref={cancelRef}
								onClick={() => {
									setCurrPlayer({});
									onAlertClose();
								}}>
								Cancel
							</Button>
						)}
						<Button colorScheme="red" ml={3} onClick={handleDelete} isLoading={loading}>
							Delete
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
		</>
	);

	// const tableMobile = () => (
	// 	<Container
	// 		bgColor="white"
	// 		boxShadow={"sm"}
	// 		borderRadius="lg"
	// 		py="1rem"
	// 		px="1.5rem">
	// 		<Stack spacing={5} divider={<Divider />} fontSize="lg">
	// 			<HStack justify="space-between">
	// 				<Text fontWeight="bold">Position</Text>
	// 				<Text>{data[0].position}</Text>
	// 			</HStack>
	// 			<HStack justify="space-between">
	// 				<Text fontWeight="bold">Player Name</Text>
	// 				<Text>{data[0].player}</Text>
	// 			</HStack>
	// 			<Stack>
	// 				<HStack justify="space-between">
	// 					<Text fontWeight="bold">Matches Played</Text>
	// 					<Text>{data[0].played}</Text>
	// 				</HStack>
	// 				<HStack justify="space-between">
	// 					<Text fontWeight="bold">Points</Text>
	// 					<Text>{data[0].points}</Text>
	// 				</HStack>
	// 				<HStack justify="space-between">
	// 					<Text fontWeight="bold">Opponent(s) Strength</Text>
	// 					<Text>{data[0].os}</Text>
	// 				</HStack>
	// 				<HStack justify="space-between">
	// 					<Text fontWeight="bold">Net Score</Text>
	// 					<Text>{data[0].netscore}</Text>
	// 				</HStack>
	// 			</Stack>
	// 			<Stack direction={["column", "row"]} justify="center" pt="1rem">
	// 				{/*-----------For Pagination----------------------*/}
	// 				<Flex justify="center">
	// 					<AddPlayerModal />
	// 				</Flex>
	// 			</Stack>
	// 		</Stack>
	// 	</Container>
	// );

	return tableDesktop();
};

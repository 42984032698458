import {
	Container,
	Heading,
	Icon,
	useBreakpointValue,
	Flex,
	Stack,
	HStack,
	Card,
	CardHeader,
	Center,
	useColorModeValue,
	FormControl,
	Select,
	Text,
} from "@chakra-ui/react";
import { Navbar, NavbarEvents } from "../../Components/Navbar/Navbar";
import { PlayerSelectionTable } from "../../Components/Events/PlayerSelection/PlayerSelectionTable";
import { Sidebar } from "../../Components/Sidebar/Sidebar";
import { PlayerSelectionIcon } from "../../icons/Icons";
import { useParams } from "react-router-dom";
import ApiService from "../../service/Api.service";
import { useEffect, useState } from "react";
import _ from "lodash";
import { useAuthProvider } from "../../context/AuthProvider";

export const PlayerSelection = () => {
	// User Details
	const user = useAuthProvider();

	//Desktop BreakPoint Value
	const isDesktop = useBreakpointValue({
		base: false,
		lg: true,
	});
	// Mobile Breakpoint Value
	const isMobile = useBreakpointValue({
		base: true,
		sm: true,
		md: false,
	});
	//Page Header
	const pageHeader = useBreakpointValue({
		base: "xs",
		sm: "sm",
	});
	//Box Shadow
	const shadow = useColorModeValue("sm", "sm-dark");
	//Card Heading
	const cardHeading = useBreakpointValue({
		base: "xs",
		md: "sm",
	});

	// Parameters from url
	const { teamId } = useParams();

	// States
	let [plyrSelRounds, setPlyrSelRounds] = useState([]);
	let [selectedPlyrRound, setSelectedPlyrRound] = useState({});
	let [plyrSelData, setPlyrSelData] = useState([]);
	const [opponent, setOpponent] = useState(null);
	let [plyrSelPositions, setPlyrSelPositions] = useState([]);
	let [initialPlayers, setInitialPlayers] = useState([]);
	let [selectedPlayers, setSelectedPlayers] = useState([]);
	const [isEditable, setIsEditable] = useState(0);
	const [reload, setReload] = useState(false);
	const [editMode, setEditMode] = useState(false);

	const fetchPlyrSelData = async () => {
		try {
			let plyrSelRound = await ApiService.httpGet(
				"/TeamEventTeams?Class=TeamIdRoundLookup&TETeamId=" + teamId,
				"U00184"
			);
			setPlyrSelRounds(plyrSelRound.Rounds);
			let selectedRound = plyrSelRound.Rounds.find((round) => round.Latest === "YES");
			setSelectedPlyrRound(selectedRound);
			let response = await ApiService.httpGet(
				`/TeamsRoundPlayers?Class=Rounds&TETeamId=${teamId}&RoundNo=${selectedRound.RoundNo}`,
				"U00184"
			);
			setPlyrSelData(response.Teams[0]);
			setOpponent(response.Opponent);
			setIsEditable(response.isEditable);
			setInitialPlayers(response.Teams[0].Rounds);
			setSelectedPlayers(
				response.Teams[0]?.Rounds?.map((obj) => {
					if (obj.MatchType === "Singles" && obj.hasOwnProperty("Player2Id")) {
						const { Player2Id, Player2Name, ...newObj } = obj;
						return newObj;
					}
					return obj;
				}).filter((player) => {
					if (player.hasOwnProperty("MatchType")) {
						if (player.MatchType === "Singles") {
							return player.Player1Id !== "" && player.Player1Id !== undefined;
						} else {
							return (
								player.Player1Id !== "" &&
								player.Player1Id !== undefined &&
								player.Player2Id !== "" &&
								player.Player2Id !== undefined
							);
						}
					}
					return false;
				})
			);
			console.log("Res", response);
			response.Teams[0]?.Rounds?.some((ele) =>
				ele.MatchType === "Singles"
					? ele.Player1Id === ""
					: ele.Player1Id === "" || ele.Player2Id === ""
			)
				? setEditMode(true)
				: setEditMode(false);
			let plyrPos = await ApiService.httpGet(
				"/TeamsRoundPlayers?Class=PositionsLookUp&TETeamId=" + teamId,
				"U00184"
			);
			setPlyrSelPositions(plyrPos.Lookup);
		} catch (err) {
			console.log(err);
		}
	};
	const updatePlyrSelection = async (roundNo) => {
		console.log("Updated RoundNo", roundNo);
		setSelectedPlyrRound({ RoundNo: roundNo });
		let response = await ApiService.httpGet(
			`/TeamsRoundPlayers?Class=Rounds&TETeamId=${teamId}&RoundNo=${roundNo}`,
			"U00184"
		);
		setPlyrSelData(response.Teams[0]);
		let plyrPos = await ApiService.httpGet(
			"/TeamsRoundPlayers?Class=PositionsLookUp&TETeamId=" + teamId,
			"U00184"
		);
		setPlyrSelPositions(plyrPos.Lookup);
	};

	useEffect(() => {
		fetchPlyrSelData();
	}, [reload]);

	return (
		<Container
			flex="1"
			maxWidth="100vw"
			marginTop={isDesktop ? "7rem" : "5rem"}
			marginX={0}
			{...(isMobile && { px: "1" })}
			paddingBottom={10}>
			<Stack spacing="1.7rem">
				<Heading size={pageHeader} fontWeight="bold">
					Player Selection
				</Heading>
				<Stack spacing="1rem">
					<Card variant={"elevated"} bgColor="white" boxShadow={shadow}>
						<CardHeader>
							<Stack
								direction={{
									base: "column",
									md: "row",
								}}
								spacing="1.5rem"
								align={{
									base: "start",
									md: "center",
								}}
								justify="space-between">
								<HStack spacing="1.2rem" align="center">
									<Center bgColor="accent" p="1rem" borderRadius="xl">
										<Icon as={PlayerSelectionIcon} />
									</Center>
									<Stack spacing={0}>
										<Heading as="h2" size={cardHeading}>
											{plyrSelData?.TeamName}
										</Heading>
										<Text fontWeight={"medium"} textColor={"GrayText"}>
											{opponent !== null &&
												opponent !== undefined &&
												"vs " + opponent}
										</Text>
									</Stack>
								</HStack>
								<Stack
									spacing="1rem"
									direction={{
										base: "column",
										sm: "row",
									}}>
									<FormControl>
										<Select
											name="Round"
											w="10rem"
											colorScheme="blue"
											value={selectedPlyrRound.RoundNo}
											fontSize={{ base: "sm", md: "md" }}
											textAlign="start"
											onChange={(event) => {
												updatePlyrSelection(event.target.value);
											}}>
											{plyrSelRounds.map((round, index) => {
												return (
													<option
														key={round.RoundNo}
														value={round.RoundNo}>
														{round.RoundName}
													</option>
												);
											})}
										</Select>
									</FormControl>
								</Stack>
							</Stack>
						</CardHeader>
					</Card>

					<PlayerSelectionTable
						plyrSelData={plyrSelData}
						plyrSelPositions={plyrSelPositions}
						currTeamId={teamId}
						selectedPlayers={selectedPlayers}
						initialPlayers={initialPlayers}
						setInitialPlayers={setInitialPlayers}
						setSelectedPlayers={setSelectedPlayers}
						setReload={setReload}
						setEditMode={setEditMode}
						editMode={editMode}
						isEditable={isEditable}
					/>
				</Stack>
			</Stack>
		</Container>
	);
};

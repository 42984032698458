import {
	Container,
	Stack,
	IconButton,
	Heading,
	HStack,
	useBreakpointValue,
	Flex,
	Button,
	ButtonGroup,
	useToast,
	Box,
	Tooltip,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { IoChevronBack } from "react-icons/io5";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import ApiService from "../../service/Api.service";
import { UpdateMemberForm } from "../../Components/Members/UpdateMemberForm/UpdateMemberForm";
import uploadImageFile from "../../Components/Upload/uploadImageFile";
import { useAuthProvider } from "../../context/AuthProvider";
import { BallTriangle } from "react-loader-spinner";

export const UpdateMember = () => {
	// User Details
	const user = useAuthProvider();

	let navigate = useNavigate();

	let [clubs, setClubs] = useState([]);
	let [roles, setRoles] = useState([]);
	let [clubRoles, setClubRoles] = useState([]);

	// Toast
	let toast = useToast();

	//Desktop BreakPoint Value
	const isDesktop = useBreakpointValue({
		base: false,
		lg: true,
	});
	//Page Header
	const pageHeader = useBreakpointValue({
		base: "xs",
		sm: "sm",
	});

	const {
		register,
		watch,
		handleSubmit,
		setValue,
		control,
		getValues,
		clearErrors,
		formState: { isDirty, isValid, errors },
	} = useForm({
		delayError: "500",
		mode: "all",
		defaultValues: {
			FormValues: {
				clubs: [""],
				roles: [""],
			},
		},
	});

	const location = useLocation();
	let [loading, setLoading] = useState(false);
	const fetchMemberDetails = async () => {
		try {
			setLoading(true);
			const allRoles = await ApiService.httpGet("/Members?RoleId=all", user.userId);
			const allClubs = await ApiService.httpGet("/Tournaments?ClubId=all", user.userId);
			const response = await ApiService.httpGet(
				"/Members?UserId=" + location.state.userId,
				user.userId
			);
			setRoles(allRoles.Roles);
			setClubs(allClubs.Clubs);
			const res = response.Members[0];
			setClubRoles(res.ClubRoles);
			setMemberValues(res);
			setLoading(false);
		} catch (err) {
			console.error(err.message);
			setLoading(false);
		} finally {
			setLoading(false);
		}
	};

	const setMemberValues = (memberDetails) => {
		setValue("FormValues", {
			FirstName: memberDetails.FirstName,
			LastName: memberDetails.LastName,
			Email: memberDetails.Email,
			PhoneNo: memberDetails.PhoneNo,
			ImageLink: memberDetails.ImageLink,
			roles: memberDetails.ClubRoles.map((role) => role.RoleId),
			clubs: memberDetails.ClubRoles.map((club) => club.ClubId),
		});
	};

	const addRoleClubPair = () => {
		setClubRoles([...clubRoles, { club: "", role: "" }]);
		setValue("FormValues.roles", [...getValues("FormValues.roles"), ""], {
			shouldValidate: true,
		});
		setValue("FormValues.clubs", [...getValues("FormValues.clubs"), ""], {
			shouldValidate: true,
		});
	};

	const removeRoleClubPair = (indexToRemove) => {
		const updatedRoles = clubRoles.filter((role, roleIndex) => roleIndex !== indexToRemove);
		setClubRoles(updatedRoles);
		setValue(
			"FormValues.roles",
			getValues("FormValues.roles").filter((_, index) => index !== indexToRemove),
			{ shouldValidate: true }
		);
		setValue(
			"FormValues.clubs",
			getValues("FormValues.clubs").filter((_, index) => index !== indexToRemove),
			{ shouldValidate: true }
		);
	};

	let [file, setFile] = useState(null);

	// setFile that needs to be uploaded
	const handleFileChange = (event) => {
		console.log(event.target.files);
		setFile(event.target.files[0]);
		console.log("File selected");
	};

	const onSubmit = (data) => {
		console.log(data);
		const clubRoles = data.FormValues.roles.map((role, index) => ({
			RoleId: role,
			ClubId: data.FormValues.clubs[index],
		}));
		let membersObj = {
			FirstName: data.FormValues.FirstName,
			LastName: data.FormValues.LastName,
			Email: data.FormValues.Email,
			PhoneNo: data.FormValues.PhoneNo,
			ClubRoles: clubRoles,
			ImageLink:
				data.FormValues.FirstName &&
				data.FormValues.ImageLink &&
				data.FormValues.ImageLink[0]
					? data.FormValues.PhoneNo +
					  "_" +
					  Date.now() +
					  "." +
					  data.FormValues.ImageLink[0].type.split("/").pop()
					: "",
		};
		console.log(membersObj);
		updateMember(membersObj);
	};

	const updateMember = async (reqObj) => {
		try {
			setLoading(true);
			if (
				reqObj.ImageLink !== "" &&
				reqObj.ImageLink !== null &&
				reqObj.ImageLink !== undefined
			) {
				// Upload the file to Azure Blob
				const folderName = file && "Members";
				let { handleUpload } = uploadImageFile(file, toast, folderName, reqObj.ImageLink);
				await handleUpload();
			}
			const members = await ApiService.httpPut(
				"/Members?UserId=" + location.state.userId,
				reqObj
			);
			console.log("Members Status", members);
			if (members.status === true) {
				console.log("Successfully Member Updated.");
				toast({
					title: "Update Member",
					description: "Member Updated Successfully!",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				navigateToMembers();
				setLoading(false);
			} else if (members.status === false) {
				toast({
					title: "Update Member",
					description: members.Message,
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				setLoading(false);
			} else {
				toast({
					title: "Update Member",
					description: "Failed to Update Member",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				setLoading(false);
			}
		} catch (err) {
			console.error(err.message);
			toast({
				title: "Update Member",
				description: "Failed to Update Member",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
			setLoading(false);
		} finally {
			setLoading(false);
		}
	};

	const navigateToMembers = () => {
		return navigate(-1);
	};

	useEffect(() => {
		fetchMemberDetails();
	}, []);

	return (
		<Container
			flex="1"
			overflowY="auto"
			maxWidth="100vw"
			marginTop={isDesktop ? "3.5rem" : "0rem"}
			marginX={0}
			paddingBottom={10}
			css={{
				"::-webkit-scrollbar": {
					width: "0.3rem",
					height: "0.3rem",
				},
				"::-webkit-scrollbar-track": {
					background: "#bee3f8",
					borderRadius: "1rem",
				},
				"::-webkit-scrollbar-thumb": {
					background: "#3182ce",
					borderRadius: "1rem",
				},
			}}>
			<Stack
				mt={10}
				spacing={{
					base: "8",
					lg: "6",
				}}>
				<Stack
					spacing={7}
					direction={{ base: "column", sm: "row" }}
					alignItems={{ sm: "center" }}
					justify="space-between">
					<Stack
						spacing="4"
						direction={{
							base: "column",
							lg: "row",
						}}
						justify="space-between"
						align={{
							base: "start",
							lg: "center",
						}}>
						<HStack spacing="5">
							<IconButton
								borderRadius="full"
								p={2}
								size="md"
								bgColor="white"
								as={IoChevronBack}
								onClick={navigateToMembers}
							/>
							<Heading size={pageHeader} fontWeight="bold">
								Update Member
							</Heading>
						</HStack>
					</Stack>
				</Stack>

				{loading ? (
					<Box
						position={"fixed"}
						bottom={"50%"}
						left={"50%"}
						m={0}
						p={0}
						zIndex={"overlay"}>
						<BallTriangle
							height={100}
							width={100}
							radius={5}
							color="#4fa94d"
							ariaLabel="ball-triangle-loading"
							wrapperClass={{}}
							wrapperStyle=""
							visible={true}
						/>
					</Box>
				) : (
					<form onSubmit={handleSubmit(onSubmit)}>
						<Stack spacing="1rem">
							<Stack gap="1rem">
								<UpdateMemberForm
									register={register}
									watch={watch}
									errors={errors}
									control={control}
									clubs={clubs}
									roles={roles}
									setClubRoles={setClubRoles}
									clubRoles={clubRoles}
									addRoleClubPair={addRoleClubPair}
									removeRoleClubPair={removeRoleClubPair}
									clearErrors={clearErrors}
									handleFileChange={handleFileChange}
									setValue={setValue}
								/>
							</Stack>
							<Flex justify="end">
								<ButtonGroup spacing="5">
									<Button
										size="lg"
										borderRadius="full"
										colorScheme="red"
										onClick={navigateToMembers}>
										Cancel
									</Button>
									<Tooltip
										label={!isDirty ? "Nothing to update" : undefined}
										hasArrow>
										<Button
											type="submit"
											size="lg"
											borderRadius="full"
											colorScheme="blue">
											Submit
										</Button>
									</Tooltip>
								</ButtonGroup>
							</Flex>
						</Stack>
					</form>
				)}
			</Stack>
		</Container>
	);
};
import {
	Box,
	Flex,
	HStack,
	Icon,
	Center,
	Image,
	SimpleGrid,
	Stack,
	Text,
	useBreakpointValue,
	useColorModeValue,
} from "@chakra-ui/react";
import { FiMoreHorizontal } from "react-icons/fi";
import { useNavigate } from "react-router";
import { EventsGroup, EventsPerson, EventsTimer } from "../../../icons/Icons";

import eventImg from "../../../images/event-img1.png";

export const ManageEventCard = (props) => {
	//Box Shadow
	const shadow = useColorModeValue("sm", "sm-dark");
	//Card Heading
	const cardHeading = useBreakpointValue({ base: "lg", sm: "xl", md: "2xl" });

	let navigate = useNavigate();
	let tournamentData = props.tournamentData;

	let NavigateToEventsHome = (eventsData) => {
		console.log("Event", props);
		console.log("Events Data", eventsData);

		navigate("/events/manage-events", {
			state: {
				EventId: eventsData.EventId,
				TournamentName: tournamentData.TournamentName,
				TourId: tournamentData.TournamentId,
				EventType: eventsData.EventType,
			},
		});
	};
	return (
		<Box>
			<Text mb={2} fontSize={cardHeading} fontWeight="medium">
				{tournamentData.TournamentName}
			</Text>
			<Box bgColor="white" p={5} borderRadius="lg">
				<SimpleGrid columns={{ base: 1, sm: 2, md: 3, xl: 4 }} spacing={5}>
					{tournamentData.Events.map((event, index) => {
						return (
							<Box
								key={event.EventId}
								boxShadow={shadow}
								bgColor="white"
								borderRadius="lg"
								pb="4"
								_hover={{ border: "1px solid", borderColor: "blue.500" }}
								align="center">
								<Stack
									cursor={"pointer"}
									onClick={() => NavigateToEventsHome(event)}>
									<Flex justify="end" px="3" pt="1">
										<Icon
											as={FiMoreHorizontal}
											boxSize="5"
											color="blackAlpha.500"
										/>
									</Flex>
									<Center>
										<Image
											src={eventImg}
											alt="Carrom Clubs"
											bgColor="purple.200"
											borderRadius="5"
											p="1.5"
											boxSize="76"
										/>
									</Center>
									<Box>
										<Text fontWeight="bold" fontSize={["sm", "md"]}>
											{event.EventName}
										</Text>
										<Text
											fontWeight="bold"
											textColor={"GrayText"}
											fontSize={"xs"}>
											{event.EventType}
										</Text>
									</Box>
									<HStack justify="center" spacing={{ base: 2, sm: 3, md: 4 }}>
										<HStack spacing={0}>
											<Icon
												as={EventsPerson}
												boxSize={4}
												color="blackAlpha.500"
											/>
											<Text
												fontWeight="semibold"
												// fontSize={useBreakpointValue({ base: "md", sm: "lg" })}
												color="blackAlpha.500">
												{event.GroupCount}
											</Text>
										</HStack>
										<HStack spacing={0}>
											<Icon
												as={EventsGroup}
												boxSize={4}
												color="blackAlpha.500"
											/>
											<Text
												fontWeight="semibold"
												// fontSize={useBreakpointValue({ base: "md", sm: "lg" })}
												color="blackAlpha.500">
												{event.RegisteredPlayersCount}
											</Text>
										</HStack>
										<HStack spacing={0}>
											<Icon
												as={EventsTimer}
												boxSize={4}
												color="blackAlpha.500"
											/>
											<Text
												fontWeight="semibold"
												// fontSize={useBreakpointValue({ base: "md", sm: "lg" })}
												color="blackAlpha.500">
												{event.NoOfRounds}
											</Text>
										</HStack>
									</HStack>
								</Stack>
							</Box>
						);
					})}
				</SimpleGrid>
			</Box>
		</Box>
	);
};

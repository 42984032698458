import {
	HStack,
	Table,
	Tbody,
	Td,
	Box,
	Tr,
	Th,
	Thead,
	useBreakpointValue,
	Divider,
	Input,
	IconButton,
	Tooltip,
	FormControl,
	Select,
	ButtonGroup,
	Button,
	Stack,
	Modal,
	useDisclosure,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Text,
	useToast,
	useColorModeValue,
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogCloseButton,
	AlertDialogBody,
	AlertDialogFooter,
	Center,
	FormLabel,
	Checkbox,
	NumberInput,
	NumberInputField,
	Circle,
	Avatar,
	Skeleton,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { BallTriangle } from "react-loader-spinner";
import ApiService from "../../../service/Api.service";
import { TbActions } from "../../../icons/Icons";
import { FiTrash } from "react-icons/fi";
import { FaRegUser } from "react-icons/fa";
import usePagination from "../../../hooks/usePagination";
import { Pagination } from "../../Pagination/Pagination";
import uploadImageFile from "../../Upload/uploadImageFile";
import { teamImgDir } from "../../../Utilities/ImageLinks";
import _ from "lodash";
import { useAuthProvider } from "../../../context/AuthProvider";

export const TeamsTable = ({
	teams,
	teamsGroups,
	teamsPlayers,
	teamSize,
	eventId,
	fetchTeamsData,
	fetchTeamsList,
	isTeamEditable,
	setTeamsList,
	teamsList,
	isRankRequired,
	teamsLoading,
	teamsModalLoad,
}) => {
	// User Details
	const user = useAuthProvider();

	// Box Shadow
	const shadow = useColorModeValue("sm", "sm-dark");
	const isDesktop = useBreakpointValue({ base: false, xl: true });
	const isMobile = useBreakpointValue({
		base: true,
		md: false,
	});

	const { isOpen, onOpen, onClose } = useDisclosure();
	const {
		isOpen: isAddTeamOpen,
		onOpen: onAddTeamOpen,
		onClose: onAddTeamClose,
	} = useDisclosure();
	const {
		isOpen: isEditTeamOpen,
		onOpen: onEditTeamOpen,
		onClose: onEditTeamClose,
	} = useDisclosure();
	const { isOpen: isPlayerOpen, onOpen: onPlayerOpen, onClose: onPlayerClose } = useDisclosure();
	const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();
	const {
		isOpen: isDeleteAlertOpen,
		onOpen: onDeleteAlertOpen,
		onClose: onDeleteAlertClose,
	} = useDisclosure();
	const cancelRef = useRef();
	const toast = useToast();

	const [newTeamName, setNewTeamName] = useState("");
	const [newTeamGroup, setNewTeamGroup] = useState(
		teamsGroups.length === 1 ? teamsGroups[0].GroupId : ""
	);
	const [newTeamColor, setNewTeamColor] = useState("");
	const [teamDetails, setTeamDetails] = useState({});
	const [newTeamRank, setNewTeamRank] = useState(0);
	let [editToggleId, setEditToggleId] = useState(null);
	let [disableAddBtn, setDisableAddBtn] = useState(false);
	let [reload, setReload] = useState(false);
	let [koLoading, setKOLoading] = useState(false);
	const [saveLoading, setSaveLoading] = useState(false);
	const [generateLoading, setGenerateLoading] = useState(false);
	const [savePlayersLoading, setSavePlayersLoading] = useState(false);
	const [delLoading, setDelLoading] = useState(false);
	const [delPlayerLoading, setDelPlayerLoading] = useState(null);

	const [koDetails, setKODetails] = useState([]);
	const [checkbox, setCheckbox] = useState(false);

	let [initPlayers, setInitPlayers] = useState([]);
	let [initPlayersLen, setInitPlayersLen] = useState(0);
	let [activeElementIndex, setActiveElementIndex] = useState(1);
	let [selectedPlayers, setSelectedPlayers] = useState(null);

	let [currTeamId, setCurrTeamId] = useState(null);
	let [currTeam, setCurrTeam] = useState({});
	let [teamNameLen, setTeamNameLen] = useState(0);

	// State for File that needs to be uploaded
	let [file, setFile] = useState(null);

	// setFile that needs to be uploaded
	const handleFileChange = (event) => {
		console.log(event.target.files);
		setFile(event.target.files[0]);
		console.log("File selected");
	};

	const selectPlayer = (teamId, teamName, index, playerId, isCaptain, playerName) => {
		let finalObj = {
			TETeamId: teamId,
			TourEventId: eventId,
			TeamName: teamName,
		};
		let obj = {
			index: index,
			TourPlayerId: playerId,
			PlayerName: playerName,
			isCaptain: isCaptain,
		};
		if (
			selectedPlayers?.Players?.length > 0 &&
			selectedPlayers?.Players?.some((ele) => (ele.index === obj.index ? true : false)) > 0
		) {
			// To replace an existing player
			let newArray = selectedPlayers?.Players?.map((player, idx) => {
				if (player.index === obj.index) {
					return {
						...player,
						TourPlayerId: playerId,
						PlayerName: playerName,
						Captain: isCaptain,
					};
				}
				return player;
			});
			// To remove the blank option element from the array of objects
			newArray = newArray.filter((player) => player.PlayerName !== "");
			finalObj = {
				...finalObj,
				Players: newArray,
			};
			setSelectedPlayers(finalObj);
			// To enable fields one after the other and disable rest of the fields
			initPlayersLen > 0
				? setActiveElementIndex(newArray?.length - initPlayersLen + 1)
				: setActiveElementIndex(newArray?.length + 1);
		} else {
			// To add new players
			if (selectedPlayers?.Players?.length > 0) {
				finalObj = {
					...finalObj,
					Players: [...selectedPlayers?.Players, obj],
				};
				setSelectedPlayers(finalObj);
				// To enable fields one after the other and disable rest of the fields
				initPlayersLen > 0
					? setActiveElementIndex(selectedPlayers?.Players?.length - initPlayersLen + 2)
					: setActiveElementIndex(selectedPlayers?.Players?.length + 2);
			} else {
				finalObj = {
					...finalObj,
					Players: [obj],
				};
				setSelectedPlayers(finalObj);
				// To enable fields one after the other and disable rest of the fields
				setActiveElementIndex(2);
			}
		}
	};
	const selectCaptain = (teamId, teamName, isCaptain) => {
		let finalObj = {
			TETeamId: teamId,
			TourEventId: eventId,
			TeamName: teamName,
		};
		if (selectedPlayers?.Players?.length > 0) {
			let newArray = selectedPlayers?.Players?.map((player, idx) => {
				if (player.TeamsPlayerId === isCaptain) {
					return {
						...player,
						isCaptain: "Yes",
					};
				} else if (player.index === isCaptain) {
					return {
						...player,
						isCaptain: "Yes",
					};
				} else {
					return {
						...player,
						isCaptain: "No",
					};
				}
			});
			newArray = newArray.filter((player) => player.PlayerName !== "");
			finalObj = {
				...finalObj,
				Players: newArray,
			};
			setSelectedPlayers(finalObj);
		} else {
			toast({
				description: "Please select a player first",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
		}
	};
	// For Delete Player Delete
	const handlePlayerDelete = async (teamId, tourPlayerId, isCaptain) => {
		if (isCaptain === "No") {
			try {
				setDelPlayerLoading(tourPlayerId);
				let deletePlayer = await ApiService.httpDelete(
					`/TeamEventTeams?Class=TeamsPlayers&TETeamId=${teamId}&TourPlayerId=${tourPlayerId}`
				);
				if (deletePlayer.status === true) {
					toast({
						title: "Delete Player",
						description: "Player Deleted Successfully",
						status: "success",
						duration: 3000,
						isClosable: true,
						position: "top-right",
					});
					fetchTeamsData();
				} else {
					toast({
						title: "Delete Player",
						description: "Failed to Delete Player",
						status: "error",
						duration: 3000,
						isClosable: true,
						position: "top-right",
					});
				}
				setDelPlayerLoading(null);
			} catch (err) {
				console.log(err);
				toast({
					title: "Delete Player",
					description: "Failed to Delete Player",
					status: "error",
					duration: 5000,
					isClosable: true,
					position: "top-right",
				});
				setDelPlayerLoading(null);
			}
		} else {
			toast({
				title: "Delete Player",
				description: "Please select a different Captain before deleting this player",
				status: "warning",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
		}
	};
	// For adding a New Team
	const addTeam = async () => {
		onAddTeamOpen();
		// setEditToggleId(null);
		// let newTeam = {
		// 	TourEventId: eventId,
		// 	TeamName: "",
		// };
		// setTeamsList([...teamsList, newTeam]);
		// console.log("New Team Added...");
		setDisableAddBtn(true);
	};

	// To remove the added empty field
	const removeTeam = () => {
		setTeamsList(teamsList.slice(0, -1));
		setDisableAddBtn(false);
	};

	// For saving the new team
	const saveTeam = async () => {
		const teamLogoName = file
			? newTeamName.split(" ").join("") + "_" + Date.now() + "." + file.type.split("/").pop()
			: "";
		let reqObj =
			isRankRequired === "Yes"
				? {
						TourEventId: eventId,
						TeamName: newTeamName,
						GroupName: newTeamGroup,
						TeamColor: newTeamColor,
						TeamLogoLink: teamLogoName,
						Rank: newTeamRank,
				  }
				: {
						TourEventId: eventId,
						TeamName: newTeamName,
						GroupName: newTeamGroup,
						TeamColor: newTeamColor,
						TeamLogoLink: teamLogoName,
				  };

		if (newTeamName?.length > 3) {
			if (newTeamGroup !== "") {
				try {
					setSaveLoading(true);
					let saveTeam = await ApiService.httpPost("/TeamEventTeams?Class=Teams", reqObj);
					if (saveTeam.status === true) {
						toast({
							title: "Add Team",
							description: "Team Added Successfully!",
							status: "success",
							duration: 3000,
							isClosable: true,
							position: "top-right",
						});
					} else {
						toast({
							title: "Add Team",
							description: "Failed to Add Team",
							status: "error",
							duration: 3000,
							isClosable: true,
							position: "top-right",
						});
					}
					onAddTeamClose();
					fetchTeamsData();
					setDisableAddBtn(false);
					setSaveLoading(false);
				} catch (err) {
					console.error(err);
					toast({
						title: "Add Team",
						description: "Failed to Add Team",
						status: "error",
						duration: 3000,
						isClosable: true,
						position: "top-right",
					});
					setSaveLoading(false);
				}
			} else {
				toast({
					title: "Add Team",
					description: "Please Select a Group",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
		} else {
			toast({
				title: "Add Team",
				description: "Invalid Team Name",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
		}
		if (file !== "" || file !== null || file !== undefined) {
			// Upload the file to Azure Blob
			const folderName = file && "Teams";
			let { handleUpload } = uploadImageFile(file, toast, folderName, teamLogoName);
			await handleUpload();
		}
	};
	// For editing the existing team
	const saveUpdatedTeam = async () => {
		let reqObj = { ...teamDetails };
		let teamLogoName = reqObj.TeamLogoLink;
		delete reqObj.TourEventId;
		console.log("sent", reqObj);

		if (reqObj.TeamName?.length > 3) {
			if (reqObj.GroupName !== "") {
				try {
					setSaveLoading(true);
					let saveTeam = await ApiService.httpPut("/TeamEventTeams?Class=Teams", reqObj);
					toast({
						title: "Update Team",
						description: "Team Updated Successfully!",
						status: "success",
						duration: 3000,
						isClosable: true,
						position: "top-right",
					});
					onEditTeamClose();
					fetchTeamsData();
					setDisableAddBtn(false);
					setSaveLoading(false);
				} catch (err) {
					console.error(err);
					toast({
						title: "Update Team",
						description: "Failed to Update Team",
						status: "error",
						duration: 3000,
						isClosable: true,
						position: "top-right",
					});
					setSaveLoading(false);
				}
			} else {
				toast({
					title: "Update Team",
					description: "Please Select a Group",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
		} else {
			toast({
				title: "Update Team",
				description: "Invalid Team Name",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
		}
		if (file !== "" || file !== null || file !== undefined) {
			// Upload the file to Azure Blob
			const folderName = file && "Teams";
			let { handleUpload } = uploadImageFile(file, toast, folderName, teamLogoName);
			await handleUpload();
		}
	};

	// For editing the existing team
	// const editTeam = async (team) => {
	// 	let reqObj = {
	// 		TETeamId: team.TETeamId,
	// 		TeamName: team.TeamName,
	// 	};
	// 	try {
	// 		if (team.TeamName?.length > 3) {
	// 			let saveTeam = await ApiService.httpPut(
	// 				"/TeamEventTeams?Class=Teams&TourEventId=" + team.TourEventId,
	// 				reqObj
	// 			);
	// 			toast({
	// 				title: "Update Team",
	// 				description: "Team Updated Successfully!",
	// 				status: "success",
	// 				duration: 3000,
	// 				isClosable: true,
	// 				position: "top-right",
	// 			});
	// 			fetchTeamsData();
	// 			setDisableAddBtn(false);
	// 		} else {
	// 			toast({
	// 				title: "Update Team",
	// 				description: "Invalid Team Name",
	// 				status: "error",
	// 				duration: 3000,
	// 				isClosable: true,
	// 				position: "top-right",
	// 			});
	// 		}
	// 	} catch (err) {
	// 		console.error(err);
	// 		toast({
	// 			title: "Update Team",
	// 			description: "Failed to Update Team",
	// 			status: "error",
	// 			duration: 3000,
	// 			isClosable: true,
	// 			position: "top-right",
	// 		});
	// 	}
	// };

	// To delete the existing teams
	const handleDelete = async () => {
		try {
			setDelLoading(true);
			let res = await ApiService.httpDelete(
				"/TeamEventTeams?Class=Teams&TETeamId=" + currTeam.TETeamId
			);
			if (res.status === true) {
				onDeleteAlertClose();
				toast({
					title: "Delete Team",
					description: "Team Deleted Successfully!",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				fetchTeamsList();
			} else {
				onDeleteAlertClose();
				toast({
					title: "Delete Team",
					description: "Failed to Delete Team",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
			setDisableAddBtn(false);
			setDelLoading(false);
		} catch (err) {
			console.error(err);
			onDeleteAlertClose();
			toast({
				title: "Delete Team",
				description: "Failed to Delete Team",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
			setDelLoading(false);
		}
	};

	// For fetching teams list
	const fetchKOTeams = async () => {
		try {
			setKOLoading(true);
			let players = await ApiService.httpGet(
				"/TeamsRoundPlayers?Class=KO_Outline&TourEventId=" + eventId,
				user.userId
			);
			if (players.status === true) {
				onOpen();
				setKODetails(players);
			} else {
				toast({
					title: "Generate Knockout",
					description: players.Message,
					status: "warning",
					duration: 5000,
					isClosable: true,
					position: "top-right",
				});
			}
			setKOLoading(false);
		} catch (err) {
			console.error(err);
			toast({
				title: "Generate Knockout",
				description: "Failed to Fetch Knockout Details",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
			setKOLoading(false);
		}
	};

	const generateKO = async () => {
		try {
			setGenerateLoading(true);
			let response = await ApiService.httpPost("/TeamsRoundPlayers?Class=GenerateKO", {
				TourEventId: eventId,
			});
			if (response.status === true) {
				toast({
					title: "Generate Knockout",
					description: response.Message,
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				onClose();
			} else if (response.status === false) {
				toast({
					title: "Generate Knockout",
					description: "Failed to Generate Knockout",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			} else {
				toast({
					title: "Generate Knockout",
					description: "Failed to Generate Knockout",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
			setGenerateLoading(false);
		} catch (err) {
			console.error(err);
			toast({
				title: "Generate Knockout",
				description: "Failed to Generate Knockout",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
			setGenerateLoading(false);
		}
	};

	const handleSelectPlayer = (teamId, players) => {
		setCurrTeamId(teamId);
		setInitPlayers(players);
		setSelectedPlayers({ Players: players });
		setInitPlayersLen(players?.length);
		onPlayerOpen();
	};

	const fetchTeamDetails = (teamId) => {
		setTeamDetails(teamsList.find((team) => team.TETeamId === teamId));
		onEditTeamOpen();
	};

	const savePlayers = async () => {
		let plyrCount = -1;
		let playerErr = 0;
		let captainCount = 0;
		const reqObj = { ...selectedPlayers };
		reqObj?.Players?.sort((a, b) => a?.index - b?.index)?.forEach((ele) => {
			if ("isCaptain" in ele) {
				if (ele.isCaptain === "Yes") {
					captainCount = captainCount + 1;
				}
			}
			if ("index" in ele) {
				plyrCount = plyrCount + 1;
				if (ele.index !== plyrCount) {
					playerErr = playerErr + 1;
				}
			}
		});
		if (captainCount === 0) {
			toast({
				title: "Save Team Players",
				description: "Please select a Captain",
				status: "warning",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
		} else if (captainCount > 1) {
			toast({
				title: "Save Team Players",
				description: "Please select only one Captain",
				status: "warning",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
		} else if (playerErr > 0) {
			toast({
				title: "Save Team Players",
				description: "Players must be selected sequentially one after the other",
				status: "warning",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
			playerErr = 0;
		} else {
			reqObj?.Players?.forEach((ele) => "index" in ele && delete ele.index);
			try {
				setSavePlayersLoading(true);
				let savedPlayersRes = await ApiService.httpPut(
					`/TeamEventTeams?Class=PlayersInfo`,
					reqObj
				);
				if (savedPlayersRes.status === true) {
					toast({
						title: "Save Team Players",
						description: "Saved Selected Team Players successfully",
						status: "success",
						duration: 3000,
						isClosable: true,
						position: "top-right",
					});
					discardHandler();
					fetchTeamsData();
				} else if (savedPlayersRes.status === false) {
					toast({
						title: "Save Player Selection",
						description: "Failed to Save Selected Players",
						status: "error",
						duration: 3000,
						isClosable: true,
						position: "top-right",
					});
					discardHandler();
				} else {
					toast({
						title: "Save Player Selection",
						description: "Failed to Save Selected Players",
						status: "error",
						duration: 3000,
						isClosable: true,
						position: "top-right",
					});
					discardHandler();
				}
				setSavePlayersLoading(false);
			} catch (err) {
				console.error(err);
				toast({
					title: "Save Player Selection",
					description: "Failed to Save Selected Players",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				discardHandler();
				setSavePlayersLoading(false);
			}
			playerErr = 0;
		}
	};

	const discardHandler = () => {
		setInitPlayers([]);
		setInitPlayersLen(0);
		setSelectedPlayers(null);
		onAlertClose();
		setCurrTeamId(null);
		setActiveElementIndex(1);
		onPlayerClose();
	};

	// Pagination
	let [rows, setRows] = useState(10);
	const perPage = rows;

	const {
		currentPage,
		currentData,
		jump,
		prev,
		next,
		first,
		last,
		setLast,
		setCurrentPage,
		maxPage,
	} = usePagination(teamsList, perPage);

	const handleChange = (e, p) => {
		jump(p);
	};

	const handleRows = (event) => {
		setRows(Number(event.target.value));
		setLast(Number(event.target.value));
		setCurrentPage(1);
		// 	setParamTwo(Number(event.target.value));
	};

	// const previousPage = (event) => {
	// 	setParamTwo(Number(paramOne));
	// 	setParamOne(Number(paramTwo - rows));
	// };
	// const nextPage = (event) => {
	// 	setParamOne(Number(paramTwo));
	// 	setParamTwo(Number(paramTwo + rows));
	// };

	return (
		<>
			<Box>
				<Box
					borderRadius={"2xl"}
					overflow="auto"
					boxShadow={shadow}
					css={{
						"::-webkit-scrollbar": {
							width: "0.3rem",
							height: "0.3rem",
						},
						"::-webkit-scrollbar-track": {
							background: "#bee3f8",
							borderRadius: "1rem",
						},
						"::-webkit-scrollbar-thumb": {
							background: "#3182ce",
							borderRadius: "1rem",
						},
					}}>
					<Table variant="striped">
						<Thead position="sticky" top={0} bgColor="black" zIndex="2">
							<Tr>
								<Th
									textColor="white"
									fontSize="md"
									textAlign="center"
									py={5}
									px={2}
									borderTopLeftRadius="2xl"
									whiteSpace={"normal"}>
									Team Logo
								</Th>
								<Th textColor="white" fontSize="md" textAlign="start" py={5} px={2}>
									Team Name
								</Th>
								<Th
									textColor="white"
									fontSize="md"
									textAlign="center"
									py={5}
									px={2}
									whiteSpace={"normal"}>
									Team Color
								</Th>
								<Th textColor="white" fontSize="md" textAlign="start" py={5} px={2}>
									Group Name
								</Th>
								{isRankRequired === "Yes" && (
									<Th
										textColor="white"
										fontSize="md"
										textAlign="center"
										py={5}
										px={2}>
										Rank
									</Th>
								)}
								<Th
									textColor="white"
									fontSize="md"
									textAlign="center"
									borderTopRightRadius="2xl"
									px={2}>
									Action
								</Th>
							</Tr>
						</Thead>
						<Tbody>
							{teamsLoading
								? Array(5)
										.fill()
										.map((rows, index) => (
											<Tr key={index}>
												<Td>
													<Skeleton
														isLoaded={!teamsLoading}
														h={"1.2rem"}
														w={"100%"}
													/>
												</Td>
												<Td>
													<Skeleton
														isLoaded={!teamsLoading}
														h={"1.2rem"}
														w={"100%"}
													/>
												</Td>
												<Td>
													<Skeleton
														isLoaded={!teamsLoading}
														h={"1.2rem"}
														w={"100%"}
													/>
												</Td>
												<Td>
													<Skeleton
														isLoaded={!teamsLoading}
														h={"1.2rem"}
														w={"100%"}
													/>
												</Td>
												{isRankRequired === "Yes" && (
													<Td>
														<Skeleton
															isLoaded={!teamsLoading}
															h={"1.2rem"}
															w={"100%"}
														/>
													</Td>
												)}
												<Td>
													<Skeleton
														isLoaded={!teamsLoading}
														h={"1.2rem"}
														w={"100%"}
													/>
												</Td>
											</Tr>
										))
								: currentData()?.map((team, index) => (
										<Tr key={team.TETeamId}>
											<Td
												textAlign="-webkit-center"
												fontSize="lg"
												fontWeight="medium"
												px={2}>
												<Avatar
													src={`${teamImgDir}${team.TeamLogoLink}`}
													alt="Team Logo"
													name={team.TeamName}
												/>
											</Td>
											<Td
												textAlign="start"
												fontSize="lg"
												fontWeight="medium"
												px={2}
												whiteSpace={"normal"}>
												{/* {teamsList && editToggleId === (team.TETeamId || null) && (
											<FormControl textAlign="start">
												<Input
													placeholder="Team Name"
													defaultValue={team.TeamName}
													onChange={(e) => {
														team.TeamName = e.target.value;
														setTeamNameLen(e.target.value?.length);
													}}
													disabled={
														editToggleId === (team.TETeamId || null)
															? false
															: true
													}
													required
												/>
											</FormControl>
										)} */}
												{/* {teamsList && team.TETeamId !== editToggleId && ( */}
												<Text>{team.TeamName}</Text>
												{/* )} */}
											</Td>
											<Td
												textAlign="-webkit-center"
												fontSize="lg"
												fontWeight="medium"
												px={2}>
												<Circle
													size={{
														base: "1.6rem",
														md: "2rem",
													}}
													bgColor={
														team.TeamColor !== ""
															? team.TeamColor
															: "red"
													}
												/>
											</Td>
											<Td
												textAlign="start"
												fontSize="lg"
												fontWeight="medium"
												px={2}>
												<Text>
													{team.GroupName && "Group - " + team.GroupName}
												</Text>
											</Td>
											{isRankRequired == "Yes" && (
												<Td
													textAlign="-webkit-center"
													fontSize="lg"
													fontWeight="medium"
													px={2}>
													{team.Rank}
												</Td>
											)}
											<Td textAlign="center" px={2}>
												<HStack justify="center" spacing={1}>
													{/* {teamsList &&
												editToggleId !== (team.TETeamId || null) && ( */}
													<IconButton
														icon={<TbActions />}
														mb={1}
														onClick={() => {
															// setEditToggleId(team.TETeamId);
															setCurrTeamId(team.TETeamId);
															fetchTeamDetails(team.TETeamId);
															setDisableAddBtn(true);
														}}
														size="sm"
													/>
													{/* )} */}
													{/* {teamsList &&
												editToggleId === (team.TETeamId || null) && (
													<>
														<IconButton
															borderRadius="full"
															bgColor="#43CC18"
															_hover={{ bgColor: "green" }}
															icon={<TbAdd />}
															disabled={teamNameLen > 1 ? false : true}
															{...(editToggleId !== null && {
																onClick: () => {
																	"TETeamId" in team
																		? saveUpdatedTeam(team)
																		: saveTeam(team);
																	setEditToggleId("");
																	setReload(true);
																},
															})}
															{...(editToggleId === null && {
																onClick: () => {
																	"TETeamId" in team
																		? saveUpdatedTeam(team)
																		: saveTeam(team);
																	setEditToggleId("");
																	setReload(true);
																},
															})}
															size="sm"
														/>
														<IconButton
															borderRadius="full"
															bgColor="#FF4667"
															_hover={{ bgColor: "red" }}
															_active={{ bgColor: "red" }}
															{...(editToggleId !== null && {
																onClick: () => {
																	setEditToggleId("");
																	setDisableAddBtn(false);
																},
															})}
															{...(editToggleId === null && {
																onClick: () => {
																	removeTeam();
																},
															})}
															icon={<TbDelete />}
															size="sm"
														/>
													</>
												)} */}
													{teamsList &&
														// editToggleId !== (team.TETeamId || null) &&
														isTeamEditable === 1 && (
															<IconButton
																icon={<FiTrash />}
																mb={1}
																color="red"
																bgColor="red.100"
																_hover={{
																	bgColor: "red.200",
																}}
																_active={{
																	bgColor: "red.300",
																}}
																size="sm"
																onClick={() => {
																	setCurrTeam({
																		TETeamId: team.TETeamId,
																		TeamName: team.TeamName,
																	});
																	onDeleteAlertOpen();
																}}
															/>
														)}
													{teamsList && (
														// editToggleId !== (team.TETeamId || null) &&
														<>
															<Tooltip
																closeOnClick={true}
																hasArrow
																label="Teams Allocation"
																aria-label="Teams Allocation Button">
																<IconButton
																	icon={<FaRegUser />}
																	mb={1}
																	colorScheme="blue"
																	_hover={{
																		bgColor: "blue.400",
																	}}
																	_active={{
																		bgColor: "blue.700",
																	}}
																	size="sm"
																	isLoading={teamsModalLoad}
																	onClick={() => {
																		handleSelectPlayer(
																			team.TETeamId,
																			teams?.find(
																				(t) =>
																					t?.TETeamId ===
																					team.TETeamId
																			).Players
																		);
																	}}
																/>
															</Tooltip>
														</>
													)}
												</HStack>
											</Td>
										</Tr>
								  ))}
						</Tbody>
					</Table>
				</Box>
				<Divider />
				<Stack p="1rem" bgColor="white" borderBottomRadius={"2xl"}>
					{/*---------------For Pagination-------------------------------*/}
					<Stack
						direction={{ base: "column", sm: "row" }}
						justify="space-between"
						spacing="3">
						<Button
							size="md"
							borderRadius="full"
							colorScheme="blue"
							onClick={fetchKOTeams}
							isLoading={koLoading}>
							Generate Knockout
						</Button>
						<Button
							type="submit"
							size="md"
							borderRadius="full"
							colorScheme="blue"
							disabled={disableAddBtn || koLoading || isTeamEditable !== 1}
							onClick={addTeam}>
							Add Team
						</Button>
					</Stack>
					{isMobile ? (
						<Stack>
							<Center>
								<Pagination
									count={teamsList?.length}
									pageSize={rows}
									siblingCount={0}
									page={currentPage}
									onChange={(e) => setCurrentPage(e.page)}
								/>
							</Center>
							<HStack justify="space-between">
								<HStack spacing={1}>
									<Text as="span" whiteSpace="normal">
										Rows per page{" "}
									</Text>
									<Select
										boxSize={"-webkit-max-content"}
										minW="-webkit-fit-content"
										size="sm"
										value={rows}
										onChange={(event) => handleRows(event)}>
										{[10, 25, 50].map((rows) => (
											<option key={rows} value={rows}>
												{rows}
											</option>
										))}
									</Select>
								</HStack>
								<Text whiteSpace="normal">
									Showing {currentPage * rows - rows + 1} -{" "}
									{currentPage * rows < teamsList?.length
										? currentPage * rows
										: teamsList?.length}{" "}
									of {teamsList?.length} Entries
								</Text>
							</HStack>
						</Stack>
					) : (
						<HStack justify="space-between" pt={3}>
							<HStack spacing={1}>
								<Text as="span" whiteSpace={"nowrap"}>
									Rows per page{" "}
								</Text>
								<Select
									boxSize={"-webkit-max-content"}
									minW="-webkit-fit-content"
									value={rows}
									onChange={(event) => handleRows(event)}>
									{[10, 25, 50].map((rows) => (
										<option key={rows} value={rows}>
											{rows}
										</option>
									))}
								</Select>
							</HStack>
							<Center>
								<Pagination
									count={teamsList?.length}
									pageSize={rows}
									siblingCount={1}
									page={currentPage}
									onChange={(e) => setCurrentPage(e.page)}
								/>
							</Center>
							<Text whiteSpace="normal">
								Showing {currentPage * rows - rows + 1} -{" "}
								{currentPage * rows < teamsList?.length
									? currentPage * rows
									: teamsList?.length}{" "}
								of {teamsList?.length} Entries
							</Text>
						</HStack>
					)}
				</Stack>
			</Box>
			<Modal
				onClose={onClose}
				isOpen={isOpen}
				scrollBehavior="inside"
				blockScrollOnMount={false}
				isCentered
				size={{ base: "sm", sm: "xl", md: "2xl", lg: "4xl" }}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader textAlign={"center"}>Knockout Details</ModalHeader>
					<ModalCloseButton />
					<ModalBody
						css={{
							"::-webkit-scrollbar": {
								width: "0.3rem",
								height: "0.3rem",
							},
							"::-webkit-scrollbar-track": {
								background: "#bee3f8",
								borderRadius: "1rem",
							},
							"::-webkit-scrollbar-thumb": {
								background: "#3182ce",
								borderRadius: "1rem",
							},
						}}>
						<Text textAlign={"center"} pb={3}>
							{koDetails?.Message}
						</Text>
						<Box borderRadius={"2xl"} mb="1rem">
							<Table variant="striped">
								<Thead position="sticky" top={0} bgColor="black" zIndex="2">
									<Tr>
										<Th
											textColor="white"
											fontSize="md"
											textAlign="start"
											py={5}
											borderTopLeftRadius="2xl">
											Rank
										</Th>
										<Th
											textColor="white"
											fontSize="md"
											textAlign="start"
											py={5}>
											Team Name
										</Th>
										<Th
											textColor="white"
											fontSize="md"
											textAlign="start"
											py={5}>
											Group Name
										</Th>
										<Th
											textColor="white"
											fontSize="md"
											textAlign="start"
											borderTopRightRadius="2xl">
											Group Position
										</Th>
									</Tr>
								</Thead>
								<Tbody>
									{koDetails.TopTeams?.map((details, index) => {
										return (
											<Tr key={details.Rank}>
												<Td
													textAlign="start"
													fontSize="lg"
													fontWeight="medium"
													pr={2}>
													{details.Rank}
												</Td>
												<Td
													textAlign="start"
													fontSize="lg"
													fontWeight="medium"
													pr={2}>
													{details.TeamName}
												</Td>
												<Td
													textAlign="start"
													fontSize="lg"
													fontWeight="medium"
													pr={2}>
													{details.GroupName}
												</Td>
												<Td
													textAlign="start"
													fontSize="lg"
													fontWeight="medium"
													pr={2}>
													{details.GroupPosition}
												</Td>
											</Tr>
										);
									})}
								</Tbody>
							</Table>
						</Box>
						<Checkbox onChange={(e) => setCheckbox(e.target.checked)}>
							I give my consent for the Knockout to be generated
						</Checkbox>
					</ModalBody>
					<ModalFooter>
						<Button
							colorScheme={"green"}
							onClick={generateKO}
							disabled={checkbox ? false : true}
							isLoading={generateLoading}>
							Generate
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
			{/*----------------------Team Players-------------*/}
			{currTeamId !== null && (
				<Modal
					closeOnOverlayClick={false}
					closeOnEsc={false}
					onClose={() => {
						setCurrTeamId(null);
						onPlayerClose();
					}}
					isOpen={isPlayerOpen}
					scrollBehavior="inside"
					blockScrollOnMount={false}
					isCentered
					size={{ base: "sm", sm: "xl", md: "2xl", lg: "4xl" }}>
					<ModalOverlay />
					<ModalContent>
						<ModalHeader textAlign={"center"}>
							{teamSize ===
							teams?.find((team) => team?.TETeamId === currTeamId)?.Players?.length
								? "Selected "
								: "Select "}
							Players for{" "}
							{teams?.find((team) => team?.TETeamId === currTeamId)?.TeamName}
						</ModalHeader>
						<ModalBody
							css={{
								"::-webkit-scrollbar": {
									width: "0.3rem",
									height: "0.3rem",
								},
								"::-webkit-scrollbar-track": {
									background: "#bee3f8",
									borderRadius: "1rem",
								},
								"::-webkit-scrollbar-thumb": {
									background: "#3182ce",
									borderRadius: "1rem",
								},
							}}>
							<Box
								borderRadius={"2xl"}
								mb="1rem"
								boxShadow={"sm"}
								overflow="auto"
								css={{
									"::-webkit-scrollbar": {
										width: "0.3rem",
										height: "0.3rem",
									},
									"::-webkit-scrollbar-track": {
										background: "#bee3f8",
										borderRadius: "1rem",
									},
									"::-webkit-scrollbar-thumb": {
										background: "#3182ce",
										borderRadius: "1rem",
									},
								}}>
								<Table variant="striped">
									<Thead position="sticky" top={0} bgColor="black" zIndex="2">
										<Tr>
											<Th
												textColor="white"
												fontSize="md"
												textAlign="start"
												py={5}
												borderTopLeftRadius="2xl">
												Player Name
											</Th>
											<Th textColor="white" fontSize="md" textAlign="start">
												Captain
											</Th>
											{isTeamEditable === 1 && (
												<Th
													textColor="white"
													fontSize="md"
													textAlign="start"
													borderTopRightRadius="2xl">
													Action
												</Th>
											)}
										</Tr>
									</Thead>
									<Tbody>
										{initPlayers?.map((player, index) => (
											<Tr key={player.TeamsPlayerId}>
												<Td
													textAlign="start"
													fontSize="lg"
													fontWeight="medium"
													pr={2}>
													{player.PlayerName}
												</Td>
												<Td textAlign="start">
													{/* <Radio
																type={"radio"}
																name={team.TETeamId}
																value={index}
																onChange={(e) => console.log(e.target.value)}
															/> */}
													<input
														type={"radio"}
														name={
															teams?.find(
																(team) =>
																	team?.TETeamId === currTeamId
															)?.TETeamId
														}
														value={
															player.TeamsPlayerId !== null &&
															player.TeamsPlayerId
														}
														{...(isTeamEditable === 0
															? {
																	checked:
																		player.isCaptain ===
																			"Yes" && true,
															  }
															: {
																	defaultChecked:
																		player.isCaptain ===
																			"Yes" && true,
															  })}
														{...(teamsList.isTeamEditable === 1 && {
															onChange: (e) => {
																selectCaptain(
																	teams?.find(
																		(team) =>
																			team?.TETeamId ===
																			currTeamId
																	)?.TETeamId,
																	teams?.find(
																		(team) =>
																			team?.TETeamId ===
																			currTeamId
																	)?.TeamName,
																	e.target.value
																);
															},
														})}
														{...(isTeamEditable === 0 && {
															readOnly: true,
														})}
													/>
												</Td>
												{isTeamEditable === 1 && (
													<Td textAlign="start">
														<IconButton
															icon={<FiTrash />}
															color="red"
															bgColor="red.100"
															_hover={{
																bgColor: "red.200",
															}}
															_active={{
																bgColor: "red.300",
															}}
															isLoading={
																delPlayerLoading ===
																player.TourPlayerId
															}
															onClick={() => {
																handlePlayerDelete(
																	teams?.find(
																		(team) =>
																			team?.TETeamId ===
																			currTeamId
																	)?.TETeamId,
																	player.TourPlayerId,
																	player.isCaptain
																);
															}}
														/>
													</Td>
												)}
											</Tr>
										))}
										{Array(teamSize - initPlayers?.length)
											?.fill()
											?.map((temp, index) => (
												<Tr key={index}>
													<Td
														textAlign="start"
														fontSize="lg"
														fontWeight="medium"
														pr={2}>
														<FormControl textAlign="start">
															<Select
																disabled={
																	activeElementIndex <= index
																		? true
																		: false
																}
																onChange={(e) =>
																	selectPlayer(
																		teams?.find(
																			(team) =>
																				team?.TETeamId ===
																				currTeamId
																		)?.TETeamId,
																		teams?.find(
																			(team) =>
																				team?.TETeamId ===
																				currTeamId
																		)?.TeamName,
																		index,
																		JSON.parse(e.target.value)
																			.TourPlayerId,
																		"No",
																		JSON.parse(e.target.value)
																			.PlayerName
																	)
																}>
																<option
																	value={JSON.stringify({
																		TourPlayerId: "",
																		PlayerName: "",
																	})}>
																	Select Player
																</option>
																{teamsPlayers?.map(
																	(players, index) => {
																		return (
																			<option
																				hidden={selectedPlayers?.Players?.some(
																					(ele) =>
																						ele.TourPlayerId ===
																						players.TourPlayerId
																				)}
																				key={
																					players.TourPlayerId
																				}
																				value={JSON.stringify(
																					players
																				)}>
																				{players.PlayerName}
																			</option>
																		);
																	}
																)}
															</Select>
														</FormControl>
													</Td>
													<Td textAlign="start">
														{/* <Radio
																type={"radio"}
																name={team.TETeamId}
																value={index}
															/> */}
														<input
															type={"radio"}
															name={
																teams?.find(
																	(team) =>
																		team?.TETeamId ===
																		currTeamId
																)?.TETeamId
															}
															value={index}
															disabled={
																// (teams.find(
																// 	(team) => team.TETeamId === currTeamId
																// ).Captain !== null &&
																// 	teams.find(
																// 		(team) => team.TETeamId === currTeamId
																// 	).CaptainId !== null) ||
																Array.isArray(
																	selectedPlayers?.Players
																) &&
																selectedPlayers?.Players.length > 0
																	? selectedPlayers?.Players?.every(
																			(val) =>
																				val.index !== index
																	  )
																		? true
																		: false
																	: true
															}
															onChange={(e) => {
																selectCaptain(
																	teams?.find(
																		(team) =>
																			team?.TETeamId ===
																			currTeamId
																	)?.TETeamId,
																	teams?.find(
																		(team) =>
																			team?.TETeamId ===
																			currTeamId
																	)?.TeamName,
																	Number(e.target.value)
																);
															}}
														/>
													</Td>
													{isTeamEditable === 1 && (
														<Td textAlign="start"></Td>
													)}
												</Tr>
											))}
									</Tbody>
								</Table>
							</Box>
						</ModalBody>
						<ModalFooter>
							<ButtonGroup spacing="3">
								{!savePlayersLoading && (
									<Button
										size="lg"
										borderRadius="full"
										colorScheme="red"
										onClick={
											_(selectedPlayers?.Players)
												.differenceWith(initPlayers, _.isEqual)
												.isEmpty()
												? discardHandler
												: onAlertOpen
										}>
										{_(selectedPlayers?.Players)
											.differenceWith(initPlayers, _.isEqual)
											.isEmpty()
											? "Close"
											: "Cancel"}
									</Button>
								)}
								{isTeamEditable === 1 && (
									<Button
										type="submit"
										size="lg"
										borderRadius="full"
										colorScheme="blue"
										disabled={
											_(selectedPlayers?.Players)
												.differenceWith(initPlayers, _.isEqual)
												.isEmpty()
												? true
												: false
										}
										onClick={selectedPlayers !== null ? savePlayers : undefined}
										isLoading={savePlayersLoading}>
										Save
									</Button>
								)}
							</ButtonGroup>
						</ModalFooter>
					</ModalContent>
				</Modal>
			)}
			{/*-------------------Discard Dialog box for Unsaved Changes------------ */}
			<AlertDialog
				motionPreset="slideInBottom"
				leastDestructiveRef={cancelRef}
				onClose={onAlertClose}
				isOpen={isAlertOpen}
				isCentered>
				<AlertDialogOverlay />

				<AlertDialogContent>
					<AlertDialogHeader>Discard Changes?</AlertDialogHeader>
					<AlertDialogCloseButton />
					<AlertDialogBody>
						Are you sure you want to discard all the selected players?
					</AlertDialogBody>
					<AlertDialogFooter>
						<Button ref={cancelRef} onClick={onAlertClose}>
							No
						</Button>
						<Button colorScheme="red" ml={3} onClick={discardHandler}>
							Yes
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>

			<AlertDialog
				motionPreset="slideInBottom"
				leastDestructiveRef={cancelRef}
				onClose={() => {
					setCurrTeam({});
					onDeleteAlertClose();
				}}
				isOpen={isDeleteAlertOpen}
				isCentered>
				<AlertDialogOverlay />
				<AlertDialogContent>
					<AlertDialogHeader>Delete Team</AlertDialogHeader>
					<AlertDialogCloseButton />
					<AlertDialogBody>
						Are you sure you want to delete{" "}
						<Text as={"span"} fontWeight={"bold"}>
							{currTeam.TeamName}
						</Text>
						?
					</AlertDialogBody>
					<AlertDialogFooter>
						{!delLoading && (
							<Button
								ref={cancelRef}
								onClick={() => {
									setCurrTeam({});
									onDeleteAlertClose();
								}}>
								Cancel
							</Button>
						)}
						<Button
							colorScheme="red"
							ml={3}
							onClick={handleDelete}
							isLoading={delLoading}>
							Delete
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
			{/*----------------------------Add a New Team------------------------ */}
			<Modal
				size={{ base: "xs", sm: "md", md: "lg", lg: "xl" }}
				onClose={() => {
					onAddTeamClose();
					setNewTeamName("");
					setFile(null);
					setNewTeamGroup("");
					setNewTeamColor("");
					setDisableAddBtn(false);
				}}
				isOpen={isAddTeamOpen}
				isCentered>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader textAlign={"center"}>Add New Team</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Stack spacing={5}>
							<Stack direction={{ base: "column", md: "row" }} align="center">
								<FormControl>
									<FormLabel>Team Name</FormLabel>
									<Input
										type="text"
										onChange={(e) => setNewTeamName(e.target.value)}
									/>
								</FormControl>
								<FormControl>
									<FormLabel>Group Name</FormLabel>
									<Select
										placeholder="Select a Group"
										value={newTeamGroup}
										onChange={(e) => setNewTeamGroup(e.target.value)}>
										{teamsGroups?.map((group, index) => {
											return (
												<option key={group.GroupName} value={group.GroupId}>
													{group.GroupName}
												</option>
											);
										})}
									</Select>
								</FormControl>
							</Stack>
							<Stack
								direction={{ base: "column", md: "row" }}
								align="last"
								spacing={4}>
								{isRankRequired !== "No" && (
									<FormControl>
										<FormLabel>Rank</FormLabel>
										<NumberInput
											value={newTeamRank}
											min={1}
											inputMode="numeric"
											keepWithinRange={true}
											onChange={(value) => setNewTeamRank(Number(value))}>
											<NumberInputField textAlign={"center"} padding={0} />
										</NumberInput>
									</FormControl>
								)}
								<FormControl>
									<FormLabel>Team Color</FormLabel>
									<Input
										type="color"
										onChange={(e) => setNewTeamColor(e.target.value)}
									/>
								</FormControl>
								<FormControl>
									<FormLabel>Team Logo</FormLabel>
									<input
										type="file"
										name="playerPhoto"
										accept=".jpg, .jpeg, .jfi, .jpe, .jif, .jfif, .heif, .heic, .png, .svg, .svgz, .raw"
										onChange={(e) => {
											handleFileChange(e);
										}}
									/>
								</FormControl>
							</Stack>
						</Stack>
					</ModalBody>
					<ModalFooter>
						<ButtonGroup spacing={"3"}>
							{!saveLoading && (
								<Button
									borderRadius="full"
									colorScheme="red"
									onClick={() => {
										onAddTeamClose();
										setNewTeamName("");
										setFile(null);
										setNewTeamGroup("");
										setNewTeamColor("");
										setNewTeamRank(0);
										setDisableAddBtn(false);
									}}>
									Cancel
								</Button>
							)}
							<Button
								type="submit"
								borderRadius="full"
								colorScheme={"blue"}
								onClick={saveTeam}
								isLoading={saveLoading}
								disabled={newTeamName !== "" && newTeamGroup !== "" ? false : true}>
								Save
							</Button>
						</ButtonGroup>
					</ModalFooter>
				</ModalContent>
			</Modal>
			{/*------------------------Edit Team Details--------------------- */}
			{currTeamId !== null && (
				<Modal
					size={{ base: "xs", sm: "md", md: "lg", lg: "xl" }}
					onClose={() => {
						onEditTeamClose();
						setTeamDetails({});
						setCurrTeamId(null);
						setDisableAddBtn(false);
					}}
					isOpen={isEditTeamOpen}
					isCentered>
					<ModalOverlay />
					<ModalContent>
						<ModalHeader textAlign={"center"}>Edit New Team</ModalHeader>
						<ModalCloseButton />
						<ModalBody>
							<Stack spacing={5}>
								<Stack direction={{ base: "column", md: "row" }} align="center">
									<FormControl>
										<FormLabel>Team Name</FormLabel>
										<Input
											type="text"
											value={teamDetails.TeamName}
											onChange={(e) =>
												setTeamDetails((prevTeamDetails) => ({
													...prevTeamDetails,
													TeamName: e.target.value,
												}))
											}
										/>
									</FormControl>
									<FormControl>
										<FormLabel>Group Name</FormLabel>
										<Select
											placeholder="Select a Group"
											value={teamDetails.GroupName}
											onChange={(e) =>
												setTeamDetails((prevTeamDetails) => ({
													...prevTeamDetails,
													GroupName: e.target.value,
												}))
											}>
											{teamsGroups?.map((group, index) => {
												return (
													<option
														key={group.GroupName}
														value={group.GroupId}>
														{group.GroupName}
													</option>
												);
											})}
										</Select>
									</FormControl>
								</Stack>
								<Stack
									direction={{ base: "column", md: "row" }}
									align="last"
									spacing={4}>
									{isRankRequired !== "No" && (
										<FormControl>
											<FormLabel>Rank</FormLabel>
											<NumberInput
												value={teamDetails.Rank}
												min={1}
												inputMode="numeric"
												keepWithinRange={true}
												onChange={(value) =>
													setTeamDetails((prevTeamDetails) => ({
														...prevTeamDetails,
														Rank: Number(value),
													}))
												}>
												<NumberInputField
													textAlign={"center"}
													padding={0}
												/>
											</NumberInput>
										</FormControl>
									)}
									<FormControl>
										<FormLabel>Team Color</FormLabel>
										<Input
											type="color"
											value={teamDetails.TeamColor}
											onChange={(e) =>
												setTeamDetails((prevTeamDetails) => ({
													...prevTeamDetails,
													TeamColor: e.target.value,
												}))
											}
										/>
									</FormControl>
									<FormControl>
										<FormLabel>Team Logo</FormLabel>
										<input
											type="file"
											onChange={(e) => {
												handleFileChange(e);
												e.target.files.length > 0 &&
													setTeamDetails((prevTeamDetails) => ({
														...prevTeamDetails,
														TeamLogoLink: e.target.files[0]
															? prevTeamDetails.TeamName.split(
																	" "
															  ).join("") +
															  "_" +
															  Date.now() +
															  "." +
															  e.target.files[0].type
																	.split("/")
																	.pop()
															: "",
													}));
											}}
										/>
									</FormControl>
								</Stack>
							</Stack>
						</ModalBody>
						<ModalFooter>
							<ButtonGroup spacing={"3"}>
								<Button
									borderRadius="full"
									colorScheme="red"
									onClick={() => {
										onEditTeamClose();
										setTeamDetails({});
										setCurrTeamId(null);
										setDisableAddBtn(false);
									}}>
									Cancel
								</Button>
								<Button
									type="submit"
									borderRadius="full"
									colorScheme={"blue"}
									onClick={saveUpdatedTeam}
									disabled={
										teamDetails.Teamname !== "" && teamDetails.GroupName !== ""
											? false
											: true
									}>
									Save
								</Button>
							</ButtonGroup>
						</ModalFooter>
					</ModalContent>
				</Modal>
			)}
		</>
	);
};

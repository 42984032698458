import {
	Container,
	Heading,
	useBreakpointValue,
	Stack,
	Card,
	CardHeader,
	useColorModeValue,
	Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ApiService from "../../service/Api.service";
import { useAuthProvider } from "../../context/AuthProvider";
import { TeamMatchupsTable } from "../../Components/Events/TeamMatchups/TeamMatchupsTable";

export const TeamMatchups = () => {
	// User Details
	const user = useAuthProvider();

	//Desktop BreakPoint Value
	const isDesktop = useBreakpointValue({
		base: false,
		lg: true,
	});
	// Mobile Breakpoint Value
	const isMobile = useBreakpointValue({
		base: true,
		sm: true,
		md: false,
	});
	//Page Header
	const pageHeader = useBreakpointValue({
		base: "xs",
		sm: "sm",
	});
	//Box Shadow
	const shadow = useColorModeValue("sm", "sm-dark");
	//Card Heading
	const cardHeading = useBreakpointValue({
		base: "xs",
		md: "sm",
	});
	//Card Subtitle
	const cardSubtitle = useBreakpointValue({
		base: "lg",
		md: "xl",
	});

	let { teamId } = useParams();

	const [matchups, setMatchups] = useState(null);

	async function fetchMatchups() {
		try {
			let matchupRes = await ApiService.httpGet(
				`/TeamEventTeams?Class=getTeamMatches&TETeamId=${teamId}`,
				"U00184"
			);
			setMatchups(matchupRes);
		} catch (err) {
			console.log(err);
		}
	}
	useEffect(() => {
		fetchMatchups();
	}, []);

	if (matchups) {
		return (
			<Container
				flex="1"
				maxWidth="100vw"
				marginTop={isDesktop ? "7rem" : "5rem"}
				marginX={0}
				{...(isMobile && { px: "1" })}
				paddingBottom={10}>
				<Stack spacing="1rem">
					<Card variant={"elevated"} bgColor="white" boxShadow={shadow}>
						<CardHeader>
							<Stack
								justify={"space-between"}
								direction={{ base: "column", md: "row" }}
								align={"end"}>
								<Stack>
									<Heading as="h2" size={cardHeading}>
										Team Matchups
									</Heading>
									<Text
										fontSize={cardSubtitle}
										fontWeight="semibold"
										color="accent"
										as="h4">
										{matchups.TournamentName && matchups.TournamentName}
									</Text>
								</Stack>
								<Text
									fontSize={cardSubtitle}
									as="h4"
									fontWeight={"semibold"}
									textColor={matchups.TeamColour}>
									{matchups.TeamName && matchups.TeamName}
								</Text>
							</Stack>
						</CardHeader>
					</Card>

					<TeamMatchupsTable matchups={matchups.Matchups} />
				</Stack>
			</Container>
		);
	}
};

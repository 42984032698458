import {
	Box,
	HStack,
	Text,
	useColorModeValue,
	SimpleGrid,
	VStack,
} from "@chakra-ui/react";
import * as React from "react";

//Icons
import {
	ActiveClubs,
	InactiveClubs,
	AddedLastWeek,
	TotalTournaments,
} from "../../../icons/Icons";
import { ShimmerBadge } from "react-shimmer-effects-18";

export const StatsCards = ({ dashboardDetails, loading }) => {
	//Box Shadow
	const shadow = useColorModeValue("sm", "sm-dark");

	return (
		<SimpleGrid
			columns={{
				base: 1,
				sm: 2,
				xl: 4,
			}}
			spacing={5}>
			<Box
				_hover={{ border: "1px solid", borderColor: "blue.500" }}
				boxShadow={shadow}
				borderRadius="lg"
				px="0.7rem"
				py=".4rem"
				bgColor="white">
				<HStack justifyContent="space-between">
					<Box>
						<ActiveClubs />
					</Box>
					<Box>
						<VStack spacing={0} align="end">
							{loading ? (
								<ShimmerBadge width={60} />
							) : (
								<Text
									fontWeight="bold"
									textColor="#4E4EE9"
									fontSize={["2xl", "3xl"]}>
									{dashboardDetails?.ActiveClubsCount}
								</Text>
							)}
							<Text fontSize={"sm"} fontWeight="medium" marginTop="0px">
								Active Clubs
							</Text>
						</VStack>
					</Box>
				</HStack>
			</Box>
			<Box
				_hover={{ border: "1px solid", borderColor: "blue.500" }}
				boxShadow={shadow}
				borderRadius="lg"
				px="0.7rem"
				py=".4rem"
				bgColor="white">
				<HStack justifyContent="space-between">
					<Box>
						<InactiveClubs />
					</Box>
					<Box>
						<VStack spacing={0} align="end">
							{loading ? (
								<ShimmerBadge width={60} />
							) : (
								<Text
									fontWeight="bold"
									textColor="#47AFB5"
									fontSize={["2xl", "3xl"]}>
									{dashboardDetails?.TotalRegPlayers}
								</Text>
							)}
							<Text fontSize={"sm"} fontWeight="medium" marginTop="0px">
								Total Registered Players
							</Text>
						</VStack>
					</Box>
				</HStack>
			</Box>
			<Box
				_hover={{ border: "1px solid", borderColor: "blue.500" }}
				boxShadow={shadow}
				borderRadius="lg"
				px="0.7rem"
				py=".4rem"
				bgColor="white">
				<HStack justifyContent="space-between">
					<Box>
						<AddedLastWeek />
					</Box>
					<Box>
						<VStack spacing={0} align="end">
							{loading ? (
								<ShimmerBadge width={60} />
							) : (
								<Text
									textColor="#EF6EAC"
									fontWeight="bold"
									fontSize={["2xl", "3xl"]}>
									{dashboardDetails?.TotalEvents}
								</Text>
							)}
							<Text fontSize={"sm"} fontWeight="medium" marginTop="0px">
								Total Events
							</Text>
						</VStack>
					</Box>
				</HStack>
			</Box>
			<Box
				_hover={{ border: "1px solid", borderColor: "blue.500" }}
				boxShadow={shadow}
				borderRadius="lg"
				px="0.7rem"
				py=".4rem"
				bgColor="white">
				<HStack justifyContent="space-between">
					<Box>
						<TotalTournaments />
					</Box>
					<Box>
						<VStack spacing={0} align="end">
							{loading ? (
								<ShimmerBadge width={60} />
							) : (
								<Text
									fontWeight="bold"
									textColor="#E7B944"
									fontSize={["2xl", "3xl"]}>
									{dashboardDetails?.TotalTournaments}
								</Text>
							)}
							<Text fontSize="sm" fontWeight="medium" marginTop="0px">
								Total Tournaments
							</Text>
						</VStack>
					</Box>
				</HStack>
			</Box>
		</SimpleGrid>
	);
};

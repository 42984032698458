import {
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useDisclosure,
	FormControl,
	FormLabel,
	Input,
	Stack,
	Select,
	useBreakpointValue,
	ButtonGroup,
	useToast,
	FormErrorMessage,
} from "@chakra-ui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import ApiService from "../../../service/Api.service";
import { useAuthProvider } from "../../../context/AuthProvider";

export const DoublesAddTeamModal = (props) => {
	// User Details
	const user = useAuthProvider();

	const { isOpen, onOpen, onClose } = useDisclosure();
	let [dpLookup, setDPLookup] = useState([]);

	let toast = useToast();

	let [selectedPlayer1, setSelectedPlayer1] = useState("");
	let [selectedPlayer2, setSelectedPlayer2] = useState("");
	const [addLoading, setAddLoading] = useState(false);
	const [saveLoading, setSaveLoading] = useState(false);

	let teamsReload = props.teamsReload;
	let setTeamsReload = props.setTeamsReload;

	const {
		register,
		setValue,
		getValues,
		reset,
		formState: { isDirty, isValid, errors },
	} = useForm({
		delayError: "500",
		mode: "all",
	});

	const onSubmit = (data) => {
		console.log(data);
		let AddTeamObj = {
			TourEventId: props.EventId,
			TourPlayer1Id: data.TourPlayer1Id,
			TourPlayer2Id: data.TourPlayer2Id,
			TeamName: data.TeamName,
			Rank: data.Rank,
		};
		console.log(AddTeamObj);
		AddTeam(AddTeamObj);
	};

	let AddTeam = async (reqObj) => {
		try {
			setSaveLoading(true);
			const member = await ApiService.httpPost("/DoublesEventTeams", reqObj);
			console.log(member);
			onClose();
			setSelectedPlayer1("");
			setSelectedPlayer2("");
			reset();
			if (member.status === true) {
				toast({
					title: "Add Team!",
					description: "Team Added Successfully",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			} else {
				toast({
					title: "Add Team!",
					description: "Failed to Add Team",
					status: "warning",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
			setSaveLoading(false);
			setTeamsReload(!teamsReload);
		} catch (err) {
			console.error(err.message);
			toast({
				title: "Add Team!",
				description: "Failed to Add Team",
				status: "success",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
			setSaveLoading(false);
		} finally {
		}
	};

	const fetchDPLookup = async () => {
		try {
			setAddLoading(true);
			let dpLookup = await ApiService.httpGet(
				`/TourPlayers?EventId=${props.EventId}`,
				user.userId
			);
			console.log("Doubles Players List:", dpLookup.Players);
			setDPLookup(dpLookup.Players);
			setAddLoading(false);
			onOpen();
		} catch (err) {
			console.log(err);
			setAddLoading(false);
		}
	};

	return (
		<>
			<Button
				textColor="white"
				color="green.500"
				bgColor="green.100"
				onClick={fetchDPLookup}
				isLoading={addLoading}>
				Add Team
			</Button>
			<Modal
				isOpen={isOpen}
				size={useBreakpointValue({
					base: "sm",
					sm: "lg",
					md: "2xl",
					lg: "4xl",
				})}
				onClose={() => {
					onClose();
					setSelectedPlayer1("");
					setSelectedPlayer2("");
					reset();
				}}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader textAlign="center">Add Team</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<form>
							<Stack spacing={useBreakpointValue({ base: "1rem", md: "1.5rem" })}>
								<Stack
									spacing={useBreakpointValue({ base: "1rem", md: "1.5rem" })}
									direction={useBreakpointValue({
										base: "column",
										sm: "row",
									})}>
									<FormControl id="Player1">
										<FormLabel>Player 1</FormLabel>
										<Select
											name="Player1"
											colorScheme="blue"
											placeholder="Select Player 1"
											{...register("TourPlayer1Id", {
												required:
													"Please select a player from the dropdown",
												onChange: (e) => {
													setSelectedPlayer1(e.target.value);
													selectedPlayer2 !== "" && e.target.value !== ""
														? setValue(
																"TeamName",
																`${
																	dpLookup.find(
																		(player) =>
																			selectedPlayer2 ===
																			player.TourPlayerId
																	).NickName
																} & ${
																	dpLookup.find(
																		(player) =>
																			e.target.value ===
																			player.TourPlayerId
																	).NickName
																}`
														  )
														: setValue("TeamName", undefined);
												},
											})}>
											{dpLookup
												?.filter(
													(player) =>
														player.TourPlayerId !== selectedPlayer2
												)
												.map((player) => {
													return (
														<option
															key={player.TourPlayerId}
															value={player.TourPlayerId}>
															{player.PlayerName}
														</option>
													);
												})}
										</Select>
									</FormControl>
									<FormControl id="Player2">
										<FormLabel>Player 2</FormLabel>
										<Select
											name="Player2"
											colorScheme="blue"
											placeholder="Select Player 2"
											{...register("TourPlayer2Id", {
												required:
													"Please select a player from the dropdown",
												onChange: (e) => {
													setSelectedPlayer2(e.target.value);
													selectedPlayer1 !== "" && e.target.value !== ""
														? setValue(
																"TeamName",
																`${
																	dpLookup.find(
																		(player) =>
																			selectedPlayer1 ===
																			player.TourPlayerId
																	).NickName
																} & ${
																	dpLookup.find(
																		(player) =>
																			e.target.value ===
																			player.TourPlayerId
																	).NickName
																}`
														  )
														: setValue("TeamName", undefined);
												},
											})}>
											{dpLookup
												?.filter(
													(player) =>
														player.TourPlayerId !== selectedPlayer1
												)
												.map((player) => {
													return (
														<option
															key={player.TourPlayerId}
															value={player.TourPlayerId}>
															{player.PlayerName}
														</option>
													);
												})}
										</Select>
									</FormControl>
								</Stack>
								<Stack
									spacing={useBreakpointValue({ base: "1rem", md: "1.5rem" })}
									direction={useBreakpointValue({
										base: "column",
										sm: "row",
									})}>
									<FormControl id="TeamName" isInvalid={errors.TeamName}>
										<FormLabel>Team Name</FormLabel>
										<Input
											type="text"
											{...register("TeamName", {
												required: "Please enter Team Name",
												pattern: {
													value: /^[A-Za-z0-9 &]+$/,
													message:
														"Team Name can be alphanumeric and can contain ampersand and whitespaces",
												},
												minLength: {
													value: 2,
													message: "Team Name can't be this short",
												},
											})}
										/>
										<FormErrorMessage>
											{errors.TeamName && errors.TeamName.message}
										</FormErrorMessage>
									</FormControl>
									<FormControl id="Rank" isInvalid={errors.Rank}>
										<FormLabel>Rank</FormLabel>
										<Input
											type="number"
											{...register("Rank", {
												min: {
													value: 0,
													message: "Rank must be a positive number",
												},
											})}
										/>
										<FormErrorMessage>
											{errors.Rank && errors.Rank.message}
										</FormErrorMessage>
									</FormControl>
								</Stack>
							</Stack>
						</form>
					</ModalBody>

					<ModalFooter>
						<ButtonGroup spacing="5">
							{!saveLoading && (
								<Button
									onClick={() => {
										onClose();
										setSelectedPlayer1("");
										setSelectedPlayer2("");
										reset();
									}}
									size="lg"
									borderRadius="full"
									colorScheme="red">
									Cancel
								</Button>
							)}
							<Button
								type="submit"
								size="lg"
								borderRadius="full"
								colorScheme="blue"
								onClick={() => onSubmit(getValues())}
								isLoading={saveLoading}
								disabled={!isDirty || !isValid}>
								Save
							</Button>
						</ButtonGroup>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
};

import {
	Avatar,
	Box,
	Button,
	Flex,
	HStack,
	Heading,
	ScaleFade,
	Table,
	Tag,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	Wrap,
	useColorModeValue,
} from "@chakra-ui/react";
import { HiPencilAlt } from "react-icons/hi";
import ApiService from "../../../service/Api.service";
import { useState, useEffect } from "react";
import { playerImgDir, teamImgDir } from "../../../Utilities/ImageLinks";
import { useAuthProvider } from "../../../context/AuthProvider";

export const TeamRightJourney = ({ teamId }) => {
	// User Details
	const user = useAuthProvider();

	const [teamJourney, setTeamJourney] = useState(null);
	async function fetchTeamJourney() {
		try {
			let journeyResp = await ApiService.httpGet(
				`/TeamEventTeams?Class=getTeamJourney&TETeamId=${teamId}`,
				user.userId
			);
			if (journeyResp.status === true) {
				setTeamJourney(journeyResp);
			}
		} catch (err) {
			console.log(err);
		}
	}
	useEffect(() => {
		fetchTeamJourney();
	}, [teamId]);

	if (teamJourney) {
		return (
			<ScaleFade initialScale={0.1} in>
				<Flex
					direction="column"
					align={"center"}
					minW={"70vw"}
					bg={"white"}
					shadow={"base"}
					borderRadius={"2xl"}
					px={5}
					pb={5}>
					<HStack mt="-10" w={"100%"} justify={"space-between"}>
						<Avatar
							borderWidth="6px"
							borderColor={"white"}
							size="xl"
							src={`${teamImgDir}${teamJourney.TeamLogoLink}`}
							name={teamJourney.TeamName}
						/>
						<HStack>
							{teamJourney.players.ImageLinks.map((playerImage, index) => (
								<Avatar
									key={index}
									borderWidth="6px"
									borderColor={"white"}
									size="lg"
									src={`${playerImgDir}${playerImage}`}
								/>
							))}
						</HStack>
					</HStack>
					<Box w={"100%"}>
						<Heading
							size="sm"
							textAlign={"center"}
							fontWeight="extrabold"
							letterSpacing="tight"
							textColor={teamJourney.TeamColor}>
							{teamJourney.TeamName}
						</Heading>
						<Wrap justify={"center"} shouldWrapChildren mt={1} color={"gray.600"}>
							{teamJourney.players.PlayerNames.map((playerName, index) => (
								<Tag key={index} color="inherit" px="3">
									{playerName}
								</Tag>
							))}
						</Wrap>
						<Table bgColor={"white"} mt={2} variant="striped" colorScheme="gray">
							<Thead>
								<Tr>
									<Th
										textColor="#4B39B7"
										fontSize="md"
										fontWeight={"bold"}
										textAlign="start"
										px={3}>
										Round
									</Th>
									<Th
										textColor="#4B39B7"
										fontSize="md"
										fontWeight={"bold"}
										textAlign="start"
										px={3}>
										Opponent
									</Th>
									<Th
										textColor="#4B39B7"
										fontSize="md"
										fontWeight={"bold"}
										textAlign="center"
										px={3}>
										Result
									</Th>
									{teamJourney.Positions.map((position, index) => (
										<Th
											key={index}
											textColor="#4B39B7"
											fontSize="md"
											fontWeight={"bold"}
											textAlign="center"
											px={3}>
											{position}
										</Th>
									))}
								</Tr>
							</Thead>

							<Tbody bg="white">
								{teamJourney.MatchupResults.map((player, index) => (
									<Tr key={index}>
										<Td
											p={"0.5rem"}
											textAlign="start"
											fontSize="sm"
											fontWeight="medium"
											textColor="#000000">
											{player.RoundName}
										</Td>
										<Td
											p={"0.5rem"}
											textAlign="start"
											fontSize="sm"
											fontWeight="medium"
											textColor="#000000">
											{player.Opponent}
										</Td>
										<Td
											p={"0.5rem"}
											textAlign="center"
											fontSize="sm"
											fontWeight="medium"
											textColor={player.Result === "Lost" ? "red" : "green"}>
											{player.Result}
										</Td>
										{teamJourney.Positions.map((position, index) => (
											<Td
												p={"0.5rem"}
												textAlign="center"
												fontSize="sm"
												fontWeight="medium"
												textColor="#000000">
												{player[position]}
											</Td>
										))}
									</Tr>
								))}
							</Tbody>
						</Table>
					</Box>
				</Flex>
			</ScaleFade>
		);
	} else {
		return null;
	}
};

import { BlobServiceClient } from "@azure/storage-blob"

function uploadXlsFile(file, toast, closeUpload, folderName, fileName) {
	const connectionString =
		"https://caromblob.blob.core.windows.net/?sp=racw&st=2023-03-03T12:45:45Z&se=2025-03-03T12:45:45Z&spr=https&sv=2021-06-08&sr=c&sig=%2BOFEN3jhtB0yGHibQF7N58RUSUOjx6pwf3OpXPkVp38%3D";
	const containerName = "uploadfiles";
	async function handleUpload() {
		console.log(file.name);
		if (file.name.endsWith(".xlsx") || file.name.endsWith(".xls")) {
			try {
				const blobServiceClient = new BlobServiceClient(connectionString);
				const containerClient =
					blobServiceClient.getContainerClient(containerName);

				const folderPath = `${folderName}/`;
				const blobName = folderPath + `${fileName}_` + Date.now();

				const blockBlobClient = containerClient.getBlockBlobClient(blobName);
				await blockBlobClient.uploadData(file);

				console.log("File Uploaded Successfully!");
				closeUpload();
			} catch (error) {
				console.error(error);
			}
		} else {
			toast({
				title: "Wrong File Format",
				description: "You can upload only .xls or .xlsx file formats",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
		}
	}
    return {
        handleUpload
    }
}
export default uploadXlsFile;

import {
	Card,
	Button,
	CardHeader,
	IconButton,
	Icon,
	Input,
	InputGroup,
	InputLeftElement,
	Stack,
	Text,
	useBreakpointValue,
	useColorModeValue,
	Image,
	Tooltip,
	Link,
} from "@chakra-ui/react";
import { BsDownload, BsUpload } from "react-icons/bs";
import { FiMoreHorizontal, FiSearch } from "react-icons/fi";
import {
	HiDotsVertical,
	HiDownload,
	HiOutlineLocationMarker,
	HiOutlineMail,
	HiOutlinePhone,
	HiUpload,
} from "react-icons/hi";
import { SlPaperPlane } from "react-icons/sl";
import { useNavigate } from "react-router-dom";
import {
	ShimmerBadge,
	ShimmerThumbnail,
	ShimmerTitle,
	ShimmerText,
	ShimmerButton,
} from "react-shimmer-effects-18";

import ClubImage from "../../../images/club-img2.png";
import { useState, useEffect } from "react";

export const ClubDetailsCard = (props) => {
	//Box Shadow
	const shadow = useColorModeValue("sm", "sm-dark");
	//Club Name
	const title = useBreakpointValue({
		base: "lg",
		md: "xl",
	});
	//Club NickName
	const subTitle = useBreakpointValue({
		base: "xs",
		md: "sm",
	});

	let club = props.clubData;
	let loading = props.loadingClubs;

	let navigate = useNavigate();
	let navigateToTournaments = () => {
		navigate("/tournaments", { state: { clubId: club.ClubId } });
	};

	// States
	const [deviceType, setDeviceType] = useState("");

	useEffect(() => {
		let hasTouchScreen = false;
		if ("maxTouchPoints" in navigator) {
			hasTouchScreen = navigator.maxTouchPoints > 0;
		} else if ("msMaxTouchPoints" in navigator) {
			hasTouchScreen = navigator.msMaxTouchPoints > 0;
		} else {
			const mQ = window.matchMedia && matchMedia("(pointer:coarse)");
			if (mQ && mQ.media === "(pointer:coarse)") {
				hasTouchScreen = !!mQ.matches;
			} else if ("orientation" in window) {
				hasTouchScreen = true; // deprecated, but good fallback
			} else {
				// Only as a last resort, fall back to user agent sniffing
				var UA = navigator.userAgent;
				hasTouchScreen =
					/\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
					/\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
			}
		}
		if (hasTouchScreen) {
			setDeviceType("Mobile");
		} else {
			setDeviceType("Desktop");
		}
	}, []);

	return (
		<Card variant={"elevated"} bgColor="white" boxShadow={shadow}>
			<CardHeader>
				<Stack
					direction={{
						base: "column",
						sm: "row",
					}}
					spacing="1.2rem"
					justify="space-between">
					<Stack direction="row" spacing="1rem">
						{loading ? (
							<ShimmerThumbnail height={100} width={100} rounded />
						) : (
							<Image src={ClubImage} bgColor="cornflowerblue" />
						)}
						<Stack direction="column" justify="center" spacing=".7rem">
							{loading ? (
								<ShimmerBadge width={200} />
							) : (
								<Text
									as="h3"
									fontSize={title}
									textColor="accent" //#3182ce
									fontWeight="semibold">
									{club?.ClubName}
								</Text>
							)}
							{loading ? (
								<ShimmerText line={1} />
							) : (
								<Text fontSize={subTitle}>@{club?.NickName}</Text>
							)}
						</Stack>
					</Stack>
					<Stack
						direction={{ base: "row", sm: "column", md: "row" }}
						justify="flex-end"
						align="center"
						spacing=".7rem">
						<Stack direction={"row"} align={"center"}>
							{loading ? (
								<ShimmerBadge width={40} />
							) : (
								<Link href={"tel:" + club?.Phone}>
									<Tooltip hasArrow label={club?.Phone} closeOnClick={true}>
										<IconButton
											size="md"
											color="accent"
											variant="ghost"
											icon={<HiOutlinePhone size="1.7rem" />}
										/>
									</Tooltip>
								</Link>
							)}
							{loading ? (
								<ShimmerBadge width={40} />
							) : (
								<Link href={"mailto:" + club?.Email}>
									<Tooltip hasArrow label={club?.Email} closeOnClick={true}>
										<IconButton
											size="md"
											color="accent"
											variant="ghost"
											icon={<HiOutlineMail size="1.7rem" />}
										/>
									</Tooltip>
								</Link>
							)}
							{loading ? (
								<ShimmerBadge width={40} />
							) : (
								<Tooltip hasArrow label={club?.Address1} closeOnClick={false}>
									<IconButton
										as={Link}
										{...(club?.Address1 &&
											club?.Address1.length > 1 && {
												href:
													deviceType === "Mobile"
														? `geo:0,0?q=${encodeURIComponent(club?.Address1)}`
														: `https://www.google.com/maps?q=${encodeURIComponent(
																club?.Address1
														  )}`,
											})}
										{...(club?.Address1 &&
											club?.Address1.length > 1 && { target: "_blank" })}
										size="md"
										borderRadius="full"
										variant="ghost"
										color="accent"
										icon={<HiOutlineLocationMarker size="1.7rem" />}
									/>
								</Tooltip>
							)}
						</Stack>
						{loading ? (
							<ShimmerButton />
						) : (
							<Button
								textColor="white"
								color="green.500"
								bgColor="green.100"
								onClick={navigateToTournaments}>
								Tournaments
							</Button>
						)}
					</Stack>
				</Stack>
			</CardHeader>
		</Card>
	);
};

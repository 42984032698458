import {
	Avatar,
	AvatarGroup,
	Box,
	Center,
	DarkMode,
	Flex,
	Heading,
	HStack,
	Stack,
	Text,
	useBreakpointValue,
	useColorModeValue as mode,
	Container,
	Link,
	Button,
} from "@chakra-ui/react";
import * as React from "react";
import { CarromLogo } from "../../logo/Logo";
import { SignInForm } from "./SignInForm";
import { GoogleIcon } from "./ProviderIcons";

export const SignIn = ({ redirect }) => {
	//Desktop BreakPoint Value
	const isDesktop = useBreakpointValue({
		base: false,
		lg: true,
	});
	return (
		<>
			<Flex maxW="8xl" mx="auto" width="full">
				<Box
					flex="1"
					display={{
						base: "none",
						md: "block",
					}}
					bg="accent">
					<DarkMode>
						<Flex
							direction="column"
							px={{
								base: "4",
								md: "8",
							}}
							height="full"
							color="on-accent">
							<Flex justify="center" align="center" h="24">
								<CarromLogo />
							</Flex>
							<Flex flex="1" align="center"></Flex>
						</Flex>
					</DarkMode>
				</Box>
				<Center flex="1">
					{/* <SignInForm width="full" maxW="md" /> */}
					<Stack spacing="8">
						<Stack
							spacing={{
								base: "2",
								md: "3",
							}}
							textAlign="center">
							<Heading
								size={{
									base: "xs",
									md: "sm",
								}}>
								Log in to your account
							</Heading>
						</Stack>
						<Stack spacing="3">
							<Link href={`/.auth/login/google?post_login_redirect_uri=${redirect}`}>
								<Button
									variant="secondary"
									leftIcon={<GoogleIcon />}
									style={{ width: "100%" }}>
									Continue With Google
								</Button>
							</Link>
						</Stack>
					</Stack>
				</Center>
			</Flex>
		</>
	);
};

import { createContext, useContext, useEffect } from "react";
import useAuth from "../hooks/useAuth";

const AuthContext = createContext(null);

export function AuthProvider({ children }) {
  const user = useAuth();

  return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
}

export function useAuthProvider() {
    return useContext(AuthContext);
}

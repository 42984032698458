import "./MembersCards.css";
import {
	Box,
	Center,
	Flex,
	HStack,
	Stack,
	Text,
	Icon,
	useBreakpointValue,
	useColorModeValue,
	Avatar,
	SimpleGrid,
	Button,
	useDisclosure,
	useToast,
	AlertDialog,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogCloseButton,
	AlertDialogBody,
	AlertDialogOverlay,
	AlertDialogFooter,
} from "@chakra-ui/react";
import { FiEdit, FiMoreHorizontal, FiTrash } from "react-icons/fi";
import { IoMailOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import member1 from "../../../images/member-img1.png";
import { ShimmerPostItem, ShimmerBadge } from "react-shimmer-effects-18";
import { Fragment, useRef, useState } from "react";
import ApiService from "../../../service/Api.service";

export const ClubMembersCard = ({
	clubMembers,
	club,
	loadingMembers,
	reload,
	setReload,
	setLoading,
}) => {
	//Card Title
	const title = useBreakpointValue({ base: "lg", sm: "xl", md: "2xl" });
	//Box Shadow
	const shadow = useColorModeValue("sm", "sm-dark");

	// Toast
	let toast = useToast();
	const {
		isOpen: isAlertOpen,
		onOpen: onAlertOpen,
		onClose: onAlertClose,
	} = useDisclosure();
	const cancelRef = useRef();

	const [currMember, setCurrMember] = useState({});
	const [showOverlay, setShowOverlay] = useState(false);

	const handleShow = () => {
		setShowOverlay(!showOverlay);
	};

	let navigate = useNavigate();

	let navigateToAddMembers = () => {
		navigate("/members/addmember", {
			state: { clubId: club.ClubId, clubName: club.ClubName },
		});
	};

	let NavigateToUpdateMembers = (member) => {
		navigate("/members/updatemember", { state: { userId: member.UserId } });
		console.log("Navigate to UpdateMembers..");
	};

	let handleDelete = async (member) => {
		try {
			handleShow();
			onAlertClose();
			setLoading(true);
			let deleteResponse = await ApiService.httpDelete(
				`/Members?UserId=${member.UserId}`
			);
			console.log("Deleted Member:", member.UserId);
			if (deleteResponse.status === true) {
				toast({
					title: "Delete Member",
					description: "Member Deleted Successfully!",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				setLoading(false);
				setReload(!reload);
				console.log("Member Reloaded...");
			} else {
				toast({
					title: "Delete Member",
					description: "Failed to Delete Member",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				setLoading(false);
			}
		} catch (err) {
			console.log(err);
			toast({
				title: "Delete Member",
				description: "Failed to Delete Member",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
			setLoading(false);
		}
	};

	return (
		<>
			<Box>
				<HStack justify="space-between">
					<Text mb={2} fontSize={title} fontWeight="bold">
						Members
					</Text>
					<Flex py=".7rem" pr="1rem" justify="end">
						{loadingMembers ? (
							<ShimmerBadge width={200} />
						) : (
							<Button
								textColor="accent"
								bgColor="#bee3f8"
								borderRadius="full"
								onClick={navigateToAddMembers}>
								Add New Member
							</Button>
						)}
					</Flex>
				</HStack>
				<Box bg="bg-surface" boxShadow={shadow} borderRadius={"2xl"} p="2rem">
					<SimpleGrid
						columns={{ base: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
						columnGap={{ base: "4", md: "6" }}
						rowGap={{ base: "8", md: "10" }}>
						{loadingMembers
							? Array(4)
									.fill()
									.map((load, index) => (
										<ShimmerPostItem
											key={index}
											card
											title
											imageType="circular"
											imageWidth={80}
											imageHeight={80}
											contentCenter
										/>
									))
							: clubMembers?.map((member, index) => {
									return (
										<Fragment key={member.UserId}>
											<Box
												boxShadow={shadow}
												bgColor="white"
												borderRadius="lg"
												pb="4"
												_hover={{
													border: "1px solid",
													borderColor: "blue.500",
												}}
												overflowWrap="normal">
												<Stack>
													<Flex justify="end" px="3" pt="1">
														<Icon
															as={FiMoreHorizontal}
															boxSize="5"
															color="blackAlpha.500"
															onClick={handleShow}
														/>
													</Flex>
													<Center>
														<Avatar
															src={member1}
															alt="Carrom Clubs"
															size="xl"
														/>
													</Center>
													<Text align="center" fontWeight="bold" fontSize="sm">
														{member?.FirstName} {member?.LastName}
													</Text>
													<HStack spacing={1} justify="center">
														<Icon
															as={IoMailOutline}
															boxSize={4}
															color="blackAlpha.500"
														/>
														<Text
															fontWeight="semibold"
															fontSize="xs"
															color="blackAlpha.500">
															{member?.Email}
														</Text>
													</HStack>
												</Stack>
											</Box>
											{showOverlay && (
												<div className="overlayStyle">
													<Center>
														<Icon
															as={FiEdit}
															boxSize="5"
															color="blackAlpha.500"
															onClick={() => NavigateToUpdateMembers(member)}
														/>
														<Icon
															as={FiTrash}
															boxSize="5"
															color="blackAlpha.500"
															onClick={() => {
																setCurrMember(member);
																onAlertOpen();
															}}
														/>
													</Center>
												</div>
											)}
										</Fragment>
									);
							  })}
					</SimpleGrid>
				</Box>
			</Box>{" "}
			<AlertDialog
				motionPreset="slideInBottom"
				leastDestructiveRef={cancelRef}
				onClose={onAlertClose}
				isOpen={isAlertOpen}
				isCentered>
				<AlertDialogOverlay />

				<AlertDialogContent>
					<AlertDialogHeader>Delete Member</AlertDialogHeader>
					<AlertDialogCloseButton />
					<AlertDialogBody>
						Are you sure you want to delete{" "}
						<Text as={"span"} fontWeight={"bold"}>
							{currMember?.FirstName} {currMember?.LastName}
						</Text>
						?
					</AlertDialogBody>
					<AlertDialogFooter>
						<Button ref={cancelRef} onClick={onAlertClose}>
							Cancel
						</Button>
						<Button
							colorScheme="red"
							ml={3}
							onClick={() => handleDelete(currMember)}>
							Delete
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
		</>
	);
};

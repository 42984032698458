import {
	Card,
	CardHeader,
	IconButton,
	Icon,
	Input,
	InputGroup,
	InputLeftElement,
	Stack,
	Text,
	useBreakpointValue,
	useColorModeValue,
	Select,
	FormControl,
	Menu,
	MenuButton,
	CloseButton,
	MenuList,
	MenuItem,
	useToast,
	useDisclosure,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button,
	Table,
	Tbody,
	Td,
	Tr,
	Th,
	Thead,
	Skeleton,
} from "@chakra-ui/react";
import { CSVLink } from "react-csv";
import { FiDownloadCloud, FiMoreVertical, FiSearch } from "react-icons/fi";
import { useState } from "react";
import ApiService from "../../service/Api.service";

export const StandingsTabCard = (props) => {
	const headers = [
		{ label: "Position", key: "Position" },
		{
			label:
				props.eventType === "Singles"
					? "Player Name"
					: props.eventType === "Doubles"
					? "Team Name"
					: props.eventType === "Teams"
					? props.selectedClass === "Teams"
						? "Team Name"
						: "Player Name"
					: undefined,
			key: "TeamName",
		},
		{ label: "Matches Played", key: "MatchesPlayed" },
		{ label: "Points", key: props.eventType === "Teams" ? "PointsScored" : "Points" },
		{ label: "Opponent(s) Strength", key: "OppStrength" },
		{ label: "Net Score", key: "NetScore" },
	];

	const isDesktop = useBreakpointValue({
		base: false,
		md: true,
	});

	const stackDirection = useBreakpointValue({
		base: "column",
		md: "row",
	});

	let toast = useToast();
	const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();

	let [begin, setBegin] = useState(0);
	let [end, setEnd] = useState(5);
	const [intervalId, setIntervalId] = useState(null);

	// Loading States
	const [menuLoading, setMenuLoading] = useState(false);
	let groupsLookupLoad = props.groupsLookupLoad;
	let roundsLookupLoad = props.roundsLookupLoad;

	const handleNotificaiton = async (event) => {
		console.log("Action performed for:", event.target.value);
		let notification = event.target.value;
		switch (notification) {
			case "displayStandings":
				console.log("Display Standings");
				onModalOpen();
				startInterval();
				break;
			case "sendStandings":
				try {
					setMenuLoading(true);
					let sendStandingsRes = await ApiService.httpPost(
						props.eventType !== "Teams"
							? `/ScoreLink?EventId=${props.state.EventId}&Class=Standings`
							: `/ScoreLink?EventId=${props.state.EventId}&Class=TeamsStandings`,
						{}
					);
					console.log("Sent Standings:", sendStandingsRes);
					if (sendStandingsRes.status === true) {
						toast({
							title: "Send Standings",
							description: "Standings Sent Successfully!",
							status: "success",
							duration: 3000,
							isClosable: true,
							position: "top-right",
						});
					} else {
						toast({
							title: "Send Standings",
							description: "Failed to Send Standings",
							status: "error",
							duration: 3000,
							isClosable: true,
							position: "top-right",
						});
					}
					setMenuLoading(false);
				} catch (err) {
					toast({
						title: "Send Standings",
						description: "Failed to Send Standings",
						status: "error",
						duration: 3000,
						isClosable: true,
						position: "top-right",
					});
					setMenuLoading(false);
				}
				break;
		}
	};

	const startInterval = () => {
		setBegin(0);
		setEnd(5);
		setIntervalId(
			setInterval(() => {
				displayStandings();
			}, 5000)
		);
	};

	const stopInterval = () => {
		clearInterval(intervalId);
		setIntervalId(null);
	};

	const displayStandings = () => {
		setBegin((prev) => (prev + 5) % props.standingsData?.length);
		setEnd((prev) => (prev % props.standingsData?.length) + 5);
	};

	return (
		<>
			<Card
				variant={"elevated"}
				bgColor="white"
				boxShadow={useColorModeValue("sm", "sm-dark")}>
				<CardHeader>
					<Stack
						direction={useBreakpointValue({
							base: "column",
							xl: "row",
						})}
						spacing="1.2rem"
						justify="space-between">
						<Stack direction="column" spacing=".7rem">
							<Text
								as="h3"
								fontSize={useBreakpointValue({
									base: "lg",
									md: "xl",
								})}
								fontWeight="semibold">
								{/* {props.selectedGroup?.GroupId &&
									"Group " + props.selectedGroup?.GroupId + " "}
								{props.eventType === "Teams" && props.selectedClass === "Teams"
									? "Teams "
									: "Players "}
								Standings by the end of Round {props.selectedRound?.RoundNo} */}
								Standings
							</Text>
							<InputGroup maxW="xs" colorScheme="blue">
								<InputLeftElement pointerEvents="none">
									<Icon as={FiSearch} color="accent" boxSize="5" />
								</InputLeftElement>
								<Input
									w="100vw"
									placeholder="Search"
									onChange={(e) =>
										props.eventType === "Teams"
											? props.setQueryTeams(e.target.value)
											: props.setQuery(e.target.value)
									}
								/>
							</InputGroup>
						</Stack>
						<Stack
							direction={stackDirection}
							alignSelf={isDesktop && "end"}
							justify="end"
							spacing=".7rem">
							{isDesktop && props.standingsData?.length > 0 && (
								<CSVLink
									headers={headers}
									filename={
										props.eventType === "Singles"
											? "SinglesPlayerStandings.csv"
											: props.eventType === "Doubles"
											? "DoublesPlayerStandings.csv"
											: props.eventType === "Teams"
											? props.selectedClass === "Teams"
												? "TeamsStandings.csv"
												: "TeamsPlayersStandings.csv"
											: undefined
									}
									data={props.standingsData}
									asyncOnClick={true}>
									<IconButton
										variant="ghost"
										size="md"
										color="accent"
										icon={<FiDownloadCloud size="1.7rem" />}
									/>
								</CSVLink>
							)}
							<Stack spacing="1rem" flex={1} justify="end" direction="row">
								{props.eventType === "Teams" && (
									<FormControl width={"-webkit-max-content"}>
										<Select
											name="Class"
											colorScheme="blue"
											value={props.selectedClass}
											width={"-webkit-max-content"}
											fontSize={{ base: "sm", sm: "sm", md: "md" }}
											textAlign="start"
											onChange={(event) => {
												props.eventType === "Teams" &&
													props.updateTeamStandings(
														props.selectedGroup?.GroupId,
														props.selectedRound?.RoundNo,
														event.target.value
													);
											}}>
											<option value={"Teams"}>Teams</option>
											<option value={"TeamPlayers"}>Players</option>
										</Select>
									</FormControl>
								)}
								<FormControl width={"-webkit-max-content"}>
									{groupsLookupLoad ? (
										<Skeleton isLoaded={!groupsLookupLoad} w="8vw">
											<Select />
										</Skeleton>
									) : (
										<Select
											name="Group"
											colorScheme="blue"
											value={props.selectedGroup?.GroupId}
											width={"-webkit-max-content"}
											fontSize={{ base: "sm", sm: "sm", md: "md" }}
											textAlign="start"
											onChange={(event) => {
												props.eventType !== "Teams"
													? props.updateStandingsData(
															event.target.value,
															props.selectedRound?.RoundNo
													  )
													: props.updateTeamStandings(
															event.target.value,
															props.selectedRound?.RoundNo,
															props.selectedClass
													  );
											}}>
											{props.groups.map((group, index) => {
												return (
													<option
														key={group.GroupId}
														value={group.GroupId}>
														{group.GroupName}
													</option>
												);
											})}
										</Select>
									)}
								</FormControl>

								<FormControl width={"-webkit-max-content"}>
									{roundsLookupLoad ? (
										<Skeleton isLoaded={!roundsLookupLoad} w="8vw">
											<Select />
										</Skeleton>
									) : (
										<Select
											name="Round"
											colorScheme="blue"
											width={"-webkit-max-content"}
											value={props.selectedRound?.RoundNo}
											{...((props.rounds?.length < 1 ||
												props.rounds === undefined) && {
												placeholder: "-",
											})}
											fontSize={{ base: "sm", md: "md" }}
											textAlign="start"
											onChange={(event) => {
												props.eventType !== "Teams"
													? props.updateStandingsData(
															props.selectedGroup?.GroupId,
															event.target.value
													  )
													: props.updateTeamStandings(
															props.selectedGroup?.GroupId,
															event.target.value,
															props.selectedClass
													  );
											}}>
											{props.rounds?.map((round, index) => {
												return (
													<option
														key={round.RoundNo}
														value={round.RoundNo}>
														{round.RoundName}
													</option>
												);
											})}
										</Select>
									)}
								</FormControl>
							</Stack>
							{isDesktop ? (
								<Menu direction="rtl">
									{({ isOpen }) => (
										<>
											<MenuButton
												isActive={isOpen}
												as={IconButton}
												variant="ghost"
												color="green.500"
												size="md"
												borderRadius="full"
												isLoading={menuLoading}
												icon={
													isOpen ? (
														<CloseButton />
													) : (
														<FiMoreVertical size="1.7rem" />
													)
												}
											/>
											<MenuList zIndex="overlay">
												<MenuItem
													textColor="green.400"
													fontWeight="medium"
													value="displayStandings"
													onClick={handleNotificaiton}>
													Display Standings
												</MenuItem>
												<MenuItem
													textColor="green.400"
													fontWeight="medium"
													value="sendStandings"
													onClick={handleNotificaiton}>
													Send Standings
												</MenuItem>
											</MenuList>
										</>
									)}
								</Menu>
							) : (
								<Stack spacing=".7rem" direction="row" justify="end">
									{props.standingsData?.length > 0 && (
										<CSVLink
											headers={headers}
											filename={
												props.eventType === "Singles"
													? "SinglesPlayerStandings.csv"
													: props.eventType === "Doubles"
													? "DoublesPlayerStandings.csv"
													: props.eventType === "Teams"
													? props.selectedClass === "Teams"
														? "TeamsStandings.csv"
														: "TeamsPlayersStandings.csv"
													: undefined
											}
											data={props.standingsData}
											asyncOnClick={true}>
											<IconButton
												variant="ghost"
												size="md"
												color="accent"
												icon={<FiDownloadCloud size="1.7rem" />}
											/>
										</CSVLink>
									)}
									<Menu direction="rtl">
										{({ isOpen }) => (
											<>
												<MenuButton
													isActive={isOpen}
													as={IconButton}
													variant="ghost"
													color="green.500"
													size="md"
													borderRadius="full"
													icon={
														isOpen ? (
															<CloseButton />
														) : (
															<FiMoreVertical size="1.7rem" />
														)
													}
												/>
												<MenuList zIndex="overlay">
													<MenuItem
														textColor="green.400"
														fontWeight="medium"
														value="displayStandings"
														onClick={handleNotificaiton}>
														Display Standings
													</MenuItem>
													<MenuItem
														textColor="green.400"
														fontWeight="medium"
														value="sendStandings"
														onClick={handleNotificaiton}>
														Send Standings
													</MenuItem>
												</MenuList>
											</>
										)}
									</Menu>
								</Stack>
							)}
						</Stack>
					</Stack>
				</CardHeader>
			</Card>
			<Modal
				blockScrollOnMount={false}
				size="full"
				isCentered
				isOpen={isModalOpen}
				scrollBehavior="inside"
				onClose={() => {
					onModalClose();
					stopInterval();
				}}>
				<ModalOverlay />
				<ModalContent margin={0} rounded="none">
					<ModalHeader textAlign="center">Standings</ModalHeader>
					<ModalCloseButton />
					<ModalBody pb={6}>
						<Table variant="striped" borderRadius="2xl">
							<Thead position="sticky" top={0} bgColor="black" zIndex="1">
								<Tr>
									<Th textColor="white" fontSize="md" textAlign="start" py={5}>
										Position
									</Th>
									<Th textColor="white" fontSize="md" textAlign="start">
										{props.selectedClass === "Teams" && props.eventType
											? "Team Name"
											: "Player Name"}
									</Th>
									<Th textColor="white" fontSize="md" textAlign="start">
										{props.selectedClass === "Teams" && props.eventType
											? "Matches Won"
											: "Matches Played"}
									</Th>
									{props.selectedClass === "Teams" && props.eventType && (
										<Th textColor="white" fontSize="md" textAlign="start">
											Matches Lost
										</Th>
									)}
									<Th textColor="white" fontSize="md" textAlign="start">
										Points
									</Th>
									<Th textColor="white" fontSize="md" textAlign="start">
										Opponent(s) Strength
									</Th>
									{props.selectedClass !== "Teams" && props.eventType && (
										<Th textColor="white" fontSize="md" textAlign="start">
											Net Score
										</Th>
									)}
								</Tr>
							</Thead>
							<Tbody>
								{props.standingsData?.slice(begin, end).map((board) => (
									<Tr key={board.Position}>
										<Td textAlign="start" fontSize="lg" fontWeight="medium">
											{board.Position}
										</Td>
										<Td textAlign="start" fontSize="lg" fontWeight="medium">
											{board.TeamName}
										</Td>
										{props.selectedClass === "Teams" && props.eventType && (
											<Td textAlign="start" fontSize="lg" fontWeight="medium">
												{board.MatchesWon}
											</Td>
										)}
										{props.selectedClass === "Teams" && props.eventType && (
											<Td textAlign="start" fontSize="lg" fontWeight="medium">
												{board.MatchesLost}
											</Td>
										)}
										{props.selectedClass !== "Teams" && props.eventType && (
											<Td textAlign="start" fontSize="lg" fontWeight="medium">
												{board.MatchesPlayed}
											</Td>
										)}
										<Td textAlign="start" fontSize="lg" fontWeight="medium">
											{props.selectedClass === "Teams" && props.eventType
												? board.PointsScored
												: board.Points}
										</Td>
										<Td textAlign="start" fontSize="lg" fontWeight="medium">
											{board.OppStrength}
										</Td>
										{props.selectedClass !== "Teams" && props.eventType && (
											<Td textAlign="start" fontSize="lg" fontWeight="medium">
												{board.NetScore}
											</Td>
										)}
									</Tr>
								))}
							</Tbody>
						</Table>
					</ModalBody>

					<ModalFooter>
						<Button
							onClick={() => {
								onModalClose();
								stopInterval();
							}}>
							Cancel
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
};

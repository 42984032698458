import {
	Stack,
	Table,
	Tbody,
	Td,
	Box,
	Tr,
	Th,
	Thead,
	useBreakpointValue,
	Divider,
	Center,
	HStack,
	Text,
	Select,
	Link,
} from "@chakra-ui/react";
import { useState } from "react";
import usePagination from "../../../hooks/usePagination";
import { Pagination } from "../../Pagination/Pagination";
import { ExternalLinkIcon } from "@chakra-ui/icons";

export const StreamingTable = ({ streamingConfigurationsData }) => {
	//Desktop Breakpoint Value
	const isDesktop = useBreakpointValue({ base: false, xl: true });
	const isMobile = useBreakpointValue({
		base: true,
		md: false,
	});

	// Pagination
	let [rows, setRows] = useState(10);
	const perPage = rows;

	const {
		currentPage,
		currentData,
		jump,
		prev,
		next,
		first,
		last,
		setLast,
		setCurrentPage,
		maxPage,
	} = usePagination(streamingConfigurationsData, perPage);

	const handleChange = (e, p) => {
		jump(p);
	};

	const handleRows = (event) => {
		setRows(Number(event.target.value));
		setLast(Number(event.target.value));
		setCurrentPage(1);
		// 	setParamTwo(Number(event.target.value));
	};

	// const previousPage = (event) => {
	// 	setParamTwo(Number(paramOne));
	// 	setParamOne(Number(paramTwo - rows));
	// };
	// const nextPage = (event) => {
	// 	setParamOne(Number(paramTwo));
	// 	setParamTwo(Number(paramTwo + rows));
	// };

	const tableDesktop = () => (
		<>
			<Box>
				<Box
					borderTopRadius={"2xl"}
					overflow="auto"
					boxShadow={"sm"}
					css={{
						"::-webkit-scrollbar": {
							width: "0.3rem",
							height: "0.3rem",
						},
						"::-webkit-scrollbar-track": {
							background: "#bee3f8",
							borderRadius: "1rem",
						},
						"::-webkit-scrollbar-thumb": {
							background: "#3182ce",
							borderRadius: "1rem",
						},
					}}>
					<Table variant="striped">
						<Thead position="sticky" top={0} bgColor="black" zIndex="overlay">
							<Tr>
								<Th
									flex="1"
									textColor="white"
									fontSize="md"
									textAlign="center"
									py={5}
									px={2}
									whiteSpace={"normal"}>
									S.No
								</Th>
								<Th
									flex="1"
									textColor="white"
									fontSize="md"
									textAlign="center"
									whiteSpace={"normal"}>
									YouTube Links
								</Th>
							</Tr>
						</Thead>
						<Tbody>
							{currentData()?.map((board, index) => (
								<Tr key={index}>
									<Td
										flex="1"
										textAlign="center"
										fontSize="md"
										fontWeight="bold"
										px={2}>
										{board.Board}
									</Td>
									<Td
										flex="1"
										textAlign="center"
										fontSize="md"
										fontWeight="bold">
										<Link
                                            as={"a"}
											href={board.YouTubeLink}
											target="_blank"
											isExternal>
											{board.YouTubeLink}
										</Link>
									</Td>
								</Tr>
							))}
						</Tbody>
					</Table>
				</Box>
				<Divider />
				<Stack p="1rem" bgColor="white" borderBottomRadius={"2xl"}>
					{/*---------------For Pagination-------------------------------*/}
					{isMobile ? (
						<Stack>
							<Center>
								<Pagination
									count={streamingConfigurationsData?.length}
									pageSize={rows}
									siblingCount={0}
									page={currentPage}
									onChange={(e) => setCurrentPage(e.page)}
								/>
							</Center>
							<HStack justify="space-between">
								<HStack spacing={1}>
									<Text as="span" whiteSpace="normal">
										Rows per page{" "}
									</Text>
									<Select
										boxSize={"-webkit-max-content"}
										minW="-webkit-fit-content"
										size="sm"
										value={rows}
										onChange={(event) => handleRows(event)}>
										{[10, 25, 50].map((rows) => (
											<option key={rows} value={rows}>
												{rows}
											</option>
										))}
									</Select>
								</HStack>
								<Text whiteSpace="normal">
									Showing {currentPage * rows - rows + 1} -{" "}
									{currentPage * rows <
									streamingConfigurationsData?.length
										? currentPage * rows
										: streamingConfigurationsData?.length}{" "}
									of {streamingConfigurationsData?.length} Entries
								</Text>
							</HStack>
						</Stack>
					) : (
						<HStack justify="space-between">
							<HStack spacing={1}>
								<Text as="span" whiteSpace={"nowrap"}>
									Rows per page{" "}
								</Text>
								<Select
									boxSize={"-webkit-max-content"}
									minW="-webkit-fit-content"
									value={rows}
									onChange={(event) => handleRows(event)}>
									{[10, 25, 50].map((rows) => (
										<option key={rows} value={rows}>
											{rows}
										</option>
									))}
								</Select>
							</HStack>
							<Center>
								<Pagination
									count={streamingConfigurationsData?.length}
									pageSize={rows}
									siblingCount={1}
									page={currentPage}
									onChange={(e) => setCurrentPage(e.page)}
								/>
							</Center>

							<Text whiteSpace="normal">
								Showing {currentPage * rows - rows + 1} -{" "}
								{currentPage * rows < streamingConfigurationsData?.length
									? currentPage * rows
									: streamingConfigurationsData?.length}{" "}
								of {streamingConfigurationsData?.length} Entries
							</Text>
						</HStack>
					)}
				</Stack>
			</Box>
		</>
	);

	return tableDesktop();
};

import {
	HStack,
	Table,
	Tbody,
	Td,
	Box,
	Tr,
	Th,
	Thead,
	FormControl,
	Input,
	useBreakpointValue,
	Divider,
	Button,
	Checkbox,
	useToast,
	Select,
	Text,
	ButtonGroup,
	IconButton,
	Stack,
	Container,
	Center,
	Skeleton,
	NumberInput,
	NumberInputField,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
	AiOutlineDoubleLeft,
	AiOutlineDoubleRight,
	AiOutlineLeft,
	AiOutlineRight,
} from "react-icons/ai";
import ApiService from "../../../service/Api.service";
import usePagination from "../../../hooks/usePagination";
import { Pagination } from "../../Pagination/Pagination";

export const SinglesPlayersTable = (props) => {
	//Desktop Breakpoint Value
	const isDesktop = useBreakpointValue({ base: false, xl: true });
	const isMobile = useBreakpointValue({
		base: true,
		md: false,
	});

	const toast = useToast();

	const [saveLoading, setSaveLoading] = useState(false);

	// Reload States
	let setSinglesReload = props.setSinglesReload;
	let singlesReload = props.singlesReload;

	let query = props.query;
	let generateGroups = props.generateGroups;
	let EventId = props.state.EventId;
	let EventType = props.state.EventType;
	let singlesPlayers = props.singlesPlayers;

	// Loading State
	let singlesLoading = props.singlesLoading;

	let updatedPlayersList = [];

	const updateRank = (rank, live, player) => {
		player.Rank = Number(rank);
		player.Live = Number(live);
		updatedPlayersList.push({
			TE_PlayerId: player.TE_PlayerId,
			Rank: rank,
			Live: live,
		});
		console.log("Updated Player", player);
		console.log("Updated rank", rank);
		console.log("Updated live", live);
	};

	const savePlayers = async () => {
		try {
			setSaveLoading(true);
			let reqObj = {
				Players: singlesPlayers.map((event) => {
					return {
						TE_PlayerId: event.TE_PlayerId,
						Rank: event.Rank,
						Live: event.Live,
					};
				}),
			};
			let savedPlayersRes = await ApiService.httpPut(`/TourPlayers?BulkRank=Yes`, reqObj);
			if (savedPlayersRes.status === true) {
				toast({
					title: "Saved Player",
					description: "Saved Players successfully",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				console.log("Saved Players Response:", savedPlayersRes);
				setSinglesReload(!singlesReload);
			} else {
				toast({
					title: "Save Player",
					description: savedPlayersRes.Message,
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
			setSaveLoading(false);
		} catch (err) {
			console.log(err);
			toast({
				title: "Save Player",
				description: "Failed to Save Player",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
			setSaveLoading(false);
		}
	};

	// Pagination
	let [rows, setRows] = useState(10);
	const perPage = rows;

	const {
		currentPage,
		currentData,
		jump,
		prev,
		next,
		first,
		last,
		setLast,
		setCurrentPage,
		maxPage,
	} = usePagination(
		singlesPlayers?.filter((player) => {
			if (query === "") {
				return player;
			} else if (
				player.PlayerName.toLowerCase().includes(query.toLowerCase()) ||
				player.NickName.toLowerCase().includes(query.toLowerCase()) ||
				player.Email.toLowerCase().includes(query.toLowerCase()) ||
				player.MobileNumber.includes(query)
			) {
				return player;
			}
		}),
		perPage
	);

	const handleChange = (e, p) => {
		jump(p);
	};

	const handleRows = (event) => {
		setRows(Number(event.target.value));
		setLast(Number(event.target.value));
		setCurrentPage(1);
		// 	setParamTwo(Number(event.target.value));
	};

	// const previousPage = (event) => {
	// 	setParamTwo(Number(paramOne));
	// 	setParamOne(Number(paramTwo - rows));
	// };
	// const nextPage = (event) => {
	// 	setParamOne(Number(paramTwo));
	// 	setParamTwo(Number(paramTwo + rows));
	// };

	const tableDesktop = () => {
		return (
			<Box>
				<Box
					borderTopRadius={"2xl"}
					overflow="auto"
					boxShadow={"sm"}
					css={{
						"::-webkit-scrollbar": {
							width: "0.3rem",
							height: "0.3rem",
						},
						"::-webkit-scrollbar-track": {
							background: "#bee3f8",
							borderRadius: "1rem",
						},
						"::-webkit-scrollbar-thumb": {
							background: "#3182ce",
							borderRadius: "1rem",
						},
					}}>
					<Table variant="striped">
						<Thead position="sticky" top={0} bgColor="black" zIndex="2">
							<Tr>
								<Th
									flex="1"
									textColor="white"
									fontSize="md"
									textAlign="start"
									py={5}>
									Player
								</Th>

								<Th flex="1" textColor="white" fontSize="md" textAlign="start">
									Nick Name
								</Th>
								<Th flex="1" textColor="white" fontSize="md" textAlign="start">
									Mobile
								</Th>
								<Th flex="1" textColor="white" fontSize="md" textAlign="start">
									Email
								</Th>
								<Th flex="1" textColor="white" fontSize="md" textAlign="start">
									Rank
								</Th>
								<Th flex="1" textColor="white" fontSize="md" textAlign="start">
									Live
								</Th>
							</Tr>
						</Thead>
						<Tbody>
							{singlesLoading
								? Array(5)
										.fill()
										.map((rows, index) => (
											<Tr key={index}>
												<Td>
													<Skeleton
														isLoaded={!singlesLoading}
														h={"1.2rem"}
														w={"100%"}
													/>
												</Td>
												<Td>
													<Skeleton
														isLoaded={!singlesLoading}
														h={"1.2rem"}
														w={"100%"}
													/>
												</Td>
												<Td>
													<Skeleton
														isLoaded={!singlesLoading}
														h={"1.2rem"}
														w={"100%"}
													/>
												</Td>
												<Td>
													<Skeleton
														isLoaded={!singlesLoading}
														h={"1.2rem"}
														w={"100%"}
													/>
												</Td>
												<Td>
													<Skeleton
														isLoaded={!singlesLoading}
														h={"1.2rem"}
														w={"100%"}
													/>
												</Td>
												<Td>
													<Skeleton
														isLoaded={!singlesLoading}
														h={"1.2rem"}
														w={"100%"}
													/>
												</Td>
											</Tr>
										))
								: currentData()?.map((player) => (
										<Tr key={player.TE_PlayerId}>
											<Td
												flex="1"
												textAlign="start"
												fontSize="md"
												fontWeight="medium">
												{player.PlayerName}
											</Td>
											<Td
												flex="1"
												textAlign="start"
												fontSize="md"
												fontWeight="medium">
												{player.NickName}
											</Td>
											<Td
												flex="1"
												textAlign="start"
												fontSize="md"
												fontWeight="medium">
												{player.MobileNumber}
											</Td>
											<Td
												flex="1"
												textAlign="start"
												fontSize="md"
												fontWeight="medium">
												{player.Email}
											</Td>
											<Td
												flex="1"
												textAlign="start"
												fontSize="md"
												fontWeight="medium">
												<FormControl>
													<NumberInput
														defaultValue={player.Rank}
														min={1}
														inputMode="numeric"
														textAlign="center"
														fontSize="lg"
														w={"5vw"}
														px={2}
														keepWithinRange={true}
														onChange={(value) =>
															(player.Rank = Number(value))
														}
														onBlur={(e) =>
															updateRank(
																e.target.value,
																player.Live,
																player
															)
														}>
														<NumberInputField
															textAlign={"center"}
															padding={0}
														/>
													</NumberInput>
												</FormControl>
											</Td>
											<Td flex="1">
												<FormControl>
													<Checkbox
														size="lg"
														defaultChecked={player.Live}
														textAlign="start"
														onChange={(e) => {
															player.Live = !player.Live;
															updateRank(
																player.Rank,
																e.target.checked ? 1 : 0,
																player
															);
														}}
													/>
												</FormControl>
											</Td>
										</Tr>
								  ))}
						</Tbody>
					</Table>
				</Box>
				<Divider />
				<Stack p="1rem" bgColor="white" borderBottomRadius={"2xl"}>
					{/*---------------For Pagination-------------------------------*/}
					<ButtonGroup justifyContent={"space-between"}>
						<Button
							color="white"
							bgColor="bg-accent"
							borderRadius="full"
							onClick={savePlayers}
							isLoading={saveLoading}>
							Save Players
						</Button>
						<Button
							color="white"
							bgColor="bg-accent"
							borderRadius="full"
							onClick={() => generateGroups()}
							isLoading={props.generateLoading}>
							Generate Groups
						</Button>
					</ButtonGroup>
					{isMobile ? (
						<Stack>
							<Center>
								<Pagination
									count={singlesPlayers?.length}
									pageSize={rows}
									siblingCount={0}
									page={currentPage}
									onChange={(e) => setCurrentPage(e.page)}
								/>
							</Center>
							<HStack justify="space-between">
								<HStack spacing={1}>
									<Text as="span" whiteSpace="normal">
										Rows per page{" "}
									</Text>
									<Select
										boxSize={"-webkit-max-content"}
										minW="-webkit-fit-content"
										size="sm"
										value={rows}
										onChange={(event) => handleRows(event)}>
										{[10, 25, 50].map((rows) => (
											<option key={rows} value={rows}>
												{rows}
											</option>
										))}
									</Select>
								</HStack>
								<Text whiteSpace="normal">
									Showing {currentPage * rows - rows + 1} -{" "}
									{currentPage * rows < singlesPlayers?.length
										? currentPage * rows
										: singlesPlayers?.length}{" "}
									of {singlesPlayers?.length} Entries
								</Text>
							</HStack>
						</Stack>
					) : (
						<HStack justify="space-between" pt={3}>
							<HStack spacing={1}>
								<Text as="span" whiteSpace={"nowrap"}>
									Rows per page{" "}
								</Text>
								<Select
									boxSize={"-webkit-max-content"}
									minW="-webkit-fit-content"
									value={rows}
									onChange={(event) => handleRows(event)}>
									{[10, 25, 50].map((rows) => (
										<option key={rows} value={rows}>
											{rows}
										</option>
									))}
								</Select>
							</HStack>
							<Center>
								<Pagination
									count={singlesPlayers?.length}
									pageSize={rows}
									siblingCount={1}
									page={currentPage}
									onChange={(e) => setCurrentPage(e.page)}
								/>
							</Center>
							<Text whiteSpace="normal">
								Showing {currentPage * rows - rows + 1} -{" "}
								{currentPage * rows < singlesPlayers?.length
									? currentPage * rows
									: singlesPlayers?.length}{" "}
								of {singlesPlayers?.length} Entries
							</Text>
						</HStack>
					)}

					{/* <ButtonGroup>
							<IconButton
								icon={<AiOutlineDoubleLeft />}
								_hover={{ bgColor: "bg-accent", color: "white" }}
								_active={{ bgColor: "accent", color: "white" }}
								onClick={(e) => handleChange(e, 1)}
							/>
							<IconButton
								icon={<AiOutlineLeft />}
								_hover={{ bgColor: "bg-accent", color: "white" }}
								_active={{ bgColor: "accent", color: "white" }}
								onClick={prev}
							/>
							{Array(maxPage)
								.fill()
								.map((n, index) => {
									return (
										<Button
											key={index}
											textColor="blackAlpha.700"
											_hover={{ bgColor: "bg-accent-muted", color: "white" }}
											_active={{ bgColor: "bg-accent", color: "white" }}
											{...(index + 1 === currentPage && {
												bgColor: "accent",
												color: "white",
												_hover: { bgColor: "bg-accent", color: "white" },
												_active: { bgColor: "accent", color: "white" },
											})}
											onClick={(e) => handleChange(e, index + 1)}>
											{index + 1}
										</Button>
									);
								})}
							<IconButton
								icon={<AiOutlineRight />}
								_hover={{ bgColor: "bg-accent", color: "white" }}
								_active={{ bgColor: "accent", color: "white" }}
								onClick={next}
							/>
							<IconButton
								icon={<AiOutlineDoubleRight />}
								_hover={{ bgColor: "bg-accent", color: "white" }}
								_active={{ bgColor: "accent", color: "white" }}
								onClick={(e) => handleChange(e, maxPage)}
							/>
						</ButtonGroup> */}
				</Stack>
			</Box>
		);
	};

	// const tableMobile = () => (
	//   <Box>
	//     {singlesPlayers.map((player) => (
	//       <Container
	//         mb="1rem"
	//         bgColor="white"
	//         boxShadow={"sm"}
	//         borderRadius="lg"
	//         py="1rem"
	//         px="1.5rem"
	//       >
	//         <Stack spacing={5} divider={<Divider />} fontSize="lg">
	//           <HStack justify="space-between">
	//             <Text fontWeight="bold">Player</Text>
	//             <Text>{player.PlayerName}</Text>
	//           </HStack>
	//           <HStack justify="space-between">
	//             <Text fontWeight="bold">Event</Text>
	//             <Text>{player.TourEventName}</Text>
	//           </HStack>
	//           <HStack justify="space-between">
	//             <Text fontWeight="bold">Nick Name</Text>
	//             <Text>{player.NickName}</Text>
	//           </HStack>
	//           <HStack justify="space-between">
	//             <Text fontWeight="bold">Mobile</Text>
	//             <Text>{player.MobileNumber}</Text>
	//           </HStack>
	//           <HStack justify="space-between">
	//             <Text fontWeight="bold">Email</Text>
	//             <Text>{player.Email}</Text>
	//           </HStack>
	//           <HStack justify="space-between">
	//             <FormControl>
	//               <Input
	//                 placeholder={player.Rank}
	//                 textAlign="start"
	//                 fontSize="lg"
	//               />
	//             </FormControl>
	//             <FormControl>
	//               <Input
	//                 type="checkbox"
	//                 value={player.Live}
	//                 textAlign="start"
	//                 fontSize="lg"
	//               />
	//             </FormControl>
	//           </HStack>

	//           <Stack
	//             direction={["column", "row"]}
	//             justify="space-between"
	//             pt="1rem"
	//           >
	//             {/*-----------For Pagination----------------------*/}
	//           </Stack>
	//         </Stack>
	//       </Container>
	//     ))}
	//   </Box>
	// );

	return tableDesktop();
};

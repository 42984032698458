import {
	HStack,
	Table,
	Tbody,
	Td,
	Box,
	Tr,
	Th,
	Thead,
	Select,
	useBreakpointValue,
	Text,
	Stack,
	Divider,
	useColorModeValue,
	Center,
	Skeleton,
} from "@chakra-ui/react";
import { useState } from "react";
import usePagination from "../../../hooks/usePagination";
import { Pagination } from "../../Pagination/Pagination";

export const LeaderboardTable = ({ leaderboardData, leaderLoad }) => {
	//Desktop Breakpoint Value
	const isDesktop = useBreakpointValue({ base: false, xl: true });
	// Mobile Breakpoint Value
	const isMobile = useBreakpointValue({
		base: true,
		md: false,
	});
	// Tablet Breakpoint Value
	const isTablet = useBreakpointValue({
		base: false,
		sm: false,
		md: true,
		lg: false,
		xl: false,
	});
	//Box Shadow
	const shadow = useColorModeValue("sm", "sm-dark");

	// Pagination
	let [rows, setRows] = useState(10);
	const perPage = rows;

	const {
		currentPage,
		currentData,
		jump,
		prev,
		next,
		first,
		last,
		setLast,
		setCurrentPage,
		maxPage,
	} = usePagination(
		leaderboardData?.sort((a, b) => a.Position - b.Position),
		perPage
	);

	const handleChange = (e, p) => {
		jump(p);
	};

	const handleRows = (event) => {
		setRows(Number(event.target.value));
		setLast(Number(event.target.value));
		setCurrentPage(1);
		// 	setParamTwo(Number(event.target.value));
	};

	// const previousPage = (event) => {
	// 	setParamTwo(Number(paramOne));
	// 	setParamOne(Number(paramTwo - rows));
	// };
	// const nextPage = (event) => {
	// 	setParamOne(Number(paramTwo));
	// 	setParamTwo(Number(paramTwo + rows));
	// };

	const tableDesktop = () => (
		<Box>
			<Box
				borderRadius={"2xl"}
				overflow="auto"
				boxShadow={shadow}
				css={{
					"::-webkit-scrollbar": {
						width: "0.3rem",
						height: "0.3rem",
					},
					"::-webkit-scrollbar-track": {
						background: "#bee3f8",
						borderRadius: "1rem",
					},
					"::-webkit-scrollbar-thumb": {
						background: "#3182ce",
						borderRadius: "1rem",
					},
				}}>
				<Table variant="striped">
					<Thead position="sticky" top={0} bgColor="black" zIndex="2">
						<Tr>
							<Th
								textColor="white"
								fontSize={"md"}
								px={3}
								py={4}
								textAlign={isTablet ? "end" : "center"}
								{...(isMobile && {
									px: 1,
									maxW: "-webkit-fit-content",
									fontSize: "xs",
									py: 5,
								})}
								{...(isTablet && {
									maxW: "-webkit-fit-content",
									px: 1,
									fontSize: "sm",
								})}>
								Position
							</Th>
							<Th
								textColor="white"
								fontSize={isTablet ? "sm" : "md"}
								px={3}
								{...(isMobile && { px: 1, fontSize: "xs" })}
								textAlign={"start"}>
								Player
							</Th>
						</Tr>
					</Thead>
					<Tbody>
						{leaderLoad
							? Array(5)
									.fill()
									.map((rows, index) => (
										<Tr key={index}>
											<Td>
												<Skeleton
													isLoaded={!leaderLoad}
													h={"1.2rem"}
													w={"100%"}
												/>
											</Td>
											<Td>
												<Skeleton
													isLoaded={!leaderLoad}
													h={"1.2rem"}
													w={"100%"}
												/>
											</Td>
										</Tr>
									))
							: currentData()?.map((board) => (
									<Tr key={board.Position}>
										<Td
											textAlign={"center"}
											fontSize={isTablet ? "md" : "lg"}
											fontWeight="medium"
											{...(isMobile && {
												px: 1.5,
												maxW: "-webkit-fit-content",
												fontSize: "sm",
											})}
											{...(isTablet && {
												px: 2,
												maxW: "-webkit-fit-content",
											})}>
											{board.Position}
										</Td>
										<Td
											textAlign="start"
											fontSize={"lg"}
											fontWeight="medium"
											{...(isMobile && {
												px: 1,
												fontSize: "sm",
												maxW: "-webkit-fit-content",
											})}
											{...(isTablet && { px: 2 })}
											maxW="-webkit-fit-content">
											{board.TeamName}
										</Td>
									</Tr>
							  ))}
					</Tbody>
				</Table>
			</Box>
			<Divider />
			<Stack p="1rem" bgColor="white" borderBottomRadius={"2xl"}>
				{/*---------------For Pagination-------------------------------*/}
				{isMobile ? (
					<Stack>
						<Center>
							<Pagination
								count={leaderboardData?.length}
								pageSize={rows}
								siblingCount={0}
								page={currentPage}
								onChange={(e) => setCurrentPage(e.page)}
							/>
						</Center>
						<HStack justify="space-between">
							<HStack spacing={1}>
								<Text as="span" whiteSpace="normal">
									Rows per page{" "}
								</Text>
								<Select
									boxSize={"-webkit-max-content"}
									minW="-webkit-fit-content"
									size="sm"
									value={rows}
									onChange={(event) => handleRows(event)}>
									{[10, 25, 50].map((rows) => (
										<option key={rows} value={rows}>
											{rows}
										</option>
									))}
								</Select>
							</HStack>
							<Text whiteSpace="normal">
								Showing {currentPage * rows - rows + 1} -{" "}
								{currentPage * rows < leaderboardData?.length
									? currentPage * rows
									: leaderboardData?.length}{" "}
								of {leaderboardData?.length} Entries
							</Text>
						</HStack>
					</Stack>
				) : (
					<HStack justify="space-between">
						<HStack spacing={1}>
							<Text as="span" whiteSpace={"nowrap"}>
								Rows per page{" "}
							</Text>
							<Select
								boxSize={"-webkit-max-content"}
								minW="-webkit-fit-content"
								value={rows}
								onChange={(event) => handleRows(event)}>
								{[10, 25, 50].map((rows) => (
									<option key={rows} value={rows}>
										{rows}
									</option>
								))}
							</Select>
						</HStack>
						<Center>
							<Pagination
								count={leaderboardData?.length}
								pageSize={rows}
								siblingCount={1}
								page={currentPage}
								onChange={(e) => setCurrentPage(e.page)}
							/>
						</Center>
						<Text whiteSpace="normal">
							Showing {currentPage * rows - rows + 1} -{" "}
							{currentPage * rows < leaderboardData?.length
								? currentPage * rows
								: leaderboardData?.length}{" "}
							of {leaderboardData?.length} Entries
						</Text>
					</HStack>
				)}
			</Stack>
		</Box>
	);

	// const tableMobile = () => (
	// 	<Container
	// 		bgColor="white"
	// 		boxShadow={"sm"}
	// 		borderRadius="lg"
	// 		py="1rem"
	// 		px="1.5rem">
	// 		<Stack spacing={5} divider={<Divider />} fontSize="lg">
	// 			<HStack justify="space-between">
	// 				<Text fontWeight="bold">{data[0].id}</Text>
	// 				<Text>{data[0].player}</Text>
	// 			</HStack>
	// 			<Stack>
	// 				<HStack justify="space-between">
	// 					<Text fontWeight="bold">Played</Text>
	// 					<Text>{data[0].played}</Text>
	// 				</HStack>
	// 				<HStack justify="space-between">
	// 					<Text fontWeight="bold">Points</Text>
	// 					<Text>{data[0].points}</Text>
	// 				</HStack>
	// 				<HStack justify="space-between">
	// 					<Text fontWeight="bold">O/S</Text>
	// 					<Text>{data[0].os}</Text>
	// 				</HStack>
	// 				<HStack justify="space-between">
	// 					<Text fontWeight="bold">Net Diff</Text>
	// 					<Text>{data[0].netdiff}</Text>
	// 				</HStack>
	// 			</Stack>
	// 			<Stack direction={["column", "row"]} justify="space-between" pt="1rem">
	// 				{/*-----------For Pagination----------------------*/}
	// 			</Stack>
	// 		</Stack>
	// 	</Container>
	// );

	return tableDesktop();
};

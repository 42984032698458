import React, { useState } from "react";
function usePagination(data, itemsPerPage) {
	const [currentPage, setCurrentPage] = useState(1);
	const maxPage = Math.ceil(data?.length / itemsPerPage);
	const [first, setFirst] = useState(1);
	const [last, setLast] = useState(10);
	function currentData() {
		const begin = (currentPage - 1) * itemsPerPage;
		const end = begin + itemsPerPage;
		return data?.slice(begin, end);
	}
	function next() {
		setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage));
		setFirst(itemsPerPage);
		setLast(itemsPerPage);
	}
	function prev() {
		setCurrentPage((currentPage) => Math.max(currentPage - 1, 1));
		setFirst(itemsPerPage);
		setLast(itemsPerPage);
	}
	function jump(page) {
		const pageNumber = Math.max(1, page);
		setCurrentPage((currentPage) => Math.min(pageNumber, maxPage));
		setFirst(itemsPerPage);
		setLast(itemsPerPage);
	}
	return {
		next,
		prev,
		jump,
		currentData,
		currentPage,
		maxPage,
		first,
		last,
		setLast,
		setCurrentPage,
	};
}
export default usePagination;

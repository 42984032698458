import {
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useDisclosure,
	FormControl,
	FormLabel,
	Input,
	Stack,
	Select,
	useBreakpointValue,
	ButtonGroup,
	IconButton,
	FormErrorMessage,
	useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ApiService from "../../../service/Api.service";
import { TbActions } from "../../../icons/Icons";
import { useAuthProvider } from "../../../context/AuthProvider";

export const DoublesEditTeamModal = (props) => {
	// User Details
	const user = useAuthProvider();

	let TournamentId = props.TournamentId;
	const { isOpen, onOpen, onClose } = useDisclosure();
	let [dpLookup, setDPLookup] = useState([]);

	let toast = useToast();

	let [selectedPlayer1, setSelectedPlayer1] = useState("");
	let [selectedPlayer2, setSelectedPlayer2] = useState("");
	const [editLoading, setEditLoading] = useState(false);
	const [saveLoading, setSaveLoading] = useState(false);

	let teamsReload = props.teamsReload;
	let setTeamsReload = props.setTeamsReload;

	const {
		register,
		setValue,
		getValues,
		formState: { isDirty, isValid, errors },
	} = useForm({
		delayError: "500",
		mode: "all",
	});

	const onSubmit = (data) => {
		console.log(data);
		let EditTeamObj = {
			DETeamId: props.player.DETeamId,
			TourPlayer1Id: data.TourPlayer1Id,
			TourPlayer2Id: data.TourPlayer2Id,
			Rank: data.Rank,
			TeamName: data.TeamName,
		};
		console.log(EditTeamObj);
		EditTeam(EditTeamObj);
	};

	let EditTeam = async (reqObj) => {
		try {
			setSaveLoading(true);
			const member = await ApiService.httpPut(
				`/DoublesEventTeams?DETeamId=${props.player.DETeamId}`,
				reqObj
			);
			console.log(member);
			onClose();
			if (member.status === true) {
				toast({
					title: "Update Team!",
					description: "Team Updated Successfully",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			} else {
				toast({
					title: "Update Team!",
					description: "Failed to Update Team",
					status: "warning",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
			setSaveLoading(false);
			setTeamsReload(!teamsReload);
		} catch (err) {
			console.error(err.message);
			toast({
				title: "Update Team!",
				description: "Failed to Update Team",
				status: "warning",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
		}
	};

	const fetchDPLookup = async () => {
		try {
			setEditLoading(true);
			let dpLookup = await ApiService.httpGet(
				`/TourPlayers?EventId=${props.EventId}`,
				user.userId
			);
			console.log("Doubles Players List:", dpLookup.Players);
			setDPLookup(dpLookup.Players);
			setEditLoading(false);
			setValue("TourPlayer1Id", props.player.TourPlayer1Id);
			setValue("TourPlayer2Id", props.player.TourPlayer2Id);
			setSelectedPlayer1(props.player.TourPlayer1Id);
			setSelectedPlayer2(props.player.TourPlayer2Id);
			setValue("TeamName", props.player.TeamName);
			setValue("Rank", props.player.Rank);
			onOpen();
		} catch (err) {
			console.log(err);
			setEditLoading(false);
		}
	};

	return (
		<>
			<IconButton
				icon={<TbActions />}
				mb={1}
				onClick={() => {
					fetchDPLookup();
				}}
				isLoading={editLoading}
			/>
			<Modal
				isOpen={isOpen}
				size={useBreakpointValue({
					base: "sm",
					sm: "lg",
					md: "2xl",
					lg: "4xl",
				})}
				onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader textAlign="center">Edit Team</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<form>
							<Stack spacing={useBreakpointValue({ base: "1rem", md: "1.5rem" })}>
								<Stack
									spacing={useBreakpointValue({ base: "1rem", md: "1.5rem" })}
									direction={useBreakpointValue({
										base: "column",
										sm: "row",
									})}>
									<FormControl id="Player1">
										<FormLabel>Player 1</FormLabel>
										<Select
											name="Player1"
											colorScheme="blue"
											placeholder="Select Player 1"
											{...register("TourPlayer1Id", {
												required:
													"Please select a player from the dropdown",
												onChange: (e) => {
													setSelectedPlayer1(e.target.value);
												},
											})}>
											{props.player.TourPlayer1Id !== selectedPlayer2 && (
												<option value={props.player.TourPlayer1Id}>
													{props.player.Player1Name}
												</option>
											)}
											{props.player.TourPlayer2Id !== selectedPlayer2 && (
												<option value={props.player.TourPlayer2Id}>
													{props.player.Player2Name}
												</option>
											)}
											{dpLookup
												?.filter(
													(player) =>
														player.TourPlayerId !== selectedPlayer2
												)
												.map((player, index) => {
													return (
														<option
															key={player.TourPlayerId}
															value={player.TourPlayerId}>
															{player.PlayerName}
														</option>
													);
												})}
										</Select>
									</FormControl>
									<FormControl id="Player2">
										<FormLabel>Player 2</FormLabel>
										<Select
											name="Player2"
											colorScheme="blue"
											placeholder="Select Player 2"
											{...register("TourPlayer2Id", {
												required:
													"Please select a player from the dropdown",
												onChange: (e) => {
													setSelectedPlayer2(e.target.value);
												},
											})}>
											{props.player.TourPlayer1Id !== selectedPlayer1 && (
												<option value={props.player.TourPlayer1Id}>
													{props.player.Player1Name}
												</option>
											)}
											{props.player.TourPlayer2Id !== selectedPlayer1 && (
												<option value={props.player.TourPlayer2Id}>
													{props.player.Player2Name}
												</option>
											)}
											{dpLookup
												?.filter(
													(player) =>
														player.TourPlayerId !== selectedPlayer1
												)
												.map((player, index) => {
													return (
														<option
															key={player.TourPlayerId}
															value={player.TourPlayerId}>
															{player.PlayerName}
														</option>
													);
												})}
										</Select>
									</FormControl>
								</Stack>
								<Stack
									spacing={useBreakpointValue({ base: "1rem", md: "1.5rem" })}
									direction={useBreakpointValue({
										base: "column",
										sm: "row",
									})}>
									<FormControl id="TeamName" isInvalid={errors.TeamName}>
										<FormLabel>Team Name</FormLabel>
										<Input
											type="text"
											{...register("TeamName", {
												required: "Please enter Team Name",
												pattern: {
													value: /^[A-Za-z0-9 &]+$/,
													message:
														"Team Name can be alphanumeric and can contain ampersand and whitespaces",
												},
												minLength: {
													value: 2,
													message: "Team Name can't be this short",
												},
											})}
										/>
										<FormErrorMessage>
											{errors.TeamName && errors.TeamName.message}
										</FormErrorMessage>
									</FormControl>
									<FormControl id="Rank">
										<FormLabel>Rank</FormLabel>
										<Input
											type="number"
											{...register("Rank", {
												min: 0,
											})}
										/>
									</FormControl>
								</Stack>
							</Stack>
						</form>
					</ModalBody>

					<ModalFooter>
						<ButtonGroup spacing="5">
							{!saveLoading && (
								<Button
									onClick={onClose}
									size="lg"
									borderRadius="full"
									colorScheme="red">
									Cancel
								</Button>
							)}
							<Button
								type="submit"
								size="lg"
								borderRadius="full"
								colorScheme="blue"
								onClick={(e) => onSubmit(getValues())}
								disabled={!isDirty || !isValid}
								isLoading={saveLoading}>
								Save
							</Button>
						</ButtonGroup>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
};

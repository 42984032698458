import {
	HStack,
	Image,
	Table,
	Tbody,
	Td,
	Box,
	Tr,
	Th,
	Thead,
	VStack,
	FormControl,
	Select,
	Input,
	TableContainer,
	useBreakpointValue,
	Container,
	Text,
	Stack,
	Divider,
	useColorModeValue,
	FormLabel,
	IconButton,
	Icon,
	position,
	ButtonGroup,
	Button,
	Flex,
} from "@chakra-ui/react";
import { GiTrophy } from "react-icons/gi";
import { data } from "./data";

export const MatchupsResultsTable = ({ matchupsData }) => {
	//Desktop BreakPoint Value
	const isDesktop = useBreakpointValue({ base: false, xl: true });
	// Mobile Breakpoint Value
	const isMobile = useBreakpointValue({
		base: true,
		sm: true,
		md: false,
		lg: false,
		xl: false,
	});
	// Tablet Breakpoint Value
	const isTablet = useBreakpointValue({
		base: false,
		sm: false,
		md: true,
		lg: false,
		xl: false,
	});
	//Box Shadow
	const shadow = useColorModeValue("sm", "sm-dark");

	const tableDesktop = () => (
		<Box>
			<Box boxShadow={shadow} borderTopRadius={"2xl"}>
				<Table variant="striped">
					<Thead position="sticky" top={0} bgColor="black" zIndex="2">
						<Tr>
							<Th
								textColor="white"
								fontSize={"md"}
								borderTopLeftRadius={"2xl"}
								textAlign={isTablet ? "end" : "center"}
								px={3}
								py={4}
								{...(isMobile && {
									px: 1.5,
									maxW: "1rem",
									fontSize: "xs",
									py: 3,
								})}
								{...(isTablet && {
									maxW: "-webkit-fit-content",
									px: 1,
									fontSize: "sm",
								})}>
								{isMobile ? "#" : "Board"}
							</Th>
							<Th
								textColor="white"
								fontSize={isTablet ? "sm" : "md"}
								px={5}
								{...(isMobile && { px: 2, fontSize: "xs" })}
								textAlign={"start"}>
								Player 1
							</Th>
							<Th
								textColor="white"
								fontSize={isTablet ? "sm" : "md"}
								px={3}
								{...(isMobile && { px: 1, fontSize: "xs" })}
								textAlign={"center"}>
								Score
							</Th>
							<Th
								textColor="#4E4EE9"
								fontSize="xl"
								{...(isMobile && { px: 2 })}
								textAlign="center"></Th>
							<Th
								textColor="white"
								fontSize={isTablet ? "sm" : "md"}
								px={5}
								{...(isMobile && { px: 2, fontSize: "xs" })}
								textAlign={"start"}>
								Player 2
							</Th>
							<Th
								textColor="white"
								fontSize={isTablet ? "sm" : "md"}
								borderTopRightRadius={"2xl"}
								px={3}
								{...(isMobile && { px: 1, fontSize: "xs" })}
								textAlign={"center"}>
								Score
							</Th>
						</Tr>
					</Thead>
					<Tbody>
						{matchupsData.map((board) => (
							<Tr key={board.MatchupId}>
								<Td
									textAlign={"center"}
									fontSize={isTablet ? "md" : "lg"}
									fontWeight="medium"
									{...(isMobile && {
										px: 1.5,
										maxW: "-webkit-fit-content",
										fontSize: "sm",
									})}
									{...(isTablet && { px: 2, maxW: "-webkit-fit-content" })}>
									{board.Venue}
								</Td>
								<Td
									textAlign={"start"}
									fontSize={"lg"}
									fontWeight="medium"
									{...(isMobile && {
										px: 1,
										fontSize: "sm",
										maxW: "-webkit-fit-content",
									})}
									{...(isTablet && { px: 2 })}
									maxW="-webkit-fit-content"
									whiteSpace={"break-spaces"}>
									<HStack justify={"space-between"}>
										<Text>{board.Player1}</Text>
										{board.isPlayer1Won === "True" && <Icon as={GiTrophy} />}
									</HStack>
								</Td>
								<Td
									textAlign="center"
									fontSize={"lg"}
									fontWeight="medium"
									{...(isMobile && {
										px: 1,
										fontSize: "sm",
										maxW: "-webkit-fit-content",
									})}
									{...(isTablet && { px: 2 })}
									maxW="-webkit-fit-content">
									{board.Team1Score}
								</Td>
								<Td
									textColor="accent"
									textAlign="center"
									fontSize={"xl"}
									fontWeight="medium"
									{...(isMobile && {
										px: 2,
										fontSize: "lg",
										maxW: "-webkit-fit-content",
									})}
									{...(isTablet && { px: 3 })}
									maxW="-webkit-fit-content">
									vs
								</Td>
								<Td
									textAlign={"start"}
									fontSize={"lg"}
									fontWeight="medium"
									{...(isMobile && {
										px: 1,
										fontSize: "sm",
										maxW: "-webkit-fit-content",
									})}
									{...(isTablet && { px: 2 })}
									maxW="-webkit-fit-content"
									whiteSpace={"break-spaces"}>
									<HStack justify={"space-between"}>
										<Text>{board.Player2}</Text>
										{board.isPlayer2Won === "True" && <Icon as={GiTrophy} />}
									</HStack>
								</Td>
								<Td
									textAlign="center"
									fontSize={"lg"}
									fontWeight="medium"
									{...(isMobile && {
										px: 1,
										fontSize: "sm",
										maxW: "-webkit-fit-content",
									})}
									{...(isTablet && { px: 2 })}
									maxW="-webkit-fit-content">
									{board.Team2Score}
								</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</Box>
			<HStack
				justify="space-between"
				p="1rem"
				bgColor="white"
				borderBottomRadius={"2xl"}>
				{/*---------------For Pagination-------------------------------*/}
			</HStack>
		</Box>
	);

	// const tableMobile = () => (
	// 	<Container
	// 		bgColor="white"
	// 		boxShadow={shadow}
	// 		borderRadius="lg"
	// 		py="1rem"
	// 		px="1.5rem">
	// 		<Stack spacing={5} divider={<Divider />} fontSize="lg">
	// 			<HStack justify="space-between">
	// 				<Text fontWeight="bold">Board</Text>
	// 				<Text>{data[0].board}</Text>
	// 			</HStack>
	// 			<Stack>
	// 				<HStack justify="space-between">
	// 					<Text fontWeight="bold">Player 1</Text>
	// 					<Text>{data[0].player1}</Text>
	// 				</HStack>
	// 				<HStack justify="space-between">
	// 					<Text fontWeight="bold">Score</Text>
	// 					<Text>{data[0].score1}</Text>
	// 				</HStack>
	// 			</Stack>
	// 			<Flex justify="center">
	// 				<Text
	// 					textColor="#4E4EE9"
	// 					textAlign="center"
	// 					fontSize="xl"
	// 					fontWeight="bold">
	// 					Vs
	// 				</Text>
	// 			</Flex>
	// 			<Stack>
	// 				<HStack justify="space-between">
	// 					<Text fontWeight="bold">Player 2</Text>
	// 					<Text>{data[0].player2}</Text>
	// 				</HStack>
	// 				<HStack justify="space-between">
	// 					<Text fontWeight="bold">Score</Text>
	// 					<Text>{data[0].score2}</Text>
	// 				</HStack>
	// 			</Stack>

	// 			<Stack direction={["column", "row"]} justify="space-between" pt="1rem">
	// 				{/*-----------For Pagination----------------------*/}
	// 			</Stack>
	// 		</Stack>
	// 	</Container>
	// );

	return tableDesktop();
};

import {
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useDisclosure,
	HStack,
	FormControl,
	FormLabel,
	Input,
	Stack,
	Select,
	useBreakpointValue,
	ButtonGroup,
	useToast,
	FormErrorMessage,
	Slider,
	Box,
} from "@chakra-ui/react";
import Multiselect from "multiselect-react-dropdown";
import { Controller, useForm } from "react-hook-form";
import ApiService from "../../../../service/Api.service";
import { ShimmerBadge } from "react-shimmer-effects-18";
import { useState, useRef } from "react";
import uploadImageFile from "../../../Upload/uploadImageFile";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { isValidPhoneNumber } from "libphonenumber-js";
import ReactCrop from "react-image-crop";
import Cropper from "react-easy-crop";

export const AddPlayerModal = (props) => {
	const { isOpen, onOpen, onClose, setIsOpen } = useDisclosure();

	const {
		register,
		setValue,
		getValues,
		handleSubmit,
		reset,
		control,
		formState: { isDirty, isValid, errors },
	} = useForm({
		delayError: "500",
		mode: "all",
	});

	let toast = useToast();
	const formRef = useRef(null);

	// BreakPoint Values
	const modalSmall = useBreakpointValue({
		base: "sm",
		sm: "lg",
		md: "2xl",
	});
	const modalFull = useBreakpointValue({
		base: "sm",
		sm: "lg",
		md: "2xl",
		lg: "4xl",
		xl: "5xl",
	});

	// States
	const [phoneNo, setPhoneNo] = useState({});
	const [error, setError] = useState("");
	const [phoneStatus, setPhoneStatus] = useState(false);
	const [detailsStatus, setDetailsStatus] = useState(false);
	const [selectedImage, setSelectedImage] = useState(null);
	const [isModalOpen, setModalOpen] = useState(false);

	const onOpenAddView = () => {
		setError("");
		setPhoneNo("");
		setPhoneStatus(true);
		props.eventData.length === 1 && setValue("EventId", props.eventData[0].TourEventId);
		onOpen();
	};

	// State for File that needs to be uploaded
	let [file, setFile] = useState(null);

	// setFile that needs to be uploaded
	const handleFileChange = (event) => {
		const file = event.target.files[0];
		if (file) {
			setSelectedImage(URL.createObjectURL(file));
			setFile(file);
			setOpenCrop(true);
		}
	};

	const [loading, setLoading] = useState(false);

	const onSubmit = async (data) => {
		console.log(data);
		let AddPlayerObj = {
			TourId: props.tourId,
			FirstName: data.FirstName,
			LastName: data.LastName,
			NickName: data.NickName,
			Email: data.Email,
			PhoneNo: "+" + data.PhoneNo,
			DispPhoneNo: phoneNo.formattedNumber,
			Gender: data.Gender,
			PrefLanguage: data.PrefLanguage,
			EventId: data.EventId,
			ImageLink: selectedImage[0]
				? data.PhoneNo + "_" + Date.now() + ".jpg"
				: data.ImageLink[0]
				? data.PhoneNo + "_" + Date.now() + "." + data.ImageLink[0].type.split("/").pop()
				: "",
		};
		console.log(AddPlayerObj);
		await AddPlayer(AddPlayerObj);
	};

	let AddPlayer = async (reqObj) => {
		try {
			setLoading(true);
			if (
				reqObj.ImageLink !== "" ||
				reqObj.ImageLink !== null ||
				reqObj.ImageLink !== undefined
			) {
				// Upload the file to Azure Blob
				if (selectedImage) {
					const folderName = file && "Players";
					let { handleUpload } = uploadImageFile(
						croppedImg,
						toast,
						folderName,
						reqObj.ImageLink
					);
					await handleUpload();
				}
			}
			const player = await ApiService.httpPost(`/TourPlayers`, reqObj);
			if (player.status === true) {
				toast({
					title: "Add Player!",
					description: "Player Added Successfully",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				setFile(null);
				props.refreshView();
				onClose();
				reset();
			} else if (player.status === false) {
				toast({
					title: "Add Player!",
					description: player.Message,
					status: "warning",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			} else {
				toast({
					title: "Add Player!",
					description: "Failed to Add Player",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
		} catch (err) {
			console.log(err);
			toast({
				title: "Add Player!",
				description: "Failed to Add Player",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
		} finally {
			setLoading(false);
		}
	};

	let handleSelection = (event) => {
		console.log(event);
		let selectedArr = event.map((selectedValue) => selectedValue.TourEventId);
		setValue("EventId", selectedArr.join(","));
	};

	const verifyPhone = async () => {
		if (phoneNo.number?.length < 1) {
			setError("Please enter your Phone Number");
		} else if (!isValidPhoneNumber("+" + phoneNo.number, phoneNo.countryCode)) {
			console.log(isValidPhoneNumber("+" + phoneNo.number, phoneNo.countryCode));
			setError("Please enter a valid Phone Number");
		} else {
			setError("");
			try {
				let reqObj = {
					PhoneNo: "+" + phoneNo.number,
					TourId: props.tourId,
				};
				let verifyRes = await ApiService.httpPut(
					`/TourPlayers?TourId=${props.tourId}`,
					reqObj
				);
				if (verifyRes.status === false && verifyRes.ExistinginTour === true) {
					toast({
						title: "Verify Phone Number",
						description: verifyRes.Message,
						status: "warning",
						duration: 3000,
						isClosable: true,
						position: "top-right",
					});
				} else if (verifyRes.status === false && verifyRes.ExistinginTour === false) {
					setPhoneStatus(false);
					setValue("PhoneNo", phoneNo.number);
				} else if (verifyRes.status === true) {
					setPhoneStatus(false);
					setDetailsStatus(true);
					setValue("PhoneNo", phoneNo.number);
					setValue("Email", verifyRes?.Players[0]?.Email);
					setValue("FirstName", verifyRes?.Players[0]?.FirstName);
					setValue("LastName", verifyRes?.Players[0]?.LastName);
					setValue("NickName", verifyRes?.Players[0]?.NickName);
					toast({
						title: "Verify Phone Number",
						description: verifyRes.Message,
						status: "success",
						duration: 3000,
						isClosable: true,
						position: "top-right",
					});
				}
			} catch (err) {
				console.log(err);
			}
		}
	};
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const [openCrop, setOpenCrop] = useState(false);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
	const [croppedImg, setCroppedImg] = useState(null);

	const onCropComplete = (croppedArea, croppedAreaPixels) => {
		setCroppedAreaPixels(croppedAreaPixels);
		console.log(croppedArea, croppedAreaPixels);
	};
	const cropImage = async () => {
		if (file && croppedAreaPixels) {
			try {
				const { file, url } = await getCroppedImg(selectedImage, croppedAreaPixels);
				setSelectedImage(url);
				setCroppedImg(file);
				setImageOpen(true);
				setOpenCrop(false);
				return { file, url };
			} catch (error) {
				console.log("error", error);
			}
		}
		return null;
	};

	const createImage = (url) =>
		new Promise((resolve, reject) => {
			const image = new Image();
			image.addEventListener("load", () => resolve(image));
			image.addEventListener("error", (error) => reject(error));
			image.setAttribute("crossOrigin", "anonymous");
			image.src = url;
		});

	function getRadianAngle(degreeValue) {
		return (degreeValue * Math.PI) / 180;
	}

	function rotateSize(width, height, rotation) {
		const rotRad = getRadianAngle(rotation);

		return {
			width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
			height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
		};
	}
	const getCroppedImg = async (
		imageSrc,
		pixelCrop,
		rotation = 0,
		flip = { horizontal: false, vertical: false }
	) => {
		const image = await createImage(imageSrc);
		const canvas = document.createElement("canvas");
		const ctx = canvas.getContext("2d");

		if (!ctx) {
			return null;
		}

		const rotRad = getRadianAngle(rotation);

		// calculate bounding box of the rotated image
		const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
			image.width,
			image.height,
			rotation
		);
		// set canvas size to match the bounding box
		canvas.width = bBoxWidth;
		canvas.height = bBoxHeight;
		// translate canvas context to a central location to allow rotating and flipping around the center
		ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
		ctx.rotate(rotRad);
		ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
		ctx.translate(-image.width / 2, -image.height / 2);
		// draw rotated image
		ctx.drawImage(image, 0, 0);
		// croppedAreaPixels values are bounding box relative
		// extract the cropped image using these values
		const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);

		// set canvas width to final desired crop size - this will clear existing context
		canvas.width = pixelCrop.width;
		canvas.height = pixelCrop.height;

		// paste generated rotate image at the top left corner
		ctx.putImageData(data, 0, 0);

		// As Base64 string
		// return canvas.toDataURL('image/jpeg');

		// As a blob
		return new Promise((resolve, reject) => {
			canvas.toBlob((file) => {
				file.name = "cropped.jpeg";
				resolve({ file: file, url: URL.createObjectURL(file) });
			}, "image/jpeg");
		});
	};

	const handleImageCropModalOpen = () => {
		setModalOpen(true);
	};
	const handleImageCropModalClose = () => {
		console.log("Close button clicked");
		setImageOpen(false);
	};
	const [imageOpen, setImageOpen] = useState(false);
	const openImage = () => {
		setImageOpen(true);
	};

	return (
		<>
			{props.loading ? (
				<ShimmerBadge width={100} />
			) : (
				<Button
					textColor="#2b6cb0"
					w="8rem"
					bgColor="#bee3f8"
					borderRadius="full"
					onClick={onOpenAddView}>
					Add Player
				</Button>
			)}
			<Modal
				isOpen={isOpen}
				isCentered
				closeOnOverlayClick={false}
				size={phoneStatus ? modalSmall : modalFull}
				onClose={() => {
					setError("");
					setPhoneNo("");
					setPhoneStatus(true);
					setDetailsStatus(false);
					onClose();
					setFile(null);
					setSelectedImage(null);
					setCroppedImg(null);
					setCroppedAreaPixels(null);
					reset();
				}}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader textAlign="center">Add Player</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						{phoneStatus ? (
							<form
								onSubmit={(event) => {
									event.preventDefault();
									verifyPhone();
								}}>
								<FormControl isInvalid={!!error}>
									<FormLabel>Phone Number</FormLabel>
									<HStack>
										<PhoneInput
											country={"us"}
											placeholder={""}
											onChange={(number, country, e, formatted) =>
												setPhoneNo({
													number: number,
													formattedNumber: formatted,
													countryCode: country.countryCode,
												})
											}
											preferredCountries={["us", "ca", "in", "ae"]}
											preserveOrder={["preferredCountries"]}
											inputStyle={{
												paddingTop: "0",
												paddingBottom: "0",
												height: "2.5rem",
												width: "-webkit-fill-available",
											}}
											autoFormat={true}
											specialLabel={false}
											searchClass="search-class"
											searchPlaceholder="Search"
											searchStyle={{
												margin: "0",
												width: "97%",
												height: "2rem",
											}}
											enableSearch
											disableSearchIcon
											countryCodeEditable={true}
											enableLongNumbers={true}
										/>
										<Button
											type={"submit"}
											size="md"
											variant="solid"
											colorScheme="green">
											Add
										</Button>
									</HStack>
									{!error && (
										<FormLabel>
											Country code followed by Phone Number (Eg: +15551234567)
										</FormLabel>
									)}
									<FormErrorMessage>{error}</FormErrorMessage>
								</FormControl>
							</form>
						) : (
							<form onSubmit={handleSubmit(onSubmit)}>
								<Stack spacing={{ base: "1rem", md: "1.5rem" }}>
									<Stack
										spacing={{ base: "1rem", md: "1.5rem" }}
										direction={{
											base: "column",
											sm: "row",
										}}>
										<FormControl id="phone" isInvalid={errors.PhoneNo}>
											<FormLabel>Phone Number</FormLabel>
											<Controller
												name="PhoneNo"
												control={control}
												rules={{ required: "Please enter a Phone Number" }}
												render={({ field: { onChange, value } }) => (
													<PhoneInput
														disabled={!phoneStatus}
														country={"us"}
														placeholder={""}
														onChange={onChange}
														value={value}
														preferredCountries={[
															"us",
															"ca",
															"in",
															"ae",
														]}
														preserveOrder={["preferredCountries"]}
														inputStyle={{
															paddingTop: "0",
															paddingBottom: "0",
															height: "2.5rem",
															width: "-webkit-fill-available",
														}}
														autoFormat={true}
														specialLabel={false}
														searchClass="search-class"
														searchPlaceholder="Search"
														searchStyle={{
															margin: "0",
															width: "97%",
															height: "2rem",
														}}
														enableSearch
														disableSearchIcon
														countryCodeEditable={true}
														enableLongNumbers={true}
													/>
												)}
											/>
											<FormErrorMessage>
												{errors.PhoneNo && errors.PhoneNo.message}
											</FormErrorMessage>
										</FormControl>
										<FormControl id="email" isInvalid={errors.Email}>
											<FormLabel>Email Address</FormLabel>
											<Input
												type="email"
												{...register("Email", {
													required: "Please enter your email",
													pattern: {
														value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
														message: "Please enter a valid Email",
													},
												})}
											/>
											<FormErrorMessage>
												{errors.Email && errors.Email.message}
											</FormErrorMessage>
										</FormControl>
									</Stack>
									<Stack
										spacing={{ base: "1rem", md: "1.5rem" }}
										direction={{
											base: "column",
											sm: "row",
										}}>
										<FormControl id="firstName" isInvalid={errors.FirstName}>
											<FormLabel>First Name</FormLabel>
											<Input
												type="text"
												{...register("FirstName", {
													required: "Please enter your First Name",
													minLength: {
														value: 2,
														message: "First Name can't be this short",
													},
													pattern: {
														value: /^[A-Za-z\s]{1,}[\.]{0,1}[A-Za-z\s]{0,}$/,
														message: "Please enter a valid First Name",
													},
												})}
											/>
											<FormErrorMessage>
												{errors.FirstName && errors.FirstName.message}
											</FormErrorMessage>
										</FormControl>
										<FormControl id="lastName" isInvalid={errors.LastName}>
											<FormLabel>Last Name</FormLabel>
											<Input
												type="text"
												{...register("LastName", {
													required: "Please enter your Last Name",
													pattern: {
														value: /^[A-Za-z\s]{1,}[\.]{0,1}[A-Za-z\s]{0,}$/,
														message: "Please enter a valid Last Name",
													},
												})}
											/>
											<FormErrorMessage>
												{errors.LastName && errors.LastName.message}
											</FormErrorMessage>
										</FormControl>
									</Stack>
									<Stack
										spacing={{ base: "1rem", md: "1.5rem" }}
										direction={{
											base: "column",
											sm: "row",
										}}>
										<FormControl id="Event" isInvalid={errors.EventId}>
											<FormLabel>Event</FormLabel>
											<Multiselect
												options={props.eventData}
												{...(props.eventData.length === 1 && {
													selectedValues: [props.eventData[0]],
												})}
												{...register("EventId", {
													required: "Please select an Event",
												})}
												displayValue="TourEventName"
												value="TourEventId"
												showArrow
												onSelect={handleSelection}
												onRemove={handleSelection}
												onKeyPressFn={(e) => {
													if (e.key === "Enter") {
														e.preventDefault();
													}
												}}
											/>
											<FormErrorMessage>
												{errors.EventId && errors.EventId.message}
											</FormErrorMessage>
										</FormControl>
										<FormControl id="nickName" isInvalid={errors.NickName}>
											<FormLabel>Nick Name</FormLabel>
											<Input
												type="text"
												{...register("NickName", {
													required: "Please enter a Nick Name",
													pattern: {
														value: /^[A-Za-z\s]{1,}[\.]{0,1}[A-Za-z\s]{0,}$/,
														message: "Please enter a valid Nick Name",
													},
												})}
											/>
											<FormErrorMessage>
												{errors.NickName && errors.NickName.message}
											</FormErrorMessage>
										</FormControl>
									</Stack>
									<Stack
										spacing={{ base: "1rem", sm: "1.5rem" }}
										direction={{
											base: "column",
											sm: "row",
										}}>
										<FormControl id="gender" isInvalid={errors.Gender}>
											<FormLabel>Gender</FormLabel>
											<Controller
												control={control}
												name="Gender"
												rules={{ required: "Please select Gender" }}
												render={({ field: { value, onChange } }) => (
													<Select
														name="Gender"
														colorScheme="blue"
														value={value}
														placeholder="Select Gender"
														onChange={onChange}>
														<option value="Male">Male</option>
														<option value="Female">Female</option>
													</Select>
												)}
											/>
											<FormErrorMessage>
												{errors.Gender && errors.Gender.message}
											</FormErrorMessage>
										</FormControl>
										<FormControl
											id="prefLanguage"
											isInvalid={errors.PrefLanguage}>
											<FormLabel> Preferred Language</FormLabel>
											<Controller
												control={control}
												name="PrefLanguage"
												rules={{
													required: "Please select Preferred Language",
												}}
												render={({ field: { value, onChange } }) => (
													<Select
														name="PrefLanguage"
														colorScheme="blue"
														value={value}
														placeholder="Select Preferred Language"
														onChange={onChange}>
														<option value="English">English</option>
														<option value="Hindi">Hindi</option>
														<option value="Spanish">Spanish</option>
														<option value="Telugu">Telugu</option>
													</Select>
												)}
											/>
											<FormErrorMessage>
												{errors.PrefLanguage && errors.PrefLanguage.message}
											</FormErrorMessage>
										</FormControl>
									</Stack>
									<Stack
										spacing={{ base: "1rem", md: "1.5rem" }}
										direction={{
											base: "column",
											sm: "row",
										}}>
										<Button onClick={openImage}>Image</Button>
										<Modal
											isOpen={imageOpen}
											isCentered
											closeOnOverlayClick={false}
											size="lg">
											<ModalOverlay />
											<ModalContent>
												<ModalHeader textAlign={"center"}>
													Image Cropping
												</ModalHeader>
												<ModalBody>
													<FormControl
														id="image"
														isInvalid={errors.ImageLink}>
														<FormLabel></FormLabel>
														<Box width="100%" height="400px">
															<Cropper
																image={selectedImage}
																crop={crop}
																zoom={zoom}
																aspect={1 / 1}
																onCropChange={setCrop}
																onCropComplete={onCropComplete}
																onZoomChange={setZoom}
															/>
															<Slider
																type="range"
																valueLabelDisplay="auto"
																value={zoom}
																min={1}
																max={3}
																step={0.1}
																aria-labelledby="Zoom"
																onChange={(e, zoom) => {
																	setZoom(zoom);
																}}
															/>
														</Box>
														<FormErrorMessage>
															{errors.ImageLink &&
																errors.ImageLink.message}
														</FormErrorMessage>
													</FormControl>
												</ModalBody>
												<ModalFooter>
													<input
														type="file"
														accept=".jpg, .jpeg, .jfi, .jpe, .jif, .jfif, .heif, .heic, .png, .svg, .svgz, .raw"
														onChange={handleFileChange}
													/>
													<ButtonGroup spacing="5">
														<Button
															width={"-webkit-fit-content"}
															onClick={() => {
																cropImage();
															}}
															size="sm"
															borderRadius="full">
															crop
														</Button>
														<Button
															onClick={handleImageCropModalClose}
															width={"-webkit-fit-content"}
															size="sm"
															borderRadius="full">
															close
														</Button>
													</ButtonGroup>
												</ModalFooter>
											</ModalContent>
										</Modal>
										<Button
											type="submit"
											size="lg"
											borderRadius="full"
											colorScheme="blue"
											ref={formRef}
											display={"none"}>
											Save
										</Button>
									</Stack>
								</Stack>
							</form>
						)}
					</ModalBody>

					<ModalFooter>
						{phoneStatus === false && (
							<ButtonGroup spacing="5">
								{loading ? null : (
									<Button
										onClick={() => {
											setError("");
											setPhoneNo("");
											setPhoneStatus(true);
											setDetailsStatus(false);
											onClose();
											setFile(null);
											reset();
										}}
										size="lg"
										borderRadius="full"
										colorScheme="red">
										Cancel
									</Button>
								)}
								<Button
									type="button"
									size="lg"
									borderRadius="full"
									colorScheme="blue"
									isLoading={loading}
									onClick={() => {
										formRef.current.click();
									}}>
									Save
								</Button>
							</ButtonGroup>
						)}
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
};

import {
	Box,
	Divider,
	FormControl,
	FormLabel,
	Input,
	Stack,
	Text,
	useBreakpointValue,
	FormErrorMessage,
	useColorModeValue,
} from "@chakra-ui/react";
import * as React from "react";

export const AddressForm = ({ register, errors }) => {
	//Form Heading
	const formHeading = useBreakpointValue({
		base: "lg",
		sm: "xl",
	});
	//Box Shadow
	const shadow = useColorModeValue("sm", "sm-dark");

	return (
		<Box>
			<Text fontSize={formHeading} mb={2} fontWeight="medium">
				Address
			</Text>
			<Box bg="bg-surface" boxShadow={shadow} borderRadius="lg">
				<Stack
					spacing="5"
					px={{
						base: "4",
						md: "6",
					}}
					py={{
						base: "5",
						md: "6",
					}}>
					<Stack
						spacing="6"
						direction={{
							base: "column",
							md: "row",
						}}>
						<FormControl id="fullAddress" isInvalid={errors.Address1}>
							<FormLabel>Full Address</FormLabel>
							<Input
								type="text"
								{...register("Address1", {
									required: "Please enter your Full Address",
								})}
							/>
							<FormErrorMessage>
								{errors.Address1 && errors.Address1.message}
							</FormErrorMessage>
						</FormControl>
						<FormControl id="city" isInvalid={errors.City}>
							<FormLabel>City</FormLabel>
							<Input
								type="text"
								{...register("City", { required: "Please enter your City" })}
							/>
						<FormErrorMessage>
							{errors.City && errors.City.message}
						</FormErrorMessage>
						</FormControl>
					</Stack>
					<Stack
						spacing="6"
						direction={{
							base: "column",
							md: "row",
						}}>
						<FormControl id="state" isInvalid={errors.State}>
							<FormLabel>State / Province</FormLabel>
							<Input
								type="text"
								{...register("State", {
									required: "Please enter your State / Province",
								})}
							/>
							<FormErrorMessage>
								{errors.State && errors.State.message}
							</FormErrorMessage>
						</FormControl>
						<FormControl id="country" isInvalid={errors.Country}>
							<FormLabel>Country</FormLabel>
							<Input
								type="text"
								{...register("Country", {
									required: "Please enter your Country",
								})}
							/>
							<FormErrorMessage>
								{errors.Country && errors.Country.message}
							</FormErrorMessage>
						</FormControl>
						<FormControl id="zip" isInvalid={errors.ZipCode}>
							<FormLabel>ZIP Code</FormLabel>
							<Input
								type="number"
								{...register("ZipCode", {
									required: "Please enter your ZIP Code",
								})}
							/>
							<FormErrorMessage>
								{errors.ZipCode && errors.ZipCode.message}
							</FormErrorMessage>
						</FormControl>
					</Stack>
				</Stack>
				<Divider />
			</Box>
		</Box>
	);
};

import {
	Container,
	Heading,
	useBreakpointValue,
	Stack,
	Card,
	CardHeader,
	useColorModeValue,
	HStack,
	Select,
	IconButton,
	Text,
	FormControl,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ApiService from "../../service/Api.service";
import { useAuthProvider } from "../../context/AuthProvider";
import { FiRefreshCw } from "react-icons/fi";
import { PositionsTable } from "../../Components/Events/Positions/PositionsTable";

export const Positions = () => {
	// User Details
	const user = useAuthProvider();

	//Desktop BreakPoint Value
	const isDesktop = useBreakpointValue({
		base: false,
		lg: true,
	});
	// Mobile Breakpoint Value
	const isMobile = useBreakpointValue({
		base: true,
		sm: true,
		md: false,
	});
	//Page Header
	const pageHeader = useBreakpointValue({
		base: "xs",
		sm: "sm",
	});
	//Box Shadow
	const shadow = useColorModeValue("sm", "sm-dark");
	//Card Heading
	const cardHeading = useBreakpointValue({
		base: "xs",
		md: "sm",
	});
	//Card Subtitle
	const cardSubtitle = useBreakpointValue({
		base: "lg",
		md: "xl",
	});

	let { eventId } = useParams();

	const [positions, setPositions] = useState(null);
	const [selectedTeam, setSelectedTeam] = useState("");

	async function fetchPositions() {
		try {
			let res = await ApiService.httpGet(
				`/TeamsRoundPlayers?Class=PlayerPositionInfo&TourEventId=${eventId}`,
				"U00184"
			);
			setPositions(res);
			setSelectedTeam(res.lookup[0]);
		} catch (err) {
			console.log(err);
		}
	}
	useEffect(() => {
		fetchPositions();
	}, []);
	if (positions) {
		return (
			<Container
				flex="1"
				maxWidth="100vw"
				marginTop={isDesktop ? "7rem" : "5rem"}
				marginX={0}
				{...(isMobile && { px: "1" })}
				paddingBottom={10}>
				<Stack spacing="1rem">
					<Card variant={"elevated"} bgColor="white" boxShadow={shadow}>
						<CardHeader>
							<Stack
								direction={{ base: "column", sm: "row" }}
								justify={"space-between"}>
								<Stack>
									<Heading as="h2" size={cardHeading}>
										Player Positions
									</Heading>
									<Text
										fontSize={cardSubtitle}
										fontWeight="semibold"
										color="accent"
										as="h4"
										whiteSpace={"normal"}>
										{positions.TournamentName && positions.TournamentName}
										{positions.EventName && " - " + positions.EventName}
									</Text>
								</Stack>
								<HStack justify={"end"} align={"end"}>
									<IconButton
										variant="ghost"
										size="md"
										color="accent"
										icon={<FiRefreshCw size="1.7rem" />}
										onClick={() => fetchPositions()}
									/>
									<FormControl w={"max-content"}>
										<Select
											value={selectedTeam}
											onChange={(e) => setSelectedTeam(e.target.value)}
											width="fit-content">
											{positions.lookup?.map((lookup) => (
												<option key={lookup} value={lookup}>
													{lookup}
												</option>
											))}
										</Select>
									</FormControl>
								</HStack>
							</Stack>
						</CardHeader>
					</Card>

					<PositionsTable
						selectedTeam={selectedTeam}
						positionsLookup={positions.Positions}
						positionInfo={positions.positionInfo}
					/>
				</Stack>
			</Container>
		);
	}
};

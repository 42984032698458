import {
	Container,
	Heading,
	Icon,
	useBreakpointValue,
	Stack,
	Divider,
	Text,
	Card,
	CardHeader,
	Center,
	Circle,
	useColorModeValue,
	CardBody,
	Tabs,
	TabList,
	Tab,
	useToast,
	NumberInput,
	NumberInputField,
	Slider,
	SliderTrack,
	Box,
	SliderFilledTrack,
	SliderThumb,
	FormControl,
	FormLabel,
	RadioGroup,
	Radio,
	VStack,
} from "@chakra-ui/react";
import { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router";
import { useParams } from "react-router-dom";
import { BoardScoresTable } from "../../Components/Events/BoardScores/BoardScoresTable";
import { CalendarWhite } from "../../icons/Icons";
import ApiService from "../../service/Api.service";
import { useAuthProvider } from "../../context/AuthProvider";

export const BoardScores = () => {
	// User Details
	const user = useAuthProvider();

	let location = useLocation();
	//Desktop BreakPoint Value
	const isDesktop = useBreakpointValue({
		base: false,
		lg: true,
	});
	// Mobile BreakPoint Value
	const isMobile = useBreakpointValue({
		base: true,
		sm: true,
		md: false,
	});
	const isTablet = useBreakpointValue({
		base: false,
		sm: false,
		md: true,
		lg: false,
	});
	//Page Header
	const pageHeader = useBreakpointValue({
		base: "xs",
		sm: "sm",
	});
	//Box Shadow
	const shadow = useColorModeValue("sm", "sm-dark");
	//Card Heading
	const cardHeading = useBreakpointValue({
		base: "xs",
		md: "sm",
	});
	//Card Subtitle
	const cardSubtitle = useBreakpointValue({
		base: "xl",
		md: "2xl",
	});
	// Round Info Text
	const roundText = useBreakpointValue({
		base: "md",
		md: "lg",
	});
	//Player Names
	const playerNames = useBreakpointValue({
		base: "md",
		sm: "lg",
		md: "2xl",
	});
	const playerScores = useBreakpointValue({
		base: "1.6rem",
		md: "2rem",
	});

	const toast = useToast();
	let { matchupId } = useParams();
	const [setCount, setSetCount] = useState(1);
	const [koSetFlags, setKOSetFlags] = useState({});
	let [selectedSet, setSelectedSet] = useState(1);
	let [boardDetails, setBoardDetails] = useState(null);
	let [playersData, setPlayersData] = useState([]);
	const [editMode, setEditMode] = useState(false);
	const [player1Score, setPlayer1Score] = useState(null);
	const [player2Score, setPlayer2Score] = useState(null);
	const [strikeSlider, setStrikeSlider] = useState(0);
	const [player1Dir, setPlayer1Dir] = useState("");
	const [player2Dir, setPlayer2Dir] = useState("");
	const inputFocus = useRef(null);
	const [tabIndex, setTabIndex] = useState(0);
	const [loading, setLoading] = useState(false);

	const handleTabsChange = (index) => {
		setTabIndex(index);
		setSelectedSet(index + 1);
		console.log("Tab Index", index + 1);
	};

	const fetchBoardScores = async (MatchupId, setIndex) => {
		try {
			setLoading(true);
			let url = `/TourEventScores?MatchupId=${MatchupId}`;
			let boardScoresRes = await ApiService.httpGet(url, "U00184");
			console.log("Score boards data:", boardScoresRes);
			if (setIndex === -1 && "LatestKnockoutSet" in boardScoresRes) {
				setIndex = boardScoresRes.LatestKnockoutSet;
				setTabIndex(boardScoresRes.LatestKnockoutSet - 1);
				setSelectedSet(boardScoresRes.LatestKnockoutSet);
			} else if (setIndex === -1) {
				setIndex = 1;
			}
			setSetCount(boardScoresRes.SetCount);
			setKOSetFlags({
				isSet1Accessible: boardScoresRes.isSet1Accessible,
				isSet2Accessible: boardScoresRes.isSet2Accessible,
				isSet3Accessible: boardScoresRes.isSet3Accessible,
			});
			setBoardDetails(boardScoresRes.ScoreDetails);
			setPlayer1Score(boardScoresRes.ScoreDetails[setIndex - 1].Player1TotalScore);
			setPlayer2Score(boardScoresRes.ScoreDetails[setIndex - 1].Player2TotalScore);
			setPlayersData([
				{
					Id: boardScoresRes.ScoreDetails[setIndex - 1].Player1Id,
					Name: boardScoresRes.ScoreDetails[setIndex - 1].Player1Name,
				},
				{
					Id: boardScoresRes.ScoreDetails[setIndex - 1].Player2Id,
					Name: boardScoresRes.ScoreDetails[setIndex - 1].Player2Name,
				},
			]);
			setStrikeSlider(
				boardScoresRes.ScoreDetails[setIndex - 1]?.FirstStrike !== null
					? boardScoresRes.ScoreDetails[setIndex - 1]?.FirstStrike ===
					  boardScoresRes.ScoreDetails[setIndex - 1]?.Player1Id
						? -1
						: boardScoresRes.ScoreDetails[setIndex - 1]?.FirstStrike ===
						  boardScoresRes.ScoreDetails[setIndex - 1]?.Player2Id
						? 1
						: 0
					: 0
			);
			if (
				!(
					boardScoresRes.ScoreDetails[setIndex - 1]?.Team1SittingLeft === false &&
					boardScoresRes.ScoreDetails[setIndex - 1]?.Team2SittingLeft === false
				)
			) {
				setPlayer1Dir(
					boardScoresRes.ScoreDetails[setIndex - 1]?.Team1SittingLeft === true ? "L" : "R"
				);
				setPlayer2Dir(
					boardScoresRes.ScoreDetails[setIndex - 1]?.Team2SittingLeft === true ? "L" : "R"
				);
			} else {
				setPlayer1Dir("");
				setPlayer2Dir("");
			}
			setLoading(false);
		} catch (err) {
			console.log(err);
			setLoading(false);
		}
	};

	function refreshOnScoresSave(matchupId, setIndex, boardScoresRes) {
		try {
			setLoading(true);
			if (setIndex === -1 && "LatestKnockoutSet" in boardScoresRes) {
				setIndex = boardScoresRes.LatestKnockoutSet;
				setTabIndex(boardScoresRes.LatestKnockoutSet - 1);
				setSelectedSet(boardScoresRes.LatestKnockoutSet);
			} else if (setIndex === -1) {
				setIndex = 1;
			}
			setSetCount(boardScoresRes.SetCount);
			setKOSetFlags({
				isSet1Accessible: boardScoresRes.isSet1Accessible,
				isSet2Accessible: boardScoresRes.isSet2Accessible,
				isSet3Accessible: boardScoresRes.isSet3Accessible,
			});
			setBoardDetails(boardScoresRes.ScoreDetails);
			setPlayer1Score(boardScoresRes.ScoreDetails[setIndex - 1].Player1TotalScore);
			setPlayer2Score(boardScoresRes.ScoreDetails[setIndex - 1].Player2TotalScore);
			setPlayersData([
				{
					Id: boardScoresRes.ScoreDetails[setIndex - 1].Player1Id,
					Name: boardScoresRes.ScoreDetails[setIndex - 1].Player1Name,
				},
				{
					Id: boardScoresRes.ScoreDetails[setIndex - 1].Player2Id,
					Name: boardScoresRes.ScoreDetails[setIndex - 1].Player2Name,
				},
			]);
			setStrikeSlider(
				boardScoresRes.ScoreDetails[setIndex - 1]?.FirstStrike !== null
					? boardScoresRes.ScoreDetails[setIndex - 1]?.FirstStrike ===
					  boardScoresRes.ScoreDetails[setIndex - 1]?.Player1Id
						? -1
						: boardScoresRes.ScoreDetails[setIndex - 1]?.FirstStrike ===
						  boardScoresRes.ScoreDetails[setIndex - 1]?.Player2Id
						? 1
						: 0
					: 0
			);
			if (
				!(
					boardScoresRes.ScoreDetails[setIndex - 1]?.Team1SittingLeft === false &&
					boardScoresRes.ScoreDetails[setIndex - 1]?.Team2SittingLeft === false
				)
			) {
				setPlayer1Dir(
					boardScoresRes.ScoreDetails[setIndex - 1]?.Team1SittingLeft === true ? "L" : "R"
				);
				setPlayer2Dir(
					boardScoresRes.ScoreDetails[setIndex - 1]?.Team2SittingLeft === true ? "L" : "R"
				);
			} else {
				setPlayer1Dir("");
				setPlayer2Dir("");
			}
			setLoading(false);
		} catch (err) {
			console.log(err);
			setLoading(false);
		}
	}

	const saveBoardScores = async () => {
		try {
			let reqObj = {
				MatchupId: matchupId,
				Team1Score: player1Score,
				Team2Score: player2Score,
			};
			if (setCount > 1) {
				reqObj = {
					MatchupId: matchupId,
					Team1Score: player1Score,
					Team2Score: player2Score,
					KnockoutSet: selectedSet,
				};
			}
			let url = `/TourEventMatchupsByGroup?MatchupId=${matchupId}`;
			let saveScores = await ApiService.httpPut(url, reqObj);
			if (saveScores.status === true) {
				toast({
					title: "Save Board Scores",
					description: "Board Scores Saved Successfully.",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				console.log("Saved Scores: ", saveScores);
				fetchBoardScores(matchupId, selectedSet);
			} else {
				toast({
					title: "Save Board Scores",
					description: saveScores.Message,
					status: "warning",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
		} catch (err) {
			console.log(err);
			toast({
				title: "Save Board Scores",
				description: "Failed to Save Board Scores.",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
		}
	};

	const saveFirstStrike = async (value) => {
		setStrikeSlider(value);
		if (value !== 0) {
			try {
				let reqObj = {
					MatchupId: matchupId,
					FirstStrike:
						value === -1
							? boardDetails[tabIndex]?.Player1Id
							: boardDetails[tabIndex]?.Player2Id,
				};
				let url = "/TourEventScores?Class=UpdateFirstStrike";
				if (setCount > 1) {
					url = `/TourEventScores?Class=UpdateFirstStrike&KnockoutSet=${selectedSet}`;
				}
				let strikeRes = await ApiService.httpPut(url, reqObj);
				if (strikeRes.status === true) {
					toast({
						title: "Save First Strike",
						description: "First Strike Saved Successfully.",
						status: "success",
						duration: 3000,
						isClosable: true,
						position: "top-right",
					});
					fetchBoardScores(matchupId, selectedSet);
				} else {
					toast({
						title: "Save First Strike",
						description: "Failed to Save First Strike.",
						status: "error",
						duration: 3000,
						isClosable: true,
						position: "top-right",
					});
				}
				console.log(reqObj);
			} catch (err) {
				console.log(err);
				toast({
					title: "Save First Strike",
					description: "Failed to Save First Strike.",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
		}
	};

	const updatePlayerPosition = async (position, value) => {
		let reqObj;
		if (position === "Player1" && value !== "") {
			if (position === "Player1" && value === "L") {
				reqObj = {
					MatchupId: boardDetails[tabIndex]?.MatchupId,
					SittingLeft: boardDetails[tabIndex]?.Player1Id,
				};
			} else {
				reqObj = {
					MatchupId: boardDetails[tabIndex]?.MatchupId,
					SittingLeft: boardDetails[tabIndex]?.Player2Id,
				};
			}
		} else if (position === "Player2" && value !== "") {
			if (position === "Player2" && value === "L") {
				reqObj = {
					MatchupId: boardDetails[tabIndex]?.MatchupId,
					SittingLeft: boardDetails[tabIndex]?.Player2Id,
				};
			} else {
				reqObj = {
					MatchupId: boardDetails[tabIndex]?.MatchupId,
					SittingLeft: boardDetails[tabIndex]?.Player1Id,
				};
			}
		}
		try {
			let url = `/TourEventScores?Class=UpdateSittingLeft`;
			if (setCount > 1) {
				url = `/TourEventScores?Class=UpdateSittingLeft&KnockoutSet=${selectedSet}`;
			}
			let res = await ApiService.httpPut(url, reqObj);
			if (res.status === true) {
				toast({
					title: "Update Player Seating Position",
					description: "Player Seating Position Updated Successfully.",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				fetchBoardScores(matchupId, selectedSet);
			} else {
				toast({
					title: "Update Player Seating Position",
					description: "Failed to Update Player Seating Position.",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
		} catch (err) {
			console.log(err);
			toast({
				title: "Update Player Seating Position",
				description: "Failed to Update Player Seating Position.",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
		}
	};

	useEffect(() => {
		editMode && inputFocus.current.focus();
	}, [editMode]);

	useEffect(() => {
		fetchBoardScores(matchupId, -1);
	}, []);

	if (boardDetails) {
		return (
			<Container
				flex="1"
				maxWidth="100vw"
				marginTop={isDesktop ? "7rem" : "5rem"}
				marginX={0}
				{...(isMobile && { px: "1" })}
				paddingBottom={10}>
				<Stack spacing="1.7rem">
					<Stack
						direction={"row"}
						{...(isMobile ? { spacing: "1rem" } : { spacing: "1.5rem" })}
						align={{
							base: "start",
							md: "center",
						}}
						justify="space-between">
						<Heading size={pageHeader} fontWeight="bold">
							Board Scores
						</Heading>
					</Stack>
					<Stack spacing="2rem">
						<Card variant={"elevated"} bgColor="white" boxShadow={shadow}>
							<Tabs
								variant="line"
								isFitted
								size="lg"
								index={tabIndex}
								onChange={handleTabsChange}>
								{setCount > 1 && (
									<TabList>
										<Tab isDisabled={!koSetFlags.isSet1Accessible}>Set-1</Tab>
										<Tab isDisabled={!koSetFlags.isSet2Accessible}>Set-2</Tab>
										<Tab isDisabled={!koSetFlags.isSet3Accessible}>Set-3</Tab>
									</TabList>
								)}
								<CardHeader>
									<Stack spacing="1.2rem" divider={<Divider />}>
										<Stack
											direction={{
												base: "column",
												md: "row",
											}}
											{...(isMobile
												? { spacing: "1rem" }
												: { spacing: "1.5rem" })}
											align={{
												base: "start",
												md: "center",
											}}
											justify="space-between">
											<Stack
												direction={"row"}
												spacing="1.2rem"
												align="center">
												<Center
													bgColor={editMode ? "red" : "accent"}
													p="1rem"
													borderRadius="xl"
													onClick={() => {
														!(
															boardDetails[tabIndex].IsComplete ===
																true ||
															loading ||
															boardDetails[tabIndex]?.FirstStrike ===
																null ||
															(boardDetails[tabIndex]
																?.Team1SittingLeft === false &&
																boardDetails[tabIndex]
																	?.Team2SittingLeft === false)
														) &&
															boardDetails[tabIndex].FirstStrike !==
																null &&
															setEditMode(!editMode);
														(player1Score !==
															boardDetails[tabIndex]
																?.Player1TotalScore ||
															player2Score !==
																boardDetails[tabIndex]
																	?.Player2TotalScore) &&
															editMode &&
															saveBoardScores();
													}}>
													<Icon as={CalendarWhite} />
												</Center>
												<Stack
													justify={"space-between"}
													{...(isMobile && { w: "100%" })}
													spacing={0}>
													<Heading as="h2" size={cardHeading}>
														{boardDetails[tabIndex]?.TournamentName}
													</Heading>
													<Text
														fontSize={cardSubtitle}
														fontWeight="semibold"
														color="accent"
														as="h4">
														{boardDetails[tabIndex]?.EventName}
														{boardDetails[tabIndex]?.GroupName &&
															" - Group " +
																boardDetails[tabIndex]?.GroupName}
													</Text>
													<Text
														fontSize={roundText}
														color={"InactiveCaptionText"}
														fontWeight="semibold"
														as="h4">
														{boardDetails[tabIndex]?.RoundName &&
															boardDetails[tabIndex]?.RoundName}
													</Text>
												</Stack>
											</Stack>
											<VStack align={"end"} spacing={1} alignSelf={"end"}>
												<Text
													fontSize={roundText}
													color={"InactiveCaptionText"}
													fontWeight={"medium"}>
													{boardDetails[tabIndex]?.Venue !== null &&
														"Venue: " + boardDetails[tabIndex]?.Venue}
												</Text>
												<Text
													fontSize={roundText}
													color={"InactiveCaptionText"}
													fontWeight={"medium"}>
													{boardDetails[tabIndex]?.RefereeName !== null &&
														"Referee: " +
															boardDetails[tabIndex]?.RefereeName}
												</Text>
											</VStack>
										</Stack>
										<Stack direction={"row"} justify="space-between">
											<Stack direction={"column"}>
												<Stack
													direction="row"
													align={"center"}
													spacing=".7rem">
													<Text
														as="h3"
														fontSize={playerNames}
														boxSize={{
															base: "-webkit-min-content",
															sm: "-webkit-fit-content",
														}}
														fontWeight="semibold">
														{boardDetails[tabIndex].Player1Name}
													</Text>
													{editMode ? (
														<NumberInput
															min={0}
															max={25}
															defaultValue={
																boardDetails[tabIndex]
																	.Player1TotalScore
															}
															onChange={(value) => {
																setPlayer1Score(Number(value));
															}}>
															<NumberInputField
																ref={inputFocus}
																boxSize={{
																	base: "1.8rem",
																	md: "2.1rem",
																}}
																p={0}
																textAlign={"center"}
																fontWeight={"semibold"}
																bg={"#3A95FF"}
																borderRadius={"full"}
																textColor={"white"}
															/>
														</NumberInput>
													) : (
														<Circle
															size={playerScores}
															fontWeight="semibold"
															textColor="white"
															bgColor="#3A95FF">
															{
																boardDetails[tabIndex]
																	.Player1TotalScore
															}
														</Circle>
													)}
												</Stack>
												<FormControl>
													<RadioGroup
														isDisabled={
															boardDetails[tabIndex].IsComplete ===
																true ||
															(!(
																boardDetails[tabIndex]
																	?.Team1SittingLeft === false &&
																boardDetails[tabIndex]
																	?.Team2SittingLeft === false
															) &&
																boardDetails[tabIndex]
																	?.Player1TotalScore +
																	boardDetails[tabIndex]
																		?.Player2TotalScore >
																	0)
														}
														onChange={(value) => {
															setPlayer1Dir(value);
															value !== "" && value === "L"
																? setPlayer2Dir("R")
																: setPlayer2Dir("L");
															updatePlayerPosition("Player1", value);
														}}
														value={player1Dir}>
														<Stack
															direction={"row"}
															spacing={isMobile ? 2 : 3}>
															<Radio
																colorScheme="red"
																spacing={isMobile ? 1 : 2}
																value="L">
																{isMobile ? "L" : "Left"}
															</Radio>
															<Radio
																colorScheme="green"
																spacing={isMobile ? 1 : 2}
																value="R">
																{isMobile ? "R" : "Right"}
															</Radio>
														</Stack>
													</RadioGroup>
												</FormControl>
											</Stack>
											<FormControl alignSelf={"center"} w={"fit-content"}>
												<FormLabel textAlign={"center"} mr={0}>
													First Strike
												</FormLabel>
												<Slider
													value={strikeSlider}
													min={-1}
													max={1}
													step={1}
													isDisabled={
														boardDetails[tabIndex].IsComplete ===
															true ||
														(boardDetails[tabIndex]?.FirstStrike !==
															null &&
															boardDetails[tabIndex]
																?.Player1TotalScore +
																boardDetails[tabIndex]
																	?.Player2TotalScore >
																0)
													}
													onChange={(value) => {
														saveFirstStrike(value);
													}}>
													<SliderTrack
														bg={
															strikeSlider === -1
																? "#3A95FF"
																: strikeSlider === 1
																? "#800080"
																: "gray"
														}
														h={"1rem"}
														borderRadius={"full"}>
														<Box position="relative" right={10} />
														<SliderFilledTrack bg="" />
													</SliderTrack>
													<SliderThumb boxSize={6} />
												</Slider>
											</FormControl>
											<Stack direction={"column"}>
												<Stack
													direction="row"
													align={"center"}
													spacing=".7rem">
													<Text
														as="h3"
														fontSize={playerNames}
														boxSize={{
															base: "-webkit-min-content",
															sm: "-webkit-fit-content",
														}}
														fontWeight="semibold">
														{boardDetails[tabIndex].Player2Name}
													</Text>
													{editMode ? (
														<NumberInput
															min={0}
															max={25}
															defaultValue={
																boardDetails[tabIndex]
																	.Player2TotalScore
															}
															onChange={(value) => {
																setPlayer2Score(Number(value));
															}}>
															<NumberInputField
																boxSize={{
																	base: "1.8rem",
																	md: "2.1rem",
																}}
																p={0}
																textAlign={"center"}
																fontWeight={"semibold"}
																bg={"#800080"}
																borderRadius={"full"}
																textColor={"white"}
															/>
														</NumberInput>
													) : (
														<Circle
															size={playerScores}
															fontWeight="semibold"
															textColor="white"
															bgColor="#800080">
															{
																boardDetails[tabIndex]
																	.Player2TotalScore
															}
														</Circle>
													)}
												</Stack>
												<FormControl>
													<RadioGroup
														isDisabled={
															boardDetails[tabIndex].IsComplete ===
																true ||
															(!(
																boardDetails[tabIndex]
																	?.Team1SittingLeft === false &&
																boardDetails[tabIndex]
																	?.Team2SittingLeft === false
															) &&
																boardDetails[tabIndex]
																	?.Player1TotalScore +
																	boardDetails[tabIndex]
																		?.Player2TotalScore >
																	0)
														}
														onChange={(value) => {
															setPlayer2Dir(value);
															value !== "" && value === "L"
																? setPlayer1Dir("R")
																: setPlayer1Dir("L");
															updatePlayerPosition("Player2", value);
														}}
														value={player2Dir}>
														<Stack
															direction={"row"}
															spacing={isMobile ? 2 : 3}>
															<Radio
																colorScheme="red"
																spacing={isMobile ? 1 : 2}
																value="L">
																{isMobile ? "L" : "Left"}
															</Radio>
															<Radio
																colorScheme="green"
																spacing={isMobile ? 1 : 2}
																value="R">
																{isMobile ? "R" : "Right"}
															</Radio>
														</Stack>
													</RadioGroup>
												</FormControl>
											</Stack>
										</Stack>
									</Stack>
								</CardHeader>
								<CardBody p={0}>
									<BoardScoresTable
										matchupId={matchupId}
										scoresData={boardDetails[tabIndex].Scores}
										boardsData={boardDetails[tabIndex]}
										playersData={playersData}
										refreshView={fetchBoardScores}
										selectedSet={selectedSet}
										setCount={setCount}
										setLoading={setLoading}
										loading={loading}
										refreshOnScoresSave={refreshOnScoresSave}
									/>
								</CardBody>
							</Tabs>
						</Card>
					</Stack>
				</Stack>
			</Container>
		);
	}
};

import React from "react";
import {
	Table,
	Thead,
	Th,
	Td,
	Tr,
	Tbody,
	Stack,
	HStack,
	Flex,
	Image,
	Box,
	CardHeader,
	Card,
	CardBody,
	Heading,
	ScaleFade,
} from "@chakra-ui/react";
import clubLogo from "../../../assets/clublogo.png";
import logo from "../../../images/logo.png";
import { useState } from "react";
import { useEffect } from "react";
import ApiService from "../../../service/Api.service";
import { useAuthProvider } from "../../../context/AuthProvider";

export const BoardScoresTable = ({ matchupId }) => {
	// User Details
	const user = useAuthProvider();

	const [boardScores, setBoardScores] = useState(null);

	async function fetchBoardScores() {
		try {
			let boardScores = await ApiService.httpGet(
				`/TourEventScores?MatchupId=${matchupId}`,
				user.userId
			);
			if (boardScores.status === true) {
				setBoardScores(
					boardScores.ScoreDetails[
						boardScores.SetCount > 1 ? boardScores.LatestKnockoutSet - 1 : 0
					]
				);
			}
		} catch (err) {
			console.log(err);
		}
	}
	useEffect(() => {
		fetchBoardScores();
	}, [matchupId]);

	if (boardScores) {
		return (
			<ScaleFade initialScale={0.1} in>
				<Card bg="white" boxShadow={false}>
					<CardHeader py={1}>
						<HStack align="center" justify={"center"} minH={75} minW={500}>
							<Image
								src={clubLogo}
								alt="Club Image"
								blockSize={50}
								objectFit="contain"
								position="absolute"
								left={5}
								style={{ userSelect: "none" }}
								zIndex={1} // Ensure the overlay is above other components
							/>
							<Heading
								as={"h2"}
								size="sm"
								fontSize="2xl"
								fontWeight="bold"
								color="#0AB042"
								textAlign="center"
								w={"fit-content"}>
								BOARD SCORES
							</Heading>
							<Image
								src={logo}
								alt="Carrom Platform Logo"
								blockSize={75}
								objectFit="contain"
								position="absolute"
								right={0}
								style={{ userSelect: "none" }}
								zIndex={1} // Ensure the overlay is above other components
							/>
						</HStack>
					</CardHeader>

					<CardBody boxShadow="sm" pt={0}>
						<Table bgColor={"white"} variant="striped" colorScheme="gray">
							<Thead>
								<Tr>
									<Th textColor="#4B39B7" fontSize="sm" textAlign="start">
										Board
									</Th>
									<Th textColor="#4B39B7" fontSize="sm" textAlign="start">
										Queen
									</Th>
									<Th textColor="#4B39B7" fontSize="sm" textAlign="start">
										{boardScores.Player1Name}
									</Th>
									<Th textColor="#4B39B7" fontSize="sm" textAlign="start">
										{boardScores.Player2Name}
									</Th>
									<Th textColor="#4B39B7" fontSize="sm" textAlign="start">
										Board Scores
									</Th>
								</Tr>
							</Thead>

							<Tbody bg="white">
								{boardScores?.Scores?.map((board, i) => (
									<Tr key={i}>
										<Td
											style={{ padding: "0.5rem" }}
											textAlign="center"
											fontSize="sm"
											fontWeight="medium"
											textColor="#000000">
											{board.BoardId}
										</Td>
										<Td
											style={{ padding: "0.5rem" }}
											textAlign="start"
											fontSize="sm"
											fontWeight="medium"
											textColor="#000000">
											{board.QueenName}
										</Td>
										<Td
											style={{ padding: "0.5rem" }}
											textAlign="center"
											fontSize="sm"
											fontWeight="medium"
											textColor="#000000">
											{board.Player1Score}
										</Td>
										<Td
											style={{ padding: "0.5rem" }}
											textAlign="center"
											fontSize="sm"
											fontWeight="medium"
											textColor="#000000">
											{board.Player2Score}
										</Td>
										<Td
											style={{ padding: "0.5rem" }}
											textAlign="center"
											fontSize="sm"
											fontWeight="medium"
											textColor="#000000">
											{board.BoardScore}
										</Td>
									</Tr>
								))}
							</Tbody>
						</Table>
					</CardBody>
				</Card>
			</ScaleFade>
		);
	} else {
		return null;
	}
};

import {
	Container,
	Stack,
	IconButton,
	Heading,
	HStack,
	useBreakpointValue,
	Flex,
	Button,
	ButtonGroup,
	useToast,
	Box,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import { Navbar } from "../../Components/Navbar/Navbar";
import { Sidebar } from "../../Components/Sidebar/Sidebar";

import { IoChevronBack } from "react-icons/io5";
import { useForm } from "react-hook-form";
import { useContext, useEffect, useState } from "react";
import ApiService from "../../service/Api.service";
import { TournamentsForm1 } from "../../Components/Tournaments/AddTournamentForm/TournamentsForm1";
import { TournamentsForm2 } from "../../Components/Tournaments/AddTournamentForm/TournamentsForm2";
import { useAuthProvider } from "../../context/AuthProvider";
import { BallTriangle } from "react-loader-spinner";

export const AddTournament = () => {
	// User Details
	const user = useAuthProvider();

	let navigate = useNavigate();

	// Toast
	let toast = useToast();

	let [page, setPage] = useState(0);

	const steps = ["Basic Details", "Additional Details"];

	// const { setClubs } = useContext(data);
	let [clubs, setClubs] = useState([]);

	const {
		register,
		handleSubmit,
		getValues,
		formState: { isDirty, isValid, errors },
	} = useForm({
		delayError: "500",
		mode: "all",
	});

	let getStepContent = () => {
		console.log(page);
		if (page === 0) {
			return <TournamentsForm1 register={register} errors={errors} clubs={clubs} />;
		} else if (page === 1) {
			return <TournamentsForm2 register={register} errors={errors} />;
		} else {
			return <Heading> Wrong page </Heading>;
		}
	};

	const onSubmit = (data) => {
		console.log(data);
		let tournamentObj = {
			ClubId: data.ClubId,
			TournamentName: data.TournamentName,
			ContactEmail: data.ContactEmail,
			ContactNo: data.ContactNo,
			StartDate: data.StartDate,
			EndDate: data.EndDate,
			FullAddress: data.FullAddress,
			WebsiteURL: data.WebsiteURL,
			DiscountDetails: data.DiscountDetails,
			AdditionalNotes: data.AdditionalNotes,
			AdditionalDetails: data.AdditionalDetails,
			Schedule: data.Schedule,
			Trophies: data.Trophies,
			ListName: data.ListName,
			VenueCity: "Hyderabad",
			VenueState: "Telangana",
			VenueCountry: "India",
		};

		console.log(tournamentObj);
		saveTournament(tournamentObj);
	};

	let handleNext = () => {
		setPage(page + 1);
	};

	let handleBack = () => {
		setPage(page - 1);
	};

	// breakpoint value for Desktop
	const isDesktop = useBreakpointValue({
		base: false,
		lg: true,
	});
	const [loading, setLoading] = useState(false);
	const saveTournament = async (reqObj) => {
		try {
			console.log("reqObj in saveTournament", reqObj);
			setLoading(true);
			const tournament = await ApiService.httpPost("/Tournaments", reqObj);
			console.log(tournament);
			console.log("successfully Tournament Created.");
			toast({
				title: "Add Tournament",
				description: "Tournament Added Successfully!",
				status: "success",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
			navigateToTournaments();
			setLoading(false);
			//   setAlertMessage("Tournament Added Successfully");
			//   setAlertTrigger(!AlertTrigger);
		} catch (err) {
			console.error(err.message);
			toast({
				title: "Add Tournament",
				description: "Failed to Add Tournament",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
			setLoading(false);
		} finally {
			setLoading(false);
		}
	};

	const navigateToTournaments = () => {
		return navigate("/tournaments");
	};

	const fetchClubs = async () => {
		try {
			const ClubsResponse = await ApiService.httpGet("/Tournaments?ClubId=all", user.userId);
			console.log("get Clubs from AddTour", ClubsResponse);
			setClubs(ClubsResponse.Clubs);
			console.log(clubs);
		} catch (err) {
			console.error(err.message);
		}
	};

	useEffect(() => {
		fetchClubs();
	}, []);

	return (
		<Container
			flex="1"
			overflowY="auto"
			maxWidth="100vw"
			marginTop={isDesktop ? "3.5rem" : "0rem"}
			marginX={0}
			paddingBottom={10}
			css={{
				"::-webkit-scrollbar": {
					width: "0.5rem",
					height: "0.5rem",
				},
				"::-webkit-scrollbar-track": {
					background: "#bee3f8",
					borderRadius: "1rem",
				},
				"::-webkit-scrollbar-thumb": {
					background: "#3182ce",
					borderRadius: "1rem",
				},
			}}>
			<Stack
				mt={10}
				spacing={{
					base: "8",
					lg: "6",
				}}>
				<Stack
					spacing="4"
					direction={{
						base: "column",
						lg: "row",
					}}
					justify="space-between"
					align={{
						base: "start",
						lg: "center",
					}}>
					<HStack spacing="5">
						<IconButton
							borderRadius="full"
							p={2}
							size="md"
							bgColor="white"
							as={IoChevronBack}
							onClick={navigateToTournaments}
						/>
						<Heading
							size={useBreakpointValue({
								base: "xs",
								sm: "sm",
							})}
							fontWeight="bold">
							Add New Tournament
						</Heading>
					</HStack>
				</Stack>
				{loading ? (
					<Box
						position={"absolute"}
						bottom={"50%"}
						left={"50%"}
						p={0}
						m={0}
						zIndex={"overlay"}>
						<BallTriangle
							height={100}
							width={100}
							radius={5}
							color="#4fa94d"
							ariaLabel="ball-triangle-loading"
							wrapperClass={{}}
							wrapperStyle=""
							visible={true}
						/>
					</Box>
				) : (
					<form onSubmit={handleSubmit(onSubmit)}>
						<Stack spacing="1rem">
							<Stack gap="1rem">{getStepContent()}</Stack>
							<Flex justify="end">
								<ButtonGroup spacing="5">
									<Button
										onClick={page === 0 ? navigateToTournaments : handleBack}
										size="lg"
										borderRadius="full"
										colorScheme="red">
										{page === 0 ? "Cancel" : "Back"}
									</Button>
									{page !== steps.length - 1 && (
										<Button
											type="button"
											onClick={handleNext}
											size="lg"
											borderRadius="full"
											colorScheme="blue"
											isDisabled={!isDirty || !isValid}>
											Next
										</Button>
									)}
									{page === steps.length - 1 && (
										<Button
											type="submit"
											isDisabled={!isDirty || !isValid}
											size="lg"
											borderRadius="full"
											colorScheme="blue">
											Submit
										</Button>
									)}
								</ButtonGroup>
							</Flex>
						</Stack>
					</form>
				)}
			</Stack>
		</Container>
	);
};
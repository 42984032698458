export const data = [
	{
		id: 1,
		player: "Estrella Bergesen",
		played: 1,
		points: 2,
		os: 0,
		netdiff: 18
	},
	{
		id: 2,
		player: "Estrella Bergesen",
		played: 1,
		points: 2,
		os: 0,
		netdiff: 18
	},
	{
		id: 3,
		player: "Estrella Bergesen",
		played: 1,
		points: 2,
		os: 0,
		netdiff: 18
	},
	{
		id: 4,
		player: "Estrella Bergesen",
		played: 1,
		points: 2,
		os: 0,
		netdiff: 18
	},
	{
		id: 5,
		player: "Estrella Bergesen",
		played: 1,
		points: 2,
		os: 0,
		netdiff: 18
	},
];

import {
	Card,
	CardHeader,
	IconButton,
	Stack,
	Text,
	useColorModeValue,
	Select,
	FormControl,
	HStack,
	Button,
	useToast,
	Table,
	Tbody,
	Tr,
	Td,
	Skeleton,
} from "@chakra-ui/react";
import ApiService from "../../service/Api.service";
import { useState } from "react";
import { TbActions, TbDelete, TbAdd } from "../../icons/Icons";

export const KnockoutGroupsTabCard = (props) => {
	let [editToggleId, setEditToggleId] = useState("");
	const [saveLoading, setSaveLoading] = useState(false);
	const [generateLoading, setGenerateLoading] = useState(false);

	const toast = useToast();

	// Loading States
	let koMatrixLoad = props.koMatrixLoad;

	const generateMatrix = async () => {
		try {
			setGenerateLoading(true);
			let res = await ApiService.httpPost("/KnockoutMatrix?Class=KOMatrix", {
				TourEventId: props.eventId,
			});
			console.log(res);
			if (res.status === true) {
				toast({
					title: "Generate Matrix",
					description: "KO Matrix generated Successfully!",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				props.refreshKOGroups();
			} else {
				toast({
					title: "Generate Matrix",
					description: "Failed to generate KO Matrix",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
			setGenerateLoading(false);
		} catch (err) {
			console.log(err);
			toast({
				title: "Generate Matrix",
				description: "Failed to generate KO Matrix",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
			setGenerateLoading(false);
		}
	};

	const saveMatrix = async (koMatrix) => {
		try {
			setSaveLoading(true);
			let reqObj = {
				KOMatrix: [
					{
						TourEventId: props.eventId,
						KOMatrixId: koMatrix.KOMatrixId,
						Group1Name: koMatrix.Group1Name,
						Group2Name: koMatrix.Group2Name,
					},
				],
			};
			let res = await ApiService.httpPut("/KnockoutMatrix?Class=KM", reqObj);
			if (res.status === true) {
				toast({
					title: "Update Knockout Matrix",
					description: "Knockout Matrix Updated Successfully",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				props.refreshKOGroups();
				setEditToggleId("");
			} else {
				toast({
					title: "Update Knockout Matrix",
					description: "Failed to update Knockout Matrix",
					status: "warning",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				setEditToggleId("");
			}
			setSaveLoading(false);
		} catch (err) {
			console.log(err);
			toast({
				title: "Update Knockout Matrix",
				description: "Failed to update Knockout Matrix",
				status: "warning",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
			setEditToggleId("");
			setSaveLoading(false);
		}
	};

	return (
		<Card variant={"elevated"} bgColor="white" boxShadow={useColorModeValue("sm", "sm-dark")}>
			<CardHeader>
				<Stack direction="column" spacing="1rem">
					<Stack
						direction={{ base: "col", sm: "row" }}
						justify="space-between"
						align="center">
						<Text
							fontSize={{
								base: "md",
								md: "lg",
							}}
							textColor="accent"
							fontWeight="semibold">
							Knockout Matrix
						</Text>
						<Button
							color="white"
							bgColor="bg-accent"
							borderRadius="full"
							onClick={generateMatrix}
							isLoading={generateLoading}
							disabled={props.kmStatus === true}>
							Generate Matrix
						</Button>
					</Stack>
					<Table>
						<Tbody>
							{koMatrixLoad
								? Array(2)
										.fill()
										.map((rows, index) => (
											<Tr key={index}>
												<Td>
													<Skeleton
														isLoaded={!koMatrixLoad}
														h={"1.2rem"}
														w={"100%"}
													/>
												</Td>
												<Td>
													<Skeleton
														isLoaded={!koMatrixLoad}
														h={"1.2rem"}
														w={"100%"}
													/>
												</Td>
												<Td>
													<Skeleton
														isLoaded={!koMatrixLoad}
														h={"1.2rem"}
														w={"100%"}
													/>
												</Td>
												<Td>
													<Skeleton
														isLoaded={!koMatrixLoad}
														h={"1.2rem"}
														w={"100%"}
													/>
												</Td>
											</Tr>
										))
								: props.knockoutMatrixData?.map((koMatrix, index) => (
										<Tr key={koMatrix.KOMatrixId}>
											<Td textAlign="center">
												{editToggleId &&
												editToggleId === koMatrix.KOMatrixId ? (
													<FormControl>
														<Select
															name="Group"
															colorScheme="blue"
															defaultValue={koMatrix.Group1Name}
															onChange={(e) =>
																(koMatrix.Group1Name =
																	e.target.value)
															}>
															{props.groups.map((group, index) => {
																return (
																	<option
																		key={group.GroupName}
																		value={group.GroupId}>
																		{group.GroupName}
																	</option>
																);
															})}
														</Select>
													</FormControl>
												) : (
													<Text
														textAlign="center"
														fontSize="xl"
														fontWeight={"medium"}>
														{"Group - " + koMatrix.Group1Name}
													</Text>
												)}
											</Td>
											<Td textAlign="center">
												<Text
													textColor="accent"
													textAlign="center"
													fontSize="2xl"
													fontWeight="bold">
													Vs
												</Text>
											</Td>
											<Td textAlign="center">
												{editToggleId &&
												editToggleId === koMatrix.KOMatrixId ? (
													<FormControl>
														<Select
															name="Group"
															colorScheme="blue"
															defaultValue={koMatrix.Group2Name}
															onChange={(e) =>
																(koMatrix.Group2Name =
																	e.target.value)
															}>
															{props.groups.map((group, index) => {
																return (
																	<option
																		key={group.GroupName}
																		value={group.GroupId}>
																		{group.GroupName}
																	</option>
																);
															})}
														</Select>
													</FormControl>
												) : (
													<Text
														textAlign="center"
														fontSize="xl"
														fontWeight={"medium"}>
														{"Group - " + koMatrix.Group2Name}
													</Text>
												)}
											</Td>
											<Td textAlign="center">
												{koMatrix &&
												editToggleId !== koMatrix.KOMatrixId ? (
													<IconButton
														icon={<TbActions />}
														size="sm"
														mb={1}
														isLoading={saveLoading}
														onClick={() => {
															setEditToggleId(koMatrix.KOMatrixId);
														}}
													/>
												) : (
													<HStack justify="center" spacing={1}>
														<IconButton
															borderRadius="full"
															bgColor="#43CC18"
															size="sm"
															_hover={{ bgColor: "green" }}
															icon={<TbAdd />}
															isLoading={saveLoading}
															onClick={() => saveMatrix(koMatrix)}
														/>
														{!saveLoading && (
															<IconButton
																borderRadius="full"
																onClick={() => {
																	setEditToggleId("");
																}}
																bgColor="#FF4667"
																size="sm"
																_hover={{ bgColor: "red" }}
																_active={{ bgColor: "red" }}
																icon={<TbDelete />}
															/>
														)}
													</HStack>
												)}
											</Td>
											{/* <IconButton
									icon={<FiTrash />}
									color="red"
									bgColor="red.100"
									_hover={{ bgColor: "red.200" }}
									_active={{ bgColor: "red.300" }}
								/> */}
										</Tr>
								  ))}
						</Tbody>
					</Table>
				</Stack>
			</CardHeader>
		</Card>
	);
};

import {
	Card,
	CardHeader,
	Stack,
	Text,
	useBreakpointValue,
	useColorModeValue,
	Select,
	FormControl,
	Skeleton,
	IconButton,
	HStack,
} from "@chakra-ui/react";
import { FiRefreshCw } from "react-icons/fi";
import ApiService from "../../service/Api.service";
import { useState } from "react";

export const LeaderboardTabCard = ({
	eventId,
	groups,
	selectedGroup,
	updateLeaderData,
	leaderGroupsLoad,
	setLeaderGroupsLoad,
	setLeaderLoad,
}) => {
	const isDesktop = useBreakpointValue({
		base: false,
		md: true,
	});

	const stackDirection = useBreakpointValue({
		base: "column",
		md: "row",
	});

	const [refreshLoad, setRefreshLoad] = useState(false);

	async function refreshLeaderBoard() {
		setRefreshLoad(true);
		setLeaderGroupsLoad(true);
		setLeaderLoad(true);
		try {
			let leaderResp = await ApiService.httpPut(
				`/TourStandings?Class=Refresh&TourEventId=${eventId}&GroupName=${selectedGroup}`,
				{}
			);
			await updateLeaderData(selectedGroup);
		} catch (err) {
			console.log(err);
		} finally {
			setLeaderGroupsLoad(false);
			setLeaderLoad(false);
			setRefreshLoad(false);
		}
	}

	return (
		<Card variant={"elevated"} bgColor="white" boxShadow={useColorModeValue("sm", "sm-dark")}>
			<CardHeader>
				<Stack
					direction={useBreakpointValue({
						base: "row",
						xl: "row",
					})}
					spacing="1.2rem"
					justify="space-between"
					align={"center"}
					px={"1.2rem"}>
					<Text
						as="h3"
						fontSize={useBreakpointValue({
							base: "lg",
							md: "xl",
						})}
						fontWeight="semibold">
						Leaderboard for {selectedGroup}
					</Text>
					<HStack>
						<IconButton
							variant="ghost"
							size="md"
							color="accent"
							icon={<FiRefreshCw size="1.7rem" />}
							onClick={refreshLeaderBoard}
							isLoading={refreshLoad}
						/>
						<FormControl width={"-webkit-max-content"}>
							{leaderGroupsLoad ? (
								<Skeleton isLoaded={!leaderGroupsLoad} w="8vw">
									<Select />
								</Skeleton>
							) : (
								<Select
									name="Group"
									colorScheme="blue"
									width={"-webkit-max-content"}
									fontSize={{ base: "sm", sm: "sm", md: "md" }}
									textAlign="start"
									onChange={(event) => {
										updateLeaderData(event.target.value);
									}}>
									{groups.map((group, index) => {
										return (
											<option key={index} value={group}>
												{group}
											</option>
										);
									})}
								</Select>
							)}
						</FormControl>
					</HStack>
				</Stack>
			</CardHeader>
		</Card>
	);
};

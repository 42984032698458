import {
	Container,
	Heading,
	Icon,
	useBreakpointValue,
	Flex,
	Stack,
	Box,
	HStack,
	Divider,
	IconButton,
	Text,
	Card,
	CardHeader,
	CardBody,
	Center,
	Badge,
	Circle,
	Avatar,
	useColorModeValue,
	FormControl,
	Select,
	Tabs,
	Tab,
	TabList,
	TabPanels,
	TabPanel,
} from "@chakra-ui/react";
import { Navbar, NavbarEvents } from "../../Components/Navbar/Navbar";
import { StandingsTable } from "../../Components/Events/StandingsTable/StandingsTable";
import { CalendarWhite } from "../../icons/Icons";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ApiService from "../../service/Api.service";
import { TeamStandingsTable } from "../../Components/Events/TeamStandings/TeamStandings";
import { useAuthProvider } from "../../context/AuthProvider";

export const Standings = ({ eventId: propEventId }) => {
	// User Details
	const user = useAuthProvider();

	//Desktop BreakPoint Value
	const isDesktop = useBreakpointValue({
		base: false,
		lg: true,
	});
	// Mobile Breakpoint Value
	const isMobile = useBreakpointValue({
		base: true,
		sm: true,
		md: false,
	});
	//Page Header
	const pageHeader = useBreakpointValue({
		base: "xs",
		sm: "sm",
	});
	//Box Shadow
	const shadow = useColorModeValue("sm", "sm-dark");
	//Card Heading
	const cardHeading = useBreakpointValue({
		base: "xs",
		md: "sm",
	});

	let { eventId: urlParamEventId } = useParams();
	let eventId = propEventId || urlParamEventId;
	let [eventType, setEventType] = useState(null);

	let [groups, setGroups] = useState([]);
	let [standingsRounds, setStandingsRounds] = useState([]);
	let [selectedStandingsRound, setSelectedStandingsRound] = useState({});
	let [selectedStandingsGroup, setSelectedStandingsGroup] = useState({});
	let [standingsData, setStandingsData] = useState([]);
	const [teamStandingsData, setTeamStandingsData] = useState([]);
	let [tourEventName, setTourEventName] = useState("");
	let [selectedGroupId, setSelectedGroupId] = useState("");

	const fetchStandings = async (selectedRound) => {
		try {
			let roundNo = selectedRound.RoundNo;
			let standingsResponse = await ApiService.httpGet(
				`/TourStandings?TourEventId=${eventId}&GroupName=A&RoundNo=${roundNo}`,
				"U00184"
			);
			setStandingsData(standingsResponse.TourStandings);
			setTourEventName(standingsResponse.TourEventName + " " + "Event");
			let standResp = await ApiService.httpGet(
				`/TourStandings?Class=Teams&TourEventId=${eventId}&GroupName=A&RoundNo=${roundNo}`,
				"U00184"
			);
			setTeamStandingsData(standResp.TourStandings);
			//setLoading(false);
		} catch (err) {
			console.log(err);
		}
	};

	const fetchData = async () => {
		Promise.all([
			ApiService.httpGet("/EventTeams?GroupsEventId=" + eventId, "U00184"),
			ApiService.httpGet(
				"/TourStandings?Class=RoundsLookup&TourEventId=" + eventId,
				"U00184"
			),
		])
			.then(function (responses) {
				// Get a JSON object from each of the responses
				return Promise.all(
					responses.map(function (response) {
						return response;
					})
				);
			})
			.then(function (data) {
				// Log the data to the console
				// You would do something with both sets of data here
				console.log(data);
				setGroups(data[0].GroupNames);
				setStandingsRounds(data[1].Rounds);
				setSelectedStandingsGroup(data[0].GroupNames[0]);
				setSelectedStandingsRound(data[1].Rounds.find((round) => round.Latest === "YES"));
				fetchStandings(data[1].Rounds.find((round) => round.Latest === "YES"));
			})
			.catch(function (error) {
				// if there's an error, log it
				console.log(error);
			});
	};

	useEffect(() => {
		fetchData();
	}, []);

	const updateStandingsData = async (groupNameSelected, roundNo) => {
		console.log("Updated GroupName", groupNameSelected);
		console.log("Updated RoundNo", roundNo);
		setSelectedStandingsRound({ RoundNo: roundNo });
		setSelectedStandingsGroup({ GroupName: groupNameSelected });
		const group = groups.find(obj => obj.GroupName === groupNameSelected);
		const {GroupId, GroupName} = group;
		
		try {
			let updatedResponse = await ApiService.httpGet(
				`/TourStandings?TourEventId=${eventId}&GroupName=${GroupId}&RoundNo=${roundNo}`,
				"U00184"
			);
			console.log("Updated Standings Data:", updatedResponse.TourStandings);
			setStandingsData(updatedResponse.TourStandings);
			setEventType(updatedResponse.EventType);
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<Container
			flex="1"
			maxWidth="100vw"
			{...(propEventId === undefined && {
				marginTop: isDesktop ? "7rem" : "5rem",
			})}
			marginX={0}
			{...(isMobile && { px: "1" })}
			paddingBottom={10}>
			{eventType === "Teams" ? (
			
				<Stack spacing="2rem">
				<Card variant={"elevated"} bgColor="white" boxShadow={shadow}>
					<CardHeader>
						<Stack
							direction={{
								base: "column",
								md: "row",
							}}
							spacing="1.2rem"
							align={{
								base: "start",
								md: "center",
							}}
							justify="space-between">
							<HStack spacing="1.2rem" align="center">
								{isMobile ? (
									""
								) : (
									<Center bgColor="accent" p="1rem" borderRadius="xl">
										<Icon as={CalendarWhite} />
									</Center>
								)}
								<Heading as="h2" size={cardHeading}>
									{tourEventName}
								</Heading>
							</HStack>
							<Stack spacing="1rem" direction={"row"}>
								<FormControl>
									<Select
										name="Group"
										colorScheme="blue"
										fontSize={{ base: "sm", md: "md" }}
										defaultValue={selectedStandingsGroup.GroupName}
										onChange={(event) => {
											updateStandingsData(
												event.target.value,
												selectedStandingsRound.RoundNo
											);
										}}>
										{groups.map((group, index) => {
											return (
												<option
													key={group.GroupName}
													value={group.GroupName}>
													{group.GroupName}
												</option>
											);
										})}
									</Select>
								</FormControl>
								<FormControl>
									<Select
										name="Round"
										colorScheme="blue"
										fontSize={{ base: "sm", md: "md" }}
										value={selectedStandingsRound.RoundNo}
										onChange={(event) => {
											updateStandingsData(
												selectedStandingsGroup.GroupName,
												event.target.value
											);
										}}>
										{standingsRounds.map((round, index) => {
											return (
												<option
													key={round.RoundNo}
													value={round.RoundNo}>
													{round.RoundName}
												</option>
											);
										})}
									</Select>
								</FormControl>
							</Stack>
							
						</Stack>
					</CardHeader>
				</Card>
				<Tabs variant="enclosed-colored" isFitted size="lg">
			
					<TabList>
						<Tab>Team Standings</Tab>

						<Tab>Player Standings</Tab>
					</TabList>
					<TabPanels>
							<TabPanel p={0}>
								<TeamStandingsTable standingsData={teamStandingsData} />
							</TabPanel>
							<TabPanel p={0}>
								<StandingsTable standingsData={standingsData} />
							</TabPanel>
						</TabPanels>
					
				</Tabs>
				</Stack>
				) : (
					<Stack spacing="2rem">
						<Card variant={"elevated"} bgColor="white" boxShadow={shadow}>
							<CardHeader>
								<Stack
									direction={{
										base: "column",
										md: "row",
									}}
									spacing="1.2rem"
									align={{
										base: "start",
										md: "center",
									}}
									justify="space-between">
									<HStack spacing="1.2rem" align="center">
										{isMobile ? (
											""
										) : (
											<Center bgColor="accent" p="1rem" borderRadius="xl">
												<Icon as={CalendarWhite} />
											</Center>
										)}
										<Heading as="h2" size={cardHeading}>
											{tourEventName}
										</Heading>
									</HStack>
									<Stack spacing="1rem" direction={"row"}>
										<FormControl>
											<Select
												name="Group"
												colorScheme="blue"
												fontSize={{ base: "sm", md: "md" }}
												defaultValue={selectedStandingsGroup.GroupName}
												onChange={(event) => {
													updateStandingsData(
														event.target.value,
														selectedStandingsRound.RoundNo
													);
												}}>
												{groups.map((group, index) => {
													return (
														<option
															key={group.GroupName}
															value={group.GroupName}>
															{group.GroupName}
														</option>
													);
												})}
											</Select>
										</FormControl>
										<FormControl>
											<Select
												name="Round"
												colorScheme="blue"
												fontSize={{ base: "sm", md: "md" }}
												value={selectedStandingsRound.RoundNo}
												onChange={(event) => {
													updateStandingsData(
														selectedStandingsGroup.GroupName,
														event.target.value
													);
												}}>
												{standingsRounds.map((round, index) => {
													return (
														<option
															key={round.RoundNo}
															value={round.RoundNo}>
															{round.RoundName}
														</option>
													);
												})}
											</Select>
										</FormControl>
									</Stack>
									
								</Stack>
							</CardHeader>
						</Card>
						<StandingsTable standingsData={standingsData} />
				</Stack>
				)}
		</Container>
	);
};

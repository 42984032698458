import React from "react";
import {
	Table,
	Thead,
	Th,
	Td,
	Tr,
	Tbody,
	Stack,
	HStack,
	Flex,
	Image,
	Box,
	CardHeader,
	Card,
	CardBody,
	Heading,
	ScaleFade,
} from "@chakra-ui/react";
import clubLogo from "../../../assets/clublogo.png";
import logo from "../../../images/logo.png";
import { useState } from "react";
import ApiService from "../../../service/Api.service";
import { useEffect } from "react";
import { useAuthProvider } from "../../../context/AuthProvider";

export const DaySchedule = ({ tourId }) => {
	// User Details
	const user = useAuthProvider();

	const [scheduleTable, setScheduleTable] = useState(null);
	async function fetchDaySchedule() {
		try {
			let scheduleResp = await ApiService.httpGet(
				`/StreamingConfigurations?Class=DaySchedule&TourId=${tourId}`,
				user.userId
			);
			if (scheduleResp.status === true) {
				setScheduleTable(scheduleResp.Schedule);
			}
		} catch (err) {
			console.log(err);
		}
	}
	useEffect(() => {
		fetchDaySchedule();
	}, [tourId]);
	if (scheduleTable?.length > 0) {
		return (
			<ScaleFade initialScale={0.1} in>
				<Card
					bg="white"
					boxShadow={"2xl"}
					minW={"70vw"}
					userSelect={"none"}
					draggable="false">
					<CardHeader py={1}>
						<HStack align="center" justify={"center"} minH={75} minW={500}>
							<Image
								src={clubLogo}
								alt="Club Image"
								blockSize={50}
								objectFit="contain"
								position="absolute"
								left={5}
								zIndex={1} // Ensure the overlay is above other components
							/>
							<Heading
								as={"h2"}
								size="sm"
								fontSize="2xl"
								fontWeight="bold"
								color="#0AB042"
								textAlign="center"
								w={"fit-content"}
								textTransform={"uppercase"}>
								DAY SCHEDULE
							</Heading>
							<Image
								src={logo}
								alt="Carrom Platform Logo"
								blockSize={75}
								objectFit="contain"
								position="absolute"
								right={0}
								zIndex={1} // Ensure the overlay is above other components
							/>
						</HStack>
					</CardHeader>

					<CardBody boxShadow="sm" py={0}>
						<Table variant={"striped"}>
							<Thead>
								<Tr>
									<Th textColor="#4B39B7" fontSize="md" textAlign="start">
										Start Time
									</Th>

									<Th textColor="#4B39B7" fontSize="md" textAlign="start">
										End Time
									</Th>

									<Th textColor="#4B39B7" fontSize="md" textAlign="start">
										Description
									</Th>
								</Tr>
							</Thead>
							<Tbody>
								{scheduleTable?.map((board, i) => {
									if (board.Type === "B") {
										return (
											<Tr key={i}>
												<Td
													py={"0.5rem"}
													textAlign="center"
													fontSize="md"
													fontWeight="bold"
													textColor="#4B39B7"
													colSpan={3}>
													{board.Description}
												</Td>
											</Tr>
										);
									} else {
										return (
											<Tr key={i}>
												<Td
													py={"0.5rem"}
													textAlign="start"
													fontSize="sm"
													fontWeight="medium"
													textColor={"black"}>
													{board.StartTime}
												</Td>

												<Td
													py={"0.5rem"}
													textAlign="start"
													fontSize="sm"
													fontWeight="medium"
													textColor={"black"}>
													{board.EndTime}
												</Td>

												<Td
													py={"0.5rem"}
													textAlign="start"
													fontSize="sm"
													fontWeight="medium"
													textColor={"black"}>
													{board.Description}
												</Td>
											</Tr>
										);
									}
								})}
							</Tbody>
						</Table>
					</CardBody>
				</Card>
			</ScaleFade>
		);
	} else {
		return null;
	}
};

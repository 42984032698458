import {
	Container,
	Stack,
	IconButton,
	Heading,
	HStack,
	useBreakpointValue,
	Flex,
	Button,
	ButtonGroup,
	useToast,
	Box,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";

import { Navbar } from "../../Components/Navbar/Navbar";
import { Sidebar } from "../../Components/Sidebar/Sidebar";

import { IoChevronBack } from "react-icons/io5";
import { useForm } from "react-hook-form";
import { useContext, useEffect, useState } from "react";
import ApiService from "../../service/Api.service";
import { TournamentsForm1 } from "../../Components/Tournaments/AddTournamentForm/TournamentsForm1";
import { TournamentsForm2 } from "../../Components/Tournaments/AddTournamentForm/TournamentsForm2";
import { UpdateTournamentsForm1 } from "../../Components/Tournaments/UpdateTournamentForm/TournamentsForm1";
import { UpdateTournamentsForm2 } from "../../Components/Tournaments/UpdateTournamentForm/TournamentsForm2";
import { useAuthProvider } from "../../context/AuthProvider";
import { BallTriangle } from "react-loader-spinner";

export const UpdateTournament = () => {
	// User Details
	const user = useAuthProvider();

	let navigate = useNavigate();

	// Toast
	let toast = useToast();

	let [page, setPage] = useState(0);
	const [loading, setLoading] = useState(false);
	const steps = ["Basic Details", "Additional Details"];

	// const { setClubs } = useContext(data);
	let [clubs, setClubs] = useState([]);

	let getStepContent = () => {
		console.log(page);
		if (page === 0) {
			return (
				<UpdateTournamentsForm1
					tour={tours}
					register={register}
					clubs={clubs}
					errors={errors}
				/>
			);
		} else if (page === 1) {
			return <UpdateTournamentsForm2 tour={tours} register={register} errors={errors} />;
		} else {
			return <Heading> Wrong page </Heading>;
		}
	};

	const {
		register,
		handleSubmit,
		setValue,
		formState: { isDirty, isValid, errors },
	} = useForm({
		delayError: "500",
		mode: "all",
	});

	const onSubmit = (data) => {
		console.log(data);
		let tournamentObj = {
			ClubId: data.FormValues.ClubId,
			TournamentName: data.FormValues.TournamentName,
			ContactEmail: data.FormValues.ContactEmail,
			ContactNo: data.FormValues.ContactNo,
			StartDate: data.FormValues.StartDate,
			EndDate: data.FormValues.EndDate,
			FullAddress: data.FormValues.FullAddress,
			WebsiteURL: data.FormValues.WebsiteURL,
			DiscountDetails: data.FormValues.DiscountDetails,
			AdditionalNotes: data.FormValues.AdditionalNotes,
			AdditionalDetails: data.FormValues.AdditionalDetails,
			Schedule: data.FormValues.Schedule,
			Trophies: data.FormValues.Trophies,
			ListName: data.FormValues.ListName,
			VenueCity: "Hyderabad",
			VenueState: "Telangana",
			VenueCountry: "India",
		};

		console.log("saved", tournamentObj);
		updateTournament(tournamentObj);
	};

	let handleNext = () => {
		setPage(page + 1);
	};

	let handleBack = () => {
		setPage(page - 1);
	};

	// breakpoint value for Desktop
	const isDesktop = useBreakpointValue({
		base: false,
		lg: true,
	});

	const location = useLocation();

	const fetchClubs = async () => {
		try {
			const ClubsResponse = await ApiService.httpGet("/Tournaments?ClubId=all", user.userId);
			console.log("get Clubs from AddTour", ClubsResponse);
			setClubs(ClubsResponse.Clubs);
			console.log(clubs);
		} catch (err) {
			console.error(err.message);
		}
	};

	const [tours, setTours] = useState([]);
	const fetchTourDetails = async () => {
		try {
			setLoading(true);
			await fetchClubs();
			const response = await ApiService.httpGet(
				"/Tournaments?TourId=" + location.state.tourId,
				user.userId
			);
			let res = response.Tournaments[0];
			setTours(res);
			console.log("Selected Tournaments", res);
			setTourValues(res);
			setLoading(false);
		} catch (err) {
			console.error(err.message);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchTourDetails();
	}, []);

	const setTourValues = (tournamentData) => {
		setValue("FormValues", {
			ClubId: tournamentData.ClubId,
			TournamentName: tournamentData.TournamentName,
			StartDate: tournamentData.StartDate,
			EndDate: tournamentData.EndDate,
			ContactNo: tournamentData.ContactNo,
			ContactEmail: tournamentData.ContactEmail,
			FullAddress: tournamentData.FullAddress,
			WebsiteURL: tournamentData.WebsiteURL,
			DiscountDetails: tournamentData.DiscountDetails,
			AdditionalNotes: tournamentData.AdditionalNotes,
			AdditionalDetails: tournamentData.AdditionalDetails,
			Schedule: tournamentData.Schedule,
			Trophies: tournamentData.Trophies,
			ListName: tournamentData.ListName,
		});
	};

	const updateTournament = async (reqObj) => {
		try {
			setLoading(true);
			const tournament = await ApiService.httpPut(
				"/Tournaments?TourId=" + location.state.tourId,
				reqObj
			);
			console.log("SavedTournaments", tournament);
			console.log("Successfully Tournament Updated.");
			toast({
				title: "Update Tournament",
				description: "Tournament Updated Successfully!",
				status: "success",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
			navigateToTournaments();
			setLoading(false);
			//   setAlertMessage("Tournament Added Successfully");
			//   setAlertTrigger(!AlertTrigger);
		} catch (err) {
			console.error(err.message);
			toast({
				title: "Update Tournament",
				description: "Failed to Update Tournament",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
		} finally {
			setLoading(false);
		}
	};

	const navigateToTournaments = () => {
		return navigate("/tournaments");
	};

	return (
		<Container
			flex="1"
			overflowY="auto"
			maxWidth="100vw"
			marginTop={isDesktop ? "3.5rem" : "0rem"}
			marginX={0}
			paddingBottom={10}
			css={{
				"::-webkit-scrollbar": {
					width: "0.5rem",
					height: "0.5rem",
				},
				"::-webkit-scrollbar-track": {
					background: "#bee3f8",
					borderRadius: "1rem",
				},
				"::-webkit-scrollbar-thumb": {
					background: "#3182ce",
					borderRadius: "1rem",
				},
			}}>
			<Stack
				mt={10}
				spacing={{
					base: "8",
					lg: "6",
				}}>
				<Stack
					spacing="4"
					direction={{
						base: "column",
						lg: "row",
					}}
					justify="space-between"
					align={{
						base: "start",
						lg: "center",
					}}>
					<HStack spacing="5">
						<IconButton
							borderRadius="full"
							p={2}
							size="md"
							bgColor="white"
							as={IoChevronBack}
							onClick={navigateToTournaments}
						/>
						<Heading
							size={useBreakpointValue({
								base: "xs",
								sm: "sm",
							})}
							fontWeight="bold">
							Update Tournament
						</Heading>
					</HStack>
				</Stack>
				{loading ? (
					<Box
						position={"fixed"}
						bottom={"50%"}
						left={"50%"}
						m={0}
						p={0}
						zIndex={"overlay"}>
						<BallTriangle
							height={100}
							width={100}
							radius={5}
							color="#4fa94d"
							ariaLabel="ball-triangle-loading"
							wrapperClass={{}}
							wrapperStyle=""
							visible={true}
						/>
					</Box>
				) : (
					<form onSubmit={handleSubmit(onSubmit)}>
						<Stack spacing="1rem">
							{getStepContent()}
							<Flex justify="end">
								<ButtonGroup spacing="5">
									<Button
										onClick={page === 0 ? navigateToTournaments : handleBack}
										size="lg"
										borderRadius="full"
										colorScheme="red">
										{page === 0 ? "Cancel" : "Back"}
									</Button>
									{page !== steps.length - 1 && (
										<Button
											type="button"
											onClick={handleNext}
											size="lg"
											borderRadius="full"
											colorScheme="blue">
											Next
										</Button>
									)}
									{page === steps.length - 1 && (
										<Button
											type="submit"
											isDisabled={!isDirty || !isValid}
											size="lg"
											borderRadius="full"
											colorScheme="blue">
											Submit
										</Button>
									)}
									{/* <Button
										type={page === steps.length - 1 ? "submit" : "button"}
										{...(page !== steps.length - 1 && {
											onClick: () => handleNext(),
										})}
										{...(page === steps.length - 1 && { isDisabled: !isDirty })}
										size="lg"
										borderRadius="full"
										colorScheme="blue">
										{page === steps.length - 1 ? "Submit" : "Next"}
									</Button> */}
								</ButtonGroup>
							</Flex>
						</Stack>
					</form>
				)}
			</Stack>
		</Container>
	);
};
import {
	Card,
	Button,
	CardHeader,
	IconButton,
	Icon,
	Input,
	InputGroup,
	InputLeftElement,
	Stack,
	Text,
	useBreakpointValue,
	useColorModeValue,
	CloseButton,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	useDisclosure,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	ButtonGroup,
	Table,
	Box,
	Thead,
	Tr,
	Th,
	Tbody,
	Td,
	FormControl,
	HStack,
	useToast,
	Select,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { BsDownload, BsUpload } from "react-icons/bs";
import {
	FiDownloadCloud,
	FiMoreHorizontal,
	FiMoreVertical,
	FiSearch,
	FiTrash,
	FiUploadCloud,
} from "react-icons/fi";
import { HiDotsVertical, HiDownload, HiUpload } from "react-icons/hi";
import { SlPaperPlane } from "react-icons/sl";
import { useNavigate } from "react-router-dom";
import { TbActions, TbAdd, TbDelete } from "../../icons/Icons";
import ApiService from "../../service/Api.service";
import { DoublesAddTeamModal } from "../Events/DoublesTeamModal/DoublesAddTeamModal";

export const TeamsTabCard = ({ eventId }) => {
	// Box Shadow
	const shadow = useColorModeValue("sm", "sm-dark");

	const toast = useToast();

	const [menuLoading, setMenuLoading] = useState(false);

	const handleNotificaiton = async (event) => {
		console.log("Action performed for:", event.target.value);
		let notification = event.target.value;
		switch (notification) {
			case "sendLink":
				try {
					setMenuLoading(true);
					let link = await ApiService.httpPost(
						`/ScoreLink?Class=Teams&EventId=${eventId}`,
						{}
					);
					if (link.status === true) {
						toast({
							title: "Send Teams",
							description: "Teams Sent Successfully!",
							status: "success",
							duration: 3000,
							isClosable: true,
							position: "top-right",
						});
					} else {
						toast({
							title: "Send Teams",
							description: "Failed to send Teams",
							status: "error",
							duration: 3000,
							isClosable: true,
							position: "top-right",
						});
					}
					setMenuLoading(false);
				} catch (err) {
					console.log(err);
					toast({
						title: "Send Teams",
						description: "Failed to send Teams",
						status: "error",
						duration: 3000,
						isClosable: true,
						position: "top-right",
					});
					setMenuLoading(false);
				}
		}
	};

	return (
		<>
			<Card variant={"elevated"} bgColor="white" boxShadow={shadow}>
				<CardHeader>
					<Stack
						direction={{ base: "column", sm: "row" }}
						justify={"space-between"}
						align={"center"}
						spacing=".7rem">
						<Text
							as="h3"
							fontSize={useBreakpointValue({
								base: "lg",
								md: "xl",
							})}
							fontWeight="semibold">
							Teams
						</Text>
						<Menu direction="rtl">
							{({ isOpen }) => (
								<>
									<MenuButton
										isActive={isOpen}
										as={IconButton}
										variant="ghost"
										color="green.500"
										size="md"
										borderRadius="full"
										isLoading={menuLoading}
										icon={
											isOpen ? (
												<CloseButton variant="ghost" />
											) : (
												<FiMoreVertical size="1.7rem" />
											)
										}
									/>
									<MenuList zIndex="overlay">
										<MenuItem
											textColor="green.400"
											fontWeight="medium"
											value="sendLink"
											onClick={handleNotificaiton}>
											Send Player Info
										</MenuItem>
									</MenuList>
								</>
							)}
						</Menu>
						{/* <Button
							textColor="white"
							color="green.500"
							bgColor="green.100"
							onClick={sendTeams}>
							Send Teams
						</Button> */}
					</Stack>
				</CardHeader>
			</Card>
		</>
	);
};

import {
	Box,
	Divider,
	FormControl,
	FormLabel,
	Input,
	Stack,
	useColorModeValue,
	Select,
	IconButton,
	InputGroup,
	FormErrorMessage,
	InputRightElement,
	CloseButton,
	Button,
	Text,
} from "@chakra-ui/react";
import * as React from "react";
import { useState } from "react";
import { Controller, useWatch } from "react-hook-form";

export const UpdateMemberForm = ({
	register,
	watch,
	errors,
	control,
	clubs,
	roles,
	setClubRoles,
	clubRoles,
	addRoleClubPair,
	removeRoleClubPair,
	clearErrors,
	handleFileChange,
	setValue,
}) => {
	//Box Shadow
	const shadow = useColorModeValue("sm", "sm-dark");

	let [selectedRole, setSelectedRole] = useState("");
	let [selectedClub, setSelectedClub] = useState("");

	const handleFieldChange = (e, index, fieldType) => {
		const updatedFields = [...clubRoles];
		if (fieldType === "club") {
			updatedFields[index].club = e.target.value;
			const selectedClubCopy = [...selectedClub];
			selectedClubCopy[index] = e.target.value;
			console.log("Selected Club Values:", selectedClubCopy);
			setSelectedClub(selectedClubCopy);
		} else if (fieldType === "role") {
			if (e.target.value === "R002") {
				updatedFields[index].club = "";
				const selectedClubCopy = [...selectedClub];
				selectedClubCopy[index] = "";
				console.log("Selected Club Values:", selectedClubCopy);
				setSelectedClub(selectedClubCopy);
				setValue(`clubs[${index}]`, "");
				clearErrors(`clubs[${index}]`);
			} else {
				updatedFields[index].role = e.target.value;
				const selectedRoleCopy = [...selectedRole];
				selectedRoleCopy[index] = e.target.value;
				console.log("Selected Role Values:", selectedRoleCopy);
				setSelectedRole(selectedRoleCopy);
			}
		}
		setClubRoles(updatedFields);
	};

	return (
		<Box bg="bg-surface" boxShadow={shadow} borderRadius="lg">
			<Stack
				spacing="5"
				px={{
					base: "4",
					md: "6",
				}}
				py={{
					base: "5",
					md: "6",
				}}>
				<Stack
					spacing="6"
					direction={{
						base: "column",
						md: "row",
					}}>
					<FormControl id="firstName" isInvalid={errors.FormValues?.FirstName}>
						<FormLabel>First Name</FormLabel>
						<Input
							type="text"
							{...register("FormValues.FirstName", {
								required: "Please enter your First Name",
								pattern: {
									value: /^[A-Za-z\s]{1,}[\.]{0,1}[A-Za-z\s]{0,}$/,
									message: "Please enter a valid First Name",
								},
							})}
						/>
						<FormErrorMessage>
							{errors.FormValues?.FirstName && errors.FormValues?.FirstName.message}
						</FormErrorMessage>
					</FormControl>
					<FormControl id="lastName" isInvalid={errors.FormValues?.LastName}>
						<FormLabel>Last Name</FormLabel>
						<Input
							type="text"
							{...register("FormValues.LastName", {
								required: "Please enter your Last Name",
								pattern: {
									value: /^[A-Za-z\s]{1,}[\.]{0,1}[A-Za-z\s]{0,}$/,
									message: "Please enter a valid Last Name",
								},
							})}
						/>
						<FormErrorMessage>
							{errors.FormValues?.LastName && errors.FormValues?.LastName.message}
						</FormErrorMessage>
					</FormControl>
				</Stack>
				<Stack
					spacing="6"
					direction={{
						base: "column",
						md: "row",
					}}>
					<FormControl id="email" isInvalid={errors.FormValues?.Email}>
						<FormLabel>Email Address</FormLabel>
						<Input
							type="email"
							{...register("FormValues.Email", {
								required: "Please enter your email",
								pattern: {
									value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
									message: "Please enter a valid Email",
								},
							})}
						/>
						<FormErrorMessage>
							{errors.FormValues?.Email && errors.FormValues?.Email.message}
						</FormErrorMessage>
					</FormControl>
					<FormControl id="phone" isInvalid={errors.FormValues?.PhoneNo}>
						<FormLabel>Phone Number</FormLabel>
						<Input
							type="tel"
							{...register("FormValues.PhoneNo", {
								required: "Please enter your Phone Number",
								pattern: {
									value: /^(?:\+\d{1,2}\s?)?(?=\d{1,15}$)(?:\d{10}|\d{3}-\d{3}-\d{4}|\(\d{3}\)\s?\d{3}-\d{4})$|^\+91[1-9]\d{9}$|^\+44\d{10}$|^\+971\d{9}$/,
									message: "Please enter a valid Phone Number",
								},
							})}
						/>
						<FormErrorMessage>
							{errors.FormValues?.PhoneNo && errors.FormValues?.PhoneNo.message}
						</FormErrorMessage>
						{/* <PhoneInput
							{...register("Phone", { required: true })}
							country={"us"}
							preferredCountries={["us", "ca", "in", "ae"]}
							inputStyle={{
								paddingTop: "0.4rem",
								paddingBottom: "0.4rem",
								width: "-webkit-fill-available",
							}}
						/> */}
					</FormControl>
				</Stack>
				{clubRoles?.map((role, index) => (
					<Stack
						spacing="6"
						direction={{
							base: "column",
							md: "row",
						}}
						key={index}>
						<FormControl isInvalid={errors.FormValues?.roles}>
							<FormLabel>Role</FormLabel>
							<Controller
								name={`FormValues.roles[${index}]`}
								control={control}
								defaultValue={role}
								rules={{ required: "Select a Role" }}
								render={({ field: { value, onChange } }) => (
									<Select
										value={value}
										colorScheme="blue"
										placeholder="Select a Role"
										onChange={(e) => {
											handleFieldChange(e, index, "role");
											onChange(e);
										}}>
										{/* {watch("clubs")[index] !== null &&
										watch("clubs")[index] !== "" &&
										watch("clubs")[index] !== undefined
											? roles
													.filter((role) => role.RoleId !== "R002")
													.map((role) => {
														return (
															<option
																value={role.RoleId}
																key={role.RoleId}>
																{role.RoleName}
															</option>
														);
													}): */}
										{roles.map((role) => {
											return (
												<option value={role.RoleId} key={role.RoleId}>
													{role.RoleName}
												</option>
											);
										})}
									</Select>
								)}
							/>
							<FormErrorMessage>
								{errors.FormValues?.roles &&
									errors.FormValues?.roles[index]?.message}
							</FormErrorMessage>
						</FormControl>
						<FormControl isInvalid={errors.FormValues?.clubs}>
							<FormLabel>Club</FormLabel>
							<Controller
								name={`FormValues.clubs[${index}]`}
								control={control}
								defaultValue={watch("FormValues.clubs")[index]}
								onChange={([selected]) => selected}
								rules={{
									required:
										watch("FormValues.roles")[index] === "R002"
											? false
											: "Select a Club",
								}}
								render={({ field: { value, onChange } }) => (
									<Select
										value={value}
										colorScheme="blue"
										placeholder="Select a Club"
										isDisabled={watch("FormValues.roles")[index] === "R002"}
										onChange={(e) => {
											handleFieldChange(e, index, "club");
											onChange(e);
										}}>
										{clubs.map((club) => (
											<option key={club.ClubId} value={club.ClubId}>
												{club.ClubName}
											</option>
										))}
									</Select>
								)}
							/>
							<FormErrorMessage>
								{errors.FormValues?.clubs &&
									errors.FormValues?.clubs[index]?.message}
							</FormErrorMessage>
						</FormControl>

						{index > 0 && (
							<CloseButton
								alignSelf="end"
								onClick={() => removeRoleClubPair(index)}
							/>
						)}
					</Stack>
				))}
				<Button
					type="button"
					width={"-webkit-fit-content"}
					size="sm"
					colorScheme="blue"
					borderRadius="full"
					onClick={addRoleClubPair}>
					Add Role
				</Button>
				<Stack
					spacing="6"
					direction={{
						base: "column",
						md: "row",
					}}>
					<FormControl id="ImageLink" isInvalid={errors.FormValues?.ImageLink}>
						<FormLabel>Member Image</FormLabel>
						<input
							type="file"
							name="FormValues.ImageLink"
							accept=".jpg, .jpeg, .jfi, .jpe, .jif, .jfif, .heif, .heic, .png, .svg, .svgz, .raw"
							{...register("FormValues.ImageLink", {
								onChange: (e) => handleFileChange(e),
							})}
						/>

						<FormErrorMessage>
							{errors.FormValues?.ImageLink && errors.FormValues?.ImageLink.message}
						</FormErrorMessage>
					</FormControl>
				</Stack>
			</Stack>
			<Divider />
		</Box>
	);
};

import {
	HStack,
	Image,
	Table,
	Tbody,
	Td,
	Box,
	Tr,
	Th,
	Thead,
	VStack,
	FormControl,
	Select,
	Input,
	TableContainer,
	useBreakpointValue,
	Container,
	Text,
	Stack,
	Divider,
	useColorModeValue,
	FormLabel,
	IconButton,
	Icon,
	position,
	ButtonGroup,
	Button,
	Flex,
	Checkbox,
	useToast,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
	useDisclosure,
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogCloseButton,
	AlertDialogBody,
	AlertDialogFooter,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalCloseButton,
	Skeleton,
} from "@chakra-ui/react";
import { async } from "q";
import { BsTrash } from "react-icons/bs";
import { FiTrash } from "react-icons/fi";
import ApiService from "../../../service/Api.service";
import { data } from "./data";
import { useEffect, useState, useRef } from "react";
import { TbAdd, TbDelete } from "../../../icons/Icons";

export const KnockoutGroupsTable = (props) => {
	//Desktop BreakPoint Value
	const isDesktop = useBreakpointValue({ base: false, xl: true });
	const toast = useToast();

	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();
	const cancelRef = useRef();

	// Loading States
	let koGroupsLoad = props.koGroupsLoad;

	let [currGroup, setCurrGroup] = useState("");
	const [generateLoading, setGenerateLoading] = useState(false);
	const [delLoading, setDelLoading] = useState(false);
	const [saveLoading, setSaveLoading] = useState(false);

	const generateMatchups = async () => {
		let reqObj = {
			EventId: props.state.EventId,
		};
		try {
			setGenerateLoading(true);
			let response = await ApiService.httpPost(
				`/KnockoutMatchups?Class=GenerateMatchups`,
				reqObj
			);
			console.log("KO Groups", response);
			if (response.status === true) {
				onClose();
				toast({
					title: "Generate Knockout Matchups!",
					description: "Generated Knockout Matchups Successfully.",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				props.fetchFlags();
				props.navigateToKOMatchups();
			} else if (response.status === false) {
				onClose();
				toast({
					title: "Generate Knockout Matchups!",
					description: response.Message,
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			} else {
				onClose();
				toast({
					title: "Generate Knockout Matchups!",
					description: "Failed to Generate Knockout Matchups.",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
			setGenerateLoading(false);
		} catch (err) {
			console.log(err);
			onClose();
			toast({
				title: "Generate Knockout Matchups!",
				description: "Failed to Generate Knockout Matchups.",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
			setGenerateLoading(false);
		}
	};
	// useEffect(() => {
	// 	setAddGroup(props.knockoutGroupsData);
	// 	console.log("initial data set");
	// }, [props]);

	const addGroupButton = () => {
		let newGroup = {
			TournamentId: props.state.TourId,
			TourEventId: props.state.EventId,
			KOGroupName: "",
			StartingPosition: 0,
			EndingPosition: 0,
			SetCount: 3,
		};

		props.setKnockoutGroupsData([...props.knockoutGroupsData, newGroup]);
		console.log("Added New Group...");
		props.setDisableAddBtn(true);
	};

	const removeField = (index) => {
		index > -1 && props.setKnockoutGroupsData((prev) => prev.filter((_, i) => i !== index));
		props.setDisableAddBtn(false);
	};

	const handleDelete = async () => {
		try {
			setDelLoading(true);
			let res = await ApiService.httpDelete("/KnockoutMatrix?KOGroupId=" + currGroup);
			console.log(res);
			if (res.status === true) {
				onAlertClose();
				toast({
					title: "Delete KO Group",
					description: "KO Group Deleted Successfully!",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				props.refreshKOGroups();
			} else {
				onAlertClose();
				toast({
					title: "Delete KO Group",
					description: "Failed to Delete KO Group",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
			setDelLoading(false);
		} catch (err) {
			console.log(err);
			onAlertClose();
			toast({
				title: "Delete KO Group",
				description: "Failed to Delete KO Group",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
			setDelLoading(false);
		}
	};

	const saveKG = async (board) => {
		try {
			setSaveLoading(true);
			let reqObj = board;
			console.log(reqObj);
			let savedResponse =
				"KOGroupId" in reqObj
					? await ApiService.httpPut(`/KnockoutMatrix?Class=KG`, reqObj)
					: await ApiService.httpPost(`/KnockoutMatrix?Class=KG`, reqObj);
			console.log("Save Knockout Groups Data:", savedResponse);
			if (savedResponse.status === true) {
				toast({
					title: "Save Knockout Groups!",
					description: "Successfully Saved Knockout Groups.",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				props.setDisableAddBtn(false);
				props.refreshKOGroups();
			} else {
				toast({
					title: "Save Knockout Groups!",
					description: "Failed to Save Knockout Groups.",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
			setSaveLoading(false);
		} catch (err) {
			console.log(err);
			toast({
				title: "Save Knockout Groups!",
				description: "Failed to Save Knockout Groups.",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
			setSaveLoading(false);
		}
	};

	const tableDesktop = () => (
		<>
			<Box borderRadius={"2xl"} boxShadow={"sm"}>
				<Text
					as="h3"
					p={3}
					fontSize={{
						base: "md",
						md: "lg",
					}}
					fontWeight="semibold">
					Knockout Groups
				</Text>
				<Box
					overflow="auto"
					borderTopRadius={"2xl"}
					maxHeight="md"
					css={{
						"::-webkit-scrollbar": {
							width: "0.2rem",
							height: "0.2rem",
						},
						"::-webkit-scrollbar-track": {
							background: "#bee3f8",
							borderRadius: "1rem",
						},
						"::-webkit-scrollbar-thumb": {
							background: "#3182ce",
							borderRadius: "1rem",
						},
					}}>
					<Table variant="striped">
						<Thead position="sticky" top={0} bgColor="black" zIndex="overlay">
							<Tr>
								<Th
									textColor="white"
									fontSize="md"
									textAlign="center"
									py={5}
									whiteSpace={"normal"}>
									S.No
								</Th>
								<Th textColor="white" fontSize="md" textAlign="center">
									Group Name
								</Th>
								<Th
									textColor="white"
									fontSize="md"
									textAlign="center"
									whiteSpace={"normal"}>
									Starting Position
								</Th>
								<Th
									textColor="white"
									fontSize="md"
									textAlign="center"
									whiteSpace={"normal"}>
									Ending Position
								</Th>
								<Th
									textColor="white"
									fontSize="md"
									textAlign="center"
									whiteSpace={"normal"}>
									Set Count
								</Th>
								<Th
									textColor="white"
									fontSize="md"
									textAlign="start"
									whiteSpace={"normal"}>
									Actions
								</Th>
							</Tr>
						</Thead>
						<Tbody>
							{koGroupsLoad
								? Array(3)
										.fill()
										.map((rows, index) => (
											<Tr key={index}>
												<Td>
													<Skeleton
														isLoaded={!koGroupsLoad}
														h={"1.2rem"}
														w={"100%"}
													/>
												</Td>
												<Td>
													<Skeleton
														isLoaded={!koGroupsLoad}
														h={"1.2rem"}
														w={"100%"}
													/>
												</Td>
												<Td>
													<Skeleton
														isLoaded={!koGroupsLoad}
														h={"1.2rem"}
														w={"100%"}
													/>
												</Td>
												<Td>
													<Skeleton
														isLoaded={!koGroupsLoad}
														h={"1.2rem"}
														w={"100%"}
													/>
												</Td>
												<Td>
													<Skeleton
														isLoaded={!koGroupsLoad}
														h={"1.2rem"}
														w={"100%"}
													/>
												</Td>
												<Td>
													<Skeleton
														isLoaded={!koGroupsLoad}
														h={"1.2rem"}
														w={"100%"}
													/>
												</Td>
											</Tr>
										))
								: props.knockoutGroupsData?.map((board, index) => {
										return (
											<Tr key={index}>
												<Td
													flex="1"
													textAlign="start"
													fontSize="md"
													fontWeight="medium">
													{index + 1}
												</Td>
												<Td
													flex="1"
													textAlign="start"
													fontSize="md"
													fontWeight="medium">
													<FormControl>
														<Input
															type="text"
															defaultValue={board.KOGroupName}
															onChange={(e) =>
																(board.KOGroupName = e.target.value)
															}
															textAlign="start"
															fontSize="md"
															boxSizing="content-box"
														/>
													</FormControl>
												</Td>
												<Td
													flex="1"
													textAlign="start"
													fontSize="md"
													fontWeight="medium">
													<FormControl>
														<NumberInput
															inputMode="numeric"
															defaultValue={board.StartingPosition}
															onChange={(value) =>
																(board.StartingPosition =
																	Number(value))
															}
															boxSizing="content-box">
															<NumberInputField
																textAlign={"center"}
																padding={0}
															/>
														</NumberInput>
													</FormControl>
												</Td>
												<Td
													flex="1"
													textAlign="start"
													fontSize="md"
													fontWeight="medium">
													<FormControl>
														<NumberInput
															inputMode="numeric"
															defaultValue={board.EndingPosition}
															onChange={(value) =>
																(board.EndingPosition =
																	Number(value))
															}
															boxSizing="content-box">
															<NumberInputField
																textAlign={"center"}
																padding={0}
															/>
														</NumberInput>
													</FormControl>
												</Td>
												<Td
													flex="1"
													textAlign="start"
													fontSize="md"
													fontWeight="medium">
													<FormControl boxSize={"-webkit-max-content"}>
														<Select
															defaultValue={board.SetCount}
															onChange={(e) =>
																(board.SetCount = Number(
																	e.target.value
																))
															}
															px={0}>
															<option value={3}>3</option>
														</Select>
													</FormControl>
												</Td>
												<Td
													flex="1"
													textAlign="start"
													fontWeight="semibold">
													<HStack justify="center" spacing={1}>
														{"KOGroupId" in board
															? !saveLoading && (
																	<IconButton
																		icon={<FiTrash />}
																		borderRadius="full"
																		color="red"
																		bgColor="red.100"
																		_hover={{
																			bgColor: "red.200",
																		}}
																		_active={{
																			bgColor: "red.300",
																		}}
																		onClick={() => {
																			setCurrGroup(
																				board.KOGroupId
																			);
																			onAlertOpen();
																		}}
																	/>
															  )
															: !saveLoading && (
																	<IconButton
																		icon={<TbDelete />}
																		borderRadius={"full"}
																		bgColor="red"
																		_hover={{ bgColor: "red" }}
																		_active={{ bgColor: "red" }}
																		onClick={() =>
																			removeField(index)
																		}
																	/>
															  )}
														<IconButton
															borderRadius="full"
															bgColor="#43CC18"
															_hover={{ bgColor: "green" }}
															icon={<TbAdd />}
															onClick={() => saveKG(board)}
															isLoading={saveLoading}
														/>
													</HStack>
												</Td>
											</Tr>
										);
								  })}
						</Tbody>
					</Table>
					<Flex justify="center" py={3}>
						<Button
							color="#43CC18"
							bgColor="#E3F8DD"
							borderRadius="full"
							onClick={addGroupButton}
							disabled={props.disableAddBtn}>
							+ Add
						</Button>
					</Flex>
				</Box>
				<Divider />
				<HStack justify="end" p="1rem" bgColor="white" borderBottomRadius={"2xl"}>
					{/*---------------For Pagination-------------------------------*/}
					<Button
						color="white"
						bgColor="bg-accent"
						borderRadius="full"
						onClick={onOpen}
						isLoading={generateLoading}>
						Generate Matchups
					</Button>
				</HStack>
			</Box>

			<Modal
				isOpen={isOpen}
				onClose={onClose}
				size={{ base: "xs", sm: "md", md: "lg", lg: "xl" }}
				isCentered>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Generate Knockout Matchups</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Text>Are you sure want to Generate Knockout Matchups?</Text>
					</ModalBody>
					<ModalFooter>
						<Button
							colorScheme="blue"
							mr={3}
							onClick={generateMatchups}
							isLoading={generateLoading}>
							Yes
						</Button>
						{!generateLoading && <Button onClick={onClose}>No</Button>}
					</ModalFooter>
				</ModalContent>
			</Modal>

			<AlertDialog
				motionPreset="slideInBottom"
				leastDestructiveRef={cancelRef}
				onClose={() => {
					setCurrGroup("");
					onAlertClose();
				}}
				isOpen={isAlertOpen}
				isCentered>
				<AlertDialogOverlay />
				<AlertDialogContent>
					<AlertDialogHeader>Delete Knockout Group</AlertDialogHeader>
					<AlertDialogCloseButton />
					<AlertDialogBody>Are you sure you want to delete group?</AlertDialogBody>
					<AlertDialogFooter>
						{!delLoading && (
							<Button
								ref={cancelRef}
								onClick={() => {
									setCurrGroup("");
									onAlertClose();
								}}>
								Cancel
							</Button>
						)}
						<Button
							colorScheme="red"
							ml={3}
							onClick={handleDelete}
							isLoading={delLoading}>
							Delete
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
		</>
	);

	// const tableMobile = () => (
	// 	<Box>
	// 		{data.map((board) => (
	// 			<Container
	// 				mb="1rem"
	// 				bgColor="white"
	// 				boxShadow={"sm"}
	// 				borderRadius="lg"
	// 				py="1rem"
	// 				px="1.5rem">
	// 				<Stack spacing={5} divider={<Divider />} fontSize="lg">
	// 					<HStack justify="space-between">
	// 						<Text fontWeight="bold">S.No</Text>
	// 						<Text>{board.id}</Text>
	// 					</HStack>
	// 					<HStack justify="space-between">
	// 						<Text fontWeight="bold">Group Name</Text>
	// 						<FormControl>
	// 							<Input
	// 								placeholder={board.groupname}
	// 								bgColor="gray.100"
	// 								textAlign="start"
	// 								fontSize="md"
	// 							/>
	// 						</FormControl>
	// 					</HStack>
	// 					<HStack justify="space-between">
	// 						<Text fontWeight="bold">Starting Position</Text>
	// 						<FormControl>
	// 							<Input
	// 								placeholder={board.startingpos}
	// 								bgColor="gray.100"
	// 								textAlign="start"
	// 								fontSize="md"
	// 							/>
	// 						</FormControl>
	// 					</HStack>
	// 					<HStack justify="space-between">
	// 						<Text fontWeight="bold">Ending Position</Text>
	// 						<FormControl>
	// 							<Input
	// 								placeholder={board.endingpos}
	// 								bgColor="gray.100"
	// 								textAlign="start"
	// 								fontSize="md"
	// 							/>
	// 						</FormControl>
	// 					</HStack>
	// 					<HStack justify="space-between">
	// 						<Text fontWeight="bold">Set Count</Text>
	// 						<FormControl>
	// 							<Input
	// 								placeholder={board.setcount}
	// 								bgColor="gray.100"
	// 								textAlign="start"
	// 								fontSize="md"
	// 							/>
	// 						</FormControl>
	// 					</HStack>
	// 					<HStack justify="space-between">
	// 						<IconButton icon={<FiTrash />} color="red" bgColor="red.100"  />
	// 					</HStack>
	// 					<Stack
	// 						direction={["column", "row"]}
	// 						justify="space-between"
	// 						pt="1rem">
	// 						{/*-----------For Pagination----------------------*/}
	// 					</Stack>
	// 				</Stack>
	// 			</Container>
	// 		))}
	// 	</Box>
	// );

	return tableDesktop();
};

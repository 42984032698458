import {
	Container,
	Heading,
	useBreakpointValue,
	Stack,
	Box,
	HStack,
	IconButton,
	useColorModeValue,
	Tab,
	TabList,
	Tabs,
	TabPanel,
	TabPanels,
} from "@chakra-ui/react";
import { IoChevronBack } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { TournamentDetailsCard } from "../../Components/Tournaments/TournamentDetails/Details/TournamentDetailsCard";
import { TournamentEventDetailsTable } from "../../Components/Tournaments/TournamentDetails/Details/TournamentEventDetailsTable";
import { TournamentRegPlayersCard } from "../../Components/Tournaments/TournamentDetails/RegisteredPlayers/TournamentRegPlayersCard";
import { TournamentRegPlayersTable } from "../../Components/Tournaments/TournamentDetails/RegisteredPlayers/TournamentRegPlayersTable";
import { useEffect, useState } from "react";
import ApiService from "../../service/Api.service";
import { useAuthProvider } from "../../context/AuthProvider";

export const TournamentDetails = () => {
	// User Details
	const user = useAuthProvider();

	//Desktop BreakPoint Value
	const isDesktop = useBreakpointValue({
		base: false,
		lg: true,
	});
	//Page Header
	const pageHeader = useBreakpointValue({
		base: "xs",
		sm: "sm",
	});
	//Box Shadow
	const shadow = useColorModeValue("sm", "sm-dark");

	//Search Query
	let [query, setQuery] = useState("");

	let navigate = useNavigate();
	const location = useLocation();

	//Tournament Details Card
	const [tours, setTours] = useState([]);
	let [eventTypesData, setEventTypesData] = useState([]);
	let [allFormats, setAllFormats] = useState([]);
	let [loadingTourDetails, setLoadingTourDetails] = useState(false);
	const fetchTournamentDetails = async () => {
		try {
			setLoadingTourDetails(true);
			const response = await ApiService.httpGet(
				"/Tournaments?TourId=" + location.state.tourId,
				user.userId
			);
			setTours(response.Tournaments[0]);
			console.log("Tournament Details", response.Tournaments);
			setLoadingTourDetails(false);
		} catch (err) {
			console.error(err.message);
			setLoadingTourDetails(false);
		} finally {
			setLoadingTourDetails(false);
		}
	};
	const [loadingEventTable, setLoadingEventTable] = useState(false);
	const [loadingPlayersTable, setLoadingPlayerTable] = useState(false);
	const [loadingLookups, setLoadingLookups] = useState(false);
	//Tournament Event Details Table
	const [tourEvents, setTourEvents] = useState([]);
	const fetchTourEvents = async () => {
		try {
			setLoadingLookups(true);
			setLoadingEventTable(true);
			const response = await ApiService.httpGet(
				"/TourEvents?TourId=" + location.state.tourId,
				user.userId
			);
			setTourEvents(response.Events);
			setLoadingEventTable(false);
			const events = await ApiService.httpGet(`/TourEvents?EventTypes=all`, user.userId);
			setEventTypesData(events.EventType);
			const formats = await ApiService.httpGet(`/TourEvents?Class=FormatLookup`, user.userId);
			setAllFormats(formats.LookUp.Formats);
			setLoadingLookups(false);
		} catch (err) {
			console.error(err.message);
			setLoadingEventTable(false);
			setLoadingLookups(false);
		} finally {
			setLoadingEventTable(false);
			setLoadingLookups(false);
		}
	};
	//Tournament Registered Players Table
	const [tourPlayers, setTourPlayers] = useState([]);
	const fetchTourPlayers = async () => {
		try {
			setLoadingPlayerTable(true);
			const response = await ApiService.httpGet(
				"/TourPlayers?TourId=" + location.state.tourId,
				user.userId
			);
			setTourPlayers(response.TourPlayers);
			console.log("Tournament Players Table", response.TourPlayers);
			setLoadingPlayerTable(false);
		} catch (err) {
			console.error(err.message);
			setLoadingPlayerTable(false);
		} finally {
			setLoadingPlayerTable(false);
		}
	};

	const refreshTournamentsData = async () => {
		try {
			setLoadingEventTable(true);
			setLoadingPlayerTable(true);
			const response = await ApiService.httpGet(
				"/TourEvents?TourId=" + location.state.tourId,
				user.userId
			);
			setTourEvents(response.Events);
			console.log("Tournament Events Table", response.Events);
			const PlayersRes = await ApiService.httpGet(
				"/TourPlayers?TourId=" + location.state.tourId,
				user.userId
			);
			setTourPlayers(PlayersRes.TourPlayers);
			console.log("Tournament Players Table", PlayersRes.TourPlayers);
			setLoadingEventTable(false);
			setLoadingPlayerTable(false);
		} catch (err) {
			console.error(err.message);
			setLoadingEventTable(false);
			setLoadingPlayerTable(false);
		} finally {
			setLoadingEventTable(false);
			setLoadingPlayerTable(false);
		}
	};

	useEffect(() => {
		fetchTournamentDetails();
	}, []);
	// Reload Tour Players
	let [eventsReload, setEventsReload] = useState(false);
	useEffect(() => {
		fetchTourEvents();
	}, [eventsReload]);
	// Reload Tour Players
	let [playersReload, setPlayersReload] = useState(false);
	useEffect(() => {
		fetchTourPlayers();
	}, [playersReload]);

	return (
		<Container
			flex="1"
			overflowY="auto"
			maxWidth="100vw"
			marginTop={isDesktop ? "5rem" : "1rem"}
			marginX={0}
			paddingBottom={10}
			css={{
				"::-webkit-scrollbar": {
					width: "0.5rem",
					height: "0.5rem",
				},
				"::-webkit-scrollbar-track": {
					background: "#bee3f8",
					borderRadius: "1rem",
				},
				"::-webkit-scrollbar-thumb": {
					background: "#3182ce",
					borderRadius: "1rem",
				},
			}}>
			<Stack spacing="1.7rem">
				<HStack spacing="5">
					<IconButton
						borderRadius="full"
						p={2}
						size="md"
						bgColor="white"
						as={IoChevronBack}
						onClick={() => navigate("/tournaments")}
					/>
					<Heading size={pageHeader} fontWeight="bold">
						{tours.TournamentName}
					</Heading>
				</HStack>
				<Box px=".3rem" py="1rem" bgColor="white" borderRadius={"2xl"} boxShadow={shadow}>
					<Tabs variant="line" isFitted size="lg">
						<TabList>
							<Tab>Details</Tab>
							<Tab>Registered Players</Tab>
						</TabList>
						<TabPanels>
							<TabPanel p={0}>
								<Stack spacing="2rem">
									<TournamentDetailsCard
										key={tours?.TourId}
										tourData={tours}
										loadingTourDetails={loadingTourDetails}
									/>
									<TournamentEventDetailsTable
										key={tourEvents?.TourEventId}
										eventTypesData={eventTypesData}
										setEventTypesData={setEventTypesData}
										allFormats={allFormats}
										setAllFormats={setAllFormats}
										eventData={tourEvents}
										tournamentId={location.state.tourId}
										refreshView={refreshTournamentsData}
										setEventsReload={setEventsReload}
										eventsReload={eventsReload}
										loadingEventTable={loadingEventTable}
										loadingLookups={loadingLookups}
									/>
								</Stack>
							</TabPanel>
							<TabPanel p={0}>
								<Stack spacing="2rem">
									<TournamentRegPlayersCard
										TourId={location.state.tourId}
										setQuery={setQuery}
									/>
									<TournamentRegPlayersTable
										key={tourPlayers?.TourPlayerId}
										playerData={tourPlayers}
										query={query}
										eventData={tourEvents}
										tourId={location.state.tourId}
										refreshView={refreshTournamentsData}
										setPlayersReload={setPlayersReload}
										playersReload={playersReload}
										loadingPlayersTable={loadingPlayersTable}
									/>
								</Stack>
							</TabPanel>
						</TabPanels>
					</Tabs>
				</Box>
			</Stack>
		</Container>
	);
};

import {
	Stack,
	Table,
	Tbody,
	Td,
	Box,
	Tr,
	Th,
	Thead,
	useBreakpointValue,
	Divider,
	IconButton,
	Button,
	useToast,
	useDisclosure,
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogCloseButton,
	AlertDialogBody,
	AlertDialogFooter,
	Center,
	HStack,
	Text,
	Select,
	Link,
	Skeleton,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	FormControl,
	FormLabel,
	FormErrorMessage,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
	ModalFooter,
	Icon,
} from "@chakra-ui/react";
import { FiTrash } from "react-icons/fi";
import ApiService from "../../../service/Api.service";
import { useState, useRef } from "react";
import usePagination from "../../../hooks/usePagination";
import { Pagination } from "../../Pagination/Pagination";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { TbActions } from "../../../icons/Icons";
import { IoLinkSharp } from "react-icons/io5";
import CopyToClipboard from "react-copy-to-clipboard";

export const StreamingTable = ({
	eventId,
	streamingGroups,
	refreshStreaming,
	streamingLoad,
	groupsLookupLoad,
	streamingConfigurationsData,
}) => {
	//Desktop Breakpoint Value
	const isDesktop = useBreakpointValue({ base: false, xl: true });
	const isMobile = useBreakpointValue({
		base: true,
		md: false,
	});
	const toast = useToast();

	const { isOpen, onOpen, onClose } = useDisclosure();
	const cancelRef = useRef();

	let [currSB, setCurrSB] = useState("");

	const [board, setBoard] = useState(1);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const [selectedGroup, setSelectedGroup] = useState("");
	// Pagination
	let [rows, setRows] = useState(10);
	const perPage = rows;

	const {
		currentPage,
		currentData,
		jump,
		prev,
		next,
		first,
		last,
		setLast,
		setCurrentPage,
		maxPage,
	} = usePagination(streamingConfigurationsData, perPage);

	const handleChange = (e, p) => {
		jump(p);
	};

	const handleRows = (event) => {
		setRows(Number(event.target.value));
		setLast(Number(event.target.value));
		setCurrentPage(1);
		// 	setParamTwo(Number(event.target.value));
	};

	// const previousPage = (event) => {
	// 	setParamTwo(Number(paramOne));
	// 	setParamOne(Number(paramTwo - rows));
	// };
	// const nextPage = (event) => {
	// 	setParamOne(Number(paramTwo));
	// 	setParamTwo(Number(paramTwo + rows));
	// };

	const editBoard = async () => {
		if (selectedGroup === "" || selectedGroup === undefined || selectedGroup === null) {
			setError("Please Select a Group");
		} else if (currSB === "" || currSB === undefined || currSB === null) {
			console.log("Streaming Board Not Defined");
		} else {
			setError(null);
			let reqObj = {
				TourEventId: eventId,
				scBoard: currSB,
				GroupName: selectedGroup,
				Board: Number(board),
			};
			try {
				setLoading(true);
				let response = await ApiService.httpPut(
					`/StreamingConfigurations?Class=ScInfo`,
					reqObj
				);
				console.log("Update Boards", response);
				if (response.status === true) {
					toast({
						title: "Update Board",
						description: "Board Updated Successfully.",
						status: "success",
						duration: 3000,
						isClosable: true,
						position: "top-right",
					});
					setSelectedGroup("");
					setBoard(1);
					onClose();
					refreshStreaming();
				} else if (response.status === false) {
					toast({
						title: "Update Board",
						description: response.Message,
						status: "warning",
						duration: 3000,
						isClosable: true,
						position: "top-right",
					});
				} else {
					toast({
						title: "Update Board",
						description: "Failed to Update Board",
						status: "error",
						duration: 3000,
						isClosable: true,
						position: "top-right",
					});
				}
				setLoading(false);
			} catch (err) {
				console.log(err);
				toast({
					title: "Update Board",
					description: "Failed to Update Board",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				setLoading(false);
			}
			console.log(reqObj);
		}
	};

	const tableDesktop = () => (
		<>
			<Box>
				<Box
					borderTopRadius={"2xl"}
					overflow="auto"
					boxShadow={"sm"}
					css={{
						"::-webkit-scrollbar": {
							width: "0.3rem",
							height: "0.3rem",
						},
						"::-webkit-scrollbar-track": {
							background: "#bee3f8",
							borderRadius: "1rem",
						},
						"::-webkit-scrollbar-thumb": {
							background: "#3182ce",
							borderRadius: "1rem",
						},
					}}>
					<Table variant="striped">
						<Thead position="sticky" top={0} bgColor="black" zIndex="overlay">
							<Tr>
								<Th
									flex="1"
									textColor="white"
									fontSize="md"
									textAlign="center"
									py={5}
									px={2}
									whiteSpace={"normal"}>
									Stream
								</Th>
								<Th flex="1" textColor="white" fontSize="md" textAlign="center">
									Board
								</Th>
								<Th flex="1" textColor="white" fontSize="md" textAlign="start">
									Team 1
								</Th>
								<Th flex="1" textColor="white" fontSize="md" textAlign="start">
									Team 2
								</Th>
								<Th
									flex="1"
									textColor="white"
									fontSize="md"
									textAlign="start"
									px={2}>
									Group
								</Th>
								<Th
									flex="1"
									textColor="white"
									fontSize="md"
									textAlign="center"
									whiteSpace={"normal"}>
									Scoreboard Link
								</Th>
								<Th
									flex="1"
									textColor="white"
									fontSize="md"
									textAlign="center"
									whiteSpace={"normal"}>
									Control Link
								</Th>
								<Th flex="1" textColor="white" fontSize="md" textAlign="start"></Th>
							</Tr>
						</Thead>
						<Tbody>
							{streamingLoad
								? Array(5)
										.fill()
										.map((rows, index) => (
											<Tr key={index}>
												<Td>
													<Skeleton
														isLoaded={!streamingLoad}
														h={"1.2rem"}
														w={"100%"}
													/>
												</Td>
												<Td>
													<Skeleton
														isLoaded={!streamingLoad}
														h={"1.2rem"}
														w={"100%"}
													/>
												</Td>
												<Td>
													<Skeleton
														isLoaded={!streamingLoad}
														h={"1.2rem"}
														w={"100%"}
													/>
												</Td>
												<Td>
													<Skeleton
														isLoaded={!streamingLoad}
														h={"1.2rem"}
														w={"100%"}
													/>
												</Td>
												<Td>
													<Skeleton
														isLoaded={!streamingLoad}
														h={"1.2rem"}
														w={"100%"}
													/>
												</Td>
												<Td>
													<Skeleton
														isLoaded={!streamingLoad}
														h={"1.2rem"}
														w={"100%"}
													/>
												</Td>
												<Td>
													<Skeleton
														isLoaded={!streamingLoad}
														h={"1.2rem"}
														w={"100%"}
													/>
												</Td>
												<Td>
													<Skeleton
														isLoaded={!streamingLoad}
														h={"1.2rem"}
														w={"100%"}
													/>
												</Td>
											</Tr>
										))
								: currentData()?.map((board, index) => (
										<Tr key={index}>
											<Td
												flex="1"
												textAlign="center"
												fontSize="md"
												fontWeight="bold"
												px={2}>
												{board.scBoard}
											</Td>
											<Td
												flex="1"
												textAlign="center"
												fontSize="md"
												fontWeight="bold"
												whiteSpace={"normal"}>
												{board.Board}
											</Td>
											<Td
												flex="1"
												textAlign="start"
												fontSize="md"
												fontWeight="bold"
												whiteSpace={"normal"}>
												{board.Team1Name}
											</Td>
											<Td
												flex="1"
												textAlign="start"
												fontSize="md"
												fontWeight="bold"
												whiteSpace={"normal"}>
												{board.Team2Name}
											</Td>
											<Td
												flex="1"
												textAlign="start"
												fontSize="md"
												fontWeight="bold"
												px={2}>
												{board.GroupName}
											</Td>
											<Td
												flex="1"
												textAlign="center"
												fontSize="md"
												fontWeight="bold">
												<HStack
													justify={"center"}
													spacing={"4"}
													align={"end"}>
													<Link
														onClick={() =>
															window.open(
																board.ScoreBoardLink,
																"_scoreboard"
															)
														}
														target="_blank"
														isExternal>
														<ExternalLinkIcon mx="2px" />
													</Link>
													<CopyToClipboard
														text={board.ScoreBoardLink}
														onCopy={() => {
															toast({
																title: `Scoreboard Link is copied to your clipboard`,
																duration: 3000,
																position: "bottom",
																isClosable: true,
															});
														}}>
														<Icon
															as={IoLinkSharp}
															_hover={{ color: "#0a58ca" }}
															cursor={"pointer"}
														/>
													</CopyToClipboard>
												</HStack>
											</Td>
											<Td
												flex="1"
												textAlign="center"
												fontSize="md"
												fontWeight="bold">
												<HStack
													justify={"center"}
													spacing={"4"}
													align={"end"}>
													<Link
														onClick={() =>
															window.open(
																board.ScoreBoardControlLink,
																"_scoreboard"
															)
														}
														target="_blank"
														isExternal>
														<ExternalLinkIcon mx="2px" />
													</Link>
													<CopyToClipboard
														text={board.ScoreBoardControlLink}
														onCopy={() => {
															toast({
																title: `Control Link is copied to your clipboard`,
																duration: 3000,
																position: "bottom",
																isClosable: true,
															});
														}}>
														<Icon
															as={IoLinkSharp}
															_hover={{ color: "#0a58ca" }}
															cursor={"pointer"}
														/>
													</CopyToClipboard>
												</HStack>
											</Td>
											<Td
												flex="1"
												textAlign="start"
												fontSize="md"
												fontWeight="bold">
												<IconButton
													icon={<TbActions />}
													isLoading={groupsLookupLoad}
													onClick={() => {
														setCurrSB(board.scBoard);
														setBoard(board.Board);
														setSelectedGroup(board.GroupName);
														onOpen();
													}}
												/>
											</Td>
										</Tr>
								  ))}
						</Tbody>
					</Table>
				</Box>
				<Divider />
				<Stack p="1rem" bgColor="white" borderBottomRadius={"2xl"}>
					{/*---------------For Pagination-------------------------------*/}
					{isMobile ? (
						<Stack>
							<Center>
								<Pagination
									count={streamingConfigurationsData?.length}
									pageSize={rows}
									siblingCount={0}
									page={currentPage}
									onChange={(e) => setCurrentPage(e.page)}
								/>
							</Center>
							<HStack justify="space-between">
								<HStack spacing={1}>
									<Text as="span" whiteSpace="normal">
										Rows per page{" "}
									</Text>
									<Select
										boxSize={"-webkit-max-content"}
										minW="-webkit-fit-content"
										size="sm"
										value={rows}
										onChange={(event) => handleRows(event)}>
										{[10, 25, 50].map((rows) => (
											<option key={rows} value={rows}>
												{rows}
											</option>
										))}
									</Select>
								</HStack>
								<Text whiteSpace="normal">
									Showing {currentPage * rows - rows + 1} -{" "}
									{currentPage * rows < streamingConfigurationsData?.length
										? currentPage * rows
										: streamingConfigurationsData?.length}{" "}
									of {streamingConfigurationsData?.length} Entries
								</Text>
							</HStack>
						</Stack>
					) : (
						<HStack justify="space-between">
							<HStack spacing={1}>
								<Text as="span" whiteSpace={"nowrap"}>
									Rows per page{" "}
								</Text>
								<Select
									boxSize={"-webkit-max-content"}
									minW="-webkit-fit-content"
									value={rows}
									onChange={(event) => handleRows(event)}>
									{[10, 25, 50].map((rows) => (
										<option key={rows} value={rows}>
											{rows}
										</option>
									))}
								</Select>
							</HStack>
							<Center>
								<Pagination
									count={streamingConfigurationsData?.length}
									pageSize={rows}
									siblingCount={1}
									page={currentPage}
									onChange={(e) => setCurrentPage(e.page)}
								/>
							</Center>

							<Text whiteSpace="normal">
								Showing {currentPage * rows - rows + 1} -{" "}
								{currentPage * rows < streamingConfigurationsData?.length
									? currentPage * rows
									: streamingConfigurationsData?.length}{" "}
								of {streamingConfigurationsData?.length} Entries
							</Text>
						</HStack>
					)}
				</Stack>
			</Box>

			<Modal
				isOpen={isOpen}
				onClose={() => {
					onClose();
					setCurrSB("");
					setSelectedGroup("");
					setBoard(1);
				}}
				size={{ base: "xs", sm: "md", md: "lg", lg: "xl" }}
				isCentered>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Update Board</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Stack direction={"row"}>
							<FormControl
								isInvalid={
									error &&
									(selectedGroup === "" ||
										selectedGroup === undefined ||
										selectedGroup === null)
								}>
								<FormLabel>Group Name</FormLabel>
								<Select
									value={selectedGroup}
									placeholder="Select a Group"
									onChange={(e) => setSelectedGroup(e.target.value)}>
									{streamingGroups?.map((group, index) => {
										return (
											<option key={group.GroupId} value={group.GroupId}>
												{group.GroupName}
											</option>
										);
									})}
								</Select>
								<FormErrorMessage>{error && error}</FormErrorMessage>
							</FormControl>
							<FormControl>
								<FormLabel>Board</FormLabel>
								<NumberInput
									value={board}
									min={1}
									inputMode="numeric"
									onChange={(value) => setBoard(value)}>
									<NumberInputField />
									<NumberInputStepper>
										<NumberIncrementStepper />
										<NumberDecrementStepper />
									</NumberInputStepper>
								</NumberInput>
							</FormControl>
						</Stack>
					</ModalBody>
					<ModalFooter>
						<Button colorScheme="blue" mr={3} isLoading={loading} onClick={editBoard}>
							Submit
						</Button>
						{!loading && (
							<Button
								onClick={() => {
									onClose();
									setCurrSB("");
									setSelectedGroup("");
									setBoard(1);
								}}>
								Cancel
							</Button>
						)}
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);

	// const tableMobile = () => (
	// 	<Container
	// 		bgColor="white"
	// 		boxShadow={"sm"}
	// 		borderRadius="lg"
	// 		py="1rem"
	// 		px="1.5rem">
	// 		<Stack spacing={5} divider={<Divider />} fontSize="lg">
	// 			<HStack justify="space-between">
	// 				<Text fontWeight="bold">{data[0].id}</Text>
	// 				<Text>{data[0].player}</Text>
	// 			</HStack>
	// 			<Stack>
	// 				<HStack justify="space-between">
	// 					<Text fontWeight="bold">Played</Text>
	// 					<Text>{data[0].played}</Text>
	// 				</HStack>
	// 				<HStack justify="space-between">
	// 					<Text fontWeight="bold">Points</Text>
	// 					<Text>{data[0].points}</Text>
	// 				</HStack>
	// 				<HStack justify="space-between">
	// 					<Text fontWeight="bold">O/S</Text>
	// 					<Text>{data[0].os}</Text>
	// 				</HStack>
	// 				<HStack justify="space-between">
	// 					<Text fontWeight="bold">Net Diff</Text>
	// 					<Text>{data[0].netdiff}</Text>
	// 				</HStack>
	// 			</Stack>
	// 			<Stack direction={["column", "row"]} justify="space-between" pt="1rem">
	// 				{/*-----------For Pagination----------------------*/}
	// 			</Stack>
	// 		</Stack>
	// 	</Container>
	// );

	return tableDesktop();
};

import {
	HStack,
	Image,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Tr,
	VStack,
} from "@chakra-ui/react";
import * as React from "react";
import { data } from "./data";
import UpcomingTournamentImg from "../../../images/tour-img1.png";

import { Calendar } from "../../../icons/Icons";

export const TournamentsTable = ({ tourDetails }) => (
	<TableContainer>
		<Table>
			<Tbody>
				{tourDetails?.map((tournament) => (
					<Tr key={tournament.TourId}>
						<Td>
							<HStack justify="space-between">
								<HStack spacing="1rem" align={"start"}>
									<Image
										src={UpcomingTournamentImg}
										width="3rem"
										bgColor="gray.300"
										borderRadius="lg"
										padding="1"
									/>
									<VStack align="start">
										<Text fontSize={["md", "lg"]} fontWeight="semibold">
											{tournament.TournamentName}
										</Text>
										<Text
											fontSize={["sm", "md"]}
											fontWeight="semibold"
											color="blackAlpha.500">
											{tournament.ClubName}
										</Text>
										<HStack spacing={2}>
											<Calendar />
											<Text
												fontWeight="semibold"
												fontSize={["sm", "md"]}
												whiteSpace={"normal"}>
												{new Date(tournament.StartDate).toDateString()} -{" "}
												{new Date(tournament.EndDate).toDateString()}
											</Text>
										</HStack>
									</VStack>
								</HStack>
							</HStack>
						</Td>
					</Tr>
				))}
			</Tbody>
		</Table>
	</TableContainer>
);

import "./App.css";

import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "@chakra-ui/pro-theme";
import { BrowserRouter as Router } from "react-router-dom";

import { createContext } from "react";

import AppContent from "./AppContent";
import { AuthProvider } from "./context/AuthProvider";

export let data = createContext();

function App() {
	return (
		<ChakraProvider theme={theme}>
			<AuthProvider>
				<Router>
					<AppContent />
				</Router>
			</AuthProvider>
		</ChakraProvider>
	);
}

export default App;

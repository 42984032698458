import { createContext, useEffect, useReducer } from "react";

//context object
export const 
AuthContext = createContext();

//Reducer function
export const authReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return { ...state, user: action.payload };

    case "LOGOUT":
      return { ...state, user: null };

    case "AUTH_IS_READY":
      return {
        ...state,
        user: action.payload,
        authIsReady: true,
        refreshUserTriggered: false,
      };
    case "REFRESH_AUTH_INFORMATION":
      return { ...state, refreshUserTriggered: true };
    //if action type doesn't match any other state in our switch statement
    default:
      return state;
  }
};

//Custom context provider component.
export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
    authIsReady: false,
    refreshUserTriggered: false,
  });
  const { user, refreshUserTriggered } = state;

  const userAccounts = [
    {
      email: "collinskhalil@hotmail.com",
      password: "gggabc123",
      name: "Khalil",
      userId: 1,
    },
    {
      email: "adityac@icloud.com",
      password: "CarromSwft1",
      name: "Aditya Chavall",
      userId: 2,
    },
    {
      email: "info@uscarrom.org ",
      password: "USCarrom1",
      name: "USCA",
      userId: 3,
    },
  ];
  // This will check if a user is logged in after page refresh or when they first load the site
  useEffect(() => {
    const getEmail = localStorage.getItem("emailData");
    const getUserId = localStorage.getItem("userIdData");
    const getName = localStorage.getItem("userNameData");
    dispatch({
      type: "AUTH_IS_READY",
      payload:
        getEmail && getUserId && getName
          ? { userId: getUserId, email: getEmail, name: getName }
          : null,
    });
  }, []);

  const checkIfValidAccount = (email, password) => {
    let findEmail = userAccounts.find(
      (account) => email.toLowerCase() === account.email
    );
    if (findEmail && password === findEmail.password) {
      dispatch({
        type: "LOGIN",
        payload: {
          userId: findEmail.userId,
          email: findEmail.email,
          name: findEmail.name,
        },
      });
      localStorage.setItem("emailData", findEmail.email);
      localStorage.setItem("userIdData", findEmail.userId);
      localStorage.setItem("userNameData", findEmail.name);
      return true;
    }
    return false;
  };

  // Output the AuthContext state everytime we get a change
  console.log("AuthContext state: ", state);
  return (
    <AuthContext.Provider value={{ ...state, dispatch, checkIfValidAccount }}>
      {children}
    </AuthContext.Provider>
  );
};

import Logo from "../logo/logo.png";
import { Image, Link, useBreakpointValue } from "@chakra-ui/react";

export const CarromLogo = (props) => {
	const isDesktop = useBreakpointValue({
		base: false,
		lg: true,
	});

	return (
		<Link textAlign={"-webkit-center"} {...props}>
			<Image
				src={Logo}
				alt="Carrom Platform Logo"
				draggable="false"
				height={isDesktop ? "6rem" : "4.5rem"}
				style={{ userSelect: "none" }}
				sizes={"sm"}
			/>
			<Image />
		</Link>
	);
};

import {
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	Button,
	TableContainer,
	SimpleGrid,
	Box,
	Heading,
	Center,
	Spinner,
	Stack,
	useToast,
	useBreakpointValue,
} from "@chakra-ui/react";
import { useEffect, useState, useRef } from "react";
import { WebPubSubClient } from "@azure/web-pubsub-client";
import { v4 as uuid } from "uuid";
import { NewBroadcastModal } from "./NewBroadcastModal";
import ApiService from "../../../../service/Api.service";
import { useParams } from "react-router-dom";

export const BroadcastSetup = () => {
	//Desktop BreakPoint Value
	const isDesktop = useBreakpointValue({
		base: false,
		lg: true,
	});
	const { eventId } = useParams();
	const [streams, setStreams] = useState(null);
	const [broadcasts, setBroadcasts] = useState(null);
	const [oauthReq, setOauthReq] = useState({
		required: false,
		url: "",
		correlationId: uuid(),
	});
	const [isNewBroadcastModalVisible, setIsNewBroadcastModalVisible] = useState(false);
	const streamRef = useRef(null);
	const popupRef = useRef(null);
	const toast = useToast();

	const setupSocketConn = async () => {
		const resp = await fetch("https://carromslive.azurewebsites.net/pubsub/token");
		const token = await resp.json();

		const client = new WebPubSubClient(token.url);
		client.on("connected", (e) => {
			console.log(`Web PubSub connected`);
		});
		client.on("server-message", (e) => {
			console.log("message received");
			console.log(e);
			const msg = e.message.data;
			console.log(msg);
			if (
				msg.event === "youtube-auth-success" &&
				msg.correlationId === oauthReq.correlationId
			) {
				popupRef.current.close();
				setOauthReq((r) => ({ ...r, required: false }));
				fetchStreams();
			}
		});
		client.start();
		return client;
	};

	const fetchStreams = async () => {
		const streamResp = await fetch(
			`https://carromslive.azurewebsites.net/youtube/streams?correlationId=${oauthReq.correlationId}`
		);
		if (streamResp.status === 302) {
			console.log("requires oauth authentication");
			const oauthInfo = await streamResp.json();
			setOauthReq({ required: true, url: oauthInfo.url });
			oauthPopup(oauthInfo.url);
			return;
		}
		const broadcastResp = await fetch(
			`https://carromslive.azurewebsites.net/youtube/broadcasts?correlationId=${oauthReq.correlationId}`
		);
		const streams = await streamResp.json();
		const broadcasts = await broadcastResp.json();
		// sort steams by title
		streams.items.sort((a, b) => {
			if (a.snippet.title < b.snippet.title) {
				return -1;
			} else if (a.snippet.title > b.snippet.title) {
				return 1;
			} else {
				return 0;
			}
		});
		setStreams(streams);
		setBroadcasts(broadcasts);
	};

	function oauthPopup(url) {
		const left = (window.screen.width - 600) / 2;
		const top = (window.screen.height - 600) / 2;
		popupRef.current = window.open(
			url,
			"popup",
			`popup=true,width=600,height=600,top=${top},left=${left}`
		);
	}

	function getBroadcast(streamId) {
		return (
			broadcasts &&
			broadcasts.items.find((b) => b.contentDetails.boundStreamId === streamId)
		);
	}

	function handleStreamEvent(event, stream, broadcast) {
		switch (event) {
			case "create-broadcast":
				streamRef.current = stream;
				setIsNewBroadcastModalVisible(true);
				break;
			case "start-broadcast":
				startBroadcast(broadcast);
				break;
			case "stop-broadcast":
				stopBroadcast(broadcast);
				break;
			default:
				console.log("invalid event");
		}
	}

	async function createBroadcast(title, description, startTime) {
		try {
			const resp = await ApiService.httpPost("/youtube/broadcasts", {
				streamId: streamRef.current.id,
				title: title,
				description: description,
				startTime: startTime,
			});
			console.log(resp);
			setIsNewBroadcastModalVisible(false);
			toast({
				title: "Broadcast Created",
				description: `Broadcast ${title} Successfully Created.`,
				status: "success",
				duration: 5000,
				isClosable: true,
			});
			fetchStreams();
		} catch (err) {
			console.log(err);
		}
	}

	async function startBroadcast(broadcast) {
		try {
			const resp = await ApiService.httpPut("/youtube/broadcasts", {
				broadcastId: broadcast.id,
				status: "live",
			});
			console.log(resp);
			toast({
				title: "Broadcast Started",
				description: `Broadcast ${broadcast.snippet.title} Successfully Started.`,
				status: "success",
				duration: 5000,
				isClosable: true,
			});
			fetchStreams();
		} catch (err) {
			console.log(err);
		}
	}

	async function stopBroadcast(broadcast) {
		try {
			const resp = await ApiService.httpPut("/youtube/broadcasts", {
				broadcastId: broadcast.id,
				status: "complete",
			});
			console.log(resp);
			toast({
				title: "Broadcast Stopped",
				description: `Broadcast ${broadcast.snippet.title} Successfully Stopped.`,
				status: "success",
				duration: 5000,
				isClosable: true,
			});
			fetchStreams();
		} catch (err) {
			console.log(err);
		}
	}

	useEffect(() => {
		let client;
		(async () => {
			client = await setupSocketConn();
		})();
		fetchStreams();

		return () => {
			client.stop();
		};
	}, []);

	function Broadcasts({ handleBroadcastEvent }) {
		function handleClick(event, stream, broadcast) {
			handleBroadcastEvent(event, stream, broadcast);
		}

		return (
			<TableContainer>
				<Table variant="simple" size="md">
					<Thead>
						<Tr>
							<Th>Stream</Th>
							<Th>Broadcast</Th>
							<Th>Status</Th>
							<Th>Actions</Th>
						</Tr>
					</Thead>
					<Tbody>
						{streams.items.map((s) => {
							const b = getBroadcast(s.id);
							return (
								<Tr key={s.id}>
									<Td>{s.snippet.title}</Td>
									<Td>{b && b.snippet.title}</Td>
									<Td>{s.status.streamStatus}</Td>
									<Td>
										<Stack direction="row" spacing={4}>
											<Button
												colorScheme="green"
												size="sm"
												onClick={() =>
													handleClick(
														"create-broadcast",
														s,
														null
													)
												}>
												Add
											</Button>
											{b && b.status.lifeCycleStatus !== "live" && (
												<Button
													colorScheme="orange"
													size="sm"
													onClick={() =>
														handleClick(
															"start-broadcast",
															s,
															b
														)
													}>
													Start
												</Button>
											)}
											{b && b.status.lifeCycleStatus === "live" && (
												<Button
													colorScheme="red"
													size="sm"
													onClick={() =>
														handleClick(
															"stop-broadcast",
															s,
															b
														)
													}>
													Stop
												</Button>
											)}
										</Stack>
									</Td>
								</Tr>
							);
						})}
					</Tbody>
				</Table>
			</TableContainer>
		);
	}

	return (
		<SimpleGrid
			columns={1}
			spacing={10}
			w="100%"
			padding={5}
			marginTop={isDesktop ? "7rem" : "5rem"}>
			<Box textAlign="center">
				<Heading>Youtube Broadcasts</Heading>
			</Box>
			{oauthReq.required && (
				<Box textAlign="center">
					<Center>
						<Spinner
							thickness="4px"
							speed="0.65s"
							emptyColor="gray.200"
							color="blue.500"
							size="xl"
						/>
						Please Login to Continue
					</Center>
				</Box>
			)}
			{isNewBroadcastModalVisible && (
				<NewBroadcastModal
					tourEventId={eventId}
					boardNum={streamRef.current.snippet.title.split(" ")[1]}
					handleSubmit={createBroadcast}
					handleCancel={() => setIsNewBroadcastModalVisible(false)}
				/>
			)}
			<Box>
				{streams && <Broadcasts handleBroadcastEvent={handleStreamEvent} />}
			</Box>
		</SimpleGrid>
	);
};

import {
	Avatar,
	Box,
	Button,
	Flex,
	HStack,
	Heading,
	SlideFade,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	useColorModeValue,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useState } from "react";
import { HiPencilAlt } from "react-icons/hi";
import ApiService from "../../../service/Api.service";
import { playerImgDir } from "../../../Utilities/ImageLinks";
import { useAuthProvider } from "../../../context/AuthProvider";

export const PlayerLeftJourney = ({ teamId }) => {
	// User Details
	const user = useAuthProvider();

	const bgColor = useColorModeValue("white", "gray.700");
	const [playerJourney, setPlayerJourney] = useState(null);
	async function fetchPlayerJourney() {
		try {
			let journeyResp = await ApiService.httpGet(
				`/TourPlayers?Class=PlayerJourney&TeamId=${teamId}`,
				user.userId
			);
			if (journeyResp.status === true) {
				setPlayerJourney(journeyResp);
			}
		} catch (err) {
			console.log(err);
		}
	}
	useEffect(() => {
		fetchPlayerJourney();
	}, [teamId]);

	if (playerJourney) {
		return (
			<Flex
				direction="column"
				align={"center"}
				minW={"70vw"}
				bg={bgColor}
				shadow={"base"}
				borderRadius={"2xl"}
				px={5}
				pb={5}>
				<HStack mt="-10">
					{playerJourney.ImageLinks.map((player) => (
						<Avatar
							borderWidth="6px"
							borderColor={bgColor}
							size="xl"
							src={`${playerImgDir}${player}`}
							name={playerJourney.TeamName}
						/>
					))}
				</HStack>
				<Box w={"100%"}>
					<Heading
						size="sm"
						textAlign={"center"}
						fontWeight="extrabold"
						letterSpacing="tight">
						{playerJourney.TeamName}
					</Heading>
					<Table bgColor={"white"} variant="striped" colorScheme="gray">
						<Thead>
							<Tr>
								<Th textColor="#4B39B7" fontSize="sm" textAlign="start">
									Round
								</Th>
								<Th textColor="#4B39B7" fontSize="sm" textAlign="start">
									Opponent
								</Th>
								<Th textColor="#4B39B7" fontSize="sm" textAlign="center">
									Result
								</Th>
								<Th textColor="#4B39B7" fontSize="sm" textAlign="center">
									Score(s)
								</Th>
							</Tr>
						</Thead>

						<Tbody bg="white">
							{playerJourney.MatchupResults.map((player, i) => (
								<Tr key={i}>
									<Td
										py={"0.5rem"}
										textAlign="start"
										fontSize="sm"
										fontWeight="medium"
										textColor="#000000">
										{player.RoundName}
									</Td>
									<Td
										py={"0.5rem"}
										textAlign="start"
										fontSize="sm"
										fontWeight="medium"
										textColor="#000000">
										{player.Opponent}
									</Td>
									<Td
										py={"0.5rem"}
										textAlign="center"
										fontSize="sm"
										fontWeight="medium"
										textColor={player.Result === "Lost" ? "red" : "green"}>
										{player.Result}
									</Td>
									<Td
										py={"0.5rem"}
										textAlign="center"
										fontSize="sm"
										fontWeight="medium"
										textColor="#000000">
										{player.Scores}
									</Td>
								</Tr>
							))}
						</Tbody>
					</Table>
				</Box>
			</Flex>
		);
	} else {
		return null;
	}
};

import {
	Container,
	Button,
	Heading,
	Text,
	useBreakpointValue,
	Flex,
	Stack,
	SimpleGrid,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Navbar } from "../../Components/Navbar/Navbar";
import { Sidebar } from "../../Components/Sidebar/Sidebar";
import { ManageTournamentCard } from "../../Components/Tournaments/ManageTournamentCards/ManageTournamentCards";
import ApiService from "../../service/Api.service";
import { data } from "../../App";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthProvider } from "../../context/AuthProvider";

export const ManageTournaments = () => {
	// User Details
	const user = useAuthProvider();

	//Desktop BreakPoint Value
	const isDesktop = useBreakpointValue({
		base: false,
		lg: true,
	});
	//Page Header
	const pageHeader = useBreakpointValue({
		base: "xs",
		sm: "sm",
	});
	//Page Caption
	const pageCaption = useBreakpointValue({
		base: "sm",
		sm: "md",
	});

	const location = useLocation();

	let [tourReload, setTourReload] = useState(false);
	let [loading, setLoading] = useState(false);

	const [tournaments, setTournaments] = useState([]);
	const fetchTournments = async () => {
		try {
			setLoading(true);
			const response = await ApiService.httpGet("/Tournaments?ToursByClub=all", user.userId);
			setTournaments(response.Clubs);
			console.log(response.Clubs);

			setLoading(false);
		} catch (err) {
			console.error(err.message);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchTournments();
	}, [tourReload]);

	const navigate = useNavigate();
	return (
		<Container
			flex="1"
			overflowY="auto"
			maxWidth="100vw"
			marginTop={isDesktop ? "5rem" : "1rem"}
			marginX={0}
			paddingBottom={10}
			css={{
				"::-webkit-scrollbar": {
					width: "0.5rem",
					height: "0.5rem",
				},
				"::-webkit-scrollbar-track": {
					background: "#bee3f8",
					borderRadius: "1rem",
				},
				"::-webkit-scrollbar-thumb": {
					background: "#3182ce",
					borderRadius: "1rem",
				},
			}}>
			<Stack
				spacing={{
					base: "8",
					lg: "6",
				}}>
				<Stack
					spacing={7}
					direction={{ base: "column", sm: "row" }}
					alignItems={{ sm: "center" }}
					justify="space-between">
					<Stack
						spacing="4"
						direction={{
							base: "column",
							lg: "row",
						}}
						justify="space-between"
						align={{
							base: "start",
							lg: "center",
						}}>
						<Stack spacing="1">
							<Heading size={pageHeader} fontWeight="bold">
								Manage Tournaments
							</Heading>
							<Text fontWeight="bold" color="blackAlpha.500" fontSize={pageCaption}>
								View & Manage All Tournaments
							</Text>
						</Stack>
					</Stack>
					<Button
						bgColor="bg-accent"
						color="white"
						_hover={{
							bgColor: "bg-accent",
							boxShadow: "0px 3px 20px 3px cornflowerblue",
						}}
						borderRadius={50}
						fontWeight="semibold"
						onClick={() => {
							navigate("/tournaments/addtournament");
						}}>
						Add New Tournament
					</Button>
				</Stack>
				<div>
					{loading
						? Array(3)
								.fill()
								.map((load, index) => (
									<ManageTournamentCard key={index} loading={loading} />
								))
						: location.state
						? tournaments
								.filter((tournament) => tournament.ClubId === location.state.clubId)
								.map((tournament, index) => {
									return (
										<ManageTournamentCard
											key={tournament.ClubId}
											tournamentData={tournament}
											index={index}
											setTourReload={setTourReload}
											tourReload={tourReload}
										/>
									);
								})
						: tournaments.map((tournament, index) => {
								return (
									<ManageTournamentCard
										key={tournament.ClubId}
										tournamentData={tournament}
										index={index}
										setTourReload={setTourReload}
										tourReload={tourReload}
									/>
								);
						  })}

					{/*----------------------- ManageTournment Card -------------------------*/}
				</div>
			</Stack>
		</Container>
	);
};

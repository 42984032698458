const config = {
	api: "https://carromslive.azurewebsites.net",
	// api: "http://localhost:5000",
	options: {
		headers: { "content-type": "application/json" },
	},
};

const httpGet = (endpoint, userId) => {
	let userIdParam = userId === undefined ? "" : `&User=${userId}`;
	return fetch(`${config.api}${endpoint}${userIdParam}`, {
		...config.options,
	})
		.then((response) => handleResponse(response))
		.then((response) => response)
		.catch((error) => {
			console.error(error);
			throw Error(error);
		});
};

const httpPost = (endpoint, data) => {
	return fetch(`${config.api}${endpoint}`, {
		method: "post",
		body: data ? JSON.stringify(data) : null,
		...config.options,
	})
		.then((response) => handleResponse(response))
		.then((response) => response)
		.catch((error) => {
			console.error(error);
			throw Error(error);
		});
};

const httpPostWithURL = (url, data) => {
	return fetch(url, {
		method: "post",
		body: data ? JSON.stringify(data) : null,
		...config.options,
	})
		.then((response) => handleResponse(response))
		.then((response) => response)
		.catch((error) => {
			console.error(error);
			throw Error(error);
		});
};

const httpPut = (endpoint, data) => {
	return fetch(`${config.api}${endpoint}`, {
		method: "put",
		body: data ? JSON.stringify(data) : null,
		...config.options,
	})
		.then((response) => handleResponse(response))
		.then((response) => response)
		.catch((error) => {
			console.error(error);
			throw Error(error);
		});
};

const httpPutWithURL = (url, data) => {
	return fetch(url, {
		method: "put",
		body: data ? JSON.stringify(data) : null,
		...config.options,
	})
		.then((response) => handleResponse(response))
		.then((response) => response)
		.catch((error) => {
			console.error(error);
			throw Error(error);
		});
};

const httpDelete = (endpoint, data) => {
	return fetch(`${config.api}${endpoint}`, {
		method: "delete",
		...config.options,
	})
		.then((response) => handleResponse(response))
		.then((response) => response)
		.catch((error) => {
			console.error(error);
			throw Error(error);
		});
};

const handleResponse = (response) => {
	// You can handle 400 errors as well.
	if (response.status === 200) {
		return response.json();
	} else {
		throw Error(response.json() | "error");
	}
};

export default {
	httpGet,
	httpPost,
	httpPut,
	httpPutWithURL,
	httpDelete,
	httpPostWithURL,
};

import { BlobServiceClient } from "@azure/storage-blob";

function uploadImageFile(file, toast, folderName, fileName) {
	const connectionString =
		"https://caromblob.blob.core.windows.net/?sp=racw&st=2023-07-19T23:41:10Z&se=2025-07-01T07:41:10Z&sv=2022-11-02&sr=c&sig=Ak9okj5wik4sRzc2lLXfZ5VUfa2vgQsm%2BHy2w6boTm4%3D";
	const containerName = "images";
	async function handleUpload() {
		console.log(file.name);
		if (
			file.name.toLowerCase().endsWith(".jpg") ||
			file.name.toLowerCase().endsWith(".jpeg") ||
			file.name.toLowerCase().endsWith(".jfi") ||
			file.name.toLowerCase().endsWith(".jpe") ||
			file.name.toLowerCase().endsWith(".jif") ||
			file.name.toLowerCase().endsWith(".jfif") ||
			file.name.toLowerCase().endsWith(".heif") ||
			file.name.toLowerCase().endsWith(".heic") ||
			file.name.toLowerCase().endsWith(".png") ||
			file.name.toLowerCase().endsWith(".svg") ||
			file.name.toLowerCase().endsWith(".svgz") ||
			file.name.toLowerCase().endsWith(".raw")
		) {
			try {
				const blobServiceClient = new BlobServiceClient(connectionString);
				const containerClient =
					blobServiceClient.getContainerClient(containerName);

				const folderPath = `${folderName}/`;
				const blobName = folderPath + `${fileName}`;

				const blockBlobClient = containerClient.getBlockBlobClient(blobName);
				await blockBlobClient.uploadData(file);

				console.log("File Uploaded Successfully!");
			} catch (error) {
				console.error(error);
			}
		} else {
			toast({
				title: "Wrong File Format",
				description: "You can upload only Image file formats",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
		}
	}
	return {
		handleUpload,
	};
}
export default uploadImageFile;

import {
	Card,
	CardHeader,
	IconButton,
	Stack,
	Text,
	useBreakpointValue,
	useColorModeValue,
	Select,
	FormControl,
	HStack,
	Menu,
	MenuButton,
	CloseButton,
	MenuList,
	MenuItem,
	useToast,
	Skeleton,
} from "@chakra-ui/react";
import { FiMoreVertical, FiRefreshCw } from "react-icons/fi";
import ApiService from "../../service/Api.service";
import { useState } from "react";

export const KnockoutMatchupsTabCard = (props) => {
	const isDesktop = useBreakpointValue({
		base: false,
		md: true,
	});
	const stackDirection = useBreakpointValue({
		base: "column",
		md: "row",
	});

	let toast = useToast();

	// Loading States
	let groupsLookupLoad = props.groupsLookupLoad;

	const [menuLoading, setMenuLoading] = useState(false);

	let groupName = "";
	if (props.selectedKOMatchGroup) {
		groupName = props.selectedKOMatchGroup;
	}
	const scoreLinks = async () => {
		try {
			setMenuLoading(true);
			let sendLinkRes = await ApiService.httpPost(
				`/ScoreLink?EventId=${props.state.EventId}&GroupName=${props.selectedKOMatchGroup}&Class=KnockoutScoreLink`,
				{}
			);
			console.log("Sent Score Links: ", sendLinkRes);
			if (sendLinkRes.status === true) {
				toast({
					title: "Send Score Links",
					description: "Score Links Sent Successfully!",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			} else {
				toast({
					title: "Send Score Links",
					description: "Failed to Send Score Links",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
			setMenuLoading(false);
		} catch (err) {
			console.log(err);
			toast({
				title: "Send Score Links",
				description: "Failed to Send Score Links",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
			setMenuLoading(false);
		}
	};

	return (
		<Card variant={"elevated"} bgColor="white" boxShadow={useColorModeValue("sm", "sm-dark")}>
			<CardHeader>
				<Stack direction="column" spacing=".7rem">
					<Text
						as="h3"
						fontSize={useBreakpointValue({
							base: "lg",
							md: "xl",
						})}
						fontWeight="semibold">
						Knockout Matchups
					</Text>
					<Stack
						direction={useBreakpointValue({ base: "column", sm: "row" })}
						justify="space-between">
						<Text
							fontSize={useBreakpointValue({
								base: "md",
								md: "lg",
							})}
							textColor="accent"
							fontWeight="semibold">
							{groupName} Group Knockouts
						</Text>
						<Stack
							direction={stackDirection}
							align={isDesktop && "end"}
							justify="end"
							spacing=".7rem">
							{isDesktop && (
								<Stack spacing=".7rem" direction={stackDirection}>
									<IconButton
										variant="ghost"
										size="md"
										color="accent"
										icon={
											<FiRefreshCw
												size="1.7rem"
												onClick={props.refreshMatchupsView}
											/>
										}
									/>
								</Stack>
							)}
							<FormControl>
								{groupsLookupLoad ? (
									<Skeleton isLoaded={!groupsLookupLoad} w="8vw">
										<Select />
									</Skeleton>
								) : (
									<Select
										name="Challengers"
										colorScheme="blue"
										value={props.selectedKOMatchGroup}
										onChange={(e) =>
											props.updateKOMatchUpsData(e.target.value)
										}>
										{props.groups.map((group, index) => {
											return (
												<option key={group} value={group}>
													{group}
												</option>
											);
										})}
									</Select>
								)}
							</FormControl>
							<Menu direction="rtl">
								{({ isOpen }) => (
									<>
										<MenuButton
											isActive={isOpen}
											as={IconButton}
											variant="ghost"
											size="md"
											color="green.500"
											isLoading={menuLoading}
											icon={
												isOpen ? (
													<CloseButton />
												) : (
													<FiMoreVertical size="1.7rem" />
												)
											}
										/>
										<MenuList zIndex="overlay">
											<MenuItem
												textColor="green.400"
												fontWeight="medium"
												value="sendScoreLinks"
												onClick={scoreLinks}>
												Send Score Links
											</MenuItem>
										</MenuList>
									</>
								)}
							</Menu>
						</Stack>
					</Stack>
				</Stack>
			</CardHeader>
		</Card>
	);
};

export const data = [
	{
		id: "1",
		board: "1",
		player1: "Glory Miceli",
		player2: "Jennie Matuszak",
		score1: "25",
		score2: "25",
	},
	{
		id: "2",
		board: "2",
		player1: "Glory Miceli",
		player2: "Jennie Matuszak",
		score1: "25",
		score2: "25",
	},
	{
		id: "3",
		board: "3",
		player1: "Glory Miceli",
		player2: "Jennie Matuszak",
		score1: "25",
		score2: "25",
	},
	{
		id: "4",
		board: "4",
		player1: "Glory Miceli",
		player2: "Jennie Matuszak",
		score1: "25",
		score2: "25",
	},
	{
		id: "5",
		board: "5",
		player1: "Glory Miceli",
		player2: "Jennie Matuszak",
		score1: "25",
		score2: "25",
	},
];

import {
	Card,
	Button,
	CardHeader,
	IconButton,
	Icon,
	Input,
	InputGroup,
	InputLeftElement,
	Stack,
	Text,
	useBreakpointValue,
	useColorModeValue,
	SimpleGrid,
	Divider,
} from "@chakra-ui/react";
import { BsDownload, BsUpload } from "react-icons/bs";
import { FiMoreHorizontal, FiSearch } from "react-icons/fi";
import { HiDotsVertical, HiDownload, HiUpload } from "react-icons/hi";
import { SlPaperPlane } from "react-icons/sl";
import { useNavigate } from "react-router-dom";
import { ShimmerText } from "react-shimmer-effects-18";

export const TournamentDetailsCard = (props) => {
	//Card Title
	const cardTitle = useBreakpointValue({
		base: "md",
		md: "lg",
	});
	//Form Labels
	const formLabels = useBreakpointValue({ base: "xs", md: "sm" });
	//Form Values
	const formValues = useBreakpointValue({ base: "md" });
	//Box Shadow
	const shadow = useColorModeValue("sm", "sm-dark");

	let tour = props.tourData;
	let loading = props.loadingTourDetails;

	return (
		<Card variant={"elevated"} bgColor="white" boxShadow={shadow}>
			<CardHeader>
				<Stack spacing="1.5rem" divider={<Divider />}>
					<Stack direction="column" spacing="1.2rem" justify="space-between">
						<Text as="h3" fontSize={cardTitle} fontWeight="bold">
							Basic Details
						</Text>
						<SimpleGrid
							columns={{ base: 1, sm: 2, md: 3, lg: 4 }}
							columnGap={{ base: "4", md: "6" }}
							rowGap={{ base: "6", md: "8" }}>
							<Stack spacing=".2rem">
								<Text
									color="blackAlpha.500"
									fontWeight="semibold"
									fontSize={formLabels}>
									Tournament Title
								</Text>
								{loading ? (
									<ShimmerText line={2} gap={10} />
								) : (
									<Text fontWeight="semibold" fontSize={formValues}>
										{tour?.TournamentName}
									</Text>
								)}
							</Stack>
							<Stack spacing=".2rem">
								<Text
									color="blackAlpha.500"
									fontWeight="semibold"
									fontSize={formLabels}>
									Club Name
								</Text>
								{loading ? (
									<ShimmerText line={2} gap={10} />
								) : (
									<Text fontWeight="semibold" fontSize={formValues}>
										{tour?.ClubName}
									</Text>
								)}
							</Stack>
							<Stack spacing=".2rem">
								<Text
									color="blackAlpha.500"
									fontWeight="semibold"
									fontSize={formLabels}>
									Full Address
								</Text>
								{loading ? (
									<ShimmerText line={2} gap={10} />
								) : (
									<Text fontWeight="semibold" fontSize={formValues}>
										{tour?.FullAddress}
									</Text>
								)}
							</Stack>
							<Stack spacing=".2rem">
								<Text
									color="blackAlpha.500"
									fontWeight="semibold"
									fontSize={formLabels}>
									Website URL
								</Text>
								{loading ? (
									<ShimmerText line={2} gap={10} />
								) : (
									<Text fontWeight="semibold" fontSize={formValues}>
										{tour?.WebsiteURL}
									</Text>
								)}
							</Stack>
							<Stack spacing=".2rem">
								<Text
									color="blackAlpha.500"
									fontWeight="semibold"
									fontSize={formLabels}>
									Email Address
								</Text>
								{loading ? (
									<ShimmerText line={2} gap={10} />
								) : (
									<Text fontWeight="semibold" fontSize={formValues}>
										{tour?.ContactEmail}
									</Text>
								)}
							</Stack>
							<Stack spacing=".2rem">
								<Text
									color="blackAlpha.500"
									fontWeight="semibold"
									fontSize={formLabels}>
									Mobile Number
								</Text>
								{loading ? (
									<ShimmerText line={2} gap={10} />
								) : (
									<Text fontWeight="semibold" fontSize={formValues}>
										{tour?.ContactNo}
									</Text>
								)}
							</Stack>
							<Stack spacing=".2rem">
								<Text
									color="blackAlpha.500"
									fontWeight="semibold"
									fontSize={formLabels}>
									Start Date
								</Text>
								{loading ? (
									<ShimmerText line={2} gap={10} />
								) : (
									<Text fontWeight="semibold" fontSize={formValues}>
										{tour?.StartDate}
									</Text>
								)}
							</Stack>
							<Stack spacing=".2rem">
								<Text
									color="blackAlpha.500"
									fontWeight="semibold"
									fontSize={formLabels}>
									End Date
								</Text>
								{loading ? (
									<ShimmerText line={2} gap={10} />
								) : (
									<Text fontWeight="semibold" fontSize={formValues}>
										{tour?.EndDate}
									</Text>
								)}
							</Stack>
						</SimpleGrid>
					</Stack>
					<Stack direction="column" spacing="1.2rem" justify="space-between">
						<Text as="h3" fontSize={cardTitle} fontWeight="bold">
							Additional Details
						</Text>
						<SimpleGrid
							columns={{ base: 1, sm: 2, md: 3, lg: 4 }}
							rowGap={{ base: "6", md: "8" }}
							columnGap={{ base: "4", md: "6" }}>
							<Stack spacing=".2rem">
								<Text
									color="blackAlpha.500"
									fontWeight="semibold"
									fontSize={formLabels}>
									Schedule
								</Text>
								{loading ? (
									<ShimmerText line={2} gap={10} />
								) : (
									<Text fontWeight="semibold" fontSize={formValues}>
										{tour?.Schedule}
									</Text>
								)}
							</Stack>
							<Stack spacing=".2rem">
								<Text
									color="blackAlpha.500"
									fontWeight="semibold"
									fontSize={formLabels}>
									Trophies
								</Text>
								{loading ? (
									<ShimmerText line={2} gap={10} />
								) : (
									<Text fontWeight="semibold" fontSize={formValues}>
										{tour?.Trophies}
									</Text>
								)}
							</Stack>
							<Stack spacing=".2rem">
								<Text
									color="blackAlpha.500"
									fontWeight="semibold"
									fontSize={formLabels}>
									Additional Details
								</Text>
								{loading ? (
									<ShimmerText line={2} gap={10} />
								) : (
									<Text fontWeight="semibold" fontSize={formValues}>
										{tour?.AdditionalDetails}
									</Text>
								)}
							</Stack>
							<Stack spacing=".2rem">
								<Text
									color="blackAlpha.500"
									fontWeight="semibold"
									fontSize={formLabels}>
									List Name
								</Text>
								{loading ? (
									<ShimmerText line={2} gap={10} />
								) : (
									<Text fontWeight="semibold" fontSize={formValues}>
										{tour?.ListName}
									</Text>
								)}
							</Stack>
						</SimpleGrid>
					</Stack>
				</Stack>
			</CardHeader>
		</Card>
	);
};

import { useState, useEffect } from "react";

export default function useAuth() {
	async function getUserInfo() {
		try {
			// fetch user identity from static web app configuration
			let authResp = await fetch("/.auth/me");
			let userIdentity = await authResp.json();
			// check if the user has access
			let validationRes = await fetch(
				`https://carromslive.azurewebsites.net/Members?Email=${userIdentity.clientPrincipal.userDetails}`
			);
			let userValidation = await validationRes.json();
			let user = { loggedIn: false, authorized: false };

			const { clientPrincipal } = userIdentity;
			user = {
				loggedIn: true,
				authorized: userValidation?.status,
				userId: userValidation?.UserId,
				googleUserId: clientPrincipal.userId,
				userDetails: clientPrincipal.userDetails,
				firstName: getClaim(
					clientPrincipal,
					"http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname"
				),
				lastName: getClaim(
					clientPrincipal,
					"http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname"
				),
				email: getClaim(
					clientPrincipal,
					"http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"
				),
				picture: getClaim(clientPrincipal, "picture"),
			};
			console.log(user);
			return user;
		} catch (error) {
			console.error("No profile could be found");
			console.log(error);
			return { loggedIn: false, authorized: false };
		}
	}

	function getClaim(clientPrincipal, claimType) {
		return clientPrincipal.claims.find((c) => c.typ === claimType)?.val;
	}

	useEffect(() => {
		(async () => {
			setUser(await getUserInfo());
		})();
	}, []);

	const [user, setUser] = useState({ loggedIn: false, authorized: false });
	return user;
}

import {
	Container,
	Heading,
	useBreakpointValue,
	Stack,
	Card,
	CardHeader,
	useColorModeValue,
	FormControl,
	Select,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ApiService from "../../service/Api.service";
import { GroupsTable } from "../../Components/Events/Groups/GroupsTable";
import { useAuthProvider } from "../../context/AuthProvider"

export const Groups = () => {
	// User Details
	const user = useAuthProvider();

	//Desktop BreakPoint Value
	const isDesktop = useBreakpointValue({
		base: false,
		lg: true,
	});
	// Mobile Breakpoint Value
	const isMobile = useBreakpointValue({
		base: true,
		sm: true,
		md: false,
	});
	//Page Header
	const pageHeader = useBreakpointValue({
		base: "xs",
		sm: "sm",
	});
	//Box Shadow
	const shadow = useColorModeValue("sm", "sm-dark");
	//Card Heading
	const cardHeading = useBreakpointValue({
		base: "xs",
		md: "sm",
	});

	let { eventId } = useParams();
	let [groupsData, setGroupsData] = useState([]);
	let [groups, setGroups] = useState([]);
	let [allGroupsData, setAllGroupsData] = useState([]);
	let [groupsGroupName, setGroupsGroupName] = useState([]);

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		try {
			const response = await ApiService.httpGet(
				"/EventTeams?GroupsEventId=" + eventId, "U00184"
			);
			console.log(response);
			setGroups(response.GroupNames);
			fetchGroups(response.GroupNames);
		} catch (err) {
			console.error(err.message);
		}
	};

	const fetchGroups = async (grpname) => {
		try {
			//setLoading(true);
			console.log("EventId", eventId);
			const groupsResponse = await ApiService.httpGet(
				"/EventTeams?EventId=" + eventId, "U00184"
			);
			console.log("Groups Data: ", groupsResponse.Groups);
			setAllGroupsData(groupsResponse.Groups);
			let data = groupsResponse.Groups.find(
				(group) => group.GroupName === grpname[0].GroupName
			);
			setGroupsData(data?.Players);
			setGroupsGroupName(data?.GroupName);
		} catch (err) {
			console.error(err.message);
		}
	};

	const updateGroupsData = (event) => {
		console.log("Groups Change Handler", event);
		let groupData = allGroupsData.find(
			(group) => group.GroupName === event.target.value
		);
		console.log("Updated Groups data: ", groupData);
		setGroupsData(groupData.Players);
		setGroupsGroupName(groupData.GroupName);
	};

	return (
		<Container
			flex="1"
			maxWidth="100vw"
			marginTop={isDesktop ? "7rem" : "5rem"}
			marginX={0}
			{...(isMobile && { px: "1" })}
			paddingBottom={10}>
			<Stack spacing="1rem">
				<Card variant={"elevated"} bgColor="white" boxShadow={shadow}>
					<CardHeader>
						<Stack direction={"row"} spacing="1.2rem" justify="space-between">
							<Heading as="h2" size={cardHeading}>
								Groups
							</Heading>
							<FormControl maxW={"-webkit-max-content"}>
								<Select
									name="Group"
									colorScheme="blue"
									onChange={updateGroupsData}>
									{groups.map((group, index) => {
										return (
											<option key={group.GroupName} value={group.GroupName}>
												{group.GroupName}
											</option>
										);
									})}
								</Select>
							</FormControl>
						</Stack>
					</CardHeader>
				</Card>

				<GroupsTable
					groupsData={groupsData}
					groupsGroupName={groupsGroupName}
				/>
			</Stack>
		</Container>
	);
};

import {
	Box,
	Divider,
	FormControl,
	FormLabel,
	Input,
	Stack,
	useColorModeValue,
	Select,
	IconButton,
	InputGroup,
	FormErrorMessage,
	InputRightElement,
	CloseButton,
	Button,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import * as React from "react";
import { useState } from "react";
import { Controller } from "react-hook-form";

export const AddMemberForm = ({
	register,
	watch,
	errors,
	control,
	clubs,
	roles,
	setClubRoles,
	clubRoles,
	addRoleClubPair,
	removeRoleClubPair,
	clearErrors,
	handleFileChange,
	setValue,
}) => {
	//Box Shadow
	const shadow = useColorModeValue("sm", "sm-dark");

	let [showPassword, setShowPassword] = useState(false);

	let [selectedRole, setSelectedRole] = useState("");
	let [selectedClub, setSelectedClub] = useState("");

	const handleFieldChange = (e, index, fieldType) => {
		const updatedFields = [...clubRoles];
		if (fieldType === "club") {
			updatedFields[index].club = e.target.value;
			const selectedClubCopy = [...selectedClub];
			selectedClubCopy[index] = e.target.value;
			console.log("Selected Club Values:", selectedClubCopy);
			setSelectedClub(selectedClubCopy);
		} else if (fieldType === "role") {
			if (e.target.value === "R002") {
				updatedFields[index].club = "";
				const selectedClubCopy = [...selectedClub];
				selectedClubCopy[index] = "";
				console.log("Selected Club Values:", selectedClubCopy);
				setSelectedClub(selectedClubCopy);
				setValue(`clubs[${index}]`, "");
				clearErrors(`clubs[${index}]`);
			} else {
				updatedFields[index].role = e.target.value;
				const selectedRoleCopy = [...selectedRole];
				selectedRoleCopy[index] = e.target.value;
				console.log("Selected Role Values:", selectedRoleCopy);
				setSelectedRole(selectedRoleCopy);
			}
		}
		setClubRoles(updatedFields);
	};

	return (
		<Box bg="bg-surface" boxShadow={shadow} borderRadius="lg">
			<Stack
				spacing="5"
				px={{
					base: "4",
					md: "6",
				}}
				py={{
					base: "5",
					md: "6",
				}}>
				<Stack
					spacing="6"
					direction={{
						base: "column",
						md: "row",
					}}>
					<FormControl id="firstName" isInvalid={errors.FirstName}>
						<FormLabel>First Name</FormLabel>
						<Input
							type="text"
							{...register("FirstName", {
								required: "Please enter your First Name",
								pattern: {
									value: /^[A-Za-z\s]{1,}[\.]{0,1}[A-Za-z\s]{0,}$/,
									message: "Please enter a valid First Name",
								},
							})}
						/>
						<FormErrorMessage>
							{errors.FirstName && errors.FirstName.message}
						</FormErrorMessage>
					</FormControl>
					<FormControl id="lastName" isInvalid={errors.LastName}>
						<FormLabel>Last Name</FormLabel>
						<Input
							type="text"
							{...register("LastName", {
								required: "Please enter your Last Name",
								pattern: {
									value: /^[A-Za-z\s]{1,}[\.]{0,1}[A-Za-z\s]{0,}$/,
									message: "Please enter a valid Last Name",
								},
							})}
						/>
						<FormErrorMessage>
							{errors.LastName && errors.LastName.message}
						</FormErrorMessage>
					</FormControl>
				</Stack>
				<Stack
					spacing="6"
					direction={{
						base: "column",
						md: "row",
					}}>
					<FormControl id="email" isInvalid={errors.Email}>
						<FormLabel>Email Address</FormLabel>
						<Input
							type="email"
							{...register("Email", {
								required: "Please enter your email",
								pattern: {
									value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
									message: "Please enter a valid Email",
								},
							})}
						/>
						<FormErrorMessage>{errors.Email && errors.Email.message}</FormErrorMessage>
					</FormControl>
					<FormControl id="phone" isInvalid={errors.PhoneNo}>
						<FormLabel>Phone Number</FormLabel>
						<Input
							type="tel"
							{...register("PhoneNo", {
								required: "Please enter your Phone Number",
								pattern: {
									value: /^(?:\+\d{1,2}\s?)?(?=\d{1,15}$)(?:\d{10}|\d{3}-\d{3}-\d{4}|\(\d{3}\)\s?\d{3}-\d{4})$|^\+91[1-9]\d{9}$|^\+44\d{10}$|^\+971\d{9}$/,
									message: "Please enter a valid Phone Number",
								},
							})}
						/>
						<FormErrorMessage>
							{errors.PhoneNo && errors.PhoneNo.message}
						</FormErrorMessage>
						{/* <PhoneInput
							{...register("Phone", { required: true })}
							country={"us"}
							preferredCountries={["us", "ca", "in", "ae"]}
							inputStyle={{
								paddingTop: "0.4rem",
								paddingBottom: "0.4rem",
								width: "-webkit-fill-available",
							}}
						/> */}
					</FormControl>
				</Stack>
				<Stack
					spacing="6"
					direction={{
						base: "column",
						md: "row",
					}}>
					<FormControl id="password" isInvalid={errors.Password}>
						<FormLabel>Password</FormLabel>
						<InputGroup>
							<Input
								type={showPassword === false ? "password" : "text"}
								{...register("Password", {
									required: "Please enter a Password",
									pattern: {
										value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
										message:
											"Password must contain minimum six characters with at least one uppercase letter, one lowercase letter, one number and one special character",
									},
								})}
							/>
							<InputRightElement>
								<IconButton
									icon={showPassword === false ? <ViewIcon /> : <ViewOffIcon />}
									onClick={() => setShowPassword(!showPassword)}
									variant={"ghost"}
								/>
							</InputRightElement>
						</InputGroup>
						<FormErrorMessage>
							{errors.Password && errors.Password.message}
						</FormErrorMessage>
					</FormControl>
					<FormControl id="confirmPassword" isInvalid={errors.ConfirmPassword}>
						<FormLabel>Confirm Password</FormLabel>
						<Input
							type="password"
							{...register("ConfirmPassword", {
								required: "Please enter re-enter Password",
								validate: (val) => {
									if (watch("Password") !== val) {
										return "Passwords doesn't match";
									}
								},
							})}
						/>
						<FormErrorMessage>
							{errors.ConfirmPassword && errors.ConfirmPassword.message}
						</FormErrorMessage>
					</FormControl>
				</Stack>
				{clubRoles.map((role, index) => (
					<Stack
						spacing="6"
						direction={{
							base: "column",
							md: "row",
						}}
						key={index}>
						<FormControl isInvalid={errors.roles}>
							<FormLabel>Role</FormLabel>
							<Controller
								name={`roles[${index}]`}
								control={control}
								defaultValue={role}
								rules={{ required: "Select a Role" }}
								render={({ field: { value, onChange } }) => (
									<Select
										value={value}
										colorScheme="blue"
										placeholder="Select a Role"
										onChange={(e) => {
											handleFieldChange(e, index, "role");
											onChange(e);
										}}>
										{/* {watch("clubs")[index] !== null &&
										watch("clubs")[index] !== "" &&
										watch("clubs")[index] !== undefined
											? roles
													.filter((role) => role.RoleId !== "R002")
													.map((role) => {
														return (
															<option
																value={role.RoleId}
																key={role.RoleId}>
																{role.RoleName}
															</option>
														);
													}): */}
										{roles.map((role) => {
											return (
												<option value={role.RoleId} key={role.RoleId}>
													{role.RoleName}
												</option>
											);
										})}
									</Select>
								)}
							/>
							<FormErrorMessage>
								{errors.roles && errors.roles[index]?.message}
							</FormErrorMessage>
						</FormControl>
						<FormControl isInvalid={errors.clubs}>
							<FormLabel>Club</FormLabel>
							<Controller
								name={`clubs[${index}]`}
								control={control}
								defaultValue={watch("clubs")[index]}
								onChange={([selected]) => selected}
								rules={{
									required:
										watch("roles")[index] === "R002" ? false : "Select a Club",
								}}
								render={({ field: { value, onChange } }) => (
									<Select
										value={value}
										colorScheme="blue"
										placeholder="Select a Club"
										isDisabled={watch("roles")[index] === "R002"}
										onChange={(e) => {
											handleFieldChange(e, index, "club");
											onChange(e);
										}}>
										{clubs.map((club) => (
											<option key={club.ClubId} value={club.ClubId}>
												{club.ClubName}
											</option>
										))}
									</Select>
								)}
							/>
							<FormErrorMessage>
								{errors.clubs && errors.clubs[index]?.message}
							</FormErrorMessage>
						</FormControl>

						{index > 0 && (
							<CloseButton
								alignSelf="end"
								onClick={() => removeRoleClubPair(index)}
							/>
						)}
					</Stack>
				))}
				<Button
					type="button"
					width={"-webkit-fit-content"}
					size="sm"
					colorScheme="blue"
					borderRadius="full"
					onClick={addRoleClubPair}>
					Add Role
				</Button>
				<Stack
					spacing="6"
					direction={{
						base: "column",
						md: "row",
					}}>
					<FormControl id="ImageLink" isInvalid={errors.ImageLink}>
						<FormLabel>Member Image</FormLabel>
						<input
							type="file"
							name="ImageLink"
							accept=".jpg, .jpeg, .jfi, .jpe, .jif, .jfif, .heif, .heic, .png, .svg, .svgz, .raw"
							{...register("ImageLink", {
								onChange: (e) => handleFileChange(e),
							})}
						/>
						<FormErrorMessage>
							{errors.ImageLink && errors.ImageLink.message}
						</FormErrorMessage>
					</FormControl>
				</Stack>
			</Stack>
			<Divider />
		</Box>
	);
};

import {
	HStack,
	Table,
	Tbody,
	Td,
	Box,
	Tr,
	Th,
	Thead,
	useBreakpointValue,
	Text,
	Divider,
	Button,
	Avatar,
	FormControl,
	Select,
	useToast,
	Stack,
	Center,
	ButtonGroup,
	Skeleton,
} from "@chakra-ui/react";
import { useState } from "react";
import ApiService from "../../../service/Api.service";
import usePagination from "../../../hooks/usePagination";
import { Pagination } from "../../Pagination/Pagination";

export const GroupsTable = (props) => {
	//Desktop BreakPoint Value
	const isDesktop = useBreakpointValue({ base: false, xl: true });
	const isMobile = useBreakpointValue({
		base: true,
		md: false,
	});
	const toast = useToast();
	// let [updateGroupsData, setUpdatedGroupsData] = useState([]);
	let updateGroupsData = [];

	// Loading State
	let groupsDataLoading = props.groupsDataLoading;

	const [saveLoading, setSaveLoading] = useState(false);

	const updateGroups = (event, index) => {
		console.log("Updated groups value: ", event);
		console.log("Updated groups Data Index: ", index);
		console.log("Updated groups Data object at Index: ", props.groupsData[index]);
		if (props.eventType === "Singles") {
			if (
				updateGroupsData?.length > 0 &&
				updateGroupsData.findIndex(props.groupsData[index]?.SETeamId) > -1
			) {
				let indx = updateGroupsData.findIndex(props.groupsData[index]?.SETeamId);
				updateGroupsData[indx].Group = event.target.value.split(" - ")[1];
			} else {
				updateGroupsData.push({
					SETeamId: props.groupsData[index]?.SETeamId,
					Group: event.target.value.split(" - ")[1],
				});
			}
		} else {
			if (
				updateGroupsData?.length > 0 &&
				updateGroupsData.findIndex(props.groupsData[index]?.DETeamId) > -1
			) {
				let indx = updateGroupsData.findIndex(props.groupsData[index]?.DETeamId);
				updateGroupsData[indx].Group = event.target.value.split(" - ")[1];
			} else {
				updateGroupsData.push({
					DETeamId: props.groupsData[index]?.DETeamId,
					Group: event.target.value.split(" - ")[1],
				});
			}
		}
	};

	const saveGroups = async () => {
		console.log("Updated groups Data:", updateGroupsData);
		if (updateGroupsData?.length === 0) {
			toast({
				title: "Save Groups!",
				description: "Please Update Group for atleast One Player",
				status: "warning",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
		} else {
			try {
				setSaveLoading(true);
				let reqObj = {
					TourEventId: props.state.EventId,
					Groups: updateGroupsData,
				};
				let savedResponse = await ApiService.httpPut("/EventTeams", reqObj);
				if (savedResponse.status === true) {
					toast({
						title: "Save Groups!",
						description: "Groups Saved Successfully.",
						status: "success",
						duration: 3000,
						isClosable: true,
						position: "top-right",
					});
					props.refreshGroups();
				} else {
					toast({
						title: "Save Groups!",
						description: savedResponse.Message,
						status: "warning",
						duration: 3000,
						isClosable: true,
						position: "top-right",
					});
				}
				setSaveLoading(false);
			} catch (err) {
				console.log(err);
				toast({
					title: "Save Groups!",
					description: "Failed to Save Groups.",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				setSaveLoading(false);
			}
		}
	};

	// Pagination
	let [rows, setRows] = useState(10);
	const perPage = rows;

	const {
		currentPage,
		currentData,
		jump,
		prev,
		next,
		first,
		last,
		setLast,
		setCurrentPage,
		maxPage,
	} = usePagination(props.groupsData, perPage);

	const handleChange = (e, p) => {
		jump(p);
	};

	const handleRows = (event) => {
		setRows(Number(event.target.value));
		setLast(Number(event.target.value));
		setCurrentPage(1);
		// 	setParamTwo(Number(event.target.value));
	};

	// const previousPage = (event) => {
	// 	setParamTwo(Number(paramOne));
	// 	setParamOne(Number(paramTwo - rows));
	// };
	// const nextPage = (event) => {
	// 	setParamOne(Number(paramTwo));
	// 	setParamTwo(Number(paramTwo + rows));
	// };

	const tableDesktop = () => (
		<Box>
			<Box
				borderTopRadius={"2xl"}
				overflow="auto"
				boxShadow={"sm"}
				css={{
					"::-webkit-scrollbar": {
						width: "0.2rem",
						height: "0.2rem",
					},
					"::-webkit-scrollbar-track": {
						background: "#bee3f8",
						borderRadius: "1rem",
					},
					"::-webkit-scrollbar-thumb": {
						background: "#3182ce",
						borderRadius: "1rem",
					},
				}}>
				<Table variant="striped">
					<Thead position="sticky" top={0} bgColor="black" zIndex="2">
						<Tr>
							<Th flex="1" textColor="white" fontSize="md" textAlign="start" py={5}>
								{props.eventType === "Doubles" ? "Team Name" : "Player Name"}
							</Th>
							<Th flex="1" textColor="white" fontSize="md" textAlign="start">
								Group
							</Th>
							<Th flex="1" textColor="white" fontSize="md" textAlign="center">
								Pool
							</Th>
						</Tr>
					</Thead>
					<Tbody>
						{groupsDataLoading
							? Array(5)
									.fill()
									.map((rows, index) => (
										<Tr key={index}>
											<Td>
												<Skeleton
													isLoaded={!groupsDataLoading}
													h={"1.2rem"}
													w={"100%"}
												/>
											</Td>
											<Td>
												<Skeleton
													isLoaded={!groupsDataLoading}
													h={"1.2rem"}
													w={"100%"}
												/>
											</Td>
											<Td>
												<Skeleton
													isLoaded={!groupsDataLoading}
													h={"1.2rem"}
													w={"100%"}
												/>
											</Td>
										</Tr>
									))
							: currentData()?.map((board, groupIndx) => (
									<Tr key={groupIndx}>
										<Td
											flex="1"
											textAlign="start"
											fontSize="md"
											fontWeight="medium">
											<HStack>
												<Avatar />
												<Text>{board.TeamName}</Text>
											</HStack>
										</Td>
										<Td
											flex="1"
											textAlign="start"
											fontSize="md"
											fontWeight="medium">
											<FormControl>
												<Select
													name="Group"
													colorScheme="blue"
													defaultValue={props.groupName}
													onChange={(event) =>
														updateGroups(event, groupIndx)
													}>
													{props.groups.map((group, index) => {
														return (
															<option
																key={group.GroupName}
																value={group.GroupName}
																selected={
																	props.groupName ===
																	group.GroupName
																}>
																{group.GroupName}
															</option>
														);
													})}
												</Select>
											</FormControl>
										</Td>
										<Td
											flex="1"
											textAlign="center"
											fontSize="lg"
											fontWeight="medium">
											{board.PoolNo}
										</Td>
									</Tr>
							  ))}
					</Tbody>
				</Table>
			</Box>
			<Divider />
			<Stack p="1rem" bgColor="white" borderBottomRadius={"2xl"}>
				{/*---------------For Pagination-------------------------------*/}
				<ButtonGroup justifyContent={"end"}>
					<Button
						color="white"
						bgColor="bg-accent"
						borderRadius="full"
						onClick={saveGroups}
						isLoading={saveLoading}>
						Save Group
					</Button>
				</ButtonGroup>
				{isMobile ? (
					<Stack>
						<Center>
							<Pagination
								count={props.groupsData?.length}
								pageSize={rows}
								siblingCount={0}
								page={currentPage}
								onChange={(e) => setCurrentPage(e.page)}
							/>
						</Center>
						<HStack justify="space-between">
							<HStack spacing={1}>
								<Text as="span" whiteSpace="normal">
									Rows per page{" "}
								</Text>
								<Select
									boxSize={"-webkit-max-content"}
									minW="-webkit-fit-content"
									size="sm"
									value={rows}
									onChange={(event) => handleRows(event)}>
									{[10, 25, 50].map((rows) => (
										<option key={rows} value={rows}>
											{rows}
										</option>
									))}
								</Select>
							</HStack>
							<Text whiteSpace="normal">
								Showing {currentPage * rows - rows + 1} -{" "}
								{currentPage * rows < props.groupsData?.length
									? currentPage * rows
									: props.groupsData?.length}{" "}
								of {props.groupsData?.length} Entries
							</Text>
						</HStack>
					</Stack>
				) : (
					<HStack justify="space-between" pt={3}>
						<HStack spacing={1}>
							<Text as="span" whiteSpace={"nowrap"}>
								Rows per page{" "}
							</Text>
							<Select
								boxSize={"-webkit-max-content"}
								minW="-webkit-fit-content"
								value={rows}
								onChange={(event) => handleRows(event)}>
								{[10, 25, 50].map((rows) => (
									<option key={rows} value={rows}>
										{rows}
									</option>
								))}
							</Select>
						</HStack>
						<Center>
							<Pagination
								count={props.groupsData?.length}
								pageSize={rows}
								siblingCount={1}
								page={currentPage}
								onChange={(e) => setCurrentPage(e.page)}
							/>
						</Center>
						<Text whiteSpace="normal">
							Showing {currentPage * rows - rows + 1} -{" "}
							{currentPage * rows < props.groupsData?.length
								? currentPage * rows
								: props.groupsData?.length}{" "}
							of {props.groupsData?.length} Entries
						</Text>
					</HStack>
				)}
			</Stack>
		</Box>
	);

	// const tableMobile = () => (
	// 	<Box>
	// 		{data.map((board) => (
	// 			<Container
	// 				mb="1rem"
	// 				bgColor="white"
	// 				boxShadow={"sm"}
	// 				borderRadius="lg"
	// 				py="1rem"
	// 				px="1.5rem">
	// 				<Stack spacing={5} divider={<Divider />} fontSize="lg">
	// 					<HStack justify="space-between">
	// 						<Text fontWeight="bold">Player</Text>
	// 						<Text>{board.player}</Text>
	// 					</HStack>
	// 					<HStack justify="space-between">
	// 						<Text fontWeight="bold">Event</Text>
	// 						<Text>{board.event}</Text>
	// 					</HStack>
	// 					<HStack justify="space-between">
	// 						<Text fontWeight="bold">Nick Name</Text>
	// 						<Text>{board.nickname}</Text>
	// 					</HStack>
	// 					<HStack justify="space-between">
	// 						<Text fontWeight="bold">Mobile</Text>
	// 						<Text>{board.mobile}</Text>
	// 					</HStack>
	// 					<HStack justify="space-between">
	// 						<Text fontWeight="bold">Email</Text>
	// 						<Text>{board.email}</Text>
	// 					</HStack>
	// 					<HStack justify="space-between">
	// 						<FormControl>
	// 							<Input
	// 								placeholder={board.rank}
	// 								textAlign="start"
	// 								fontSize="lg"
	// 							/>
	// 						</FormControl>
	// 						<FormControl>
	// 							<Input
	// 								type="checkbox"
	// 								value={board.live}
	// 								textAlign="start"
	// 								fontSize="lg"
	// 							/>
	// 						</FormControl>
	// 					</HStack>

	// 					<Stack
	// 						direction={["column", "row"]}
	// 						justify="space-between"
	// 						pt="1rem">
	// 						{/*-----------For Pagination----------------------*/}
	// 					</Stack>
	// 				</Stack>
	// 			</Container>
	// 		))}
	// 	</Box>
	// );

	return tableDesktop();
};

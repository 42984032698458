import {
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useDisclosure,
	Box,
	Divider,
	HStack,
	Text,
	FormControl,
	FormLabel,
	Input,
	Stack,
	useColorModeValue,
	Select,
	useBreakpointValue,
	Heading,
	Checkbox,
	SimpleGrid,
	ButtonGroup,
	Flex,
	useToast,
	FormErrorMessage,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ApiService from "../../../../service/Api.service";
import { ShimmerBadge } from "react-shimmer-effects-18";

export const AddEventModal = (props) => {
	let eventTypesData = props.eventTypesData;
	let allFormats = props.allFormats;
	let setEventTypesData = props.setEventTypesData;
	let setAllFormats = props.setAllFormats;

	const { isOpen, onOpen, onClose } = useDisclosure();

	let [eventType, setEventType] = useState("");
	const [roundFormats, setRoundFormats] = useState([]);
	const [loading, setLoading] = useState(false);

	let toast = useToast();

	const KOOptions = [
		{ KOName: "Finals", KOId: "Finals" },
		{ KOName: "Semi Finals", KOId: "SemiFinals" },
		{ KOName: "Quarter Finals", KOId: "QuarterFinals" },
		{ KOName: "Pre-Quarter Finals", KOId: "Pre-QuarterFinals" },
	];

	const {
		register,
		handleSubmit,
		reset,
		watch,
		getValues,
		setValue,
		control,
		formState: { isDirty, isValid, errors },
	} = useForm({
		delayError: "500",
		mode: "all",
		defaultValues: {
			FormValues: {
				TourEventName: "",
				EventType: "",
				EventTypeId: "",
				FormatId: "",
				KnockoutFrom: "",
				Groups: 0,
				NoOfRounds: 0,
				BoardScores: 0,
				Streaming: 0,
				No_Teams: 0,
				TeamSize: 0,
				TeamsRRSet: 1,
				TeamsKOSet: 1,
				NoOfSingles: 0,
				NoOfDoubles: 0,
				NoOfMixedDoubles: 0,
				MinLevel1: 0,
				MinLevel2: 0,
			},
		},
	});

	//BoardScores List
	const BoardScores = ["Yes", "No"];

	//Handle Submit
	const onSubmit = (data) => {
		// setLoading(true);
		let SubmitJSON = {
			TournamentId: props.tourId,
			FormatId: data.FormValues.FormatId,
			TourEventName: data.FormValues.TourEventName,
			NoOfRounds: Number(data.FormValues.NoOfRounds),
			Groups: Number(data.FormValues.Groups),
			EventType: eventTypesData.find((ele) => ele.EventTypeId === data.FormValues.EventTypeId)
				.EventType,
			EventTypeId: data.FormValues.EventTypeId,
			BoardScores: data.FormValues.BoardScores === true ? "Yes" : "No",
			Streaming: data.FormValues.Streaming === true ? "Yes" : "No",
			TeamSize: Number(data.FormValues.TeamSize),
			No_Teams: Number(data.FormValues.No_Teams),
			NoOfSingles: Number(data.FormValues.NoOfSingles),
			NoOfDoubles: Number(data.FormValues.NoOfDoubles),
			NoOfMixedDoubles: Number(data.FormValues.NoOfMixedDoubles),
			MinLevel1: Number(data.FormValues.MinLevel1),
			MinLevel2: Number(data.FormValues.MinLevel2),
			KnockoutFrom: data.FormValues.KnockoutFrom,
			TeamsRRSet: Number(data.FormValues.TeamsRRSet),
			TeamsKOSet: Number(data.FormValues.TeamsKOSet),
		};
		console.log("AddEvent SubmitJSON", SubmitJSON);
		saveEvent(SubmitJSON);
	};

	const saveEvent = async (reqObj) => {
		try {
			setLoading(true);
			const eventResponse = await ApiService.httpPost("/TourEvents", reqObj);
			console.log("SavedEvent", eventResponse);
			console.log("successfully Event Created.");
			props.onCloseModal();
			onClose();
			if (eventResponse.status === true) {
				toast({
					title: "Add Event!",
					description: "Event Added Successfully",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				reset();
			} else if (eventResponse.status === false) {
				toast({
					title: "Add Event!",
					description: eventResponse.Message,
					status: "warning",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			} else {
				toast({
					title: "Add Event!",
					description: "Failed to Add Event",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
			//   handleEventDialogClose();

			//   setOnTournamentDetailsReload(!onTournamentDetailsReload);

			// setClubs(clubs.Clubs)
			//   setLoading(false);
		} catch (err) {
			console.error(err.message);
			toast({
				title: "Add Event!",
				description: "Failed to Add Event",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
		} finally {
			 setLoading(false);
		}
	};

	return (
		<>
			{props.loading ? (
				<ShimmerBadge width={100} />
			) : (
				<Button
					textColor="white"
					w="8rem"
					bgColor="accent"
					borderRadius="full"
					_hover={{ bgColor: "bg-accent" }}
					onClick={onOpen}>
					Add Event
				</Button>
			)}
			<Modal
				isOpen={isOpen}
				size={useBreakpointValue({
					base: "sm",
					sm: "lg",
					md: "2xl",
					lg: "4xl",
					xl: "5xl",
				})}
				onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader textAlign="center">Add Event</ModalHeader>
					<ModalCloseButton />
					<form onSubmit={handleSubmit((data) => onSubmit(data))}>
						<ModalBody>
							<Stack spacing="1rem">
								<Stack
									spacing={useBreakpointValue({
										base: "1rem",
										sm: "1.5rem",
									})}>
									<Stack
										spacing={useBreakpointValue({
											base: "1rem",
											sm: "1.5rem",
										})}
										direction={useBreakpointValue({
											base: "column",
											sm: "row",
										})}>
										<FormControl
											id="EventName"
											isInvalid={errors.FormValues?.TourEventName}>
											<FormLabel>Event Name</FormLabel>
											<Input
												type="text"
												placeholder="Enter Event Name"
												{...register("FormValues.TourEventName", {
													required: "Please enter Event Name",
													pattern: {
														value: /^[A-Za-z0-9 ]+$/,
														message:
															"Event Name can be alphanumeric and can contain whitespaces",
													},
													minLength: {
														value: 2,
														message: "Event Name can't be this short",
													},
												})}
											/>
											<FormErrorMessage>
												{errors.FormValues?.TourEventName &&
													errors.FormValues?.TourEventName.message}
											</FormErrorMessage>
										</FormControl>
										<FormControl
											id="EventType"
											isInvalid={errors.FormValues?.EventTypeId}>
											<FormLabel>Event Type</FormLabel>
											<Controller
												control={control}
												name="FormValues.EventTypeId"
												rules={{
													required: "Please select Event Type",
												}}
												render={({ field: { value, onChange } }) => (
													<Select
														name="EventType"
														colorScheme="blue"
														value={value}
														onChange={(e) => {
															setEventType(e.target.value);
															setRoundFormats(
																allFormats[
																	eventTypesData.find(
																		(typeId) =>
																			typeId.EventTypeId ===
																			e.target.value
																	)?.EventType
																]
															);
															setValue("FormValues.RoundFormat", "");
															setValue("FormValues.FormatId", "");
															onChange(e);
														}}
														placeholder="Select Event Type">
														{eventTypesData?.map((event, index) => {
															return (
																<option
																	key={event.EventTypeId}
																	value={event.EventTypeId}>
																	{event.EventType}
																</option>
															);
														})}
													</Select>
												)}
											/>
											<FormErrorMessage>
												{errors.FormValues?.EventTypeId &&
													errors.FormValues?.EventTypeId.message}
											</FormErrorMessage>
										</FormControl>
									</Stack>
									<Stack
										spacing={useBreakpointValue({
											base: "1rem",
											sm: "1.5rem",
										})}
										direction={useBreakpointValue({
											base: "column",
											sm: "row",
										})}>
										<FormControl
											id="format"
											isInvalid={errors.FormValues?.RoundFormat}>
											<FormLabel>Round Format</FormLabel>
											<Controller
												control={control}
												name="FormValues.RoundFormat"
												rules={{
													required: "Please select Round Format",
												}}
												render={({ field: { value, onChange } }) => (
													<Select
														name="Format"
														colorScheme="blue"
														value={value}
														placeholder="Select Format"
														onChange={(e) => {
															roundFormats.find(
																(round) =>
																	round.RoundsFormat ===
																	e.target.value
															)?.Knockouts[0].KnockoutFormat ===
																"NA" &&
																setValue(
																	"FormValues.FormatId",
																	roundFormats.find(
																		(round) =>
																			round.RoundsFormat ===
																			e.target.value
																	)?.Knockouts[0].FormatId
																);
															onChange(e);
														}}>
														{roundFormats?.map((format, index) => {
															return (
																<option
																	key={format.RoundsFormat}
																	value={format.RoundsFormat}>
																	{format.RoundsFormat}
																</option>
															);
														})}
													</Select>
												)}
											/>
											<FormErrorMessage>
												{errors.FormValues?.RoundFormat &&
													errors.FormValues?.RoundFormat.message}
											</FormErrorMessage>
										</FormControl>
										{watch("FormValues.RoundFormat") && (
											<FormControl
												id="format"
												isInvalid={errors.FormValues?.FormatId}>
												<FormLabel>KO Format</FormLabel>
												<Controller
													control={control}
													name="FormValues.FormatId"
													rules={{
														required: "Please select KO Format",
													}}
													render={({ field: { value, onChange } }) => (
														<Select
															name="Format"
															colorScheme="blue"
															value={value}
															placeholder="Select Format"
															onChange={(e) => {
																onChange(e);
															}}>
															{roundFormats
																.find(
																	(round) =>
																		round.RoundsFormat ===
																		watch(
																			"FormValues.RoundFormat"
																		)
																)
																?.Knockouts?.map(
																	(format, index) => {
																		return (
																			<option
																				key={
																					format.FormatId
																				}
																				value={
																					format.FormatId
																				}>
																				{
																					format.KnockoutFormat
																				}
																			</option>
																		);
																	}
																)}
														</Select>
													)}
												/>
												<FormErrorMessage>
													{errors.FormValues?.FormatId &&
														errors.FormValues?.FormatId.message}
												</FormErrorMessage>
											</FormControl>
										)}
										{eventType === "ET00003" &&
											watch("FormValues.FormatId") === "3" && (
												<FormControl
													id="KnockoutFrom"
													isInvalid={errors.FormValues?.FormatId}>
													<FormLabel>Knockout Starts From</FormLabel>
													<Controller
														control={control}
														name="FormValues.KnockoutFrom"
														rules={{
															required:
																"Please select when the Knockouts must start",
														}}
														render={({
															field: { value, onChange },
														}) => (
															<Select
																name="KnockoutFrom"
																colorScheme="blue"
																value={value}
																placeholder="Select where the KO Starts"
																onChange={(e) => {
																	onChange(e);
																}}>
																{KOOptions?.map(
																	(options, index) => {
																		return (
																			<option
																				key={options.KOId}
																				value={
																					options.KOId
																				}>
																				{options.KOName}
																			</option>
																		);
																	}
																)}
															</Select>
														)}
													/>
													<FormErrorMessage>
														{errors.FormValues?.KnockoutFrom &&
															errors.FormValues?.KnockoutFrom.message}
													</FormErrorMessage>
												</FormControl>
											)}
									</Stack>
									<SimpleGrid
										spacing={{ base: "1rem", sm: "1.5rem" }}
										columns={{ base: "1", sm: "2" }}>
										<Stack
											spacing={{
												base: "1rem",
												sm: "1.5rem",
											}}
											direction={{
												base: "column",
												sm: "row",
											}}>
											<FormControl
												id="NumberOfGroups"
												isInvalid={errors.FormValues?.Groups}>
												<FormLabel>Number Of Groups</FormLabel>
												<Controller
													control={control}
													name="FormValues.Groups"
													rules={{
														required: "Please enter No. of Groups",
														min: {
															value: 1,
															message:
																"No. of Groups must be greater than one",
														},
													}}
													render={({ field: { value, onChange } }) => (
														<NumberInput
															inputMode="numeric"
															min={0}
															keepWithinRange={true}
															value={value}
															onChange={(value) => {
																onChange(value);
															}}>
															<NumberInputField />
															<NumberInputStepper>
																<NumberIncrementStepper />
																<NumberDecrementStepper />
															</NumberInputStepper>
														</NumberInput>
													)}
												/>
												<FormErrorMessage>
													{errors.FormValues?.Groups &&
														errors.FormValues?.Groups.message}
												</FormErrorMessage>
											</FormControl>
											<FormControl
												id="NumberOfRounds"
												isInvalid={errors.FormValues?.NoOfRounds}>
												<FormLabel>Number Of Rounds</FormLabel>
												<Controller
													control={control}
													name="FormValues.NoOfRounds"
													rules={{
														required: "Please enter No. of Rounds",
														min: {
															value: 1,
															message:
																"No. of Rounds must be greater than one",
														},
													}}
													render={({ field: { value, onChange } }) => (
														<NumberInput
															inputMode="numeric"
															min={0}
															keepWithinRange={true}
															value={value}
															onChange={(value) => {
																onChange(value);
															}}>
															<NumberInputField />
															<NumberInputStepper>
																<NumberIncrementStepper />
																<NumberDecrementStepper />
															</NumberInputStepper>
														</NumberInput>
													)}
												/>
												<FormErrorMessage>
													{errors.FormValues?.NoOfRounds &&
														errors.FormValues?.NoOfRounds.message}
												</FormErrorMessage>
											</FormControl>
										</Stack>
										<HStack align="center">
											<Checkbox
												type="checkbox"
												{...register("FormValues.BoardScores")}>
												Board Score
											</Checkbox>
											<Checkbox
												type="checkbox"
												{...register("FormValues.Streaming")}>
												Streaming
											</Checkbox>
										</HStack>
									</SimpleGrid>
								</Stack>
								{eventType === "ET00003" && (
									<Stack spacing="1rem">
										<Text
											as="h5"
											fontWeight="semibold"
											fontSize={{ base: "lg", sm: "xl" }}
											textAlign="center">
											Team Event Details
										</Text>
										<Stack
											spacing={{
												base: "1rem",
												sm: "1.5rem",
											}}>
											<Stack
												spacing={{
													base: "1rem",
													sm: "1.5rem",
												}}
												direction={{
													base: "column",
													sm: "row",
												}}>
												<FormControl
													id="NumberOfTeams"
													isInvalid={errors.FormValues?.No_Teams}>
													<FormLabel>Number Of Teams</FormLabel>
													<Controller
														control={control}
														name="FormValues.No_Teams"
														rules={{
															required: "Please enter No. of Teams",
															min: {
																value: 1,
																message:
																	"No. of Teams must be greater than one",
															},
														}}
														render={({
															field: { value, onChange },
														}) => (
															<NumberInput
																inputMode="numeric"
																min={0}
																keepWithinRange={true}
																value={value}
																onChange={(value) => {
																	onChange(value);
																}}>
																<NumberInputField />
																<NumberInputStepper>
																	<NumberIncrementStepper />
																	<NumberDecrementStepper />
																</NumberInputStepper>
															</NumberInput>
														)}
													/>
													<FormErrorMessage>
														{errors.FormValues?.No_Teams &&
															errors.FormValues?.No_Teams.message}
													</FormErrorMessage>
												</FormControl>
												<FormControl
													id="TeamSize"
													isInvalid={errors.FormValues?.TeamSize}>
													<FormLabel>Team Size</FormLabel>
													<Controller
														control={control}
														name="FormValues.TeamSize"
														rules={{
															required: "Please enter Team Size",
															min: {
																value: 1,
																message:
																	"Team Size must be greater than one",
															},
														}}
														render={({
															field: { value, onChange },
														}) => (
															<NumberInput
																inputMode="numeric"
																min={0}
																keepWithinRange={true}
																value={value}
																onChange={(value) => {
																	onChange(value);
																}}>
																<NumberInputField />
																<NumberInputStepper>
																	<NumberIncrementStepper />
																	<NumberDecrementStepper />
																</NumberInputStepper>
															</NumberInput>
														)}
													/>
													<FormErrorMessage>
														{errors.FormValues?.TeamSize &&
															errors.FormValues?.TeamSize.message}
													</FormErrorMessage>
												</FormControl>
												{(watch("FormValues.FormatId") === "3" ||
													watch("FormValues.FormatId") === "5") && (
													<FormControl
														id="TeamsRRSet"
														isInvalid={errors.FormValues?.TeamsRRSet}>
														<FormLabel>RR Set Count</FormLabel>
														<Controller
															control={control}
															name="FormValues.TeamsRRSet"
															rules={{
																required:
																	"Please enter the Set Count for RR",
																min: {
																	value: 1,
																	message:
																		"Set Count must be greater than one",
																},
															}}
															render={({
																field: { value, onChange },
															}) => (
																<NumberInput
																	inputMode="numeric"
																	min={0}
																	keepWithinRange={true}
																	value={value}
																	onChange={(value) => {
																		onChange(value);
																	}}>
																	<NumberInputField />
																	<NumberInputStepper>
																		<NumberIncrementStepper />
																		<NumberDecrementStepper />
																	</NumberInputStepper>
																</NumberInput>
															)}
														/>
														<FormErrorMessage>
															{errors.FormValues?.TeamsRRSet &&
																errors.FormValues?.TeamsRRSet
																	.message}
														</FormErrorMessage>
													</FormControl>
												)}
												{watch("FormValues.FormatId") === "3" && (
													<FormControl
														id="TeamsKOSet"
														isInvalid={errors.FormValues?.TeamsKOSet}>
														<FormLabel>Knockout Set Count</FormLabel>
														<Controller
															control={control}
															name="FormValues.TeamsKOSet"
															rules={{
																required:
																	"Please enter the Set Count for Knockout",
																min: {
																	value: 1,
																	message:
																		"Set Count must be greater than one",
																},
															}}
															render={({
																field: { value, onChange },
															}) => (
																<NumberInput
																	inputMode="numeric"
																	min={0}
																	keepWithinRange={true}
																	value={value}
																	onChange={(value) => {
																		onChange(value);
																	}}>
																	<NumberInputField />
																	<NumberInputStepper>
																		<NumberIncrementStepper />
																		<NumberDecrementStepper />
																	</NumberInputStepper>
																</NumberInput>
															)}
														/>
														<FormErrorMessage>
															{errors.FormValues?.TeamsKOSet &&
																errors.FormValues?.TeamsKOSet
																	.message}
														</FormErrorMessage>
													</FormControl>
												)}
											</Stack>
											<Stack
												spacing={{
													base: "1rem",
													sm: "1.5rem",
												}}
												direction={{
													base: "column",
													sm: "row",
												}}>
												<FormControl
													id="NumberOfSingles"
													isInvalid={errors.FormValues?.NoOfSingles}>
													<FormLabel>Number Of Singles</FormLabel>
													<Controller
														control={control}
														name="FormValues.NoOfSingles"
														rules={{
															required: "Please enter No. of Singles",
															min: {
																value: 1,
																message:
																	"No. of Singles must be greater than one",
															},
															validate: (val) => {
																if (
																	val >
																	watch("FormValues.TeamSize") -
																		watch(
																			"FormValues.NoOfDoubles"
																		) *
																			2
																) {
																	return "Please don't exceed the team size";
																}
															},
														}}
														render={({
															field: { value, onChange },
														}) => (
															<NumberInput
																inputMode="numeric"
																min={0}
																keepWithinRange={true}
																value={value}
																onChange={(value) => {
																	onChange(value);
																}}>
																<NumberInputField />
																<NumberInputStepper>
																	<NumberIncrementStepper />
																	<NumberDecrementStepper />
																</NumberInputStepper>
															</NumberInput>
														)}
													/>
													<FormErrorMessage>
														{errors.FormValues?.NoOfSingles &&
															errors.FormValues?.NoOfSingles.message}
													</FormErrorMessage>
												</FormControl>
												<FormControl
													id="NumberOfDoubles"
													isInvalid={errors.FormValues?.NoOfDoubles}>
													<FormLabel>Number Of Doubles</FormLabel>
													<Controller
														control={control}
														name="FormValues.NoOfDoubles"
														rules={{
															required: "Please enter No. of Doubles",
															min: {
																value: 1,
																message:
																	"No. of Doubles must be greater than one",
															},
															validate: (val) => {
																if (
																	val * 2 >
																	watch("FormValues.TeamSize") -
																		watch(
																			"FormValues.NoOfSingles"
																		)
																) {
																	return "Please don't exceed the team size";
																}
															},
														}}
														render={({
															field: { value, onChange },
														}) => (
															<NumberInput
																inputMode="numeric"
																min={0}
																keepWithinRange={true}
																value={value}
																onChange={(value) => {
																	onChange(value);
																}}>
																<NumberInputField />
																<NumberInputStepper>
																	<NumberIncrementStepper />
																	<NumberDecrementStepper />
																</NumberInputStepper>
															</NumberInput>
														)}
													/>
													<FormErrorMessage>
														{errors.FormValues?.NoOfDoubles &&
															errors.FormValues?.NoOfDoubles.message}
													</FormErrorMessage>
												</FormControl>
												<FormControl
													id="NoOfMixedDoubles"
													isInvalid={errors.FormValues?.NoOfMixedDoubles}>
													<FormLabel>Number Of Mixed Doubles</FormLabel>
													<Controller
														control={control}
														name="FormValues.NoOfMixedDoubles"
														rules={{
															validate: (val) => {
																if (
																	watch(
																		"FormValues.NoOfDoubles"
																	) < val
																) {
																	return `Number of Mixed Doubles must be less than or equal to No. of Doubles`;
																}
															},
														}}
														render={({
															field: { value, onChange },
														}) => (
															<NumberInput
																inputMode="numeric"
																min={0}
																keepWithinRange={true}
																value={value}
																onChange={(value) => {
																	onChange(value);
																}}>
																<NumberInputField />
																<NumberInputStepper>
																	<NumberIncrementStepper />
																	<NumberDecrementStepper />
																</NumberInputStepper>
															</NumberInput>
														)}
													/>
													<FormErrorMessage>
														{errors.FormValues?.NoOfMixedDoubles &&
															errors.FormValues?.NoOfMixedDoubles
																.message}
													</FormErrorMessage>
												</FormControl>
											</Stack>
											<Stack
												spacing={{
													base: "1rem",
													sm: "1.5rem",
												}}
												direction={{
													base: "column",
													sm: "row",
												}}>
												<FormControl
													id="MinLevel1"
													isInvalid={errors.FormValues?.MinLevel1}>
													<FormLabel>Player1 minlevel1s </FormLabel>
													<Controller
														control={control}
														name="FormValues.MinLevel1"
														rules={{
															required: "Please enter MinLevel1",
															min: {
																value: 1,
															},
														}}
														render={({
															field: { value, onChange },
														}) => (
															<NumberInput
																inputMode="numeric"
																min={0}
																keepWithinRange={true}
																value={value}
																onChange={(value) => {
																	onChange(value);
																}}>
																<NumberInputField />
																<NumberInputStepper>
																	<NumberIncrementStepper />
																	<NumberDecrementStepper />
																</NumberInputStepper>
															</NumberInput>
														)}
													/>
													<FormErrorMessage>
														{errors.FormValues?.MinLevel1 &&
															errors.FormValues?.MinLevel1.message}
													</FormErrorMessage>
												</FormControl>
												<FormControl
													id="MinLevel2"
													isInvalid={errors.FormValues?.MinLevel2}>
													<FormLabel> Player2 minlevel2s</FormLabel>
													<Controller
														control={control}
														name="FormValues.MinLevel2"
														rules={{
															required: "Please enter MinLevel2",
															min: {
																value: 1,
															},
														}}
														render={({
															field: { value, onChange },
														}) => (
															<NumberInput
																inputMode="numeric"
																min={0}
																keepWithinRange={true}
																value={value}
																onChange={(value) => {
																	onChange(value);
																}}>
																<NumberInputField />
																<NumberInputStepper>
																	<NumberIncrementStepper />
																	<NumberDecrementStepper />
																</NumberInputStepper>
															</NumberInput>
														)}
													/>
													<FormErrorMessage>
														{errors.FormValues?.MinLevel2 &&
															errors.FormValues?.MinLevel2.message}
													</FormErrorMessage>
												</FormControl>
											</Stack>
										</Stack>
									</Stack>
								)}
							</Stack>
						</ModalBody>

						<ModalFooter>
							<ButtonGroup spacing="5">
							{loading ? null : (
								<Button
									onClick={() => {
										onClose();
										reset();
									}}
									size="lg"
									borderRadius="full"
									colorScheme="red">
									Cancel
								</Button>
							)}
								<Button
									type="submit"
									size="lg"
									borderRadius="full"
									colorScheme="blue"
									isLoading={loading}
									disabled={!isDirty || !isValid}>
									Save
								</Button>
							</ButtonGroup>
						</ModalFooter>
					</form>
				</ModalContent>
			</Modal>
		</>
	);
};
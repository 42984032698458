import {
	HStack,
	Image,
	Table,
	Tbody,
	Td,
	Box,
	Tr,
	Th,
	Thead,
	VStack,
	FormControl,
	Select,
	Input,
	TableContainer,
	useBreakpointValue,
	Container,
	Text,
	Stack,
	Divider,
	useColorModeValue,
	FormLabel,
	IconButton,
	Icon,
	position,
	ButtonGroup,
	Button,
	Flex,
} from "@chakra-ui/react";

export const TeamsTable = ({ teams, currTeamId }) => {
	//Desktop Breakpoint Value
	const isDesktop = useBreakpointValue({ base: false, xl: true });
	// Mobile Breakpoint Value
	const isMobile = useBreakpointValue({
		base: true,
		sm: true,
		md: true,
		lg: false,
		xl: false,
	});
	// Tablet Breakpoint Value
	const isTablet = useBreakpointValue({
		base: false,
		sm: false,
		md: true,
		lg: false,
		xl: false,
	});
	//Box Shadow
	const shadow = useColorModeValue("sm", "sm-dark");

	const tableDesktop = () => (
		<Box>
			<Box borderTopRadius={"2xl"} boxShadow={shadow}>
				<Table variant="striped">
					<Thead position="sticky" top={0} bgColor="black" zIndex="2">
						<Tr>
							<Th
								textColor="white"
								fontSize={"md"}
								py={4}
								textAlign={"start"}
								borderTopRadius={"2xl"}>
								Player Name
							</Th>
							{/* <Th
								textColor="white"
								fontSize={isTablet ? "sm" : "md"}
								px={3}
								{...(isMobile && { px: 1, fontSize: "xs" })}
								textAlign={"start"}
								borderTopRightRadius={"2xl"}>
								Captain
							</Th> */}
						</Tr>
					</Thead>
					<Tbody>
						{teams
							?.find((team) => team.TETeamId === currTeamId)
							?.Players?.map((player, index) => (
								<Tr key={player.TeamsPlayerId}>
									<Td
										textAlign={"start"}
										fontSize={"lg"}
										{...(player.isCaptain === "Yes"
											? {
													fontWeight: "bold",
											  }
											: {
													fontWeight: "medium",
											  })}
										whiteSpace={"break-spaces"}>
										{player.PlayerName}
										{player.isCaptain === "Yes" && " (c)"}
									</Td>
									{/* <Td
										textAlign="start"
										fontSize={"lg"}
										fontWeight="medium"
										maxW="-webkit-fit-content">
										<input
											readOnly
											type={"radio"}
											name={
												teams.find((team) => team.TETeamId === currTeamId)
													.TETeamId
											}
											checked={player.isCaptain === "Yes" && true}
										/>
									</Td> */}
								</Tr>
							))}
					</Tbody>
				</Table>
			</Box>
			<HStack
				justify="space-between"
				p="1rem"
				bgColor="white"
				borderBottomRadius={"2xl"}>
				{/*---------------For Pagination-------------------------------*/}
			</HStack>
		</Box>
	);

	return tableDesktop();
};

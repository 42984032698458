import {
	Container,
	Button,
	Heading,
	Text,
	useBreakpointValue,
	Flex,
	Stack,
	SimpleGrid,
	Box,
} from "@chakra-ui/react";
import { Navbar } from "../../Components/Navbar/Navbar";
import { Sidebar } from "../../Components/Sidebar/Sidebar";
import { ManageClubCard } from "../../Components/Clubs/ManageClubCards/ManageClubCards";
import ApiService from "../../service/Api.service";
import { useContext, useEffect, useState } from "react";
import { data } from "../../App";
import { useNavigate } from "react-router-dom";
import { ShimmerPostItem } from "react-shimmer-effects-18";
import { BallTriangle } from "react-loader-spinner";
import { useAuthProvider } from "../../context/AuthProvider";

export const ManageClubs = () => {
	// User Details
	const user = useAuthProvider();

	//Desktop BreakPoint Value
	const isDesktop = useBreakpointValue({
		base: false,
		lg: true,
	});
	//Page Header
	const pageHeader = useBreakpointValue({
		base: "xs",
		sm: "sm",
	});
	//Page Caption
	const pageCaption = useBreakpointValue({
		base: "sm",
		sm: "md",
	});
	// Navigate function
	const navigate = useNavigate();

	// States
	let [clubReload, setClubReload] = useState(false);
	const [loading, setLoading] = useState(false);
	const [loadingDelete, setLoadingDelete] = useState(false);

	// let { loading, setLoading } = useContext(data);
	const [clubs, setClubs] = useState([]);
	const fetchClubs = async () => {
		try {
			setLoading(true);
			const response = await ApiService.httpGet("/Clubs?ClubId=all", user.userId);
			setClubs(response.Clubs);
			console.log("clubs list", response.Clubs);
			setLoading(false);
		} catch (err) {
			console.error(err.message);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchClubs();
	}, [clubReload]);

	return (
		<>
			{loadingDelete ? (
				<Box
					position={"absolute"}
					bottom={"50%"}
					left={"50%"}
					p={0}
					m={0}
					zIndex={"overlay"}>
					<BallTriangle
						height={100}
						width={100}
						radius={5}
						color="#4fa94d"
						ariaLabel="ball-triangle-loading"
						wrapperClass={{}}
						wrapperStyle=""
						visible={true}
					/>
				</Box>
			) : (
				<Container
					flex="1"
					overflowY="auto"
					maxWidth="100vw"
					marginTop={isDesktop ? "5rem" : "1rem"}
					marginX={0}
					paddingBottom={10}
					css={{
						"::-webkit-scrollbar": {
							width: "0.3rem",
							height: "0.3rem",
						},
						"::-webkit-scrollbar-track": {
							background: "#bee3f8",
							borderRadius: "1rem",
						},
						"::-webkit-scrollbar-thumb": {
							background: "#3182ce",
							borderRadius: "1rem",
						},
					}}>
					<Stack
						spacing={{
							base: "8",
							lg: "6",
						}}>
						<Stack
							spacing={7}
							direction={{ base: "column", sm: "row" }}
							alignItems={{ sm: "center" }}
							justify="space-between">
							<Stack
								spacing="4"
								direction={{
									base: "row ",
									lg: "row",
								}}
								justify="space-between"
								align={{
									base: "start",
									lg: "center",
								}}>
								<Stack spacing="1">
									<Heading size={pageHeader} fontWeight="bold">
										Manage Clubs
									</Heading>
									<Text
										fontWeight="bold"
										color="blackAlpha.500"
										fontSize={pageCaption}>
										Access your Clubs here
									</Text>
								</Stack>
							</Stack>
							<Button
								bgColor="bg-accent"
								color="white"
								_hover={{
									bgColor: "bg-accent",
									boxShadow: "0px 3px 20px 3px cornflowerblue",
								}}
								borderRadius={50}
								onClick={() => {
									navigate("/clubs/addclub");
								}}>
								Add New Club
							</Button>
						</Stack>
						<div>
							<SimpleGrid
								columns={{ base: 2, sm: 3, md: 4, lg: 5, xl: 6 }}
								columnGap={{ base: "4", md: "8" }}
								rowGap={{ base: "4", md: "8" }}>
								{loading
									? Array(10)
											.fill()
											.map((load, index) => (
												<ShimmerPostItem
													key={index}
													card
													title
													imageType="thumbnail"
													imageWidth={60}
													imageHeight={60}
													contentCenter
												/>
											))
									: clubs.map((club, index) => {
											// if (
											//   searchBarValue.length &&
											//   (club.ClubId.toLowerCase().includes(searchBarValue) ||
											//     club.ClubName.toLowerCase().includes(searchBarValue) ||
											//     club.NickName.toLowerCase().includes(searchBarValue))
											// ) {
											return (
												<ManageClubCard
													key={club.ClubId}
													clubData={club}
													index={index}
													setClubReload={setClubReload}
													clubReload={clubReload}
													setLoadingDelete={setLoadingDelete}
												/>
											);
											// } else if (!searchBarValue.length) {
											//   return <ManageClubCard key={club.ClubId} clubData={club} />;
											// }
									  })}

								{/*----------------------- ManageClub Card -------------------------*/}
							</SimpleGrid>
						</div>
					</Stack>
				</Container>
			)}
		</>
	);
};

import {
    Pagination as ArkPagination,
    PaginationEllipsis,
    PaginationNextPageTrigger,
    PaginationPageTrigger,
    PaginationPrevPageTrigger,
  } from '@ark-ui/react'
  import {
    Button,
    ButtonGroup,
    IconButton,
    useBreakpointValue,
    VisuallyHidden,
  } from '@chakra-ui/react'
  import { FiArrowLeft, FiArrowRight } from 'react-icons/fi'
  
  export const Pagination = (props) => {
    const isMobile = useBreakpointValue({
      base: true,
      md: false,
    })
    return (
      <ArkPagination {...props}>
        {({ pages }) => (
          <ButtonGroup isAttached gap={0} variant="secondary">
            <PaginationPrevPageTrigger asChild>
                <IconButton icon={<FiArrowLeft />} aria-label="Previous Page" />
            </PaginationPrevPageTrigger>
            {pages.map((page, index) =>
              page.type === 'page' ? (
                <PaginationPageTrigger key={index} {...page} asChild>
                  <Button
                    borderRadius="inherit"
                    marginInlineEnd="-1px"
                    _selected={{
                      bg: 'gray.100',
                      _dark: {
                        bg: 'gray.700',
                      },
                    }}
                  >
                    {page.value}
                  </Button>
                </PaginationPageTrigger>
              ) : (
                <PaginationEllipsis index={index} asChild>
                  <Button borderRadius="inherit" marginInlineEnd="-1px" cursor="default">
                    &#8230;
                  </Button>
                </PaginationEllipsis>
              ),
            )}
            <PaginationNextPageTrigger asChild>
                <IconButton icon={<FiArrowRight />} aria-label="Next Page" />
            </PaginationNextPageTrigger>
          </ButtonGroup>
        )}
      </ArkPagination>
    )
  }
import {
	Box,
	Divider,
	Text,
	FormControl,
	FormLabel,
	Input,
	Stack,
	useColorModeValue,
	FormErrorMessage,
	Textarea,
} from "@chakra-ui/react"

export const TournamentsForm2 = ({ register, errors }) => {
	return (
		<Box>
			<Text fontSize="xl" mb={2} fontWeight="medium">
				Additional Details
			</Text>
			<Box
				as="form"
				bg="bg-surface"
				boxShadow={useColorModeValue("sm", "sm-dark")}
				borderRadius="lg">
				<Stack
					spacing="5"
					px={{
						base: "4",
						md: "6",
					}}
					py={{
						base: "5",
						md: "6",
					}}>
					<Stack
						spacing="6"
						direction={{
							base: "column",
							md: "row",
						}}>
						<FormControl id="Schedule">
							<FormLabel>Schedule</FormLabel>
							<Input type="text" {...register("Schedule")} />
						</FormControl>
						<FormControl id="Trophies" isInvalid={errors?.Trophies}>
							<FormLabel>Trophies</FormLabel>
							<Input
								type="number"
								{...register("Trophies", {
									pattern: {
										value: /^[1-9][0-9]*$/,
										message: "Please enter the number of Trophies",
									},
								})}
							/>
							<FormErrorMessage>
								{errors.FormValues?.Trophies &&
									errors.FormValues?.Trophies.message}
							</FormErrorMessage>
						</FormControl>
						<FormControl id="ListName">
							<FormLabel>List Name</FormLabel>
							<Input type="text" {...register("ListName")} />
						</FormControl>
					</Stack>
					<Stack
						spacing="6"
						direction={{
							base: "column",
							md: "row",
						}}>
						<FormControl id="AdditionalDetails">
							<FormLabel>Additional Details</FormLabel>
							<Textarea
								rows="5"
								resize="none"
								type="text"
								{...register("AdditionalDetails")}
							/>
						</FormControl>
					</Stack>
				</Stack>
				<Divider />
			</Box>
		</Box>
	)
}

import "./ManageMemberCards.css";
import {
	Avatar,
	Box,
	Flex,
	HStack,
	Icon,
	SimpleGrid,
	Stack,
	Center,
	Text,
	useColorModeValue,
	useToast,
	useDisclosure,
	Button,
	AlertDialog,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogCloseButton,
	AlertDialogBody,
	AlertDialogOverlay,
	AlertDialogFooter,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	IconButton,
} from "@chakra-ui/react";
import { useEffect, useState, useRef } from "react";
import { FiEdit, FiMoreHorizontal, FiTrash } from "react-icons/fi";
import { IoMailOutline } from "react-icons/io5";
import { useNavigate } from "react-router";

import member1 from "../../../images/member-img1.png";
import member2 from "../../../images/member-img2.png";
import member3 from "../../../images/member-img3.png";
import member4 from "../../../images/member-img4.png";
import member5 from "../../../images/member-img5.png";
import ApiService from "../../../service/Api.service";

export const ManageMemberCards = (props) => {
	//Box Shadow
	const shadow = useColorModeValue("sm", "sm-dark");
	const [showOverlay, setShowOverlay] = useState(false);

	const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();
	const cancelRef = useRef();

	// Toast
	let toast = useToast();

	let member = props.memberData;
	let setMemberReload = props.setMemberReload;
	let memberReload = props.memberReload;
	let setLoading = props.setLoadingDelete;

	let navigate = useNavigate();

	let NavigateToUpdateMembers = () => {
		navigate("/members/updatemember", { state: { userId: member.UserId } });
		console.log("Navigate to UpdateMembers..");
	};
	let handleDelete = async () => {
		try {
			let deleteResponse = await ApiService.httpDelete(`/Members?UserId=${member.UserId}`);
			console.log("Deleted Member:", member.UserId);
			if (deleteResponse.status === true) {
				onAlertClose();
				toast({
					title: "Delete Member",
					description: "Member Deleted Successfully!",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			} else {
				onAlertClose();
				toast({
					title: "Delete Member",
					description: "Failed to Delete Member",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
			setMemberReload(!memberReload);
			console.log("Member Reloaded...");
		} catch (err) {
			console.log(err);
			onAlertClose();
			toast({
				title: "Delete Member",
				description: "Failed to Delete Member",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
		}
	};

	return (
		<>
			<div>
				<Box
					boxShadow={shadow}
					bgColor="white"
					borderRadius="lg"
					pb="4"
					_hover={{ border: "1px solid", borderColor: "blue.500" }}>
					<Stack>
						<Flex justify="end" px="3" pt="1">
							<Menu>
								<MenuButton
									as={IconButton}
									icon={<FiMoreHorizontal />}
									size="sm"
									variant="ghost"
									color="blackAlpha.500"
								/>
								<MenuList minWidth="unset" maxWidth="fit-content">
									<MenuItem onClick={NavigateToUpdateMembers}>Edit</MenuItem>
								</MenuList>
							</Menu>
						</Flex>
						<Center>
							<Avatar src={member1} alt="Carrom Clubs" size="xl" />
						</Center>
						<Text align="center" fontWeight="bold" fontSize="sm">
							{member?.FirstName} {member?.LastName}
						</Text>
						<HStack spacing={1} justify="center">
							<Icon as={IoMailOutline} boxSize={4} color="blackAlpha.500" />
							<Text
								fontWeight="semibold"
								fontSize="xs"
								color="blackAlpha.500"
								overflow={"auto"}>
								{member?.Email}
							</Text>
						</HStack>
					</Stack>
				</Box>
			</div>
			<AlertDialog
				motionPreset="slideInBottom"
				leastDestructiveRef={cancelRef}
				onClose={onAlertClose}
				isOpen={isAlertOpen}
				isCentered>
				<AlertDialogOverlay />

				<AlertDialogContent>
					<AlertDialogHeader>Delete Member</AlertDialogHeader>
					<AlertDialogCloseButton />
					<AlertDialogBody>
						Are you sure you want to delete{" "}
						<Text as={"span"} fontWeight={"bold"}>
							{member?.FirstName} {member?.LastName}
						</Text>
						?
					</AlertDialogBody>
					<AlertDialogFooter>
						<Button ref={cancelRef} onClick={onAlertClose}>
							Cancel
						</Button>
						<Button colorScheme="red" ml={3} onClick={handleDelete}>
							Delete
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
		</>
	);
};
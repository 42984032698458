import React, { useState, useEffect } from "react";
import {
	Table,
	Thead,
	Th,
	Td,
	Tr,
	Tbody,
	Stack,
	HStack,
	Flex,
	Image,
	Box,
	CardHeader,
	Card,
	CardBody,
	Text,
	Icon,
	Heading,
	ScaleFade,
} from "@chakra-ui/react";
import { GiTrophy } from "react-icons/gi";
import clubLogo from "../../../assets/clublogo.png";
import logo from "../../../images/logo.png";
import ApiService from "../../../service/Api.service";
import { useAuthProvider } from "../../../context/AuthProvider";

export const TeamMatchupResults = ({ eventId, groupname, roundNo }) => {
	// User Details
	const user = useAuthProvider();

	const [matchupResults, setMatchupResults] = useState([]);
	const [currentIndex, setCurrentIndex] = useState(0);

	async function fetchMatchups() {
		try {
			let matchupRes = await ApiService.httpGet(
				`/TeamEventTeams?TourEventId=${eventId}&GroupName=${groupname}&RoundNo=${roundNo}&Class=TeamMatchups`,
				user.userId
			);
			if (matchupRes.status === true) {
				setMatchupResults(matchupRes);
			}
		} catch (err) {
			console.log(err);
		}
	}
	useEffect(() => {
		fetchMatchups();
	}, [eventId, groupname, roundNo]);

	useEffect(() => {
		// Function to show the next values
		console.log("second useEffect");
		const showNextValues = () => {
			if (currentIndex < matchupResults.length) {
				setCurrentIndex(currentIndex);
			} else {
				setCurrentIndex(0); // Start from the beginning if reached the end
			}
		};

		// Automatically show next values every 5 seconds
		const timer = setTimeout(showNextValues, 5000);

		// Clear the timer when the component unmounts or when the currentIndex changes
		return () => clearTimeout(timer);
	}, [currentIndex, matchupResults?.length]);

	if (matchupResults?.Matchups?.length > 0) {
		return (
			<ScaleFade initialScale={0.9} in>
				<Card
					bg="white"
					boxShadow={"2xl"}
					minW={"70vw"}
					userSelect={"none"}
					draggable="false">
					<CardHeader py={1}>
						<HStack align="center" justify={"center"} minH={75} minW={500}>
							<Image
								src={clubLogo}
								alt="Club Image"
								blockSize={50}
								objectFit="contain"
								position="absolute"
								left={5}
								style={{ userSelect: "none" }}
								zIndex={1} // Ensure the overlay is above other components
							/>
							<Flex flex={1} alignItems="center" flexDirection="column">
								<Heading
									as={"h2"}
									size="sm"
									fontSize="2xl"
									fontWeight="bold"
									color="#0AB042"
									textAlign="center"
									w={"fit-content"}>
									TEAM MATCHUPS RESULTS
								</Heading>
							</Flex>
							<Image
								src={logo}
								alt="Carrom Platform Logo"
								blockSize={75}
								objectFit="contain"
								position="absolute"
								right={0}
								style={{ userSelect: "none" }}
								zIndex={1} // Ensure the overlay is above other components
							/>
						</HStack>
					</CardHeader>
					<CardBody boxShadow="sm" pt={0}>
						<Table bgColor={"white"} variant="striped" colorScheme="gray">
							<Thead>
								<Tr>
									<Th textColor="#4B39B7" fontSize="sm" textAlign="start">
										S.No
									</Th>
									<Th textColor="#4B39B7" fontSize="sm" textAlign="start">
										Team1
									</Th>
									<Th textColor="#4B39B7" fontSize="sm" textAlign="start">
										Points
									</Th>
									<Th textColor="#4B39B7" fontSize="sm" textAlign="start"></Th>
									<Th textColor="#4B39B7" fontSize="sm" textAlign="start">
										Team2
									</Th>
									<Th textColor="#4B39B7" fontSize="sm" textAlign="start">
										Points
									</Th>
								</Tr>
							</Thead>
							<Tbody bg="white">
								{matchupResults?.Matchups?.slice(0, 10).map((board, i) => (
									<Tr key={i}>
										<Td
											py={0.5}
											textAlign="center"
											fontSize="sm"
											fontWeight="medium"
											textColor="#000000">
											{i + 1}
										</Td>
										<Td
											py={0.5}
											textAlign="center"
											fontSize="sm"
											fontWeight="medium"
											textColor="#000000">
											<HStack justify="space-between">
												<Text>{board.Team1Name}</Text>
												{board.isTeam1won === 1 && <Icon as={GiTrophy} />}
											</HStack>
										</Td>
										<Td
											py={0.5}
											textAlign="center"
											fontSize="sm"
											fontWeight="medium"
											textColor="#000000">
											{board.Team1Points}
										</Td>
										<Td
											py={0.5}
											textAlign="center"
											fontSize="sm"
											fontWeight="medium"
											textColor="#000000">
											VS
										</Td>
										<Td
											py={0.5}
											textAlign="center"
											fontSize="sm"
											fontWeight="medium"
											textColor="#000000">
											<HStack justify="space-between">
												<Text>{board.Team2Name}</Text>
												{board.isTeam2won === 1 && <Icon as={GiTrophy} />}
											</HStack>
										</Td>
										<Td
											py={0.5}
											textAlign="center"
											fontSize="sm"
											fontWeight="medium"
											textColor="#000000">
											{board.Team2Points}
										</Td>
									</Tr>
								))}
							</Tbody>
						</Table>
					</CardBody>
				</Card>
			</ScaleFade>
		);
	} else {
		return null;
	}
};

import {
	Box,
	Stack,
	Text,
	useBreakpointValue,
	useColorModeValue,
} from "@chakra-ui/react";
import { ClubsTable } from "./ClubsTable";
import { TournamentsTable } from "./TournamentsTable";
import { ShimmerTable } from "react-shimmer-effects-18";

export const Table1 = ({ clubsDetails, loading }) => {
	//Table Heading
	const tableHeading = useBreakpointValue({ base: "lg", sm: "xl", md: "2xl" });
	//Box Shadow
	const shadow = useColorModeValue("sm", "sm-dark");

	return (
		<Box py=".7rem">
			<Text mb={2} fontSize={tableHeading} fontWeight="semibold">
				Most Active Clubs
			</Text>
			<Box bg="bg-surface" boxShadow={shadow} borderRadius={"lg"} py="0.5rem">
				{loading ? (
					<ShimmerTable row={4} col={4} />
				) : (
					<ClubsTable clubsDetails={clubsDetails} />
				)}
			</Box>
		</Box>
	);
};

export const Table2 = ({ tourDetails, loading }) => {
	//Table Heading
	const tableHeading = useBreakpointValue({ base: "lg", sm: "xl", md: "2xl" });
	//Box Shadow
	const shadow = useColorModeValue("sm", "sm-dark");

	return (
		<Box py=".7rem">
			<Text mb={2} fontSize={tableHeading} fontWeight="semibold">
				Upcoming Tournaments
			</Text>
			<Box bg="bg-surface" boxShadow={shadow} borderRadius={"lg"} py="0.5rem">
				{loading ? (
					<ShimmerTable row={4} col={4} />
				) : (
					<TournamentsTable tourDetails={tourDetails} />
				)}
			</Box>
		</Box>
	);
};

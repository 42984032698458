import {
	HStack,
	Image,
	Table,
	Tbody,
	Td,
	Text,
	Tr,
	VStack,
	Icon,
	Divider,
	TableContainer,
} from "@chakra-ui/react";
import * as React from "react";
import { data } from "./data";
import ActiveClubImg from "../../../images/club-img1.png";

import { FiMapPin } from "react-icons/fi";
import { IoPersonOutline } from "react-icons/io5";

export const ClubsTable = ({ clubsDetails }) => (
	<TableContainer>
		<Table>
			<Tbody>
				{clubsDetails?.map((club) => (
					<Tr key={club.ClubId}>
						<Td>
							<HStack justify="space-between">
								<HStack spacing="1rem">
									<Image
										src={ActiveClubImg}
										width="3rem"
										bgColor="gray.300"
										borderRadius="lg"
										padding="1"
									/>
									<VStack align="start">
										<Text
											fontSize={["md", "lg"]}
											fontWeight="semibold"
											whiteSpace={"break-spaces"}>
											{club.ClubName}
										</Text>
										<Text
											fontSize={["sm", "md"]}
											fontWeight="semibold"
											color="blackAlpha.500">
											{club.NickName}
										</Text>
									</VStack>
								</HStack>
								<HStack
									spacing={1}
									divider={
										<Divider orientation="vertical" blockSize={"2rem"} />
									}>
									<HStack spacing={1}>
										<Icon as={FiMapPin} />
										<Text fontWeight="semibold" fontSize={["sm", "lg"]}>
											{club.Events}
										</Text>
									</HStack>
									<HStack spacing={1}>
										<Icon as={IoPersonOutline} />
										<Text fontWeight="semibold" fontSize={["sm", "lg"]}>
											{club.PlayerCount}
										</Text>
									</HStack>
								</HStack>
							</HStack>
						</Td>
					</Tr>
				))}
			</Tbody>
		</Table>
	</TableContainer>
);

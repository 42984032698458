import {
	Box,
	Divider,
	Text,
	FormControl,
	FormLabel,
	Input,
	Stack,
	useColorModeValue,
	FormErrorMessage,
	useBreakpointValue,
	Switch,
} from "@chakra-ui/react"
import { useState } from "react"
import PhoneInput from "react-phone-input-2"

export const ClubsBasicDetailsForm = ({
	register,
	errors,
	handleFileChange,
	setValue, getValues
}) => {
	//Form Heading
	const formHeading = useBreakpointValue({
		base: "lg",
		sm: "xl",
	});
	//Box Shadow
	const shadow = useColorModeValue("sm", "sm-dark");

	const handleToggleChange = (isChecked) => {
		// Update the "Streaming" field value based on the isChecked value
		setValue("Streaming", isChecked ? 1 : 0);
	};

	return (
		<Box>
			<Text fontSize={formHeading} mb={2} fontWeight="medium">
				Basic Details
			</Text>
			<Box bg="bg-surface" boxShadow={shadow} borderRadius="lg">
				<Stack
					spacing="5"
					px={{
						base: "4",
						md: "6",
					}}
					py={{
						base: "5",
						md: "6",
					}}>
					<Stack
						spacing="6"
						direction={{
							base: "column",
							md: "row",
						}}>
						<FormControl id="ClubName" isInvalid={errors.ClubName}>
							<FormLabel>Club Name</FormLabel>
							<Input
								type="text"
								{...register("ClubName", {
									required: "Please enter Club Name",
									pattern: {
										value: /^[A-Za-z0-9 ]+$/,
										message:
											"Club Name can be alphanumeric and contain whitespaces",
									},
									minLength: {
										value: 2,
										message: "Club Name can't be this short",
									},
								})}
							/>
							<FormErrorMessage>
								{errors.ClubName && errors.ClubName.message}
							</FormErrorMessage>
						</FormControl>
						<FormControl id="RegisteredName" isInvalid={errors.RegisteredName}>
							<FormLabel>Official Registered Name</FormLabel>
							<Input
								type="text"
								{...register("RegisteredName", {
									required: "Please enter your Registered Name",
									pattern: {
										value: /^[A-Za-z0-9 ]+$/,
										message: "Please enter a valid Name",
									},
								})}
							/>
							<FormErrorMessage>
								{errors.RegisteredName && errors.RegisteredName.message}
							</FormErrorMessage>
						</FormControl>
						<FormControl id="NickName" isInvalid={errors.NickName}>
							<FormLabel>Nick Name</FormLabel>
							<Input
								type="text"
								{...register("NickName", {
									required: "Please enter a Nick Name",
									pattern: {
										value: /^[A-Za-z\s]{1,}[\.]{0,1}[A-Za-z\s]{0,}$/,
										message: "Please enter a valid Nick Name",
									},
								})}
							/>
							<FormErrorMessage>
								{errors.NickName && errors.NickName.message}
							</FormErrorMessage>
						</FormControl>
					</Stack>
					<Stack
						spacing="6"
						direction={{
							base: "column",
							md: "row",
						}}>
						<FormControl id="Email" isInvalid={errors.Email}>
							<FormLabel>Email Address</FormLabel>
							<Input
								type="email"
								{...register("Email", {
									required: "Please enter your email",
									pattern: {
										value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
										message: "Please enter a valid Email",
									},
								})}
							/>
							<FormErrorMessage>
								{errors.Email && errors.Email.message}
							</FormErrorMessage>
						</FormControl>
						<FormControl id="phone" isInvalid={errors.Phone}>
							<FormLabel>Phone Number</FormLabel>
							<Input
								type="tel"
								{...register("Phone", {
									required: "Please enter your Phone Number",
									pattern: {
										value:
											/^(?:\+\d{1,2}\s?)?(?=\d{1,15}$)(?:\d{10}|\d{3}-\d{3}-\d{4}|\(\d{3}\)\s?\d{3}-\d{4})$|^\+91[1-9]\d{9}$|^\+44\d{10}$|^\+971\d{9}$/,
										message: "Please enter a valid Phone Number",
									},
								})}
							/>
							<FormErrorMessage>
								{errors.Phone && errors.Phone.message}
							</FormErrorMessage>
							{/* <PhoneInput
								country={"us"}
								inputProps={{ name: "Phone", ...register("Phone", { required: true }) }}
								placeholder={"false"}
								preferredCountries={["us", "ca", "in", "ae"]}
								preserveOrder={["preferredCountries"]}
								inputStyle={{
									paddingTop: "0",
									paddingBottom: "0",
									height: "2.5rem",
									width: "-webkit-fill-available",
								}}
								autoFormat={false}
								specialLabel={false}
								searchClass="search-class"
								searchPlaceholder="Search"
								searchStyle={{ margin: "0", width: "97%", height: "2rem" }}
								enableSearch
								disableSearchIcon
								countryCodeEditable={false}
							/> */}
						</FormControl>
					</Stack>
					<Stack
						spacing="6"
						direction={{
							base: "column",
							md: "row",
						}}>
						<FormControl id="image" isInvalid={errors.ClubImage}>
							<FormLabel>Club Image</FormLabel>
							<input
								type="file"
								name="clubPhoto"
								accept=".jpg, .jpeg, .jfi, .jpe, .jif, .jfif, .heif, .heic, .png, .svg, .svgz, .raw"
								{...register("ClubImage", {
									onChange: (e) => handleFileChange(e),
									required: "Please select a Photo",
								})}
							/>
							<FormErrorMessage>
								{errors.ClubImage && errors.ClubImage.message}
							</FormErrorMessage>
						</FormControl>
						<FormControl id="streaming" isInvalid={errors.Streaming}>
							<FormLabel>Streaming</FormLabel>
							<Switch
								colorScheme="blue"
								size="lg"
								{...register("Streaming")}
								onChange={(e) => handleToggleChange(e.target.checked)}
							/>
							<FormErrorMessage>
								{errors.Streaming && errors.Streaming.message}
							</FormErrorMessage>
						</FormControl>
					</Stack>
				</Stack>

				<Divider />
			</Box>
		</Box>
	);
};
